import { InlineMultiselect } from '@tribeplatform/react-ui-kit/Multiselect'

import { SpreadFilterItems } from '../../components/SpreadFilterItems.js'

interface InlineMultiselectPickerProps {
  value: string[]
  options: string[]
  labels?: Record<string, string>
  spread?: boolean
  className?: string
  onChange: (memberIds: string[]) => void
}
export const InlineMultiselectPicker = ({
  value,
  options,
  labels,
  spread,
  className,
  onChange,
}: InlineMultiselectPickerProps) => {
  if (spread) {
    return (
      <SpreadFilterItems options={options} value={value} onChange={onChange}>
        {selectedOption => (
          <span title={labels?.[selectedOption] ?? selectedOption}>
            {labels?.[selectedOption] ?? selectedOption}
          </span>
        )}
      </SpreadFilterItems>
    )
  }

  const handleClick = (memberId: string) => {
    if (value.includes(memberId)) {
      const newValue = value.filter(id => id !== memberId)
      onChange(newValue)
      return
    }

    const newValue = [...value, memberId]
    onChange(newValue)
  }

  return (
    <InlineMultiselect className={className}>
      <InlineMultiselect.Items>
        {options.map((option, index) => (
          <InlineMultiselect.Item
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            selected={value.includes(option)}
            onSelect={() => handleClick(option)}
          >
            <div className="truncate text-base">
              {labels?.[option] ?? option}
            </div>
          </InlineMultiselect.Item>
        ))}
      </InlineMultiselect.Items>
    </InlineMultiselect>
  )
}
