import { Space } from '@tribeplatform/gql-client/types'

import { T } from '../../i18n/index.js'
import { SpaceImage } from '../../Space/SpaceImage.js'

type SpaceCoverImageModalTitleProps = {
  space: Space
}
export const SpaceCoverImageModalTitle = ({
  space,
}: SpaceCoverImageModalTitleProps) => {
  if (!space) {
    return null
  }

  return (
    <div className="overflow-hidden pe-3 hidden sm:flex">
      <div className="flex space-s-2 min-w-0 items-center">
        <div className="shrink-0">
          <SpaceImage readOnly space={space} size="lg" />
        </div>

        <p className="text-md">
          <T
            defaultMessage="Cover image for {space_name} {SPACE}"
            id="ModalTitle.SpaceCoverImage"
            values={{
              space_name: (
                <strong className="text-content">{space.name}</strong>
              ),
            }}
          />
        </p>
      </div>
    </div>
  )
}
