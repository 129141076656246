import { T, useI18n } from '@tribeplatform/react-components/i18n'
import { useNavigate } from '@tribeplatform/react-components/Link'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { TopBarButton } from '@tribeplatform/react-ui-kit/TopBar'

interface LoginButtonProps {
  href: string
}
export const LoginButton = ({ href }: LoginButtonProps) => {
  const { $t } = useI18n()
  const navigate = useNavigate()

  return (
    <>
      <TopBarButton
        className="lg:hidden shrink-0"
        aria-label={$t({
          defaultMessage: 'Log in',
          id: 'Generics.LogIn',
        })}
        icon={<SvgIcon name="member" />}
        onClick={() => navigate(href)}
      />
      <Button
        variant="secondaryNeutral"
        className="hidden lg:block !text-label-sm text-content-on-topbar bg-topbar hover:bg-topbar-subdued shrink-0"
        onClick={() => navigate(href)}
      >
        <T defaultMessage="Log in" id="Generics.LogIn" />
      </Button>
    </>
  )
}
