import { RuntimeConfigs } from '../../configs/runtime.js'
import { LogLevel } from '../@types/index.js'
import { ClientLogger } from './client-logger.js'

const isClient = typeof window !== 'undefined'

// eslint-disable-next-line import/no-default-export
const DEFAULT_CLIENT_LOGGER = new ClientLogger({
  level: isClient ? (RuntimeConfigs.CLIENT_LOG_LEVEL as LogLevel) : 'silent',
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const error = (message: any, ...meta: any[]) => {
  DEFAULT_CLIENT_LOGGER.error(message, ...meta)
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const warn = (message: any, ...meta: any[]) => {
  DEFAULT_CLIENT_LOGGER.warn(message, ...meta)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const info = (message: any, ...meta: any[]) => {
  DEFAULT_CLIENT_LOGGER.info(message, ...meta)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const log = (message: any, ...meta: any[]) => {
  DEFAULT_CLIENT_LOGGER.info(message, ...meta)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debug = (message: any, ...meta: any[]) => {
  DEFAULT_CLIENT_LOGGER.debug(message, ...meta)
}
