import { forwardRef, MouseEventHandler } from 'react'

import { clsx } from 'clsx'

import { PinnedInto, Post, PostStatus } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { useAuthMember } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Link } from '@tribeplatform/react-ui-kit/Link'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

import { MemberAvatar } from '../common/components/member/MemberAvatar.js'
import { Time } from '../common/components/Time.js'
import { dayjs } from '../common/lib/dayjs.js'
import { T, useI18n, useStaticIntl } from '../i18n/index.js'
import { MemberBadgeLocation } from '../MemberBadge/MemberBadge.js'
import { MemberBadges } from '../MemberBadge/MemberBadges.js'
import { LegacySingleReaction } from '../Post/Actions/LegacySingleReaction.js'
import { PostHiddenIndicator } from '../Post/PostHiddenIndicator.js'
import { getListFromParticipants } from '../Post/PostInfoBar.js'
import { PostPendingIndicator } from '../Post/PostPendingIndicator.js'
import { PostContext } from '../Post/types.js'
import { ReplyContent } from './ReplyContent.js'
import { ReplyOptions } from './ReplyOptions.js'

interface Props {
  post: Post
  context: PostContext
  reply: Post
  readonly?: boolean
  canReply?: boolean
  unloadedReplies?: number
  isFetching?: boolean
  isHighlighted?: boolean
  children?: React.ReactNode
  editing?: boolean
  onReply?: MouseEventHandler<HTMLAnchorElement>
  onStopEditing?: () => unknown
  onStartEditing?: () => unknown
  onLoadMore?: () => unknown
}

export const GenericReply = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      context,
      reply,
      readonly,
      post,
      canReply,
      unloadedReplies,
      editing,
      isFetching = false,
      isHighlighted = false,
      onReply,
      onStopEditing,
      onStartEditing,
      onLoadMore,
    },
    ref,
  ) => {
    const participantsSummary = useStaticIntl(getListFromParticipants)
    const { $t } = useI18n()
    const { Link: ReactLink } = useRouter()
    const { data: authMember } = useAuthMember()

    const isPinnedReply = reply?.pinnedInto?.includes(PinnedInto.post)
    const member = reply?.owner?.member

    return (
      <div
        ref={ref}
        className={clsx(
          'flex space-s-3 sm:space-s-4',
          (isHighlighted || (context === 'post' && isPinnedReply)) && [
            'rounded-base',
            '-mx-2 px-2 -my-2.5 py-2.5',
            isHighlighted && 'ring ring-offset-0 ring-action-primary',
            context === 'post' &&
              isPinnedReply &&
              'bg-surface-positive-subdued',
          ],
        )}
      >
        <div className="shrink-0">
          <div className="hidden sm:block">
            <MemberAvatar size="3x" member={member} clickable />
          </div>
          <div className="sm:hidden">
            <MemberAvatar size="2.5x" member={member} clickable />
          </div>
        </div>
        <div className="min-w-0 grow max-w-full -mt-1 pt-1">
          <div>
            <div className="flex justify-between gap-2">
              <div className="flex grow min-w-0 max-w-full gap-x-2 gap-y-0.5 flex-wrap">
                <ReactLink
                  variant="inherit"
                  href={member?.relativeUrl}
                  className="font-medium text-content shrink-0 max-w-full focus-visible:z-10"
                  translate="no"
                  truncate
                  title={member?.name}
                >
                  {member?.name}
                </ReactLink>
                <MemberBadges
                  member={member}
                  size="sm"
                  location={MemberBadgeLocation.PostMemberBar}
                />
              </div>
              <div className="shrink-0 flex items-center">
                {reply.status === PostStatus.BLOCKED && (
                  <PostPendingIndicator />
                )}
                {reply.isHidden && <PostHiddenIndicator isReply />}
                {!readonly && (
                  <ReplyOptions
                    onEdit={onStartEditing}
                    reply={reply}
                    post={post}
                  />
                )}
              </div>
            </div>
            <div className="text-sm text-content-subdued flex max-w-full whitespace-nowrap mt-0.5">
              <ReactLink href={reply?.relativeUrl} variant="inherit" truncate>
                {reply?.status === PostStatus.DELETED && (
                  <span>
                    <span className="text-action-destructive inline-flex items-center">
                      <span>
                        <T id="Generics.Deleted" defaultMessage="Deleted" />
                      </span>
                      <SvgIcon className="w-4 h-4 ms-0.5" name="trash" />
                    </span>
                    {/* eslint-disable-next-line react/jsx-no-literals */}
                    <span>&nbsp;·&nbsp;</span>
                  </span>
                )}
                <Time dateTime={reply.createdAt}>
                  {dayjs(reply.createdAt).fromNow()}
                </Time>
                {member?.tagline ? (
                  // No need to translate the dot
                  // eslint-disable-next-line react/jsx-no-literals
                  <span className="truncate"> · {member?.tagline}</span>
                ) : null}
              </ReactLink>
            </div>
          </div>
          <div className="mt-2">
            <ReplyContent
              reply={reply}
              edit={editing}
              onCancel={onStopEditing}
              onEdit={onStopEditing}
              context={
                context === 'post' && isPinnedReply ? 'pinnedReply' : 'reply'
              }
            />
          </div>
          {!readonly && (
            <div className="flex space-s-5 text-sm">
              <Tooltip>
                <Tooltip.Trigger>
                  <LegacySingleReaction
                    Icon={<SvgIcon name="heart" />}
                    post={reply}
                    reaction="+1"
                    className="font-medium"
                    simple
                    text={
                      reply.reactionsCount > 0
                        ? $t(
                            {
                              id: 'Generics.Likes.Plural',
                              defaultMessage:
                                '{likes_count, plural, zero {No likes} one {# like} other {# likes}}',
                            },
                            {
                              likes_count: reply.reactionsCount,
                            },
                          )
                        : $t({
                            id: 'Generics.Like.Verb',
                            defaultMessage: 'Like',
                          })
                    }
                  />
                </Tooltip.Trigger>
                {reply.reactions && (
                  <Tooltip.Panel>
                    {participantsSummary(
                      reply.reactions?.flatMap(
                        reaction => reaction.participants?.nodes || [],
                      ),
                      reply.reactionsCount,
                      authMember,
                    )}
                  </Tooltip.Panel>
                )}
              </Tooltip>
              {canReply && (
                <Link
                  as="button"
                  variant="neutral"
                  onClick={onReply}
                  className="font-medium"
                >
                  <T id="Generics.Reply" defaultMessage="Reply" />
                </Link>
              )}
            </div>
          )}
          {unloadedReplies > 0 ? (
            <div className="flex items-center mt-4 space-s-3">
              <Link
                variant="inherit"
                className="text-sm leading-5 font-medium text-content-subdued flex items-center space-s-1"
                onClick={onLoadMore}
                title={$t(
                  {
                    id: 'Reply.TotalRepliesOf',
                    defaultMessage: 'total replies of {total_replies_count}',
                  },
                  { total_replies_count: reply.totalRepliesCount },
                )}
              >
                <SvgIcon name="reply" inline />
                <span>
                  <T
                    defaultMessage="{replies_count, plural, =1 {# more reply} other {# more replies}}"
                    id="Reply.More.Count"
                    values={{
                      replies_count: unloadedReplies,
                    }}
                  />
                </span>
              </Link>
              {isFetching && (
                <SvgIcon className="animate-spin" name="spinner" />
              )}
            </div>
          ) : null}
          {children}
        </div>
      </div>
    )
  },
)
