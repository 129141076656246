import { lazy } from 'react'

import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { SearchFor, SearchSource, Target } from '../HeroBanner/types.js'
import { NavbarBlock as Component } from './Block.js'
import { sectionsFactory } from './config.js'
import {
  SubNavigationPosition,
  ItemAlignment,
  SubMenuStyle,
  MobileMenuSectionItem,
  type MenuItem,
  type NavbarProps,
} from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.NavbarBlockSettings })),
)

export const genericMenuItem: MenuItem = {
  id: '',
  url: '',
  name: '',
  target: Target.blank,
  subMenuStyle: SubMenuStyle.dropdown,
  menuItems: [],
}

export const RegisteredNavbarBlock = (
  intl: I18nShape,
): RegisteredBlock<NavbarProps> => {
  const { $t } = intl

  return {
    recommendedName: 'Navbar',
    Component,
    config: {
      type: BlockType.Block,
      removable: true,
      usable: true,
      availabilityConditions: {
        header: true,
      },
      fullSidebarSettings: true,

      displayName: $t({
        defaultMessage: 'Top navigation',
        id: 'Blocks.TopNavigation.DisplayName',
      }),
      Settings,
      Icon: <SvgIcon name="layout-top" />,
      initialProps: {
        version: '2',
        sections: sectionsFactory(intl),
        searchSettings: {
          searchFor: SearchFor.all,
          searchSource: SearchSource.allSpaces,
          spaceIds: [],
          placeholder: $t({
            id: 'Generics.SearchDotDotDot',
            defaultMessage: 'Search...',
          }),
        },
        subNavigation: {
          enabled: false,
          position: SubNavigationPosition.top,
          alignment: ItemAlignment.start,
          menuItems: [
            {
              id: 'item1',
              name: 'Item 1',
              url: '',
              target: Target.blank,
              subMenuStyle: SubMenuStyle.dropdown,
              menuItems: [],
            },
            {
              id: 'item2',
              name: 'Item 2',
              url: '',
              target: Target.blank,
              subMenuStyle: SubMenuStyle.dropdown,
              menuItems: [],
            },
          ],
        },
        mobileMenuSections: [
          {
            id: MobileMenuSectionItem.headerMenu,
            enabled: true,
          },
          {
            id: MobileMenuSectionItem.headerSubMenu,
            enabled: true,
          },
          {
            id: MobileMenuSectionItem.sidebar,
            enabled: true,
          },
        ],
      },
    },
  }
}
