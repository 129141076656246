import type { Tag } from '@tribeplatform/gql-client/types'
import { useSpaceTags } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Multiselect } from '@tribeplatform/react-ui-kit/Multiselect'

import { useI18n } from '../i18n/index.js'

interface TagPickerFromIdsProps {
  value: string[]
  options: string[]
  onChange: (tagIds: string[]) => void
  placeholder?: string
  noWrapper?: boolean
  className?: string
}
export const TagPickerFromIds = ({
  value,
  options,
  onChange,
  placeholder,
  noWrapper,
  className,
}: TagPickerFromIdsProps) => {
  const { $t } = useI18n()
  const { data, isInitialLoading } = useSpaceTags({
    variables: { ids: options, limit: options.length },
    useInfiniteQueryOptions: {
      enabled: options.length > 0,
    },
  })
  const { nodes: tagOptions } = simplifyPaginatedResult<Tag>(data)

  const getPlaceHolder = () => {
    if (isInitialLoading || !tagOptions) {
      return $t({
        id: 'Generics.LoadingDotDotDot',
        defaultMessage: 'Loading...',
      })
    }

    return placeholder
  }

  return (
    <Multiselect
      value={value}
      options={options}
      onChange={newValues => onChange(newValues)}
      disabled={isInitialLoading}
      className={className}
    >
      <Multiselect.Button placeholder={getPlaceHolder()}>
        {tagOptions &&
          tagOptions
            .filter(({ id }) => value.includes(id))
            .map((tag, index) => (
              <Multiselect.SelectedItem
                key={tag.id}
                value={tag.id}
                index={index}
              >
                <span className="truncate">{tag.title}</span>
              </Multiselect.SelectedItem>
            ))}
      </Multiselect.Button>
      <Multiselect.Items noWrapper={noWrapper}>
        {tagOptions &&
          tagOptions.map((tag, index) => (
            <Multiselect.Item key={tag.id} value={tag.id} index={index}>
              <span className="truncate">{tag.title}</span>
            </Multiselect.Item>
          ))}
      </Multiselect.Items>
    </Multiselect>
  )
}
