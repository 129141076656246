import { EntityImage } from '@tribeplatform/react-components/common/components'
import { useRouter } from '@tribeplatform/react-sdk'
import { Accordion } from '@tribeplatform/react-ui-kit/Accordion'
import { NavVertical } from '@tribeplatform/react-ui-kit/Sidebar'

import { useSlate } from '../../../../hooks/slate.hook.js'
import { Target } from '../../../HeroBanner/types.js'
import { MenuItem } from '../../types.js'

interface MobileMenuItemProps {
  item: MenuItem
  close: () => void
}
export const MobileMenuItem = ({
  item: { id, name, url, target, menuItems },
  close,
}: MobileMenuItemProps) => {
  const { Link } = useRouter()
  const { mode } = useSlate()
  const isPreviewMode = mode !== 'live'
  const hasSubMenu = menuItems && menuItems.length > 0

  if (hasSubMenu) {
    return (
      <Accordion variant="chevron-down">
        <Accordion.Button>{name}</Accordion.Button>
        <Accordion.Panel noPadding>
          <NavVertical.Group>
            {menuItems.map(({ id, name, target, url, icon }) => (
              <NavVertical.Item
                key={id}
                as={Link}
                href={url}
                target={target === Target.blank ? '_blank' : '_self'}
                leadingAddon={
                  !!icon && (
                    <EntityImage
                      entity={{ image: icon, name: id }}
                      size="lg"
                      className="me-3"
                    />
                  )
                }
                onClick={e => {
                  if (isPreviewMode) {
                    e.preventDefault()
                  }

                  close()
                }}
              >
                {name}
              </NavVertical.Item>
            ))}
          </NavVertical.Group>
        </Accordion.Panel>
      </Accordion>
    )
  }

  return (
    <NavVertical.Item
      key={id}
      as={Link}
      href={url}
      target={target === Target.blank ? '_blank' : '_self'}
      onClick={e => {
        if (isPreviewMode) {
          e.preventDefault()
        }

        close()
      }}
    >
      {name}
    </NavVertical.Item>
  )
}
