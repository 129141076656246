import { useCallback } from 'react'

import { Button } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { IndicatorBadge } from '@tribeplatform/react-ui-kit/IndicatorBadge'
import { Scrollable } from '@tribeplatform/react-ui-kit/Scrollable'

import { T } from '../../i18n/index.js'
import { useModal } from '../../Modal/Provider.js'
import { type Filter, FilterAppearances } from '../types.js'
import { isValueNotEmpty } from '../utils/values.js'
import { InlineFilterItem } from './InlineFilterItem.js'
import { InlineFiltersModal } from './InlineFiltersModal.js'

interface InlineFiltersProps {
  filters: Filter[]
  appearance: FilterAppearances
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>
}
export const InlineFilters = ({
  filters,
  appearance = FilterAppearances.INLINE,
  setFilters,
}: InlineFiltersProps) => {
  const { openModal } = useModal()
  const activeFilterCount = filters.filter(({ value }) =>
    isValueNotEmpty(value),
  ).length

  const handleSetFilter = useCallback(
    (newFilter: Filter) => {
      const newFilters = filters.map(filter => {
        if (filter.id === newFilter.id) {
          return newFilter
        }

        return filter
      })

      setFilters(newFilters)
    },
    [filters, setFilters],
  )

  const handleRemoveFilter = useCallback(
    (filterId: string) => {
      const newFilters = filters.map(filter => {
        if (filter.id === filterId) {
          const newFilter: Filter = {
            ...filter,
            value: undefined,
          }

          return newFilter
        }

        return filter
      })

      setFilters(newFilters)
    },
    [filters, setFilters],
  )

  if (appearance === FilterAppearances.MODAL) {
    return (
      <div className="flex justify-end py-1">
        <Button
          variant="tertiaryNeutral"
          leadingIcon={<SvgIcon name="account-setting" />}
          className="bg-surface"
          onClick={() => {
            openModal({
              name: 'inlineFiltersModal',
              component: InlineFiltersModal,
              props: {
                filters,
                onSave: setFilters,
              },
            })
          }}
          {...(activeFilterCount !== 0
            ? {
                trailingIcon: (
                  <IndicatorBadge variant="primary" count={activeFilterCount} />
                ),
              }
            : {})}
        >
          <T defaultMessage="Filters" id="Generics.Filters" />
        </Button>
      </div>
    )
  }

  return (
    <Scrollable className="mx-2 sm:mx-0">
      <div className="flex space-x-2 p-1">
        {filters.map(filter => (
          <InlineFilterItem
            key={filter.id}
            filter={filter}
            setFilter={handleSetFilter}
            removeFilter={() => handleRemoveFilter(filter.id)}
            spread={filters.length === 1}
            allowOperatorSelection={
              !!filter?.extraProps?.allowOperatorSelection
            }
            inlineActivator
          />
        ))}
      </div>
    </Scrollable>
  )
}
