import { useAuthMember } from '@tribeplatform/react-sdk/hooks'
import { LoadingOverlay } from '@tribeplatform/react-ui-kit/LoadingOverlay'

import { useLatch } from '../../PostsBlock/hooks/useLatch.js'
import { useSpacesList } from '../hooks/useSpacesList.js'
import type { SpacesListBlockView } from '../types.js'
import { EmptyState } from './EmptyState.js'
import { GenericSpacesList } from './GenericSpacesList.js'
import { InvalidSettingsState } from './InvalidSettingsState.js'
import { LoadingState } from './LoadingState.js'

interface GenericSpacesBlockProps {
  view: SpacesListBlockView
  preview: boolean
  activeFieldId: string
}
export const GenericSpacesBlock = ({
  view: almostView,
  preview,
  activeFieldId,
}: GenericSpacesBlockProps) => {
  const spaceListContext = useSpacesList(almostView)
  const { spaces, showMoreButton, mergedStaticFields, view } = useLatch({
    value: spaceListContext,
    trigger: !spaceListContext.isLoading,
    enabled: spaceListContext.isLoading && spaceListContext.spaces.length === 0,
  })
  const { style, limit, gallerySize, showDivider, filterBy, spaceSlugs } = view
  const { data: isAdmin } = useAuthMember()

  if (spaceListContext.isInvalid) {
    return <>{isAdmin ? <InvalidSettingsState /> : <EmptyState />}</>
  }

  return (
    <LoadingOverlay isLoading={spaceListContext.isLoading}>
      {spaceListContext.isLoading && spaces.length === 0 ? (
        <LoadingState
          count={filterBy === 'spaceSlugs' ? spaceSlugs?.length ?? 1 : limit}
          style={style}
          gallerySize={gallerySize}
          showDivider={showDivider}
        />
      ) : (
        <GenericSpacesList
          style={style}
          spaces={spaces}
          fields={mergedStaticFields}
          gallerySize={gallerySize}
          showDivider={showDivider}
          activeFieldId={activeFieldId}
          showMoreButton={showMoreButton}
          preview={preview}
        />
      )}
    </LoadingOverlay>
  )
}
