import { useMemo } from 'react'

import { type Member } from '@tribeplatform/gql-client/types'
import { useMembers, useNetworkRoles } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import type { MemberPickerProps } from '../../../EntityPicker/MemberPicker.js'
import { T } from '../../../i18n/index.js'
import { SpreadFilterItems } from '../../components/SpreadFilterItems.js'
import { INFINITE_SPREAD_PAGE_SIZE } from '../../utils/inlineFilters.js'

interface InfiniteSpreadMemberPickerProps {
  value: string[]
  memberGroup?: MemberPickerProps['memberGroup']
  onChange: (tagIds: string[]) => void
}
export const InfiniteSpreadMemberPicker = ({
  value,
  memberGroup,
  onChange,
}: InfiniteSpreadMemberPickerProps) => {
  const networkRoles = useNetworkRoles()

  const roleIds = useMemo(() => {
    switch (memberGroup) {
      case 'member':
        return [networkRoles.member.id]
      case 'staff':
        return [networkRoles.admin.id, networkRoles.moderator.id]
      case 'all':
      default: {
        return undefined
      }
    }
  }, [
    memberGroup,
    networkRoles.admin,
    networkRoles.member,
    networkRoles.moderator,
  ])

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useMembers({
    variables: {
      limit: INFINITE_SPREAD_PAGE_SIZE,
      roleIds,
    },
  })

  const { nodes: members } = simplifyPaginatedResult<Member>(data)

  return (
    <>
      <SpreadFilterItems
        options={members.map(({ id }) => id)}
        value={value}
        onChange={onChange}
        isLoading={!data}
      >
        {selectedOption => (
          <span
            title={members.find(({ id }) => id === selectedOption)?.name ?? ''}
          >
            {members.find(({ id }) => id === selectedOption)?.name ?? ''}
          </span>
        )}
      </SpreadFilterItems>
      {hasNextPage && (
        <Button
          variant="tertiaryNeutral"
          onClick={() => fetchNextPage()}
          className="flex-shrink-0"
          trailingIcon={
            isFetchingNextPage ? (
              <SvgIcon className="animate-spin" size="lg" name="spinner" />
            ) : null
          }
        >
          <T defaultMessage="Show more" id="Generics.ShowMore" />
        </Button>
      )}
    </>
  )
}
