import type { Tag } from '@tribeplatform/gql-client/types'
import { useSpaceTags } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'

import { SpreadFilterItems } from '../../components/SpreadFilterItems.js'

interface SpreadTagPickerProps {
  value: string[]
  options: string[]
  onChange: (tagIds: string[]) => void
}
export const SpreadTagPicker = ({
  value,
  options,
  onChange,
}: SpreadTagPickerProps) => {
  const { data } = useSpaceTags({
    variables: { ids: options, limit: options.length },
    useInfiniteQueryOptions: {
      enabled: options.length > 0,
    },
  })
  const { nodes: tagOptions } = simplifyPaginatedResult<Tag>(data)

  return (
    <SpreadFilterItems
      options={tagOptions.map(({ id }) => id)}
      value={value}
      onChange={onChange}
      isLoading={!data}
    >
      {selectedOption => (
        <span
          title={
            tagOptions.find(({ id }) => id === selectedOption)?.title ?? ''
          }
        >
          {tagOptions.find(({ id }) => id === selectedOption)?.title ?? ''}
        </span>
      )}
    </SpreadFilterItems>
  )
}
