import {
  CustomFieldType,
  PostListOrderByEnum,
  ShortContentFormat,
} from '@tribeplatform/gql-client/types'
import { FilterAppearances } from '@tribeplatform/react-components/Filters'
import type { I18nShape } from '@tribeplatform/react-components/i18n'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { StaticFieldTypes } from '@tribeplatform/react-components/Views'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { getAssetPath } from '../../../configs/static.js'
import {
  PostListSource,
  PostTypeSource,
  PostsBlockProps,
  PostsBlockView,
  SortDirections,
} from './types.js'

export const genericViewFactory: (intl: I18nShape) => PostsBlockView = ({
  $t,
}) => ({
  id: '',
  name: $t({
    defaultMessage: 'Default',
    id: 'Generics.Default',
  }),
  filterBy: [],
  inlineFilters: [],
  filterAppearance: FilterAppearances.INLINE,
  allowInlineFilters: false,
  sort: PostListOrderByEnum.publishedAt,
  reverseSort: true,
  sortDirection: SortDirections.DESC,
  style: 'list',
  gallerySize: 'medium',
  showDivider: true,
  limit: 10,
  fields: [],
  linkTo: '',
  showMore: false,
  source: PostListSource.currentSpace,
  postTypeSource: PostTypeSource.allPostTypes,
})

export const staticFieldsFactory: (intl: I18nShape) => StaticField[] = ({
  $t,
}) => [
  {
    id: 'postInfoBar',
    label: $t({
      defaultMessage: 'Post info bar',
      id: 'PostsBlocks.FieldLabels.PostInfoBar',
    }),
    enabled: false,
    locked: false,
    styles: ['card'],
    settings: { showBackToSpaceButton: true },
    isStatic: true,
    icon: <SvgIcon name="info" />,
  },
  {
    id: 'cardMemberInfo',
    label: $t({
      defaultMessage: '{MEMBER_CC} info',
      id: 'PostsBlocks.FieldLabels.MemberInfo',
    }),
    enabled: false,
    locked: false,
    styles: ['card'],
    settings: {
      fullWidth: true,
      showSpaceName: true,
      showPostOptions: false,
    },
    isStatic: true,
    icon: <SvgIcon name="identification" />,
  },
  {
    id: 'galleryBanner',
    label: $t({
      defaultMessage: 'Post banner',
      id: 'PostsBlocks.FieldLabels.PostBanner',
    }),
    enabled: true,
    locked: false,
    styles: ['gallery', 'carousel'],
    type: StaticFieldTypes.banner,
    settings: {
      bannerStyle: 'banner',
      bannerSource: 'post',
      aspectRatio: 'aspect-video',
    },
    isStatic: true,
    icon: <SvgIcon name="image" />,
    default: getAssetPath('images/banners/banner-placeholder.jpg'),
  },
  {
    id: 'cardBanner',
    label: $t({
      defaultMessage: 'Post banner',
      id: 'PostsBlocks.FieldLabels.PostBanner',
    }),
    enabled: false,
    locked: true,
    styles: ['card'],
    type: StaticFieldTypes.banner,
    settings: {
      bannerStyle: 'banner',
      bannerSource: 'post',
      aspectRatio: 'aspect-[4/1]',
    },
    isStatic: true,
    icon: <SvgIcon name="image" />,
    default: getAssetPath('images/banners/banner-placeholder.jpg'),
  },
  {
    id: 'listAvatar',
    label: $t({
      defaultMessage: '{MEMBER_CC} avatar',
      id: 'PostsBlocks.FieldLabels.MemberAvatar',
    }),
    enabled: true,
    locked: false,
    styles: ['list'],
    type: StaticFieldTypes.avatar,
    settings: {
      avatarSize: 'lg',
      bannerSource: 'member',
    },
    isStatic: true,
    icon: <SvgIcon name="member" />,
  },
  {
    id: 'listPostSummary',
    label: $t({
      defaultMessage: 'Post summary',
      id: 'PostsBlocks.FieldLabels.PostSummary',
    }),
    enabled: true,
    locked: false,
    styles: ['list'],
    settings: {
      stretchX: true,
    },
    isStatic: true,
    icon: <SvgIcon name="posts" />,
    fields: [
      {
        id: 'listPostTitle',
        label: $t({
          defaultMessage: 'Post title',
          id: 'PostsBlocks.FieldLabels.PostTitle',
        }),
        enabled: true,
        locked: false,
        type: StaticFieldTypes.title,
        settings: {
          titleSize: 'md',
        },
        isStatic: true,
        icon: <SvgIcon name="field" />,
        default: 'Lorem sunt excepteur aliquip',
      },
      {
        id: 'listPostContent',
        label: $t({
          defaultMessage: 'Post content',
          id: 'PostsBlocks.FieldLabels.PostContent',
        }),
        enabled: true,
        locked: false,
        settings: {
          contentFormat: ShortContentFormat.Text,
        },
        isStatic: true,
        icon: <SvgIcon name="field-text" />,
        default:
          'Commodo magna esse ea in in. Minim occaecat aute veniam pariatur laboris veniam.',
      },
      {
        id: 'listTags',
        label: $t({
          defaultMessage: '{TAGS_CC}',
          id: 'Generics.Tags',
        }),
        enabled: false,
        locked: false,
        settings: {},
        isStatic: true,
        icon: <SvgIcon name="tag" />,
      },
    ],
  },
  {
    id: 'galleryPostTitle',
    label: $t({
      defaultMessage: 'Post title',
      id: 'PostsBlocks.FieldLabels.PostTitle',
    }),
    enabled: true,
    locked: false,
    styles: ['gallery', 'carousel', 'card'],
    type: StaticFieldTypes.title,
    settings: {
      fullWidth: true,
      titleSize: 'md',
    },
    isStatic: true,
    icon: <SvgIcon name="field" />,
    default: 'Lorem sunt excepteur aliquip',
  },
  {
    id: 'galleryPostContent',
    label: $t({
      defaultMessage: 'Post content',
      id: 'PostsBlocks.FieldLabels.PostContent',
    }),
    enabled: true,
    locked: false,
    styles: ['gallery', 'carousel'],
    settings: {
      fullWidth: true,
      contentFormat: ShortContentFormat.Text,
    },
    isStatic: true,
    icon: <SvgIcon name="field-text" />,
    default:
      'Commodo magna esse ea in in. Minim occaecat aute veniam pariatur laboris veniam.',
  },
  {
    id: 'cardPostContent',
    label: $t({
      defaultMessage: 'Post content',
      id: 'PostsBlocks.FieldLabels.PostContent',
    }),
    enabled: true,
    locked: false,
    styles: ['card'],
    settings: {
      fullWidth: true,
      displayStyle: 'expandable',
      truncateSize: 'md',
    },
    isStatic: true,
    icon: <SvgIcon name="field-text" />,
    default:
      '<p>Commodo <b>magna esse</b> ea in in. Minim <i>occaecat</i> aute <a>veniam pariatur</a> laboris veniam.</p>',
  },
  {
    id: 'tags',
    label: $t({
      defaultMessage: '{TAGS_CC}',
      id: 'Generics.Tags',
    }),
    enabled: false,
    locked: false,
    settings: { fullWidth: true },
    isStatic: true,
    icon: <SvgIcon name="tag" />,
  },
  {
    id: 'reactButton',
    label: $t({
      defaultMessage: 'React button',
      id: 'PostsBlocks.FieldLabels.ReactButton',
    }),
    enabled: false,
    locked: false,
    type: StaticFieldTypes.button,
    styles: ['carousel', 'gallery', 'list'],
    settings: { buttonVariant: 'iconText' },
    isStatic: true,
    icon: <SvgIcon name="heart" />,
  },
  {
    id: 'reactionCount',
    label: $t({
      defaultMessage: 'Reaction count',
      id: 'PostsBlocks.FieldLabels.ReactionCount',
    }),
    enabled: false,
    locked: false,
    settings: {},
    isStatic: true,
    styles: ['carousel', 'gallery', 'list'],
    icon: <SvgIcon name="heart" />,
  },
  {
    id: 'cardPostInfoBar',
    label: $t({
      defaultMessage: 'Post activity bar',
      id: 'PostsBlocks.FieldLabels.PostActivityBar',
    }),
    enabled: false,
    locked: false,
    settings: {
      showReactionCount: true,
      showReplyCount: true,
      showFollowCount: true,
      fullWidth: true,
    },
    isStatic: true,
    styles: ['card'],
    icon: <SvgIcon name="info" />,
  },
  {
    id: 'replyCount',
    label: $t({
      defaultMessage: 'Reply count',
      id: 'PostsBlocks.FieldLabels.ReplyCount',
    }),
    enabled: false,
    locked: false,
    settings: {},
    isStatic: true,
    styles: ['carousel', 'gallery', 'list'],
    icon: <SvgIcon name="reply" />,
  },
  {
    id: 'publishDate',
    label: $t({
      defaultMessage: 'Publish date',
      id: 'Generics.PublishDate',
    }),
    enabled: false,
    locked: false,
    type: CustomFieldType.date,
    isStatic: true,
    icon: <SvgIcon name="calendar" />,
    settings: {
      customFieldSettings: [
        {
          key: 'view',
          value: $t({
            defaultMessage: '"Date"',
            id: 'Generics.PublishDate.Value',
          }),
        },
      ],
    },
  },
  {
    id: 'galleryMemberInfo',
    label: $t({
      defaultMessage: '{MEMBER_CC} info',
      id: 'PostsBlocks.FieldLabels.MemberInfo',
    }),
    enabled: false,
    locked: false,
    styles: ['gallery', 'carousel'],
    settings: {
      fullWidth: true,
      showSpaceName: true,
    },
    isStatic: true,
    icon: <SvgIcon name="identification" />,
  },
  {
    id: 'actionBar',
    label: $t({
      defaultMessage: 'Action bar',
      id: 'PostsBlocks.FieldLabels.ActionBar',
    }),
    enabled: true,
    locked: false,
    styles: ['card'],
    settings: { buttonVariant: 'iconText', fullWidth: true },
    isStatic: true,
    icon: <SvgIcon name="share" />,
  },
  {
    id: 'replyList',
    label: $t({
      defaultMessage: 'Comment box',
      id: 'PostsBlocks.FieldLabels.CommentBox',
    }),
    enabled: false,
    locked: true,
    styles: ['card'],
    settings: { fullWidth: true },
    isStatic: true,
    icon: <SvgIcon name="reply" />,
  },
  {
    id: 'customFields',
    label: $t({
      defaultMessage: 'Custom fields',
      id: 'PostsBlocks.FieldLabels.PostTypeFields',
    }),
    enabled: false,
    locked: false,
    styles: ['gallery', 'carousel', 'card'],
    type: StaticFieldTypes.custom,
    settings: { fullWidth: true },
    isStatic: true,
    icon: <SvgIcon name="collection" />,
  },
]

export const PostsConfig: (intl: I18nShape) => {
  defaultProps: PostsBlockProps
  displayName: string
  removable: boolean
  Icon: JSX.Element
} = ({ $t }) => ({
  defaultProps: {
    views: [],
    title: '',
    description: '',
    linkText: '',
    linkUrl: '',
  },
  displayName: $t({ defaultMessage: 'Posts', id: 'Generics.Posts' }),
  removable: true,
  Icon: <SvgIcon name="posts" />,
})
