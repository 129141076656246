import { Container } from '@tribeplatform/react-ui-kit/Layout'

import { type SubNavigation } from '../types.js'
import { SubNavMenuRenderer } from './SubNavMenuRenderer.js'

interface SubNavbarProps {
  menuItems: SubNavigation['menuItems']
  alignment: SubNavigation['alignment']
}
export const SubNavbar = ({ menuItems, alignment }: SubNavbarProps) => {
  return (
    <div className="hidden lg:block py-1 bg-topbar-subdued opacity-80">
      <Container
        size="xl"
        containerProps={{
          className: '@container mx-auto',
        }}
      >
        <div className="screen-padding-x-xs">
          <SubNavMenuRenderer menuItems={menuItems} alignment={alignment} />
        </div>
      </Container>
    </div>
  )
}
