import { lazy } from 'react'

import type { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { ContainerBlock as Component } from './Block.js'
import { ContainerDeprecatedProps } from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.ContainerBlockSettings })),
)

export const RegisteredContainerBlock = ({
  $t,
}: I18nShape): RegisteredBlock<ContainerDeprecatedProps> => ({
  recommendedName: 'Container',
  Component,
  config: {
    type: BlockType.Layout,
    usable: true,
    deprecated: true,
    displayName: $t({
      id: 'Blocks.Container.DisplayName',
      defaultMessage: 'Container',
    }),
    Settings,
    Icon: <SvgIcon name="columns-01" />,
    acceptsChildren: true,
    defaultProps: {
      direction: 'vertical',
      spacing: 'md',
      padding: 'none',
      size: 'xl',
    },
    getContextAwareConfig: ({ props }) => ({
      description: `${props.size}, ${props.direction}`,
    }),
  },
  meta: {
    deprecationMessage: $t({
      id: 'Blocks.Container.DeprecationMessage',
      defaultMessage: 'Try the new ‘Section’ block for improved layout options',
    }),
  },
})

const RegisteredDeprecatedLayouts = (
  intl: I18nShape,
): RegisteredBlock<ContainerDeprecatedProps> => ({
  ...RegisteredContainerBlock(intl),
  config: {
    ...RegisteredContainerBlock(intl).config,
    usable: false,
    editable: true,
  },
})

export const RegisteredListBlock = (
  intl: I18nShape,
): RegisteredBlock<ContainerDeprecatedProps> => ({
  ...RegisteredDeprecatedLayouts(intl),
  recommendedName: 'List',
})

export const RegisteredColumnsBlock = (
  intl: I18nShape,
): RegisteredBlock<ContainerDeprecatedProps> => ({
  ...RegisteredDeprecatedLayouts(intl),
  recommendedName: 'Columns',
  config: {
    ...RegisteredDeprecatedLayouts(intl).config,
    editable: false,
    lockedChildren: true,
  },
})
