import { CustomFieldType } from '@tribeplatform/gql-client/types'

import { logger } from '../../common/lib/logger.js'
import { CustomFieldSubtype } from '../../CustomField/utils.js'
import { ArrayOperatorPicker } from '../operatorPickers/ArrayOperatorPicker.js'
import { BooleanOperatorPicker } from '../operatorPickers/BooleanOperatorPicker.js'
import { DateOperatorPicker } from '../operatorPickers/DateOperatorPicker.js'
import { NumberOperatorPicker } from '../operatorPickers/NumberOperatorPicker.js'
import { RelationOperatorPicker } from '../operatorPickers/RelationOperatorPicker.js'
import { SelectOperatorPicker } from '../operatorPickers/SelectOperatorPicker.js'
import { TextOperatorPicker } from '../operatorPickers/TextOperatorPicker.js'
import type { Filter, ListFilterByOperator } from '../types.js'

interface OperatorPickerProps {
  filter: Filter
  onChange: (operator: ListFilterByOperator) => void
}
export const OperatorPicker = ({ filter, onChange }: OperatorPickerProps) => {
  const OperatorPickerComponent = filter?.customOperatorPickerComponent
  if (OperatorPickerComponent) {
    return <OperatorPickerComponent filter={filter} onChange={onChange} />
  }

  const { type, subType } = filter
  switch (type) {
    case CustomFieldType.array:
      return <ArrayOperatorPicker filter={filter} onChange={onChange} />
    case CustomFieldType.boolean:
      return <BooleanOperatorPicker filter={filter} onChange={onChange} />
    case CustomFieldType.date:
      return <DateOperatorPicker filter={filter} onChange={onChange} />
    case CustomFieldType.number:
      return <NumberOperatorPicker filter={filter} onChange={onChange} />
    case CustomFieldType.relation:
      return <RelationOperatorPicker filter={filter} onChange={onChange} />
    case CustomFieldType.text:
      if (subType === CustomFieldSubtype.SELECT) {
        return <SelectOperatorPicker filter={filter} onChange={onChange} />
      }
      return <TextOperatorPicker filter={filter} onChange={onChange} />
    case CustomFieldType.object:
    case CustomFieldType.richText:
      return null
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = type
      logger.warn('Not supported field type: ', exhaustiveCheck)
      return null
    }
  }
}
