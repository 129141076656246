import { NetworkVisibility } from '@tribeplatform/gql-client/types'
import { useAuthMember, useAuthToken } from '@tribeplatform/react-sdk/hooks'

import { HamburgerMenu } from '../mobileMenu/HamburgerMenu.js'
import { useSearchBar } from '../providers/SearchBarProvider.js'
import { SearchOnlyLayout } from '../Sections/SearchOnlyLayout.js'
import { type NavbarProps } from '../types.js'
import { checkIfTrueCenter, extractSections } from '../utils/utils.js'
import { SectionRenderer } from './SectionRenderer.js'

interface NavbarWrapperProps {
  sections: NavbarProps['sections']
  mobileMenuSections: NavbarProps['mobileMenuSections']
  subNavigation: NavbarProps['subNavigation']
}
export const NavbarWrapper = ({
  sections,
  mobileMenuSections,
  subNavigation,
}: NavbarWrapperProps) => {
  const {
    data: { networkPublicInfo },
  } = useAuthToken()
  const { isGuest } = useAuthMember()
  const isNetworkPrivate =
    networkPublicInfo?.visibility === NetworkVisibility.private
  const {
    searchOpen,
    settings: { searchFor, placeholder, searchSource, spaceIds },
  } = useSearchBar()
  const { menu: menuSection, actions: actionsSection } =
    extractSections(sections)
  const isLogoTrueCenter = checkIfTrueCenter(sections)

  if (searchOpen && (!isGuest || !isNetworkPrivate)) {
    return (
      <SearchOnlyLayout
        searchFor={searchFor}
        placeholder={placeholder}
        searchSource={searchSource}
        spaceIds={spaceIds}
      />
    )
  }

  return (
    <div className="flex h-16 items-center gap-x-2 sm:gap-x-8">
      <HamburgerMenu
        mobileMenuSections={mobileMenuSections}
        headerMenu={menuSection}
        actionsSection={actionsSection}
        headerSubmenu={subNavigation}
        fixedWidth={isLogoTrueCenter}
      />
      {sections
        .filter(({ enabled }) => !!enabled)
        .map(section => (
          <SectionRenderer
            key={section.id}
            section={section}
            logoTrueCenter={isLogoTrueCenter}
          />
        ))}
    </div>
  )
}
