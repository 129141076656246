import { useI18n } from '@tribeplatform/react-components/i18n'
import { useBlock } from '@tribeplatform/react-slate-kit/hooks'
import { FormControl } from '@tribeplatform/react-ui-kit/FormControl'
import { Select } from '@tribeplatform/react-ui-kit/Select'

import { SpaceHeaderProps } from './types.js'

const views = [
  { value: 'card', text: 'Card' },
  { value: 'simple', text: 'Simple' },
]

export const SpaceHeaderSettings = () => {
  const { $t } = useI18n()
  const {
    block: {
      props: { view },
    },
    upsertProp,
  } = useBlock<SpaceHeaderProps>()

  return (
    <div className="space-y-5">
      <FormControl.Select
        name="view"
        value={view}
        onChange={(value: SpaceHeaderProps['view']) => {
          upsertProp('view', value)
          return value
        }}
        label={$t({
          defaultMessage: 'Default view style',
          id: 'Space.Settings.DefaultViewStyle',
        })}
      >
        <Select.Button>{views.find(v => v.value === view).text}</Select.Button>
        <Select.Items>
          {views.map(view => (
            <Select.Item key={view.value} value={view.value}>
              {view.text}
            </Select.Item>
          ))}
        </Select.Items>
      </FormControl.Select>
    </div>
  )
}
