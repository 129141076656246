import { FC, ReactElement, ReactNode } from 'react'

import { SpaceType } from '@tribeplatform/gql-client/types'
import {
  AdditionalContainerProps,
  ContainerSize,
} from '@tribeplatform/react-ui-kit/Layout'

import { SlateContextProps } from './context.types.js'
import { ObjectQuery } from './query.types.js'

export enum BlockType {
  Block = 'BLOCK',
  BuildingBlock = 'BUILDING_BLOCK',
  Layout = 'LAYOUT',
}

export type RegisteredBlockProps<T extends UnknownProps> = T & {
  // These props are added by the slate kit
  className?: string
  'data-block-id'?: string
  'data-block-name'?: string
  /**
   * `direct` is a property for **Login** block extracted and becomes `true` when
   * accessing **\/auth/login/direct**, it is `undefined` otherwise.
   */
  direct?: true
  /**
   * `invitationLinkId` is a route parameter from **\/auth/join/:id** used by SignUp block
   */
  invitationLinkId?: string
}

export type BlockSize = ContainerSize

export type UnknownProps = Record<string, unknown>

export type BC<P extends UnknownProps> = FC<RegisteredBlockProps<P>>
export type BSC<P extends UnknownProps> = FC<
  Omit<Omit<P, 'pushSettings'>, 'popSettings'> & {
    pushSettings: <K extends UnknownProps>(
      title: string,
      Component: BSC<K>,
      props?: Partial<K>,
    ) => void
    popSettings: () => void
    deactivateBlock: () => void
  }
>

export type LayoutBlockProps = UnknownProps & {
  containerProps?: AdditionalContainerProps
  /**
   * Layout blocks cannot be wrapped with an extra div
   * because the children, for example container inside container,
   * assumes to be direct children of the parent container and
   * sets it's layout accordingly.
   * So we pass the highlight ring and add block buttons component
   * as a prop to the layout block.
   */
  HighlightWrapper?: ReactNode
}
export type LBC<P extends LayoutBlockProps> = BC<P>

export type UnknownBlockComponent = BC<UnknownProps>

export type BlockMeta = {
  releaseDate?: string
  releaseMessage?: string
  deprecationMessage?: string
}

export type BlockDynamicConfig = {
  hide?: boolean
  displayName?: string
  selectedState?: string
}

export type BlockContextAwareConfig = {
  displayName?: string
  description?: string
}

export type BlockAvailabilityConditions = {
  size: BlockSize
  spaceType: SpaceType
  header: boolean
}

export type BlockConfig<P extends UnknownProps> = BlockContextAwareConfig &
  BlockDynamicConfig & {
    type: BlockType
    usable?: boolean
    availabilityConditions?: ObjectQuery<BlockAvailabilityConditions>
    deprecated?: boolean
    removable?: boolean
    editable?: boolean
    copyDisabled?: boolean
    acceptsChildren?: boolean
    lockedChildren?: boolean
    states?: string[]

    Settings?: BSC<UnknownProps>
    fullSidebarSettings?: boolean
    Icon?: ReactElement
    defaultProps?: Partial<P> | undefined
    initialProps?: P
  }

export type RegisteredBlockConfig<P extends UnknownProps> = BlockConfig<P> & {
  getContextAwareConfig?: (options: {
    context: SlateContextProps
    props?: P
    compiledProps?: P
  }) => Partial<BlockContextAwareConfig>
}

export type RegisteredBlock<P extends UnknownProps> = {
  recommendedName: string
  Component: BC<P>
  meta?: BlockMeta
  config: RegisteredBlockConfig<P>
}
export type RegisteredBlockWithName<P extends UnknownProps> =
  RegisteredBlock<P> & {
    name: string
  }

export type RegisteredBlocks = Record<
  string,
  RegisteredBlock<Record<string, unknown>>
>
export type RegisteredBlocksWithNames = Record<
  string,
  RegisteredBlockWithName<Record<string, unknown>>
>

export type BlockWrapperComponent = FC<{
  Block: UnknownBlockComponent
  childrenBlocks: ReactElement[]
  children: ReactElement | string
  onAddBlock?: (position: AddBlockPosition) => void
}>

export type CompiledBlock<P extends UnknownProps> = {
  id: string
  parentId: string
  name: string
  Component: BC<P>
  props: P
  extraProps: BlockDynamicConfig
  compiledProps: P
  config: BlockConfig<P>
  output: UnknownProps
  children: string[]
}

export type UnknownCompiledBlock = CompiledBlock<UnknownProps>

export type BlockQuery<P extends UnknownProps> = ObjectQuery<
  Omit<BlockConfig<P>, 'availabilityConditions'>
>

export enum AddBlockPosition {
  Before = 'before',
  After = 'after',
}
