import { clsx } from 'clsx'

import type { Media } from '@tribeplatform/gql-client/types'
import { EntityImage } from '@tribeplatform/react-components/common/components'
import { HtmlContent } from '@tribeplatform/react-components/HtmlContent'

import {
  ActionType,
  ButtonAction,
  SearchAction,
  type TextAlignment,
  type TextColor,
} from '../types.js'
import { getAlignmentClass, getTextColorClass } from '../utils/utils.js'
import { ButtonActionsRenderer } from './ButtonActionsRenderer.js'
import { SearchActionRenderer } from './SearchActionRenderer.js'

interface ContentRendererProps {
  actionType: ActionType
  buttonActions: ButtonAction[]
  searchAction: SearchAction
  textColor: TextColor
  textAlignment: TextAlignment
  icon?: Media
  eyebrow?: string
  heading?: string
  description?: string
  className?: string
}
export const ContentRenderer = ({
  actionType,
  buttonActions,
  searchAction,
  textColor,
  textAlignment,
  icon,
  eyebrow = '',
  heading = '',
  description = '',
  className = '',
}: ContentRendererProps) => {
  const alignmentClass = getAlignmentClass(textAlignment)
  const textColorClass = getTextColorClass(textColor)
  const isTextAvailable = !!eyebrow || !!heading || !!description
  const isHeaderAvailable = !!eyebrow || !!heading

  return (
    <div
      className={clsx(
        'w-full flex flex-col justify-center relative inset-0 z-10',
        alignmentClass,
        textColorClass,
        className,
      )}
    >
      {!!icon && (
        <div className="font-semibold text-heading-md @xl:text-heading-xl mb-2">
          <EntityImage entity={{ image: icon, name: heading }} size="md" />
        </div>
      )}
      {!!eyebrow && (
        <div className="font-semibold text-heading-2xs @xl:text-heading-xs">
          {eyebrow}
        </div>
      )}
      {!!heading && (
        <h2 className="font-bold text-heading-md @xl:text-heading-xl">
          {heading}
        </h2>
      )}
      {!!description && (
        <div
          className={clsx(
            'text-md opacity-95 w-full',
            isHeaderAvailable && 'mt-3',
          )}
        >
          <article className="prose" style={{ color: 'inherit' }}>
            <HtmlContent value={description} hideUrl />
          </article>
        </div>
      )}
      {actionType === ActionType.button && (
        <div className={clsx(isTextAvailable && 'mt-6')}>
          <ButtonActionsRenderer action={buttonActions} />
        </div>
      )}
      {actionType === ActionType.search && (
        <div className={clsx('w-full', isTextAvailable && 'mt-6')}>
          <SearchActionRenderer action={searchAction} />
        </div>
      )}
    </div>
  )
}
