import { Image } from '@tribeplatform/gql-client/types'
import { useAuthToken } from '@tribeplatform/react-sdk/hooks'
import { useColorSchemeContext } from '@tribeplatform/react-ui-kit/ThemeProvider'

import type { LogoSize } from './types.js'
import { getLogoSize } from './utils.js'

interface LogoProps {
  image?: Image
  logoSize?: LogoSize
}
export const Logo = ({ image: imageFromProp, logoSize }: LogoProps) => {
  const { data: authToken } = useAuthToken()
  const { networkPublicInfo } = authToken ?? {}
  const { colorSchemeMode } = useColorSchemeContext()

  const { images } = networkPublicInfo ?? {}
  const fallbackLogo = images?.lightLogo ?? networkPublicInfo.logo
  const lightImage =
    imageFromProp ?? ((images?.lightLogo ?? fallbackLogo) as Image)
  const darkImage =
    imageFromProp ?? ((images?.darkLogo ?? fallbackLogo) as Image)
  const image = colorSchemeMode === 'dark' ? darkImage : lightImage
  const imageUrl = image?.urls?.large ?? image?.url

  if (!imageUrl) {
    return (
      <div className="flex space-s-2 items-center logo-image">
        <div className="font-medium truncate">{networkPublicInfo.name}</div>
      </div>
    )
  }

  const { width: imageWidth, height: imageHeight } = getLogoSize(
    image,
    logoSize,
  )
  const { width: lightImageWidth, height: lightImageHeight } = getLogoSize(
    lightImage,
    logoSize,
  )
  const { width: darkImageWidth, height: darkImageHeight } = getLogoSize(
    darkImage,
    logoSize,
  )

  if (colorSchemeMode === null) {
    // on SSR we don't have access to the colorSchemeMode, that's why we need to render both logos and let browser decide which one to show
    return (
      <div className="block logo-image">
        <img
          className="object-contain inline-block dark:hidden max-h-[var(--c-header-logo-max-height)]"
          width={lightImageWidth}
          height={lightImageHeight}
          src={lightImage?.urls?.large ?? lightImage?.url}
          alt={networkPublicInfo.name}
        />
        <img
          className="object-contain hidden dark:inline-block max-h-[var(--c-header-logo-max-height)]"
          width={darkImageWidth}
          height={darkImageHeight}
          src={darkImage?.urls?.large ?? darkImage?.url}
          alt={networkPublicInfo.name}
        />
      </div>
    )
  }

  return (
    <div className="block logo-image">
      <img
        className="object-contain max-h-[var(--c-header-logo-max-height)]"
        width={imageWidth}
        height={imageHeight}
        src={imageUrl}
        alt={networkPublicInfo.name}
      />
    </div>
  )
}
