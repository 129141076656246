import { useRef } from 'react'

import { clsx } from 'clsx'

import { BackgroundProvider } from '@tribeplatform/react-ui-kit/BackgroundContext'
import {
  useDocument,
  useIsomorphicLayoutEffect,
} from '@tribeplatform/react-ui-kit/hooks'
import { Container } from '@tribeplatform/react-ui-kit/Layout'
import { useThemeContext } from '@tribeplatform/react-ui-kit/ThemeProvider'
import { setPropertyValue } from '@tribeplatform/react-ui-kit/utils'

interface HeaderProps {
  sticky: boolean
  children: React.ReactNode
}
export const Header = ({ sticky, children }: HeaderProps) => {
  const { document } = useDocument()
  const headerRef = useRef<HTMLDivElement>(null)
  const {
    style: { cards: cardStyle = 'plain' },
  } = useThemeContext()

  useIsomorphicLayoutEffect(() => {
    const headerElement = headerRef.current
    const resizeObserver = new ResizeObserver(entries => {
      const headerHeight = entries[0].borderBoxSize[0].blockSize
      setPropertyValue(document, '--c-header-height', `${headerHeight}px`)
    })

    resizeObserver.observe(headerElement)

    return () => {
      resizeObserver.unobserve(headerElement)
    }
  }, [document])

  return (
    <BackgroundProvider backgroundType="secondary">
      <Container
        as="header"
        size="full"
        containerProps={{
          ref: headerRef,
          className: clsx(
            'shrink-0 text-content-on-topbar overflow-hidden',
            '-ms-[calc(env(safe-area-inset-left))] ps-[calc(env(safe-area-inset-left))]',
            '-me-[calc(env(safe-area-inset-right))] pe-[calc(env(safe-area-inset-right))]',
            cardStyle === 'bordered' && 'border-b border-topbar',
            cardStyle === 'elevated' && 'shadow-md',
            sticky &&
              'sticky z-20 inset-0 backdrop-filter backdrop-blur-[20px] backdrop-saturate-[180%] before:absolute before:inset-0 before:w-full before:h-full before:bg-topbar before:opacity-80',
          ),
        }}
      >
        {children}
      </Container>
    </BackgroundProvider>
  )
}
