import { clsx } from 'clsx'

import { useBackgroundContext } from '@tribeplatform/react-ui-kit/BackgroundContext'
import { Button } from '@tribeplatform/react-ui-kit/Button'

interface SpreadFilterItemsProps {
  options: string[]
  value: string[]
  isLoading?: boolean
  onChange: (newOptions: string[]) => void
  children: (selectedOption: string) => JSX.Element
}
export const SpreadFilterItems = ({
  options,
  value,
  isLoading,
  onChange,
  children,
}: SpreadFilterItemsProps) => {
  const { backgroundType } = useBackgroundContext()
  const buttonVariant =
    backgroundType === 'main' ? 'tertiaryNeutral' : 'secondaryNeutral'

  if (isLoading) {
    return (
      <>
        <Button
          variant={buttonVariant}
          className="bg-surface text-sm text-center animate-pulse"
        >
          <span className="w-full h-full inline-flex justify-between items-center">
            <span className="inline-block h-3 bg-skeleton rounded-full w-20"></span>
          </span>
        </Button>
        <Button
          variant={buttonVariant}
          className="bg-surface text-sm text-center animate-pulse"
        >
          <span className="w-full h-full inline-flex justify-between items-center">
            <span className="inline-block h-3 bg-skeleton rounded-full w-14"></span>
          </span>
        </Button>
        <Button
          variant={buttonVariant}
          className="bg-surface text-sm text-center animate-pulse"
        >
          <span className="w-full h-full inline-flex justify-between items-center">
            <span className="inline-block h-3 bg-skeleton rounded-full w-16"></span>
          </span>
        </Button>
      </>
    )
  }

  return (
    <>
      {options.map(option => (
        <Button
          key={option}
          variant={buttonVariant}
          onClick={() => onChange([option])}
          pressed={value.includes(option)}
          className={clsx(
            'max-w-[15rem] bg-surface aria-pressed:bg-surface text-sm text-start',
            value.includes(option) && 'text-interactive',
          )}
        >
          <span className="w-full inline-flex justify-between items-center">
            <span className="flex-1 truncate">{children(option)}</span>
          </span>
        </Button>
      ))}
    </>
  )
}
