import { NavVertical } from '@tribeplatform/react-ui-kit/Sidebar'

import { ActionItem } from '../../types.js'
import { MobileMenuActionItem } from './MobileMenuActionItem.js'

interface MobileMenuActionsProps {
  actions: ActionItem[]
  close: () => void
}
export const MobileMenuActions = ({
  actions,
  close,
}: MobileMenuActionsProps) => {
  return (
    <NavVertical className="mb-3">
      <NavVertical.Group>
        {actions.map(({ id }) => (
          <MobileMenuActionItem key={id} id={id} close={close} />
        ))}
      </NavVertical.Group>
    </NavVertical>
  )
}
