import { MemberListFilterByInput } from '@tribeplatform/gql-client/types'
import { FilterAppearances } from '@tribeplatform/react-components/Filters'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { GridListColumns } from '@tribeplatform/react-ui-kit/Layout'
import type { TextVariant } from '@tribeplatform/react-ui-kit/Text'

import type { MembersBlockView } from '../types.js'

interface OrderBy {
  orderBy: 'createdAt' | 'name'
  reverse: boolean
}
export const getOrderBy = (sort: MembersBlockView['sort']): OrderBy => {
  switch (sort) {
    case 'oldest-first':
      return { orderBy: 'createdAt', reverse: false }
    case 'alphabetical':
      return { orderBy: 'name', reverse: false }
    case 'newest-first':
      return { orderBy: 'createdAt', reverse: true }
    default:
      return { orderBy: 'createdAt', reverse: false }
  }
}

export const getRoleNameFromFilter = (
  filterBy: MembersBlockView['filterBy'],
) => {
  const roleFilter = (filterBy as MemberListFilterByInput[])?.find(
    filter => filter?.key === 'role.name',
  )

  if (!roleFilter) {
    return undefined
  }

  try {
    const roleName = JSON.parse(roleFilter.value) as string
    return roleName
  } catch (err) {
    logger.error('Could not parse role of the member filter')
    return undefined
  }
}

export const getColumns = (
  gallerySize: MembersBlockView['gallerySize'],
): GridListColumns => {
  if (!gallerySize) {
    return 3
  }

  switch (gallerySize) {
    case 'small':
      return 4
    case 'medium':
      return 3
    case 'large':
      return 2
    default:
      // Code should never reach the default case
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = gallerySize
      return exhaustiveCheck
  }
}

export const getCardWidth = (
  gallerySize: MembersBlockView['gallerySize'],
): number => {
  switch (gallerySize) {
    case 'small':
      return 300
    case 'medium':
      return 400
    case 'large':
      return 500
    default:
      // Code should never reach the default case
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = gallerySize
      return exhaustiveCheck
  }
}

export const sanitizeView = (
  almostView: MembersBlockView,
): MembersBlockView => {
  const { filterBy, inlineFilters, allowInlineFilters, filterAppearance } =
    almostView
  const view: MembersBlockView = {
    ...almostView,
    filterBy: filterBy ?? [],
    allowInlineFilters: !!allowInlineFilters,
    inlineFilters: inlineFilters ?? [],
    filterAppearance: filterAppearance ?? FilterAppearances.INLINE,
  }

  return view
}

export const getTitleVariant = (
  size: StaticField['settings']['titleSize'],
): TextVariant => {
  switch (size) {
    case 'sm':
      return 'heading2xs'
    case 'md':
    default:
      return 'headingXs'
    case 'lg':
      return 'headingSm'
  }
}
