import { T } from '@tribeplatform/react-components/i18n'
import { useRouter } from '@tribeplatform/react-sdk'
import { useAuthMember } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { NavVertical } from '@tribeplatform/react-ui-kit/Sidebar'

import { useSlate } from '../../../../hooks/slate.hook.js'
import { useLanguageSwitch } from '../../hooks/useLanguageSwitch.js'

interface LanguageSwitchActionProps {
  close: () => void
}
export const LanguageSwitchAction = ({ close }: LanguageSwitchActionProps) => {
  const { mode } = useSlate()
  const isPreviewMode = mode !== 'live'
  const { Link } = useRouter()
  const { isGuest } = useAuthMember()
  const { items: availableLocales } = useLanguageSwitch()
  const hasMultipleLanguages = availableLocales.length > 1

  if (!hasMultipleLanguages || isGuest) {
    return null
  }

  return (
    <NavVertical.Item
      as={Link}
      href="/settings/account"
      leadingAddon={<SvgIcon name="globe" size="lg" className="me-3 -ms-1" />}
      onClick={e => {
        if (isPreviewMode) {
          e.preventDefault()
        }

        close()
      }}
    >
      <T
        defaultMessage="Language switch"
        id="Navbar.ActionItems.LanguageSwitch"
      />
    </NavVertical.Item>
  )
}
