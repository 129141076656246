import { useRef, useState, useEffect, useMemo } from 'react'

import type { Member } from '@tribeplatform/gql-client/types'
import { useMembersByIds } from '@tribeplatform/react-sdk/hooks'

import { useI18n } from '../i18n/index.js'
import {
  LOGGED_IN_MEMBER_ID,
  MemberPicker,
  type MemberPickerProps,
} from './MemberPicker.js'

interface MemberIdPickerProps {
  value: string[]
  onChange: (memberIds: string[]) => void
  multiple?: boolean
  placeholder?: string
  noWrapper?: boolean
  memberGroup?: MemberPickerProps['memberGroup']
  addLoggedInMember?: boolean
  className?: string
}
export const MemberIdPicker = ({
  value,
  onChange,
  multiple = false,
  placeholder,
  noWrapper,
  memberGroup,
  addLoggedInMember = false,
  className,
}: MemberIdPickerProps) => {
  const { $t } = useI18n()
  const [members, setMembers] = useState<Member[]>([])
  const memberIdsToFetch = value.filter(id => id !== LOGGED_IN_MEMBER_ID)
  const didLoadInitialData = useRef(memberIdsToFetch.length === 0)
  const { data: initialMembers, isInitialLoading } = useMembersByIds({
    variables: { ids: memberIdsToFetch },
    fields: {
      profilePicture: { onImage: { urls: 'basic' } },
    },
    useQueryOptions: {
      enabled: !didLoadInitialData.current,
    },
  })
  const membersWithLoggedInMember = useMemo<Member[]>(() => {
    if (
      value.some(id => id === LOGGED_IN_MEMBER_ID) &&
      !members.some(({ id }) => id === LOGGED_IN_MEMBER_ID)
    ) {
      const loggedInMember = {
        id: LOGGED_IN_MEMBER_ID,
        name: $t({
          defaultMessage: 'Logged in {MEMBER}',
          id: 'Generics.LoggedInMember',
        }),
      } as Member
      return [loggedInMember, ...members]
    }

    return members
  }, [$t, members, value])

  useEffect(() => {
    if (!didLoadInitialData.current && initialMembers) {
      setMembers(initialMembers)
      didLoadInitialData.current = true
    }
  }, [initialMembers])

  useEffect(() => {
    if (value.length === 0) {
      setMembers([])
    }
  }, [value])

  const getPlaceHolder = () => {
    if (isInitialLoading || !members) {
      return $t({
        id: 'Generics.LoadingDotDotDot',
        defaultMessage: 'Loading...',
      })
    }

    return placeholder
  }

  const handleChanges = (members: Member[]) => {
    setMembers(members)
    onChange(members.map(member => member.id))
  }

  return (
    <MemberPicker
      value={membersWithLoggedInMember}
      onChange={handleChanges}
      multiple={multiple}
      disabled={isInitialLoading}
      placeholder={getPlaceHolder()}
      noWrapper={noWrapper}
      memberGroup={memberGroup}
      className={className}
      addLoggedInMember={addLoggedInMember}
    />
  )
}
