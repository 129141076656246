import type { WebLogger } from '../logger/@types/index.js'
import * as defaultLogger from '../logger/client/index.js'

export {}

declare global {
  interface Window {
    logger: WebLogger
  }
}

window.logger = {
  error: defaultLogger.error,
  warn: defaultLogger.warn,
  info: defaultLogger.info,
  debug: defaultLogger.debug,
}
