import type { ReactNode } from 'react'

import { clsx } from 'clsx'

interface LoadingOverlayProps {
  isLoading: boolean
  pulse?: boolean
  className?: string
  opaque?: boolean
  children: ReactNode
  /**
   * If provided, this component will be rendered instead of the children when `isLoading` is `true`
   */
  loadingComponent?: ReactNode
}
export const LoadingOverlay = ({
  isLoading,
  pulse = true,
  className,
  children,
  opaque = true,
  loadingComponent = children,
}: LoadingOverlayProps) => {
  return (
    <div
      className={clsx(
        isLoading && 'relative',
        isLoading && opaque && 'opacity-50',
        isLoading && pulse && 'animate-pulse',
        className,
      )}
    >
      <div
        className={clsx(
          !isLoading && 'hidden',
          'absolute inset-0 cursor-default z-50',
        )}
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
      ></div>
      {isLoading ? loadingComponent : children}
    </div>
  )
}
