import { WebLogger, LogLevels, LogLevel } from '../@types/index.js'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

export class ClientLogger implements WebLogger {
  #level: (typeof LogLevels)[keyof typeof LogLevels] = LogLevels.info

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  #error: (message: any, ...meta: any[]) => void = noop

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  #warn: (message: any, ...meta: any[]) => void = noop

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  #info: (message: any, ...meta: any[]) => void = noop

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  #debug: (message: any, ...meta: any[]) => void = noop

  constructor(options: { level?: LogLevel }) {
    const { level } = options
    if (level !== undefined) {
      this.#level = LogLevels[level]
    }
    if (this.#level === LogLevels.silent) {
      return
    }
    if (this.#level >= LogLevels.error) {
      // eslint-disable-next-line no-console
      this.#error = console.error
    }
    if (this.#level >= LogLevels.warn) {
      // eslint-disable-next-line no-console
      this.#warn = console.warn
    }
    if (this.#level >= LogLevels.info) {
      // eslint-disable-next-line no-console
      this.#info = console.info
    }
    if (this.#level >= LogLevels.debug) {
      // eslint-disable-next-line no-console
      this.#debug = console.debug
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(message: any, ...meta: any[]): void {
    this.#error(message, ...meta)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn(message: any, ...meta: any[]): void {
    this.#warn(message, ...meta)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info(message: any, ...meta: any[]): void {
    this.#info(message, ...meta)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debug(message: any, ...meta: any[]): void {
    this.#debug(message, ...meta)
  }
}
