import { clsx } from 'clsx'

import { Container } from '@tribeplatform/react-ui-kit/Layout'

import { CookieContextProvider } from '../../Apps/CookieConsent/CookieContext.js'
import { SidebarFooter } from './SidebarFooter.js'

interface SidebarProps {
  sticky: boolean
  layoutHasHeader: boolean
  children: React.ReactNode
}
export const Sidebar = ({
  sticky,
  layoutHasHeader,
  children,
}: SidebarProps) => {
  return (
    <Container
      as="aside"
      size="sm"
      direction="vertical"
      spacing="none"
      hide={{ default: true, lg: false }}
      containerProps={{
        'aria-label': 'Navigation',
        className: clsx(
          'shrink-0',
          sticky && [
            'sticky scrollbar-hide overscroll-contain',
            layoutHasHeader &&
              'top-[var(--c-header-height)] max-h-[calc(100vh-var(--c-header-height))]',
          ],
        ),
      }}
    >
      {children}
      <CookieContextProvider>
        <SidebarFooter />
      </CookieContextProvider>
    </Container>
  )
}
