import { useRef, useState, useEffect } from 'react'

import type { Tag } from '@tribeplatform/gql-client/types'
import { useSpaceTags } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'

import { useI18n } from '../i18n/index.js'
import { TagPicker } from './TagPicker.js'

interface TagIdPickerProps {
  value: string[]
  onChange: (tagIds: string[]) => void
  multiple?: boolean
  placeholder?: string
  noWrapper?: boolean
  className?: string
}
export const TagIdPicker = ({
  value,
  onChange,
  multiple = false,
  placeholder,
  noWrapper,
  className,
}: TagIdPickerProps) => {
  const { $t } = useI18n()
  const [tags, setTags] = useState<Tag[]>([])
  const didLoadInitialData = useRef(value.length === 0)
  const { data, isInitialLoading } = useSpaceTags({
    variables: { ids: value, limit: value.length },
    useInfiniteQueryOptions: {
      enabled: value.length > 0 && !didLoadInitialData.current,
    },
  })

  const { nodes: initialTags } = simplifyPaginatedResult<Tag>(data)

  useEffect(() => {
    if (!didLoadInitialData.current && data) {
      setTags(initialTags)
      didLoadInitialData.current = true
    }
  }, [initialTags, data])

  useEffect(() => {
    if (value.length === 0) {
      setTags([])
    }
  }, [value])

  const getPlaceHolder = () => {
    if (isInitialLoading || !tags) {
      return $t({
        id: 'Generics.LoadingDotDotDot',
        defaultMessage: 'Loading...',
      })
    }

    return placeholder
  }

  const handleChanges = (tags: Tag[]) => {
    setTags(tags)
    onChange(tags.map(tag => tag.id))
  }

  return (
    <TagPicker
      value={tags}
      onChange={handleChanges}
      multiple={multiple}
      disabled={isInitialLoading}
      placeholder={getPlaceHolder()}
      noWrapper={noWrapper}
      className={className}
    />
  )
}
