import { ChangeEvent, useRef } from 'react'

import { Image, Member } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import {
  useNetwork,
  useUpdateMember,
  useUploadFile,
} from '@tribeplatform/react-sdk/hooks'
import { Avatar, AvatarSize } from '@tribeplatform/react-ui-kit/Avatar'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { useThemeContext } from '@tribeplatform/react-ui-kit/ThemeProvider'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { useCropImageModal } from '../../../CropImageModal/useCropImageModal.js'
import { T } from '../../../i18n/components/T.js'
import { useI18n } from '../../../i18n/providers/I18nProvider.js'
import { fileSizeLimitError } from '../../lib/upload.js'
import { MemberAvatarPlaceholder } from './MemberAvatarPlaceholder.js'

interface ProfilePictureProps {
  member: Member
  className?: string
  size?: AvatarSize
  editable?: boolean
}
export const ProfilePicture = ({
  member,
  className,
  size = '8x',
  editable = true,
}: ProfilePictureProps) => {
  const intl = useI18n()
  const { $t } = intl
  const { data: network } = useNetwork()
  const { Link } = useRouter()
  const { style } = useThemeContext()

  const fileInputRef = useRef<HTMLInputElement>()
  const {
    loadingFile,
    uploadFile,
    loading: uploadFileLoading,
  } = useUploadFile()
  const { mutateAsync: updateMember, isLoading: updateMemberLoading } =
    useUpdateMember()

  const onUpload = ({ croppedImage }) => {
    if (croppedImage.size > network?.settings?.imageSizeLimit) {
      toast({
        title: $t({
          id: 'Generics.Error',
          defaultMessage: 'Error',
        }),
        description: fileSizeLimitError(
          network?.settings?.imageSizeLimit,
          'image',
          intl,
        ),
        status: 'error',
      })
      return
    }
    uploadFile(croppedImage, async uploadedImage => {
      await updateMember(
        {
          input: {
            profilePictureId: uploadedImage.id,
          },
          id: member.id,
        },
        {
          onSuccess: () => {
            toast({
              title: $t({
                id: 'ProfilePicture.Updated',
                defaultMessage: 'Profile picture successfully updated',
              }),
              status: 'success',
            })
          },
          onError: () => {
            toast({
              title: $t({
                id: 'ProfilePicture.FailedToUpdate',
                defaultMessage:
                  'Something went wrong and we were unable to update your profile picture! Please try again.',
              }),
              status: 'error',
            })
          },
        },
      )
    })
  }

  const { onSelectFile, children: cropModal } = useCropImageModal({
    onSuccess: onUpload,
    options: {
      shape: style.avatarShape === 'full-rounded' ? 'round' : 'rect',
      format: 'image/jpeg',
    },
  })

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = Array.from(e.target.files)?.[0] as File
    // allow to select same file twice
    e.target.value = ''
    onSelectFile(file)
  }

  const avatar = loadingFile || (member.profilePicture as Image)
  const avatarMarkup = (
    <Avatar
      size={size}
      name={member.name}
      className={className}
      src={avatar?.urls?.small || avatar?.url}
      fallback={MemberAvatarPlaceholder}
    />
  )
  return (
    <div>
      <div className="hover:bg-surface-hovered rounded-avatar relative">
        {editable ? (
          avatarMarkup
        ) : (
          <Link href={member.relativeUrl} disabled={editable}>
            {avatarMarkup}
          </Link>
        )}
        {editable && (
          <input
            type="file"
            disabled={updateMemberLoading}
            className="hidden"
            multiple={false}
            ref={fileInputRef}
            onChange={onChangeFile}
            accept="image/*"
          />
        )}
        {(uploadFileLoading || updateMemberLoading) && (
          <div className="flex items-center justify-center text-white rounded-avatar bg-background-backdrop absolute top-0 bottom-0 start-0 end-0">
            <SvgIcon className="animate-spin h-7 w-7" name="spinner" />
          </div>
        )}
        {editable && !(uploadFileLoading || updateMemberLoading) && (
          <div
            onClick={() => {
              fileInputRef.current?.click()
            }}
            className="flex items-center justify-center rounded-avatar text-white bg-black bg-opacity-50 opacity-0 hover:opacity-90 absolute top-0 bottom-0 start-0 end-0 cursor-pointer"
          >
            <T id="Generics.Change.Verb" defaultMessage="Change" />
          </div>
        )}
      </div>
      {cropModal}
    </div>
  )
}
