import { useEffect, useRef } from 'react'

import { clsx } from 'clsx'

import { customizerPreviewScrollTo } from '@bettermode/common/customizer'
import { useBlock, useSlate } from '@tribeplatform/react-slate-kit/hooks'
import {
  BlockWrapperComponent,
  UnknownProps,
} from '@tribeplatform/react-slate-kit/types'
import { useDocument } from '@tribeplatform/react-ui-kit/hooks'
import { getPropertyValue } from '@tribeplatform/react-ui-kit/utils'

import { EditorBlockExtraContext, EditorSlateExtraContext } from '../types.js'
import { BlockOverlay } from './BlockOverlay.js'

const isChildNull = children => {
  try {
    return Boolean(children.type() === null)
  } catch (e) {
    return false
  }
}

export const BlockWrapper: BlockWrapperComponent = ({
  Block,
  childrenBlocks,
  onAddBlock,
}) => {
  const { document } = useDocument()
  const {
    mode,
    extraContext: {
      slate: {
        focusBlock,
        unfocusBlock,
        selectBlock,
        unselectBlock,
        activateBlock,
      },
    },
  } = useSlate<EditorSlateExtraContext, EditorBlockExtraContext>()
  const {
    id,
    block: {
      compiledProps,
      config: {
        displayName,
        hide,
        editable,
        removable,
        copyDisabled,
        acceptsChildren,
        lockedChildren,
      },
    },
    extraContext: { focused, selected, activated },
  } = useBlock<UnknownProps, EditorBlockExtraContext>()
  const ref = useRef<HTMLDivElement>()
  useEffect(() => {
    if (ref.current && selected && mode === 'edit') {
      customizerPreviewScrollTo({
        headerHeightString: getPropertyValue(document, '--c-header-height'),
        element: ref.current,
        document,
      })
    }
  }, [selected, ref, mode, document])

  const renderedComponent = <Block {...compiledProps}>{childrenBlocks}</Block>
  const renderingEmpty = isChildNull(renderedComponent)

  const clickable = mode === 'edit' && !selected

  return (
    <div
      ref={ref}
      className={clsx(
        'relative',
        clickable && 'cursor-pointer',
        activated && 'z-40 bg-background',
      )}
      onMouseEnter={() => focusBlock(id)}
      onMouseLeave={() => unfocusBlock(id)}
      onClick={() => {
        if (clickable) {
          if (selected) {
            unselectBlock(id)
          } else {
            selectBlock(id)
          }
        }
      }}
      onDoubleClick={() => {
        if (mode === 'edit') {
          activateBlock(id)
        }
      }}
    >
      <div
        className={clsx(
          'min-h-[40px] isolate',
          renderingEmpty &&
            'bg-surface text-content-subdued rounded-lg flex items-center px-4 py-2',
        )}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {hide ? (
          // eslint-disable-next-line react/jsx-no-literals
          <div className="py-10 text-center mb-5 bg-surface rounded-base">{`${displayName}: Hidden`}</div>
        ) : renderingEmpty ? (
          // eslint-disable-next-line react/jsx-no-literals
          <span className="text-sm font-medium">{displayName} - Block</span>
        ) : (
          renderedComponent
        )}
      </div>
      <BlockOverlay
        id={id}
        mode={mode}
        selected={selected}
        focused={focused}
        editable={editable}
        removable={removable}
        copyDisabled={copyDisabled}
        acceptsChildren={acceptsChildren && !lockedChildren}
        hide={!focused}
        onAddBlock={onAddBlock}
      />
    </div>
  )
}
