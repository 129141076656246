import { T } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

export const SpacePrivacyIcon = ({ space }) => {
  if (space.private) {
    return (
      <Tooltip>
        <Tooltip.Trigger asChild>
          <SvgIcon
            className="text-content-subdued hover:text-content-hovered"
            name="lock"
            inline
          />
        </Tooltip.Trigger>
        <Tooltip.Panel>
          <T defaultMessage="Private {SPACE}" id="Generics.PrivateSpace" />
        </Tooltip.Panel>
      </Tooltip>
    )
  }
  return null
}
