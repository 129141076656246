import { useFirstRenderContext } from '@tribeplatform/react-components/common/ssr'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { TopBarButton } from '@tribeplatform/react-ui-kit/TopBar'

import { DropDownItemRenderer } from '../Components/DropDownItemRenderer.js'
import { LanguageIcon } from '../Components/LanguageIcon.js'
import { useLanguageSwitch } from '../hooks/useLanguageSwitch.js'

interface LanguageSwitchButtonProps {
  label: string
}
export const LanguageSwitchButton = ({ label }: LanguageSwitchButtonProps) => {
  const { isFirstRender } = useFirstRenderContext()
  const { locale, items, isLoading } = useLanguageSwitch()

  if (isFirstRender) {
    return (
      <div className="h-full hidden lg:flex justify-center items-center shrink-0">
        <TopBarButton
          className="flex justify-center items-center"
          aria-label={label}
          icon={<LanguageIcon locale="" className="pt-px" />}
        />
      </div>
    )
  }

  return (
    <div className="h-full hidden lg:flex justify-center items-center shrink-0">
      <Dropdown placement="bottom-end">
        <Dropdown.ButtonMinimal
          className="navbar-language-switch"
          aria-label={label}
          as="div"
        >
          <TopBarButton
            icon={
              isLoading ? (
                <SvgIcon name="spinner" className="animate-spin" />
              ) : (
                <LanguageIcon
                  locale={locale}
                  className="pt-px flex justify-center items-center"
                />
              )
            }
            rounded
          />
        </Dropdown.ButtonMinimal>
        <Dropdown.Items className="navbar-language-switch__dropdown">
          {items.map(item => (
            <DropDownItemRenderer key={item.id} item={item} />
          ))}
        </Dropdown.Items>
      </Dropdown>
    </div>
  )
}
