import type { AppearanceStyle } from '@tribeplatform/design-system/types'
import type { IconSize } from '@tribeplatform/react-ui-kit/Icon'
import { iconStyles } from '@tribeplatform/react-ui-kit/Icon'
import { useThemeContext } from '@tribeplatform/react-ui-kit/ThemeProvider'

import {
  iconResourceUrl,
  pickerIconFromGlyphText,
} from '../../utils/glyph.utils.js'
import { useDomain } from '../hooks/useDomain.js'

interface MediaGlyphIconProps {
  text: string
  size?: IconSize
  iconStyle?: AppearanceStyle['icons']
  className?: string
}
export const MediaGlyphIcon = ({
  text,
  size,
  iconStyle,
  className,
}: MediaGlyphIconProps) => {
  const { subfolder } = useDomain()
  const { style } = useThemeContext()

  const icon = pickerIconFromGlyphText(text, iconStyle ?? style.icons)
  if (!icon) {
    return null
  }

  const iconUrl = iconResourceUrl(icon, { subfolder })

  return (
    <span
      className={iconStyles({ size, className })}
      style={{
        maskImage: `url(${iconUrl})`,
        WebkitMaskImage: `url(${iconUrl})`,
        maskSize: 'cover',
        WebkitMaskSize: 'cover',
        backgroundColor: 'currentColor',
      }}
    />
  )
}
