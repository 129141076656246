import { RefObject } from 'react'

import { getPropertyValue } from '@tribeplatform/react-ui-kit/utils'

interface props {
  elementRef: RefObject<HTMLElement>
  headerHeightVariable?: string
  customOffsetTop?: number
}

/**
 * Utility function to scroll an element into view with an offset for a fixed header.
 *
 * @param props - Object containing the parameters for the scroll function.
 * @param props.elementRef - ref for the DOM element to scroll into view.
 * @param props.headerHeightVariable - The CSS variable for the header height (default is '--c-header-height').
 * @param props.customOffsetTop - Additional custom offset to adjust the scroll position (default is 15).
 */
export const scrollToElement = ({
  elementRef,
  headerHeightVariable = '--c-header-height',
  customOffsetTop = 15,
}: props) => {
  const element = elementRef?.current
  if (!element || typeof window === 'undefined') {
    return
  }

  const headerHeight = getPropertyValue(document, headerHeightVariable)
  // Default to 0 if header height is not a number
  const headerHeightValue = Number.parseInt(headerHeight, 10) || 0
  const elementOffsetTop = element.getBoundingClientRect().top
  const offsetPosition =
    window.scrollY + elementOffsetTop - headerHeightValue - customOffsetTop
  window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
}
