import { useMemo } from 'react'

import {
  InlineFilters,
  useStoredFilters,
} from '@tribeplatform/react-components/Filters'
import { encodeViewName } from '@tribeplatform/react-components/Views'
import { useNetwork, useAuthMember } from '@tribeplatform/react-sdk/hooks'
import { useBlock } from '@tribeplatform/react-slate-kit/hooks'
import { LoadingOverlay } from '@tribeplatform/react-ui-kit/LoadingOverlay'

import { useLatch } from '../hooks/useLatch.js'
import { usePostsList } from '../hooks/usePostsList.js'
import type { PostsBlockView } from '../types.js'
import { sanitizeView } from '../utils/index.js'
import { EmptyState } from './EmptyState.js'
import { GenericPostsList } from './GenericPostsList.js'
import { InvalidSettingsState } from './InvalidSettingsState.js'
import { LoadingState } from './LoadingState.js'

interface GenericPostsBlockProps {
  view: PostsBlockView
  preview: boolean
  activeFieldId: string
}
export const GenericPostsBlock = ({
  view: almostView,
  preview,
  activeFieldId,
}: GenericPostsBlockProps) => {
  const {
    data: { postTypes },
  } = useNetwork()
  const sanitizedView = useMemo(
    () => sanitizeView(almostView ?? ({} as PostsBlockView), postTypes),
    [almostView, postTypes],
  )
  const { block } = useBlock()
  const blockName = block.extraProps.displayName ?? block.name
  const viewName = encodeViewName(sanitizedView.name)
  const [storedFilters, setStoredFilters] = useStoredFilters(
    encodeURIComponent(blockName),
    encodeURIComponent(viewName),
  )
  const postListContext = usePostsList(
    sanitizedView,
    storedFilters,
    setStoredFilters,
  )
  const {
    posts,
    showMoreButton,
    mergedStaticFields,
    inlineFilters,
    setInlineFilters,
    hasInlineFilters,
    view,
  } = useLatch({
    value: postListContext,
    trigger: !postListContext.isLoading,
    enabled: postListContext.isLoading && postListContext.posts.length === 0,
  })
  const {
    allowInlineFilters,
    filterAppearance,
    postTypeSlugs,
    style,
    limit,
    gallerySize,
    showDivider,
    linkTo,
  } = view
  const { data: isAdmin } = useAuthMember()

  if (postListContext.isInvalid) {
    return <>{isAdmin ? <InvalidSettingsState /> : <EmptyState />}</>
  }

  return (
    <LoadingOverlay isLoading={postListContext.isLoading}>
      <div className="space-y-3 sm:space-y-3.5 md:space-y-4 lg:space-y-5">
        {allowInlineFilters && hasInlineFilters && (
          <InlineFilters
            filters={inlineFilters}
            appearance={filterAppearance}
            setFilters={setInlineFilters}
          />
        )}
        {postListContext.isLoading && posts.length === 0 ? (
          <LoadingState
            count={limit}
            style={style}
            gallerySize={gallerySize}
            showDivider={showDivider}
          />
        ) : (
          <GenericPostsList
            style={style}
            posts={posts}
            fields={mergedStaticFields}
            gallerySize={gallerySize}
            showDivider={showDivider}
            activeFieldId={activeFieldId}
            showMoreButton={showMoreButton}
            linkTo={linkTo}
            singlePostType={postTypeSlugs.length === 1}
            preview={preview}
          />
        )}
      </div>
    </LoadingOverlay>
  )
}
