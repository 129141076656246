import { clsx } from 'clsx'

import { CustomFieldSchema } from '@tribeplatform/gql-client/types'
import type { Post } from '@tribeplatform/gql-client/types'
import { FieldView } from '@tribeplatform/react-components/CustomField'
import type { PostContext } from '@tribeplatform/react-components/Post'
import type { StaticField } from '@tribeplatform/react-components/Views'

interface CustomFieldProps {
  post: Post
  field: StaticField
  highlighted: boolean
  allowEmpty: boolean
  stretchX: string
  preview: boolean
  postField: CustomFieldSchema
  context?: PostContext
  showLabel?: boolean
}
export const CustomField = ({
  post,
  field,
  highlighted,
  allowEmpty,
  stretchX,
  preview,
  postField,
  context,
  showLabel,
}: CustomFieldProps) => {
  return (
    <FieldView
      highlighted={highlighted}
      field={{
        ...postField,
        ...(field.settings?.customFieldSettings
          ? { settings: field.settings?.customFieldSettings }
          : {}),
      }}
      entity={post}
      className={clsx(
        'mt-0 min-w-0 break-words',
        !allowEmpty && 'empty:hidden',
        stretchX,
      )}
      preview={preview}
      allowEmpty={allowEmpty}
      context={context}
      showLabel={showLabel}
      showFieldPrivacyBadge={false}
    />
  )
}
