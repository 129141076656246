import { lazy } from 'react'

import { I18nShape } from '@tribeplatform/react-components/i18n'
import {
  BlockType,
  RegisteredBlocks,
} from '@tribeplatform/react-slate-kit/types'

import { CollectionPage } from './components/Collection/CollectionPage.js'
import { NotFound } from './components/Error/NotFound.js'
import { MembersConfig as LegacyMembersConfig } from './components/LegacyMembersBlock/config.js'
import { MembersBlock as LegacyMembers } from './components/LegacyMembersBlock/index.js'
import { MemberAbout } from './components/Member/MemberAbout.js'
import { MemberHeader } from './components/Member/MemberHeader.js'
import { MemberPage } from './components/Member/MemberPage.js'
import { MembersConfig } from './components/MembersBlock/config.js'
import { MembersBlock as Members } from './components/MembersBlock/index.js'
import { PostPage } from './components/Post/PostPage.js'
import { PostsConfig } from './components/PostsBlock/config.js'
import { PostsBlock as Posts } from './components/PostsBlock/index.js'
import { QuickLinksConfig } from './components/QuickLinksBlock/config.js'
import { QuickLinksBlock } from './components/QuickLinksBlock/index.js'
import { SinglePostConfig } from './components/SinglePostBlock/config.js'
import { SinglePostBlock } from './components/SinglePostBlock/index.js'
import { SpaceHeaderConfig } from './components/Space/SpaceHeader/config.js'
import { SpaceHeader } from './components/Space/SpaceHeader/index.js'
import { SpaceHeaderSettings } from './components/Space/SpaceHeader/Settings.js'
import { SpacesListConfig } from './components/SpacesListBlock/config.js'
import { SpacesListBlock } from './components/SpacesListBlock/index.js'
import { Tabs } from './components/Tabs/index.js'
import { TagsBlockConfig } from './components/TagsBlock/config.js'
import { RegisteredFeedBlocks } from './modified-blocks/Feed/registered.js'
import { RegisteredPostListBlocks } from './modified-blocks/PostList/registered.js'

const MembersSettings = lazy(
  () => import('./components/MembersBlock/Settings.js'),
)

const PostsSettings = lazy(() => import('./components/PostsBlock/Settings.js'))

const SinglePostSettings = lazy(
  () => import('./components/SinglePostBlock/Settings.js'),
)

const QuickLinksSettings = lazy(
  () => import('./components/QuickLinksBlock/Settings.js'),
)

const SpacesListSettings = lazy(
  () => import('./components/SpacesListBlock/Settings.js'),
)

const NotificationList = lazy(
  () => import('./components/Notification/NotificationList.js'),
)

const Search = lazy(() => import('./components/Search/Search.js'))

const Shaman = lazy(() => import('./components/Shaman/index.js'))

const SpaceList = lazy(() => import('./components/SpaceList/index.js'))

const Tags = lazy(() => import('./components/TagsBlock/index.js'))

const TagsSettings = lazy(() => import('./components/TagsBlock/Settings.js'))

const ForgotPassword = lazy(() => import('./components/Auth/ForgotPassword.js'))

const Login = lazy(() => import('./components/Auth/Login.js'))

const ResetPassword = lazy(() => import('./components/Auth/ResetPassword.js'))

const Verify = lazy(() => import('./components/Auth/Verify.js'))

const SignUp = lazy(() => import('./components/Auth/SignUp.js'))

export const getRegisteredBlocks = (intl: I18nShape): RegisteredBlocks => {
  const { $t } = intl
  return {
    Feed: RegisteredFeedBlocks(intl),
    PostList: RegisteredPostListBlocks(intl),

    CollectionPage: {
      recommendedName: 'CollectionPage',
      Component: CollectionPage,
      config: {
        type: BlockType.Block,
        removable: false,
        displayName: $t({
          defaultMessage: 'Collection page',
          id: 'Blocks.CollectionPage.DisplayName',
        }),
      },
    },
    ForgotPassword: {
      recommendedName: 'ForgotPassword',
      Component: ForgotPassword,
      config: {
        type: BlockType.Block,
        removable: false,
        displayName: $t({
          defaultMessage: 'Forgot password',
          id: 'Blocks.ForgotPassword.DisplayName',
        }),
      },
    },
    ResetPassword: {
      recommendedName: 'ResetPassword',
      Component: ResetPassword,
      config: {
        type: BlockType.Block,
        removable: false,
        displayName: $t({
          defaultMessage: 'Reset password',
          id: 'Generics.ResetPassword',
        }),
      },
    },
    Login: {
      recommendedName: 'Login',
      Component: Login,
      config: {
        type: BlockType.Block,
        removable: false,
        displayName: $t({
          defaultMessage: 'Login',
          id: 'Blocks.Login.DisplayName',
        }),
      },
    },
    PostPage: {
      recommendedName: 'PostPage',
      Component: PostPage,
      config: {
        type: BlockType.Layout,
        removable: false,
        displayName: $t({
          defaultMessage: 'Post page',
          id: 'Blocks.PostPAge.DisplayName',
        }),
      },
    },
    MemberAbout: {
      recommendedName: 'MemberAbout',
      Component: MemberAbout,
      config: {
        type: BlockType.Block,
        removable: false,
        displayName: $t({
          defaultMessage: 'About member',
          id: 'Blocks.MemberAbout.DisplayName',
        }),
      },
    },
    MemberHeader: {
      recommendedName: 'MemberHeader',
      Component: MemberHeader,
      config: {
        type: BlockType.Block,
        removable: false,
        displayName: $t({
          defaultMessage: 'Member header',
          id: 'Blocks.MemberHeader.DisplayName',
        }),
      },
    },
    MemberPage: {
      recommendedName: 'MemberPage',
      Component: MemberPage,
      config: {
        type: BlockType.Block,
        removable: false,
        displayName: $t({
          defaultMessage: 'Member page',
          id: 'Blocks.MemberPage.DisplayName',
        }),
      },
    },
    Members: {
      recommendedName: 'Members',
      Component: LegacyMembers,
      config: {
        type: BlockType.Block,
        usable: false,
        ...LegacyMembersConfig(intl),
      },
    },
    MembersBlock: {
      recommendedName: 'Members',
      Component: Members,
      config: {
        type: BlockType.Block,
        usable: true,
        Settings: MembersSettings,
        fullSidebarSettings: true,
        ...MembersConfig(intl),
      },
    },
    Posts: {
      recommendedName: 'Posts',
      Component: Posts,
      config: {
        type: BlockType.Block,
        usable: true,
        Settings: PostsSettings,
        fullSidebarSettings: true,
        ...PostsConfig(intl),
      },
    },
    SinglePost: {
      recommendedName: 'Single post',
      Component: SinglePostBlock,
      config: {
        type: BlockType.Block,
        usable: true,
        Settings: SinglePostSettings,
        fullSidebarSettings: true,
        ...SinglePostConfig(intl),
      },
    },
    NewQuickLinks: {
      recommendedName: 'Quick links',
      Component: QuickLinksBlock,
      config: {
        type: BlockType.Block,
        usable: true,
        Settings: QuickLinksSettings,
        fullSidebarSettings: true,
        ...QuickLinksConfig(intl),
      },
    },
    SpacesList: {
      recommendedName: 'Space list',
      Component: SpacesListBlock,
      config: {
        type: BlockType.Block,
        usable: true,
        Settings: SpacesListSettings,
        fullSidebarSettings: true,
        ...SpacesListConfig(intl),
      },
    },
    NotificationList: {
      recommendedName: 'NotificationList',
      Component: NotificationList,
      config: {
        type: BlockType.Block,
        removable: false,
        displayName: $t({
          defaultMessage: 'Notifications list',
          id: 'Blocks.NotificationsList.DisplayName',
        }),
      },
    },
    NotFound: {
      recommendedName: 'NotFound',
      Component: NotFound,
      config: {
        type: BlockType.Block,
        removable: false,
        displayName: $t({
          defaultMessage: 'Tabs',
          id: 'Generics.Tabs',
        }),
      },
    },
    SignUp: {
      recommendedName: 'SignUp',
      Component: SignUp,
      config: {
        type: BlockType.Block,
        removable: false,
        displayName: $t({
          defaultMessage: 'Sign up',
          id: 'Generics.SignUp.Verb',
        }),
      },
    },
    Search: {
      recommendedName: 'Search',
      Component: Search,
      config: {
        type: BlockType.Block,
        removable: false,
        displayName: $t({
          defaultMessage: 'Search',
          id: 'Generics.Search',
        }),
      },
    },
    Settings: {
      recommendedName: 'Settings',
      Component: () => <></>,
      config: {
        type: BlockType.Block,
        removable: false,
        displayName: $t({
          defaultMessage: 'Settings',
          id: 'Blocks.Settings.DisplayName',
        }),
      },
    },
    Shaman: {
      recommendedName: 'Shaman',
      Component: Shaman,
      config: { type: BlockType.Block, removable: false },
    },
    SpaceHeader: {
      recommendedName: 'SpaceHeader',
      Component: SpaceHeader,
      config: {
        type: BlockType.Block,
        usable: true,
        Settings: SpaceHeaderSettings,
        ...SpaceHeaderConfig(intl),
      },
    },
    SpaceList: {
      recommendedName: 'SpaceList',
      Component: SpaceList,
      config: {
        type: BlockType.Block,
        usable: false,
        displayName: $t({
          defaultMessage: 'Spaces',
          id: 'Blocks.SpaceList.DisplayName',
        }),
      },
    },
    Tabs: {
      recommendedName: 'Tabs',
      Component: Tabs,
      config: {
        type: BlockType.Block,
        removable: true,
        acceptsChildren: true,
        lockedChildren: true,
        displayName: $t({
          defaultMessage: 'Tabs',
          id: 'Generics.Tabs',
        }),
      },
    },
    Tags: {
      recommendedName: 'Tags',
      Component: Tags,
      config: {
        type: BlockType.Block,
        usable: true,
        Settings: TagsSettings,
        ...TagsBlockConfig(intl),
      },
    },
    Verify: {
      recommendedName: 'Verify',
      Component: Verify,
      config: {
        type: BlockType.Block,
        removable: false,
        displayName: $t({
          defaultMessage: 'Verify',
          id: 'Generics.Verify',
        }),
      },
    },
  }
}
