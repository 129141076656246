import { MESSAGING_POST_TYPE_SLUG } from '@bettermode/common/utils'
import {
  PostListFilterByOperator,
  PostTypeContext,
  type Image,
  type Post,
  type PostType,
  type Space,
  type MediaUrls,
} from '@tribeplatform/gql-client/types'
import { findPostType } from '@tribeplatform/react-components/CMS'
import {
  FilterAppearances,
  ListFilterByOperator,
} from '@tribeplatform/react-components/Filters'
import type { StaticField, View } from '@tribeplatform/react-components/Views'
import type { GridListColumns } from '@tribeplatform/react-ui-kit/Layout'
import type { TextVariant } from '@tribeplatform/react-ui-kit/Text'

import {
  SortDirections,
  type PostsBlockView,
  PostListSource,
  PostTypeSource,
} from '../types.js'

export const getColumns = (
  gallerySize: View['gallerySize'],
): GridListColumns => {
  if (!gallerySize) {
    return 3
  }

  switch (gallerySize) {
    case 'small':
      return 4
    case 'medium':
      return 3
    case 'large':
      return 2
    default:
      // Code should never reach the default case
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = gallerySize
      return exhaustiveCheck
  }
}

export const getCardWidth = (gallerySize: View['gallerySize']) => {
  switch (gallerySize) {
    case 'small':
      return 300
    case 'medium':
      return 400
    case 'large':
      return 500
    default:
      // Code should never reach the default case
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = gallerySize
      return exhaustiveCheck
  }
}

interface GetMediaUrl {
  field: StaticField
  post: Post
  quality?: keyof MediaUrls
}
export const getMediaUrl = ({
  field,
  post,
  quality = 'medium',
}: GetMediaUrl) => {
  const { bannerSource } = field.settings

  switch (bannerSource) {
    case 'member':
      return (
        (post?.owner?.member?.profilePicture as Image)?.urls?.[quality] ||
        (post?.owner?.member?.profilePicture as Image)?.url
      )
    case 'post':
      return (
        (post?.thumbnail as Image)?.urls?.[quality] ||
        (post?.thumbnail as Image)?.url
      )
    case 'seo':
      return (
        (post?.customSeoDetail?.thumbnail as Image)?.urls?.[quality] ||
        (post?.customSeoDetail?.thumbnail as Image)?.url
      )
    default: {
      const media = post?.fields?.find(field => field.key === bannerSource)
        ?.relationEntities?.medias?.[0] as Image

      return media?.urls?.[quality] || media?.url
    }
  }
}

interface GetLinkToUrl {
  linkTo: PostsBlockView['linkTo']
  post: Post
}
export const getLinkToUrl = ({ linkTo, post }: GetLinkToUrl) => {
  if (!linkTo) {
    return post?.relativeUrl ?? ''
  }

  try {
    const linkValue = post?.fields?.find(field => field.key === linkTo)?.value
    return JSON.parse(linkValue) ?? ''
  } catch (error) {
    return ''
  }
}

export const getPostFieldValue = (post: Post, fieldId: string) => {
  try {
    return JSON.parse(
      post?.fields?.find(field => field.key === fieldId)?.value,
    ) as string
  } catch (error) {
    return undefined
  }
}

/** @deprecated getFilterKeyString is deprecated */
export const getFilterKeyString = (key: string) => {
  // TODO: The key is casted manually to PostListFilterByEnum to prevent ts error
  // These types should be fixed from the gqlClient
  if (key === 'owner.member') {
    return 'ownerId'
  }

  if (key === 'tags') {
    return 'tagIds'
  }

  return key as string
}

/** @deprecated getFilterOperator is deprecated */
export const getFilterOperator = (
  key: string,
  operator: ListFilterByOperator,
) => {
  if (key === 'tags') {
    if (operator === ListFilterByOperator.not) {
      return PostListFilterByOperator.nin
    }

    return PostListFilterByOperator.contains
  }

  return operator?.split(':')[0] as PostListFilterByOperator
}

/** @deprecated getFilterValue is deprecated */
export const getFilterValue = (
  key: string,
  operator: ListFilterByOperator,
  value: string,
) => {
  if (key === 'tags' && operator === ListFilterByOperator.not) {
    try {
      const parsedValue = JSON.parse(value)
      const arrayValue = [parsedValue]
      const newValue = JSON.stringify(arrayValue)
      return newValue
    } catch (error) {
      return value
    }
  }

  return value
}

const getPostTypeSlugs = (
  postTypeSlug: string,
  postTypeId: string,
  postTypes: PostType[],
): string[] => {
  if (postTypeSlug) {
    return [postTypeSlug]
  }

  const postType = postTypes.find(postType => postType.id === postTypeId)
  if (postType) {
    return [postType.slug]
  }

  return []
}

const getCurrentSource = (
  source: PostListSource,
  spaceId: string,
): PostListSource => {
  if (source) {
    return source
  }

  if (!spaceId) {
    return PostListSource.allSpaces
  }

  if (spaceId === '{{ spaceId }}') {
    return PostListSource.currentSpace
  }

  return PostListSource.specificSpaces
}

export const sanitizeView = (
  almostView: PostsBlockView,
  postTypes: PostType[],
): PostsBlockView => {
  const {
    postTypeSource,
    postTypeSlugs,
    postTypeSlug,
    postTypeId,
    filterBy,
    linkTo,
    inlineFilters,
    allowInlineFilters,
    filterAppearance,
    sortDirection,
    reverseSort,
    spaceId,
    spaceIds,
    source,
  } = almostView
  const legacyReverseSort = reverseSort ?? true
  const legacyPostDirection = legacyReverseSort
    ? SortDirections.DESC
    : SortDirections.ASC
  const view: PostsBlockView = {
    ...almostView,
    postTypeSource: postTypeSource ?? PostTypeSource.specificPostTypes,
    postTypeSlugs:
      postTypeSlugs ?? getPostTypeSlugs(postTypeSlug, postTypeId, postTypes),
    filterBy: filterBy ?? [],
    allowInlineFilters: !!allowInlineFilters,
    linkTo: linkTo ?? '',
    sortDirection: sortDirection ?? legacyPostDirection,
    source: getCurrentSource(source, spaceId),
    spaceIds: spaceIds ?? (spaceId ? [spaceId] : []),
    inlineFilters: inlineFilters ?? [],
    filterAppearance: filterAppearance ?? FilterAppearances.INLINE,
  }

  return view
}

export const getSpaceId = (
  source: PostListSource,
  currentSpaceId: string,
  spaceIds: string[],
): string[] => {
  if (source === PostListSource.currentSpace) {
    return [currentSpaceId]
  }

  return spaceIds ?? []
}

export const getQuerySpaceIds = (
  source: PostListSource,
  spaceIds: string[],
  currentSpaceId: string,
  joinedSpacesIds: string[],
): string[] => {
  switch (source) {
    case PostListSource.allSpaces:
      return []
    case PostListSource.currentSpace:
      return [currentSpaceId]
    case PostListSource.joinedSpaces:
      return joinedSpacesIds
    case PostListSource.specificSpaces:
      return spaceIds
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = source
      return exhaustiveCheck
    }
  }
}

export const getAvailablePostTypes = (
  source: PostListSource,
  spaces: Space[],
  allPostTypes: PostType[],
): PostType[] => {
  if (source === PostListSource.allSpaces) {
    const postTypes = allPostTypes.filter(
      postType =>
        postType?.context === PostTypeContext.post &&
        postType.slug !== MESSAGING_POST_TYPE_SLUG,
    )
    return postTypes
  }

  if (!spaces?.length) {
    return null
  }

  if (
    source === PostListSource.currentSpace ||
    source === PostListSource.specificSpaces
  ) {
    const spacesPostTypes = spaces.reduce<PostType[]>((result, space) => {
      const spacePostTypes = space?.authMemberProps?.availablePostTypes ?? []
      if (!spacePostTypes) {
        return result
      }

      const newPostTypes = spacePostTypes.filter(
        postType => !result.some(({ id }) => postType.id === id),
      )
      return [...result, ...newPostTypes]
    }, [])
    const postTypes = allPostTypes.filter(postType =>
      spacesPostTypes.some(spacePostType => postType.id === spacePostType.id),
    )
    return postTypes
  }

  return []
}

export const getPostTypeIdsFromSlugs = (
  networkId: string,
  postTypeSource: PostTypeSource,
  postTypeSlugs: string[],
  postTypes: PostType[],
): string[] => {
  if (postTypeSource === PostTypeSource.allPostTypes) {
    return []
  }

  return postTypeSlugs
    .map(slug => findPostType({ networkId, slug, postTypes }))
    .filter(Boolean)
    .map(({ id }) => id)
}

export const getInvalidState = (
  source: PostListSource,
  spaceIds: string[],
  postTypeSource: PostTypeSource,
  postTypeSlugs: string[],
  availablePostTypes?: PostType[],
): boolean => {
  if (source === PostListSource.specificSpaces && spaceIds.length === 0) {
    return true
  }

  if (
    postTypeSource === PostTypeSource.specificPostTypes &&
    postTypeSlugs &&
    postTypeSlugs.length === 0
  ) {
    return true
  }

  if (availablePostTypes && availablePostTypes.length === 0) {
    return true
  }

  return false
}

export const getTitleVariant = (
  size: StaticField['settings']['titleSize'],
): TextVariant => {
  switch (size) {
    case 'sm':
      return 'heading2xs'
    case 'md':
    default:
      return 'headingXs'
    case 'lg':
      return 'headingSm'
  }
}

export const groupFieldsByStretchY = (fields: StaticField[]) => {
  const initialValue = { currentIndex: 0, groups: [] } as {
    currentIndex: number
    groups: StaticField[][]
  }
  const { groups } = fields.reduce((groupedFields, field, index) => {
    const stretchY = field.settings?.stretchY ?? false
    const { currentIndex, groups } = groupedFields
    if (stretchY) {
      const newGroups = [...groups, [field]]
      const newIndex = index === 0 ? currentIndex + 1 : currentIndex + 2
      return { groups: newGroups, currentIndex: newIndex }
    }

    if (groups[currentIndex]?.length) {
      const newGroups = groups.map((group, index) => {
        if (index === currentIndex) {
          return [...group, field]
        }
        return group
      })
      return { groups: newGroups, currentIndex }
    }

    const newGroups = [...groups, [field]]
    return { groups: newGroups, currentIndex }
  }, initialValue)

  return groups
}
