import {
  Post,
  PostMappingField,
  PostMappingTypeEnum,
} from '@tribeplatform/gql-client/types'
import type { I18nShape } from '@tribeplatform/react-components/i18n'
import { KnownPostTypes } from '@tribeplatform/react-slate-kit/types'

export const getPostFields = (
  post: Post,
): Record<string, Record<string, unknown>> =>
  post?.mappingFields?.reduce((acc, curr) => {
    try {
      return {
        ...acc,
        [curr.key]: { ...curr, value: JSON.parse(curr?.value || '""') },
      }
    } catch (e) {
      return {
        ...acc,
        [curr.key]: curr,
      }
    }
  }, {})

export const getPostFieldValue = (post: Post | null, field: string): string => {
  if (!post) {
    return ''
  }

  const value =
    post?.mappingFields?.find?.(f => f?.key === field)?.value ?? '""'
  try {
    return JSON.parse(value)
  } catch (e) {
    return value
  }
}

export const getMappingFields = ({
  title,
  content,
  postTypeName,
}: {
  title?: string
  content: string
  postTypeName: string
}): PostMappingField[] => {
  let mappingFields: PostMappingField[]
  switch (postTypeName?.toLowerCase()) {
    case 'discussion':
      mappingFields = generateMappingFieldForDiscussion({
        title,
        content,
      })
      break
    case 'comment':
      mappingFields = generateMappingFieldForComment({
        content,
      })
      break
    case 'question':
      mappingFields = generateMappingFieldForQuestion({
        title,
        content,
      })
      break
    case 'answer':
      mappingFields = generateMappingFieldForAnswer({
        content,
      })
      break
    case 'message':
      mappingFields = generateMappingFieldForMessage({
        content,
      })
      break
    default:
      mappingFields = []
      break
  }
  return mappingFields
}

export const generateMappingFieldForDiscussion = ({
  title,
  content,
}: {
  title?: string
  content?: string
}): PostMappingField[] => {
  const output: PostMappingField[] = []
  if (typeof title === 'string') {
    output.push({
      key: 'title',
      type: PostMappingTypeEnum.text,
      value: JSON.stringify(title),
    })
  }

  if (typeof content === 'string') {
    output.push({
      key: 'content',
      type: PostMappingTypeEnum.html,
      value: JSON.stringify(content),
    })
  }

  return output
}

export const generateMappingFieldForComment = ({
  content,
}: {
  content?: string
}): PostMappingField[] => {
  const output: PostMappingField[] = []

  if (typeof content === 'string') {
    output.push({
      key: 'content',
      type: PostMappingTypeEnum.html,
      value: JSON.stringify(content),
    })
  }

  return output
}

export const generateMappingFieldForQuestion = ({
  title,
  content,
}: {
  title?: string
  content?: string
}): PostMappingField[] => {
  const output: PostMappingField[] = []
  if (typeof title === 'string') {
    output.push({
      key: 'question',
      type: PostMappingTypeEnum.text,
      value: JSON.stringify(title),
    })
    output.push({
      key: 'title',
      type: PostMappingTypeEnum.text,
      value: JSON.stringify(title),
    })
  }

  if (typeof content === 'string') {
    output.push({
      key: 'description',
      type: PostMappingTypeEnum.html,
      value: JSON.stringify(content),
    })
    output.push({
      key: 'content',
      type: PostMappingTypeEnum.html,
      value: JSON.stringify(content),
    })
  }

  return output
}

export const generateMappingFieldForAnswer = ({
  content,
}: {
  content?: string
}): PostMappingField[] => {
  const output: PostMappingField[] = []
  if (typeof content === 'string') {
    output.push({
      key: 'answer',
      type: PostMappingTypeEnum.html,
      value: JSON.stringify(content),
    })
    output.push({
      key: 'content',
      type: PostMappingTypeEnum.html,
      value: JSON.stringify(content),
    })
  }

  return output
}

export const generateMappingFieldForMessage = ({
  content,
}: {
  content?: string
}): PostMappingField[] => {
  const output: PostMappingField[] = []
  if (typeof content === 'string') {
    output.push({
      key: 'content',
      type: PostMappingTypeEnum.html,
      value: JSON.stringify(content),
    })
  }

  return output
}

export const getTranslatedAddPostText = (
  { $t }: I18nShape,
  postTypeName: string,
) => {
  const knownPostTypeName = postTypeName as KnownPostTypes
  switch (knownPostTypeName) {
    case 'Discussion':
      return $t({
        defaultMessage: 'Add discussion',
        id: 'Space.Actions.AddPost.Discussion',
      })
    case 'Question':
      return $t({
        defaultMessage: 'Add question',
        id: 'Space.Actions.AddPost.Question',
      })
    case 'Idea':
      return $t({
        defaultMessage: 'Add idea',
        id: 'Space.Actions.AddPost.Idea',
      })
    case 'Article':
      return $t({
        defaultMessage: 'Add article',
        id: 'Space.Actions.AddPost.Article',
      })
    case 'Event':
      return $t({
        defaultMessage: 'Add event',
        id: 'Space.Actions.AddPost.Event',
      })
    case 'Product update':
      return $t({
        defaultMessage: 'Add product update',
        id: 'Space.Actions.AddPost.ProductUpdate',
      })
    case 'Changelog':
      return $t({
        defaultMessage: 'Add changelog',
        id: 'Space.Actions.AddPost.Changelog',
      })
    case 'Job':
      return $t({
        defaultMessage: 'Add job',
        id: 'Space.Actions.AddPost.Job',
      })
    default: {
      const unknownPostTypeName: never = knownPostTypeName

      return $t(
        {
          defaultMessage: 'Add <lower>{post_type}</lower>',
          id: 'Space.Actions.AddPost',
        },
        {
          post_type: unknownPostTypeName,
        },
      )
    }
  }
}
