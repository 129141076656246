import { Fragment } from 'react'

import { CustomFieldType } from '@tribeplatform/gql-client/types'
import type { Post, PostType } from '@tribeplatform/gql-client/types'
import {
  TruncateSettings,
  TruncateSettingsContext,
} from '@tribeplatform/react-components/Feed'
import type { StaticField } from '@tribeplatform/react-components/Views'

import { useCustomFields } from '../hooks/useCustomFields.js'
import { CustomField } from './CustomField.js'

interface CustomFieldsProps {
  postType: PostType
  post: Post
  field: StaticField
  highlighted: boolean
  allowEmpty: boolean
  stretchX: string
  truncateSettings: TruncateSettings
  isPostPage: boolean
  preview: boolean
}
export const CustomFields = ({
  postType,
  post,
  field,
  highlighted,
  allowEmpty,
  stretchX,
  truncateSettings,
  isPostPage,
  preview,
}: CustomFieldsProps) => {
  const { customFields } = useCustomFields({
    postTypeSlugs: [postType?.slug],
    excludeRichText: false,
    excludePrivateFields: false,
  })

  if (!customFields) {
    return null
  }

  return (
    <>
      {customFields.map(postField => (
        <Fragment key={postField?.key}>
          {postField.type === CustomFieldType.richText ? (
            <TruncateSettingsContext.Provider value={truncateSettings}>
              <CustomField
                field={field}
                postField={postField}
                post={post}
                highlighted={highlighted}
                allowEmpty={allowEmpty}
                stretchX={stretchX}
                preview={preview}
                showLabel={field.settings?.showFieldName}
              />
            </TruncateSettingsContext.Provider>
          ) : (
            <CustomField
              field={field}
              postField={postField}
              post={post}
              highlighted={highlighted}
              allowEmpty={allowEmpty}
              stretchX={stretchX}
              preview={preview}
              context={isPostPage ? 'post' : null}
              showLabel={field.settings?.showFieldName}
            />
          )}
        </Fragment>
      ))}
    </>
  )
}
