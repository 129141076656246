import type { Tag } from '@tribeplatform/gql-client/types'
import { useSpaceTags } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { T } from '../../../i18n/index.js'
import { SpreadFilterItems } from '../../components/SpreadFilterItems.js'
import { INFINITE_SPREAD_PAGE_SIZE } from '../../utils/inlineFilters.js'

interface InfiniteSpreadTagPickerProps {
  value: string[]
  onChange: (tagIds: string[]) => void
}
export const InfiniteSpreadTagPicker = ({
  value,
  onChange,
}: InfiniteSpreadTagPickerProps) => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useSpaceTags(
    {
      variables: { limit: INFINITE_SPREAD_PAGE_SIZE },
    },
  )
  const { nodes: tags } = simplifyPaginatedResult<Tag>(data)

  return (
    <>
      <SpreadFilterItems
        options={tags.map(({ id }) => id)}
        value={value}
        onChange={onChange}
        isLoading={!data}
      >
        {selectedOption => (
          <span
            title={tags.find(({ id }) => id === selectedOption)?.title ?? ''}
          >
            {tags.find(({ id }) => id === selectedOption)?.title ?? ''}
          </span>
        )}
      </SpreadFilterItems>
      {hasNextPage && (
        <Button
          variant="tertiaryNeutral"
          onClick={() => fetchNextPage()}
          className="flex-shrink-0"
          trailingIcon={
            isFetchingNextPage ? (
              <SvgIcon className="animate-spin" size="lg" name="spinner" />
            ) : null
          }
        >
          <T defaultMessage="Show more" id="Generics.ShowMore" />
        </Button>
      )}
    </>
  )
}
