import { useMemo } from 'react'

import {
  InlineFilters,
  useStoredFilters,
} from '@tribeplatform/react-components/Filters'
import { encodeViewName } from '@tribeplatform/react-components/Views'
import { useBlock } from '@tribeplatform/react-slate-kit/hooks'
import { LoadingOverlay } from '@tribeplatform/react-ui-kit/LoadingOverlay'

import { useLatch } from '../../PostsBlock/hooks/useLatch.js'
import { useMembersList } from '../hooks/useMembersList.js'
import type { MembersBlockView } from '../types.js'
import { sanitizeView } from '../utils/utils.js'
import { GenericMembersList } from './GenericMembersList.js'
import { LoadingState } from './LoadingState.js'

interface GenericMembersBlockProps {
  view: MembersBlockView
  activeFieldId: string
}
export const GenericMembersBlock = ({
  view: almostView,
  activeFieldId,
}: GenericMembersBlockProps) => {
  const sanitizedView = useMemo(
    () => sanitizeView(almostView ?? ({} as MembersBlockView)),
    [almostView],
  )
  const { block } = useBlock()
  const blockName = block.extraProps.displayName ?? block.name
  const viewName = encodeViewName(sanitizedView.name)
  const [storedFilters, setStoredFilters] = useStoredFilters(
    blockName,
    viewName,
  )
  const memberListContext = useMembersList(
    sanitizedView,
    storedFilters,
    setStoredFilters,
  )
  const {
    members,
    showMoreButton,
    mergedStaticFields,
    inlineFilters,
    setInlineFilters,
    hasInlineFilters,
    view,
  } = useLatch({
    value: memberListContext,
    trigger: !memberListContext.isLoading,
    enabled:
      memberListContext.isLoading && memberListContext.members.length === 0,
  })
  const {
    allowInlineFilters,
    filterAppearance,
    style,
    limit,
    gallerySize,
    showDivider,
  } = view
  return (
    <LoadingOverlay isLoading={memberListContext.isLoading}>
      <div className="space-y-3 sm:space-y-3.5 md:space-y-4 lg:space-y-5">
        {allowInlineFilters && hasInlineFilters && (
          <InlineFilters
            filters={inlineFilters}
            appearance={filterAppearance}
            setFilters={setInlineFilters}
          />
        )}
        {memberListContext.isLoading && members.length === 0 ? (
          <LoadingState
            count={limit}
            style={style}
            gallerySize={gallerySize}
            showDivider={showDivider}
          />
        ) : (
          <GenericMembersList
            style={style}
            members={members}
            fields={mergedStaticFields}
            gallerySize={gallerySize}
            showDivider={showDivider}
            activeFieldId={activeFieldId}
            showMoreButton={showMoreButton}
          />
        )}
      </div>
    </LoadingOverlay>
  )
}
