import JsCookies from 'js-cookie'
import isEqual from 'react-fast-compare'

import { merge } from '@bettermode/common/merge'
import { theme as defaultTheme } from '@tribeplatform/design-system/themes/default'
import type { Theme } from '@tribeplatform/design-system/types'
import {
  ThemeColors,
  Typography,
  Palette,
  AppearanceStyle,
  ColorScheme,
  ColorSchemeMode,
} from '@tribeplatform/design-system/types'
import {
  Network,
  NewTheme as NetworkTheme,
} from '@tribeplatform/gql-client/types'
import { logger } from '@tribeplatform/react-components/common'

import { BASE_THEME } from './base.theme.js'
import { resolveColorTheme } from './colors.js'
import {
  COOKIE_COLOR_SCHEME,
  FONT_FALLBACK,
  FONT_MONO_FALLBACK,
  DEFAULT_STYLE,
  LEGACY_NETWORK_COLOR_SCHEME,
  THEME_STYLE_ID,
  TYPOGRAPHY_LINK_ID,
} from './constants.js'
import {
  getBorderColorConfig,
  getBorderWidthConfig,
  getRadiusConfig,
  getShadowConfig,
} from './style.utils.js'
import {
  ThemeConvertor as LegacyThemeConvertor,
  convertToTokens,
} from './theme-convertor.js'
import { THEME_VERSION_V2 } from './theme.constants.js'
import type {
  ThemeTypography,
  Appearance,
  CustomizerTheme,
  NetworkColorScheme,
  NetworkOrPartial,
  UserColorScheme,
} from './types.js'
import { resolveTypography } from './typography.js'

const getCssVarsFromObject = (
  variables: Record<string, string> | ThemeColors,
  category: string,
) => {
  if (!variables) {
    return ''
  }

  return Object.entries(variables).reduce(
    (result, [key, value]) => `${result}--bm-${category}-${key}: ${value};`,
    '',
  )
}

const getColorCssText = (
  {
    theme,
    userColorScheme,
    networkColorScheme,
  }: {
    theme: Theme
    networkColorScheme?: NetworkColorScheme
    userColorScheme: UserColorScheme
  },
  selector: string,
) => {
  const colorScheme = calculateColorScheme({
    userColorScheme,
    networkColorScheme,
  })

  if (colorScheme) {
    const colorSchemeMode = calculateColorSchemeMode({
      userColorScheme,
      networkColorScheme,
    })
    const themeColors = theme[colorScheme]
    return `${selector} {
     ${getCssVarsFromObject(themeColors, 'color')};
     color-scheme: ${colorSchemeMode};
    }`
  }

  /**
   * If no color scheme is defined, we produce css for both light and dark themes and let browser decide which one to use
   */
  const themeColorsLight = theme[networkColorScheme.light]
  const themeColorsDark = theme[networkColorScheme.dark]

  return `
  ${selector} { 
    ${getCssVarsFromObject(themeColorsLight, 'color')}
    color-scheme: light;
  }
  @media (prefers-color-scheme: dark) {
  ${selector} { 
    ${getCssVarsFromObject(themeColorsDark, 'color')}
    color-scheme: dark;
  }
  }
`
}

const FONT_VARS_DESKTOP_TO_MOBILE_MAPPING = {
  'size-xs': 'size-xs',
  'size-sm': 'size-xs',
  'size-md': 'size-md',
  'size-lg': 'size-md',
  'size-xl': 'size-lg',
  'line-height-xs': 'line-height-xs',
  'line-height-sm': 'line-height-xs',
  'line-height-md': 'line-height-md',
  'line-height-lg': 'line-height-md',
  'line-height-xl': 'line-height-lg',

  'size-h-2xs': 'size-h-2xs',
  'size-h-xs': 'size-h-2xs',
  'size-h-sm': 'size-h-xs',
  'size-h-md': 'size-h-sm',
  'size-h-lg': 'size-h-sm',
  'size-h-xl': 'size-h-md',
  'size-h-2xl': 'size-h-lg',
  'line-height-h-2xs': 'line-height-h-2xs',
  'line-height-h-xs': 'line-height-h-2xs',
  'line-height-h-sm': 'line-height-h-xs',
  'line-height-h-md': 'line-height-h-sm',
  'line-height-h-lg': 'line-height-h-sm',
  'line-height-h-xl': 'line-height-h-md',
  'line-height-h-2xl': 'line-height-h-lg',
} as const

const getTypographyCssText = (
  {
    typography,
  }: {
    typography: Typography
  },
  selector: string,
) => {
  const {
    'family-display': fontFamilyDisplay,
    'family-mono': fontFamilyMono,
    'family-sans': fontFamilySans,
    url: _,
    ...rest
  } = typography.font

  const cleanFontVars = {
    ...rest,
    'family-display': fontFamilyDisplay
      ? `${fontFamilyDisplay}, ${FONT_FALLBACK}`
      : FONT_FALLBACK,
    'family-sans': fontFamilySans
      ? `${fontFamilySans}, ${FONT_FALLBACK}`
      : FONT_FALLBACK,
    'family-mono': fontFamilyMono
      ? `${fontFamilyMono}, ${FONT_MONO_FALLBACK}`
      : FONT_MONO_FALLBACK,
  }

  const mobileFontVars = Object.keys(cleanFontVars).reduce((result, key) => {
    const mappingKey = FONT_VARS_DESKTOP_TO_MOBILE_MAPPING[key]
    result[key] = mappingKey ? cleanFontVars[mappingKey] : cleanFontVars[key]
    return result
  }, {})

  return `
  ${selector} { 
    ${getCssVarsFromObject(cleanFontVars, 'font')}
  }
  @media (max-width:640px) {
  ${selector} { 
    ${getCssVarsFromObject(mobileFontVars, 'font')}
  }
  }
`
}

type StylesProps = {
  typography: Typography
  theme: Theme
  userColorScheme: UserColorScheme
  networkColorScheme: NetworkColorScheme
  style: AppearanceStyle
  elementId?: string
}

export const getCssText = ({
  theme,
  userColorScheme,
  networkColorScheme,
  typography,
  style,
  elementId,
}: StylesProps): string => {
  const selector = !elementId ? ':root' : `#${elementId}`

  const colorCssText = getColorCssText(
    {
      theme,
      userColorScheme,
      networkColorScheme,
    },
    selector,
  )

  const typographyCssText = getTypographyCssText({ typography }, selector)

  const variables = [
    typographyCssText,
    getCssVarsFromObject(getShadowConfig(style), 'shadow'),
    getCssVarsFromObject(getRadiusConfig(style), 'radius'),
    getCssVarsFromObject(getBorderColorConfig(style), 'border-color'),
    getCssVarsFromObject(getBorderWidthConfig(style), 'border-width'),
  ].join('')

  const variablesCssText = `${selector} { ${variables} }`
  return [colorCssText, typographyCssText, variablesCssText].join('')
}

const getElementById = (doc: Document, id: string): Element => {
  let element = doc?.head?.firstElementChild
  while (element) {
    if (element.id === id) {
      return element
    }
    element = element.nextElementSibling
  }
  return null
}

const getTypographyLinkTag = (typography: Typography) => {
  const link = typography?.font?.url
  if (link) {
    return `<link id="${TYPOGRAPHY_LINK_ID}" href="${link}" rel="stylesheet" type="text/css">`
  }
  return ''
}

export const injectFontLink = ({
  document,
  typography,
}: {
  document: Document
  typography: Typography
}) => {
  const link = getTypographyLinkTag(typography)
  if (!link || !document) {
    return
  }

  const linkElement =
    document.getElementById(TYPOGRAPHY_LINK_ID) ??
    getElementById(document, TYPOGRAPHY_LINK_ID)
  if (linkElement) {
    if (linkElement.outerHTML !== link) {
      linkElement.outerHTML = link
    }
  } else {
    const style = document.createElement('link')
    style.id = TYPOGRAPHY_LINK_ID
    style.href = typography?.font?.url
    document.head.appendChild(style)
  }
}

export const injectStyles = ({
  document,
  elementId,
  userColorScheme,
  networkColorScheme,
  ...props
}: StylesProps & {
  document: Document
}) => {
  const styleString = getCssText({
    ...props,
    userColorScheme,
    networkColorScheme,
    elementId,
  })

  if (!styleString || !document) {
    return
  }

  const calculatedColorSchemeMode = calculateColorSchemeMode({
    userColorScheme,
    networkColorScheme,
  })

  const rootElement = elementId
    ? document.getElementById(elementId)
    : document.body
  rootElement?.setAttribute('data-color-scheme-mode', calculatedColorSchemeMode)

  const linkElementId = elementId
    ? `${THEME_STYLE_ID}-${elementId}`
    : THEME_STYLE_ID
  const linkElement =
    document.getElementById(linkElementId) ??
    getElementById(document, linkElementId)
  if (linkElement) {
    if (linkElement.innerHTML !== styleString) {
      linkElement.innerHTML = styleString
    }
  } else {
    const style = document.createElement('style')
    style.id = elementId
    style.innerHTML = styleString
    document.head.appendChild(style)
  }
}

const getColorSchemeMode = (colorScheme: ColorScheme): ColorSchemeMode => {
  switch (colorScheme) {
    case 'light1':
    case 'light2':
    case 'light3':
      return 'light'
    case 'dark1':
    case 'dark2':
      return 'dark'
    default: {
      const exhaustiveCheck: never = colorScheme
      return exhaustiveCheck
    }
  }
}

export const calculateColorScheme = ({
  userColorScheme,
  networkColorScheme,
}: {
  networkColorScheme: NetworkColorScheme
  userColorScheme: UserColorScheme
}): ColorScheme | null => {
  if (networkColorScheme.mode === 'single') {
    return networkColorScheme.default
  }

  if (userColorScheme === 'light' || userColorScheme === 'dark') {
    return userColorScheme === 'light'
      ? networkColorScheme.light
      : networkColorScheme.dark
  }

  return null
}

export const calculateColorSchemeMode = ({
  userColorScheme,
  networkColorScheme,
}: {
  networkColorScheme: NetworkColorScheme
  userColorScheme: UserColorScheme
}): ColorSchemeMode | null => {
  const colorScheme = calculateColorScheme({
    userColorScheme,
    networkColorScheme,
  })

  if (colorScheme) {
    return getColorSchemeMode(colorScheme)
  }

  return null
}

export const getColorSchemeCookie = (): UserColorScheme => {
  try {
    return JsCookies.get(COOKIE_COLOR_SCHEME)
  } catch (err) {
    logger.error('get colorScheme cookie error', err)
    return 'auto'
  }
}

export const setColorSchemeCookie = (colorScheme: UserColorScheme): void => {
  try {
    JsCookies.set(COOKIE_COLOR_SCHEME, colorScheme, {
      expires: 365,
    })
  } catch (err) {
    logger.error('set colorScheme into cookie error', err)
  }
}

export const convertNetworkToAppearance = (
  network: NetworkOrPartial,
): Appearance => {
  // if (network.activeTheme.id === 'v2') {
  // TODO skip converting colors
  // }
  const customizerTheme = extractCustomizerTheme(network)
  return customizerThemeToAppearance(customizerTheme)
}

export const extractCustomizerTheme = (
  network: NetworkOrPartial,
): CustomizerTheme => {
  const legacyConverter = new LegacyThemeConvertor({
    base: BASE_THEME,
    networkOldTheme: network.themes?.active,
    networkTheme: network.activeTheme,
  })

  return legacyConverter.toTheme()
}

const themeFromTokens = (customizerTheme: CustomizerTheme) => {
  const tokens = customizerTheme.typography
  const { colorTheme: colorThemeName, customTheme } = tokens

  if (!isEmptyCustomTheme(customTheme)) {
    return merge(defaultTheme, customTheme)
  }

  if (colorThemeName) {
    return resolveColorTheme(colorThemeName)
  }
  return customizerTheme.newColors
}

export const customizerThemeToAppearance = (
  customizerTheme: CustomizerTheme,
): Appearance => {
  const tokens = customizerTheme.typography
  const {
    theme: typographyName,
    style: savedStyle,
    colorThemeSettings,
  } = tokens

  const typography = resolveTypography(typographyName)
  const theme = themeFromTokens(customizerTheme)
  const networkColorScheme =
    colorThemeSettings && Object.keys(colorThemeSettings).length > 0
      ? colorThemeSettings
      : LEGACY_NETWORK_COLOR_SCHEME
  const style =
    savedStyle && Object.keys(savedStyle).length > 0
      ? savedStyle
      : DEFAULT_STYLE

  return {
    theme,
    typography,
    style,
    networkColorScheme,
  }
}

export const isEmptyCustomTheme = (theme: Theme) => {
  return (
    !theme ||
    Object.keys(theme).length === 0 ||
    (Object.keys(theme.light1).length === 0 &&
      Object.keys(theme.light2).length === 0 &&
      Object.keys(theme.light3).length === 0 &&
      Object.keys(theme.dark1).length === 0 &&
      Object.keys(theme.dark2).length === 0)
  )
}

export const convertAppearanceToNetwork = (
  appearance: Appearance,
  network: Network,
): NetworkTheme => {
  const {
    typography,
    networkColorScheme,
    style,
    theme: customTheme,
  } = appearance

  const presetColorTheme = resolveColorTheme(customTheme.id)
  const isPreset = isEqual(presetColorTheme, {
    ...customTheme,
    id: presetColorTheme.id,
    name: presetColorTheme.name,
  })

  const networkTypography: ThemeTypography = {
    theme: typography.id,
    colorTheme: customTheme.id,
    style,
    colorThemeSettings: networkColorScheme,
    customTheme: isPreset ? undefined : customTheme,
    version: THEME_VERSION_V2,
  }

  const legacyConverter = new LegacyThemeConvertor({
    base: BASE_THEME,
    networkOldTheme: network.themes?.active,
    networkTheme: network.activeTheme,
  })

  const oldNetworkTheme = legacyConverter.toNetworkTheme()

  const networkTheme: NetworkTheme = {
    id: 'v2',
    name: 'v2',
    colors: oldNetworkTheme.colors,
    typography: convertToTokens(
      Object.entries(networkTypography).reduce(
        (preValue, [key, value]) => ({
          ...preValue,
          [key]: JSON.stringify(value),
        }),
        {},
      ),
    ),
  }
  return networkTheme
}

export const generateTheme = async (
  { primary, neutral, accent }: Palette,
  { subfolder }: { subfolder?: string },
) => {
  const response = await fetch(
    `${subfolder || ''}/api/theme/generate?primary=${encodeURIComponent(
      primary,
    )}&neutral=${encodeURIComponent(neutral)}&accent=${encodeURIComponent(
      accent,
    )}`,
  )
  const { data } = await response.json()
  return data as Theme
}
