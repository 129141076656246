import { useMemo, useRef, useState } from 'react'

import InfiniteScroll from 'react-infinite-scroller'

import { type Space, SpaceType } from '@tribeplatform/gql-client/types'
import { useSpaces } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { InlineMultiselect } from '@tribeplatform/react-ui-kit/Multiselect'

import { useDebounce } from '../../../common/hooks/useDebounce.js'
import { useI18n, T } from '../../../i18n/index.js'
import { INLINE_MULTI_SELECT_PAGE_SIZE } from '../../utils/inlineFilters.js'

interface InlineSpaceIdPickerProps {
  value: string[]
  className?: string
  onChange: (spaceIds: string[]) => void
}
export const InlineSpaceIdPicker = ({
  value,
  className,
  onChange,
}: InlineSpaceIdPickerProps) => {
  const { $t } = useI18n()
  const dataRef = useRef<Space[]>(undefined)
  const [searchInput, setSearchInput] = useState('')
  const query = useDebounce(searchInput, 500)

  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isInitialLoading,
  } = useSpaces({
    variables: {
      limit: INLINE_MULTI_SELECT_PAGE_SIZE,
      query: searchInput ? query : '',
      type: [SpaceType.Group],
    },
  })

  const spaces = useMemo(() => {
    const prevData = dataRef.current
    if (prevData && !data && isInitialLoading) {
      return prevData
    }

    if (!data && !prevData) {
      return []
    }

    const { nodes } = simplifyPaginatedResult<Space>(data)
    dataRef.current = nodes

    return nodes
  }, [data, isInitialLoading])

  const handleClick = (spaceId: string) => {
    if (value.includes(spaceId)) {
      const newValue = value.filter(id => id !== spaceId)
      onChange(newValue)
      return
    }

    const newValue = [...value, spaceId]
    onChange(newValue)
  }

  return (
    <InlineMultiselect className={className}>
      <InlineMultiselect.SearchBar
        value={searchInput}
        placeHolder={$t({
          defaultMessage: 'Search...',
          id: 'Generics.SearchDotDotDot',
        })}
        onChange={setSearchInput}
      />
      <InlineMultiselect.Items isLoading={isInitialLoading}>
        {spaces.length === 0 && isInitialLoading && (
          <div className="text-sm text-content-subdued flex justify-center">
            <SvgIcon className="animate-spin" size="lg" name="spinner" />
          </div>
        )}
        {!isInitialLoading && spaces.length === 0 && (
          <div className="text-sm text-content-subdued">
            <T id="Generics.NoResults" defaultMessage="No results" />
          </div>
        )}
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchNextPage}
          useWindow={false}
          hasMore={hasNextPage ?? false}
        >
          {spaces.map(space => (
            <InlineMultiselect.Item
              key={space.id}
              selected={value.includes(space.id)}
              onSelect={() => handleClick(space.id)}
            >
              <div className="truncate text-base">{space.name}</div>
            </InlineMultiselect.Item>
          ))}
          {isFetchingNextPage && (
            <div className="flex justify-center">
              <SvgIcon className="animate-spin" size="lg" name="spinner" />
            </div>
          )}
        </InfiniteScroll>
      </InlineMultiselect.Items>
    </InlineMultiselect>
  )
}
