import { clsx } from 'clsx'

import { NetworkVisibility } from '@tribeplatform/gql-client/types'
import { useQuery } from '@tribeplatform/react-components/common/hooks'
import { SearchBar } from '@tribeplatform/react-components/Search'
import { useAuthMember, useAuthToken } from '@tribeplatform/react-sdk/hooks'

import { useSlate } from '../../../hooks/index.js'
import {
  SearchFor,
  type SearchAction as SearchItem,
} from '../../HeroBanner/types.js'
import { getSpaceIdsToSearch } from '../../HeroBanner/utils/utils.js'

interface SearchBarRendererProps extends SearchItem {
  fullWidth?: boolean
  autoFocus?: boolean
}
export const SearchBarRenderer = ({
  searchFor,
  placeholder,
  searchSource,
  spaceIds,
  fullWidth,
  autoFocus,
}: SearchBarRendererProps) => {
  const {
    context: { spaceId: slateSpaceId },
  } = useSlate()
  const {
    data: { networkPublicInfo },
  } = useAuthToken()
  const { isGuest } = useAuthMember()
  const { query: searchQuery } = useQuery<{ query: string }>()
  const entityType = searchFor === SearchFor.all ? '' : searchFor
  const spaceIdsToSearch = getSpaceIdsToSearch(
    searchSource,
    spaceIds,
    slateSpaceId,
  )
  const isNetworkPrivate =
    networkPublicInfo?.visibility === NetworkVisibility.private

  if (isNetworkPrivate && isGuest) {
    return null
  }

  return (
    <div className={clsx(fullWidth ? 'w-full' : 'max-w-[40rem]')}>
      <SearchBar
        entityType={entityType}
        spaceIds={spaceIdsToSearch}
        autoFocus={autoFocus}
        placeholder={placeholder}
        query={searchQuery}
      />
    </div>
  )
}
