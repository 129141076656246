import { Link } from 'react-router-dom'

import {
  PostType,
  Space,
  SpaceMembershipStatus,
} from '@tribeplatform/gql-client/types'
import { useHotkeysSettings } from '@tribeplatform/react-components/common/hooks'
import { useFirstRenderContext } from '@tribeplatform/react-components/common/ssr'
import { T, useI18n } from '@tribeplatform/react-components/i18n'
import { SubscriptionBell } from '@tribeplatform/react-components/Notification'
import { useAuthMember } from '@tribeplatform/react-sdk/hooks'
import {
  Button,
  ButtonSize,
  IconButton,
} from '@tribeplatform/react-ui-kit/Button'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { getTranslatedAddPostText } from '../../utils/post.js'
import { SpaceActionItems } from './SpaceActionItems.js'
import { SpaceMembershipButton } from './SpaceMembershipButton.js'

export const AddPostButton = ({
  space,
  availablePostTypes,
  size = 'lg',
}: {
  space: Space
  availablePostTypes: Array<PostType>
  size?: ButtonSize
}) => {
  const intl = useI18n()
  const { isGuest } = useAuthMember()

  /**
   * @tag Vite migration
   * Dropdown produces hydration error because of prop `id` did not match.
   * As a workaround, Dropdown is initialized client-side only
   */
  const { isFirstRender } = useFirstRenderContext()
  if (isGuest || !availablePostTypes?.length) {
    return null
  }

  if (availablePostTypes.length === 1) {
    const postType = availablePostTypes[0]
    return (
      <Button
        size={size}
        as={Link}
        to={`${space.relativeUrl}/new?post_type=${postType?.id}`}
      >
        {getTranslatedAddPostText(intl, postType.name)}
      </Button>
    )
  }

  const activator = (
    <Button
      variant="primary"
      size={size}
      trailingIcon={<SvgIcon name="chevron-down" />}
    >
      <T defaultMessage="Add post" id="Posts.Add" />
    </Button>
  )

  if (isFirstRender) {
    return activator
  }

  return (
    <Dropdown placement="bottom-end">
      <Dropdown.ButtonMinimal as="div">{activator}</Dropdown.ButtonMinimal>
      <Dropdown.Items>
        {availablePostTypes.map(postType => (
          <Dropdown.Item
            key={postType.id}
            leadingIcon={<SvgIcon name="post" />}
            as={Link}
            to={`${space.relativeUrl}/new?post_type=${postType.id}`}
          >
            {getTranslatedAddPostText(intl, postType.name)}
          </Dropdown.Item>
        ))}
      </Dropdown.Items>
    </Dropdown>
  )
}

export const SpaceActionButtons = ({ space }: { space: Space }) => {
  const { $t } = useI18n()
  /**
   * @tag Vite migration
   * Dropdown produces hydration error because of prop `id` did not match.
   * As a workaround, Dropdown is initialized client-side only
   */
  const { isFirstRender } = useFirstRenderContext()
  const { disabled: hotkeysDisabled } = useHotkeysSettings()

  const joined =
    space.authMemberProps?.membershipStatus === SpaceMembershipStatus.joined
  const canSubscribe = joined || !space.private

  const availablePostTypes = space.authMemberProps?.availablePostTypes || []

  return (
    <>
      {joined && (
        <AddPostButton availablePostTypes={availablePostTypes} space={space} />
      )}
      <SpaceMembershipButton space={space} />
      {canSubscribe && (
        <SubscriptionBell
          publisherType="space"
          publisherId={space.id}
          subscribed={space.authMemberProps?.subscribed}
        />
      )}
      {isFirstRender ? (
        <IconButton
          variant="secondaryNeutral"
          icon={<SvgIcon name="dots-horizontal" />}
          size="lg"
        />
      ) : (
        <Dropdown placement="bottom-end">
          <Dropdown.ButtonMinimal
            aria-label={$t({
              defaultMessage: 'Options',
              id: 'Generics.Options',
            })}
          >
            <IconButton
              as="span"
              variant="secondaryNeutral"
              icon={<SvgIcon name="dots-horizontal" />}
              size="lg"
            />
          </Dropdown.ButtonMinimal>
          <SpaceActionItems space={space} hideHotKeys={hotkeysDisabled} />
        </Dropdown>
      )}
    </>
  )
}
