import { IconButton } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { FieldOperatorPicker } from './FieldOperatorPicker.js'
import { FieldPicker } from './FieldPicker.js'
import { FieldsFilterValue } from './FieldsFilterValue.js'
import { TableFilterField, TableFilterValueParsed } from './types.js'

interface FilterItemProps {
  fields: TableFilterField[]
  name: string
  field: TableFilterField
  filter: TableFilterValueParsed
  onChange: (newValue: Partial<TableFilterValueParsed>) => void
  onReset: () => void
  value: TableFilterValueParsed['value']
}

export const FilterItem = ({
  fields: filterFields,
  name,
  field,
  filter,
  value,
  onChange,
  onReset,
}: FilterItemProps) => {
  const onOperatorChange = ({ operator }) => {
    const shouldResetValue = operator === 'in' || filter.operator === 'in'
    const defaultValue = operator === 'in' ? [] : ''

    onChange({
      operator,
      value: shouldResetValue ? defaultValue : value,
    })
  }

  return (
    <div className="flex space-s-3 items-center bg-surface p-3 rounded-base shadow">
      <div className="flex-shrink-0">
        <FieldPicker
          fields={filterFields}
          value={filter?.key}
          onChange={key => {
            onChange({ key, value: '' })
          }}
        />
      </div>
      {filter?.key ? (
        <FieldOperatorPicker
          value={filter?.operator}
          onChange={onOperatorChange}
          field={field}
        />
      ) : null}
      <FieldsFilterValue
        operator={filter?.operator}
        onChange={value => {
          onChange({ value })
        }}
        value={value}
        field={field}
        name={name}
      />
      <IconButton
        size="sm"
        variant="tertiaryNeutral"
        rounded
        icon={<SvgIcon name="close" />}
        onClick={onReset}
      ></IconButton>
    </div>
  )
}
