import React, { useCallback } from 'react'

import { Space, UploadImagesArgs } from '@tribeplatform/gql-client/types'
import {
  useCreateImages,
  useNetwork,
  useUpdateSpace,
} from '@tribeplatform/react-sdk/hooks'
import type { IconSize } from '@tribeplatform/react-ui-kit/Icon'
import { iconStyles } from '@tribeplatform/react-ui-kit/Icon'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { fileSizeLimitError } from '../common/lib/upload.js'
import { useCropImageModal } from '../CropImageModal/useCropImageModal.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import { PopoverImagePicker } from '../Picker/PopoverImagePicker.js'
import { emojiToImageId, iconToImageId } from '../Picker/utils/picker.utils.js'

interface SpaceImagePickerProps {
  space: Space
  size: IconSize
}
export const SpaceImagePicker = ({ space, size }: SpaceImagePickerProps) => {
  const { data: network } = useNetwork()
  const intl = useI18n()
  const { $t } = intl
  const { mutateAsync: createImages } = useCreateImages()
  const { mutate: updateSpace } = useUpdateSpace()

  const uploadFile = useCallback(
    async (files: Array<File>) => {
      const args: UploadImagesArgs[] = [{ file: files[0] }]
      const uploadedImage = await createImages(args)
      if (uploadedImage?.[0]?.id) {
        updateSpace({
          id: space.id,
          input: {
            imageId: uploadedImage[0].id,
          },
        })
      }
    },
    [createImages, updateSpace, space],
  )

  const { onSelectFile, children: cropModal } = useCropImageModal({
    onSuccess: ({ croppedImage }) => {
      if (croppedImage.size > network?.settings?.imageSizeLimit) {
        toast({
          title: $t({
            id: 'Generics.Error',
            defaultMessage: 'Error',
          }),
          description: fileSizeLimitError(
            network?.settings?.imageSizeLimit,
            'image',
            intl,
          ),
          status: 'error',
        })
        return
      }
      uploadFile([croppedImage])
    },
    options: {
      shape: 'rect',
      format: 'image/png',
    },
  })

  if (!space) {
    return null
  }

  return (
    <>
      <PopoverImagePicker
        tabs={['emoji', 'icon', 'upload', 'link']}
        sizeConfig={{
          recommendedWidth: 512,
          recommendedHeight: 512,
        }}
        onImageChange={files => onSelectFile(files?.[0])}
        onEmojiChange={async emoji => {
          updateSpace({
            id: space.id,
            input: {
              imageId: emojiToImageId(emoji),
            },
          })
        }}
        onIconChange={async icon => {
          updateSpace({
            id: space.id,
            input: {
              imageId: iconToImageId(icon),
            },
          })
        }}
        placement="right-start"
      >
        <button
          type="button"
          className={iconStyles({ size })}
          aria-label="Change icon"
        />
      </PopoverImagePicker>
      {cropModal}
    </>
  )
}
