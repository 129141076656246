import type {
  MemberFields,
  PostFields,
  PostTypeFields,
  SearchEntityFields,
} from '@tribeplatform/gql-client/graphql'
import type {
  QueryMemberPostsArgs,
  QueryPostArgs,
  QueryPostReactionParticipantsArgs,
  QueryPostsArgs,
  QueryPostTypeArgs,
  QueryPostTypesArgs,
  QueryRepliesArgs,
  QuerySearchArgs,
  QuerySmartSearchArgs,
  QueryTagPostsArgs,
  QueryTagsArgs,
} from '@tribeplatform/gql-client/types'
import type {
  PaginatedTagFields,
  SmartSearchResultFields,
} from '@tribeplatform/gql-client/types/generated'

import { KeyConstructor } from './keyConstructor.js'

const SMART_SEARCH_KEY = 'smartSearch'
const SEARCH_KEY = 'search'
const POST_KEY = 'post'
const POSTS_KEY = 'posts'
const TAGS_KEY = 'tags'
const POST_REACTION_PARTICIPANTS_KEY = 'postReactionParticipants'
const POST_TYPE_KEY = 'postType'
const POST_TYPES_KEY = 'postTypes'

export const getSearchKey = KeyConstructor<QuerySearchArgs, SearchEntityFields>(
  SEARCH_KEY,
)
export const getSmartSearchKey = KeyConstructor<
  QuerySmartSearchArgs,
  SmartSearchResultFields
>(SMART_SEARCH_KEY)
export const getPostKey = KeyConstructor<QueryPostArgs, PostFields>(POST_KEY)
export const getPostsKey = KeyConstructor<
  QueryPostsArgs | QueryTagPostsArgs | QueryRepliesArgs | QueryMemberPostsArgs,
  PostFields
>(POSTS_KEY)
export const getTagsKey = KeyConstructor<QueryTagsArgs, PaginatedTagFields>(
  TAGS_KEY,
)
export const getPostReactionParticipantsKey = KeyConstructor<
  QueryPostReactionParticipantsArgs,
  MemberFields
>(POST_REACTION_PARTICIPANTS_KEY)

export const getPostTypeKey = KeyConstructor<QueryPostTypeArgs, PostTypeFields>(
  POST_TYPE_KEY,
)
export const getPostTypesKey = KeyConstructor<
  QueryPostTypesArgs,
  PostTypeFields
>(POST_TYPES_KEY)
