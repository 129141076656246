import { lazy } from 'react'

import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { In } from '../../utils/index.js'
import { AnnouncementBannerBlock as Component } from './Block.js'
import { AnnouncementBannerProps } from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({
    default: m.AnnouncementBannerBlockSettings,
  })),
)

export const RegisteredAnnouncementBannerBlock = (
  intl: I18nShape,
): RegisteredBlock<AnnouncementBannerProps> => {
  const { $t } = intl

  return {
    recommendedName: 'AnnouncementBanner',
    Component,
    config: {
      type: BlockType.Block,
      usable: true,
      availabilityConditions: {
        header: In([true, false]),
      },

      displayName: $t({
        id: 'Blocks.AnnouncementBanner.DisplayName',
        defaultMessage: 'Announcement Banner',
      }),
      Settings,
      Icon: <SvgIcon name="cursor-click" />,
      defaultProps: {
        title: $t({
          id: 'Blocks.AnnouncementBanner.DefaultPropsTitle',
          defaultMessage: 'A big announcement!',
        }),
        url: 'https://google.com',
        rounded: true,
        style: 'primary',
      },
    },
  }
}
