import { clsx } from 'clsx'

import type { Image } from '@tribeplatform/gql-client/types'

import { LegacyBanner } from './LegacyBanner.js'

interface BannerContentProps {
  banner: Image
  className?: string
}
export const BannerContent = ({ banner, className }: BannerContentProps) => {
  // support for old crop values
  const isLegacyFormat =
    (banner.cropX !== undefined && banner.cropX !== 0) ||
    (banner.cropY !== undefined && banner.cropY !== 0)

  if (isLegacyFormat) {
    return <LegacyBanner banner={banner} className={className} />
  }

  return (
    <img
      srcSet={
        banner.urls
          ? `${banner.urls.small} 500w, ${banner.urls.medium} 1000w, ${banner.urls.large} 2000w`
          : undefined
      }
      src={banner.urls?.large ?? banner.url}
      style={{
        background: banner.dominantColorHex,
      }}
      className={clsx('w-full object-cover', className)}
      alt=""
    />
  )
}
