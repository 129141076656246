import { clsx } from 'clsx'

import { useSlate } from '../../../hooks/slate.hook.js'
import { MainMenuItem } from '../Components/MainMenuItem.js'
import { ItemAlignment, type MenuSection as TMenuSection } from '../types.js'

interface MenuSectionProps extends TMenuSection {
  active: boolean
  fixedWidth?: boolean
}
export const MenuSection = ({
  active,
  stretch,
  alignment,
  items,
  fixedWidth,
}: MenuSectionProps) => {
  const { mode } = useSlate()
  const isPreviewMode = mode !== 'live'

  return (
    <div
      className={clsx(
        'flex h-full items-center overflow-hidden',
        fixedWidth && 'w-1/2 justify-start',
        stretch ? 'flex' : 'hidden lg:flex',
        isPreviewMode && 'px-1',
        active &&
          'rounded-base ring ring-customizer-blue ring-inset ring-offset-0',
        stretch && [
          'flex-1',
          alignment === ItemAlignment.start && 'justify-start',
          alignment === ItemAlignment.center && 'justify-center',
          alignment === ItemAlignment.end && 'justify-end',
        ],
      )}
    >
      <div className="hidden lg:flex lg:flex-wrap h-full items-center gap-x-8 gap-y-10 py-2">
        {items.map(({ id, name, url, target, subMenuStyle, menuItems }) => (
          <MainMenuItem
            key={id}
            id={id}
            name={name}
            url={url}
            target={target}
            menuItems={menuItems}
            subMenuStyle={subMenuStyle}
            parentAlignment={alignment}
          />
        ))}
      </div>
    </div>
  )
}
