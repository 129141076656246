import type {
  Image,
  Media,
  MediaUrls,
  Space,
} from '@tribeplatform/gql-client/types'
import type {
  StaticField,
  View,
  ViewField,
} from '@tribeplatform/react-components/Views'
import type { IconSize } from '@tribeplatform/react-ui-kit/Icon'
import { GridListColumns } from '@tribeplatform/react-ui-kit/Layout'
import type { TextVariant } from '@tribeplatform/react-ui-kit/Text'

export const getColumns = (
  gallerySize: View['gallerySize'],
): GridListColumns => {
  if (!gallerySize) {
    return 3
  }

  switch (gallerySize) {
    case 'small':
      return 4
    case 'medium':
      return 3
    case 'large':
      return 2
    default:
      // Code should never reach the default case
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = gallerySize
      return exhaustiveCheck
  }
}

export const getCardWidth = (gallerySize: View['gallerySize']) => {
  switch (gallerySize) {
    case 'small':
      return 300
    case 'medium':
      return 400
    case 'large':
      return 500
    default:
      // Code should never reach the default case
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = gallerySize
      return exhaustiveCheck
  }
}

interface GetMedia {
  source: StaticField['settings']['bannerSource']
  space: Space
  size?: keyof MediaUrls
}
export const getMedia = ({ source, space, size = 'medium' }: GetMedia) => {
  switch (source) {
    case 'banner':
      return getMediaUrl(space?.banner, size) ?? ''
    case 'image':
      return getMediaUrl(space?.image, size) ?? ''
    case 'seo':
      return getMediaUrl(space?.customSeoDetail?.thumbnail, size) ?? ''
    default: {
      return ''
    }
  }
}

interface GetGlyph {
  source: StaticField['settings']['bannerSource']
  space: Space
}
export const getGlyph = ({ source, space }: GetGlyph): Media => {
  switch (source) {
    case 'banner':
      return space?.banner
    case 'image':
      return space?.image
    case 'seo':
      return space?.customSeoDetail?.thumbnail
    default: {
      return null
    }
  }
}

const getMediaUrl = (media: Media, size: keyof MediaUrls) => {
  return (media as Image)?.urls?.[size] || (media as Image)?.url
}

export const getTitleVariant = (
  titleSize: ViewField['settings']['titleSize'],
): TextVariant => {
  switch (titleSize) {
    case 'sm':
      return 'heading2xs'
    case 'md':
    default:
      return 'headingXs'
    case 'lg':
      return 'headingSm'
  }
}

export const getGlyphSize = (gallerySize: View['gallerySize']): IconSize => {
  switch (gallerySize) {
    case 'small':
      return '2x'
    case 'medium':
      return '4x'
    case 'large':
      return '6x'
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = gallerySize
      return exhaustiveCheck
    }
  }
}
