import { clsx } from 'clsx'

import { EntityImage } from '@tribeplatform/react-components/common/components'
import { useFirstRenderContext } from '@tribeplatform/react-components/common/ssr'
import { useRouter } from '@tribeplatform/react-sdk'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Link as UILink } from '@tribeplatform/react-ui-kit/Link'

import { useBlock } from '../../../hooks/block.hook.js'
import { useSlate } from '../../../hooks/slate.hook.js'
import { Target } from '../../HeroBanner/types.js'
import {
  ItemAlignment,
  type NavbarProps,
  type MenuItem as TMenuItem,
} from '../types.js'

interface MenuItemWithDropDownProps {
  id: string
  name: string
  menuItems: TMenuItem[]
  parentAlignment: ItemAlignment
  itemSize: 'sm' | 'lg'
}
export const MenuItemWithDropDown = ({
  id,
  name,
  menuItems,
  parentAlignment,
  itemSize,
}: MenuItemWithDropDownProps) => {
  const { Link } = useRouter()
  const { isFirstRender } = useFirstRenderContext()
  const {
    extraContext: { activeMenuItemId, activeSubMenuItemId },
  } = useBlock<
    NavbarProps,
    { activeMenuItemId?: string; activeSubMenuItemId?: string }
  >()
  const { mode } = useSlate()
  const isPreviewMode = mode !== 'live'
  const isMenuOpenInCustomizer = isPreviewMode && activeMenuItemId === id

  if (isFirstRender) {
    return (
      <UILink
        as="button"
        variant="neutral"
        className={clsx(
          'flex items-center gap-x-1 shrink-0 h-full text-md transition-none',
          itemSize === 'sm' && 'text-sm',
          itemSize === 'lg' && 'text-md font-medium',
        )}
      >
        {name}
        <SvgIcon
          className={clsx(
            'w-4 h-4 transform transition-transform ease-in-out duration-150',
          )}
          name="chevron-down"
        />
      </UILink>
    )
  }

  return (
    <Dropdown
      placement={
        parentAlignment === ItemAlignment.end ? 'bottom-end' : 'bottom-start'
      }
      className="shrink-0"
      forceOpen={isMenuOpenInCustomizer}
    >
      {({ open }) => {
        return (
          <>
            <Dropdown.ButtonMinimal
              aria-label={name}
              className="bg-transparent"
            >
              <Link
                variant="neutral"
                className={clsx(
                  'inline-flex items-center gap-x-1 shrink-0 transition-none',
                  itemSize === 'sm' && 'text-sm',
                  itemSize === 'lg' && 'text-md font-medium',
                )}
              >
                {name}
                <SvgIcon
                  className={clsx(
                    'w-4 h-4 transform transition-transform ease-in-out duration-150',
                    open || isMenuOpenInCustomizer
                      ? '-rotate-180 rtl:rotate-180'
                      : '',
                  )}
                  name="chevron-down"
                />
              </Link>
            </Dropdown.ButtonMinimal>
            <Dropdown.Items
              className="z-[60]"
              noAnimation={isMenuOpenInCustomizer}
            >
              {menuItems.map(({ id, name, description, icon, url, target }) => (
                <Dropdown.Item
                  key={id}
                  as={Link}
                  href={url}
                  target={target === Target.blank ? '_blank' : '_self'}
                  className={clsx(
                    id === activeSubMenuItemId && 'bg-surface-hovered',
                  )}
                  onClick={e => {
                    if (isPreviewMode) {
                      e.preventDefault()
                    }
                  }}
                >
                  <div className="flex gap-x-4">
                    {!!icon && (
                      <div className="shrink-0">
                        <EntityImage
                          entity={{ image: icon, name: id }}
                          size="xl"
                        />
                      </div>
                    )}
                    <div className="flex-1 space-y-1">
                      <div className="text-content-on-background font-medium">
                        {name}
                      </div>
                      {!!description && (
                        <div className="text-content-on-background-subdued text-sm">
                          {description}
                        </div>
                      )}
                    </div>
                  </div>
                </Dropdown.Item>
              ))}
            </Dropdown.Items>
          </>
        )
      }}
    </Dropdown>
  )
}
