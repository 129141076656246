import { useCallback, useState } from 'react'

import { clsx } from 'clsx'

import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Link } from '@tribeplatform/react-ui-kit/Link'
import { useKeypressListener } from '@tribeplatform/react-ui-kit/utils'

import { T } from '../../i18n/components/T.js'
import type { Filter } from '../types.js'
import { isValueNotEmpty } from '../utils/values.js'
import { OperatorPicker } from './OperatorPicker.js'
import { ValuePicker } from './ValuePicker.js'

interface FilterItemPopOverProps {
  filter: Filter
  inline?: boolean
  needsApply?: boolean
  allowOperatorSelection?: boolean
  showFilterLabel?: boolean
  onChange: (newFilter: Filter) => void
  removeFilter: () => void
}
export const FilterItemPopOver = ({
  filter,
  inline,
  needsApply,
  allowOperatorSelection = true,
  showFilterLabel = true,
  onChange,
  removeFilter,
}: FilterItemPopOverProps) => {
  const [filterDraft, setFilterDraft] = useState(filter)
  const showValue = isValueNotEmpty(filter.value)
  const onOperatorChange = (operator: Filter['operator']) => {
    setFilterDraft({ ...filterDraft, operator })
    if (needsApply) {
      return
    }

    onChange({ ...filterDraft, operator })
  }

  const onValueChange = (value: Filter['value']) => {
    setFilterDraft({ ...filterDraft, value })
    if (needsApply) {
      return
    }

    onChange({ ...filterDraft, value })
  }

  const onRemoveFilter = () => {
    setFilterDraft({ ...filterDraft, value: undefined })
    removeFilter()
  }

  const handleEnterKey = useCallback(() => {
    if (!needsApply) {
      return
    }

    onChange(filterDraft)
  }, [filterDraft, needsApply, onChange])

  useKeypressListener('Enter', handleEnterKey, 'keydown')

  return (
    <div
      className={clsx(
        'min-w-64',
        (showFilterLabel || showValue) && 'space-y-3',
      )}
    >
      <div className="flex justify-between gap-x-2 items-center">
        <div className="flex-1 max-w-[13rem]">
          {showFilterLabel && (
            <>
              <span className="text-sm font-bold text-content-subdued">
                {filter.name}
              </span>{' '}
              <span className="text-sm font-semibold">
                {allowOperatorSelection && (
                  <OperatorPicker
                    filter={filterDraft}
                    onChange={onOperatorChange}
                  />
                )}
              </span>
            </>
          )}
        </div>
        {showValue && (
          <Link variant="neutral" className="text-sm" onClick={onRemoveFilter}>
            <T id="Generics.Clear.Verb" defaultMessage="Clear" />
          </Link>
        )}
      </div>
      <ValuePicker
        filter={filterDraft}
        onChange={onValueChange}
        inline={inline}
        className="w-64"
      />
      {needsApply && (
        <div className="flex justify-end">
          <Button
            fullWidth
            variant="secondaryNeutral"
            onClick={() => onChange(filterDraft)}
            disabled={!isValueNotEmpty(filterDraft.value)}
          >
            <T defaultMessage="Apply" id="Generics.Apply" />
          </Button>
        </div>
      )}
    </div>
  )
}
