import { datadogRum } from '@datadog/browser-rum'

import { RuntimeConfigs } from '../../configs/runtime.js'
import { StaticConfigs } from '../../configs/static.js'

if (RuntimeConfigs.DD_ENABLED) {
  datadogRum.init({
    applicationId: RuntimeConfigs.DD_RUM_APP_ID,
    clientToken: RuntimeConfigs.DD_RUM_CLIENT_TOKEN,
    service: RuntimeConfigs.DD_RUM_SERVICE_NAME,
    site: 'datadoghq.com',
    env: RuntimeConfigs.RELEASE_CHANNEL,
    version: StaticConfigs.APP_VERSION,
    sessionSampleRate: RuntimeConfigs.DD_RUM_SAMPLE_RATE,
    sessionReplaySampleRate: 40,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.setGlobalContextProperty('domain', window.location.hostname)

  datadogRum.startSessionReplayRecording()
}
