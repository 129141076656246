import { gql } from 'graphql-tag'

import * as Types from './tribe-graphql.generated.js'

export type AccessGroupFields = 'basic' | 'all' | Record<string, never>

export const AccessGroupGQLFields = (fields: AccessGroupFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        createdAt
description
entityId
entityType
id
info
name
networkId
type
      `
}

export type ActionFields = 'basic' | 'all' | Record<string, never>

export const ActionGQLFields = (fields: ActionFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        status
      `
}

export interface CustomActionPermissionsFields {
  inputPermissions?: InputPathPermissionsFields
  isAuthorized?: IsAuthorizedFields
  outputPermissions?: PathPermissionsFields
}
export type ActionPermissionsFields =
  | 'basic'
  | 'all'
  | CustomActionPermissionsFields

export const ActionPermissionsGQLFields = (fields: ActionPermissionsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      inputPermissions: 'basic',
      isAuthorized: 'basic',
      outputPermissions: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.inputPermissions
        ? `
      inputPermissions {
        ${InputPathPermissionsGQLFields(fields.inputPermissions)}
      }
    `
        : ``
    }


    ${
      fields.isAuthorized
        ? `
      isAuthorized {
        ${IsAuthorizedGQLFields(fields.isAuthorized)}
      }
    `
        : ``
    }

name

    ${
      fields.outputPermissions
        ? `
      outputPermissions {
        ${PathPermissionsGQLFields(fields.outputPermissions)}
      }
    `
        : ``
    }

      `
}

export type ActiveSsoFields = 'basic' | 'all' | Record<string, never>

export const ActiveSsoGQLFields = (fields: ActiveSsoFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        logoutUrl
settingsUrl
      `
}

export type AddonInformationFields = 'basic' | 'all' | Record<string, never>

export const AddonInformationGQLFields = (fields: AddonInformationFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        priceId
productId
quantity
      `
}

export interface CustomAnalyticsLimitsFields {
  planLimits?: AnalyticsPlanLimitsFields
}
export type AnalyticsLimitsFields =
  | 'basic'
  | 'all'
  | CustomAnalyticsLimitsFields

export const AnalyticsLimitsGQLFields = (fields: AnalyticsLimitsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      planLimits: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.planLimits
        ? `
      planLimits {
        ${AnalyticsPlanLimitsGQLFields(fields.planLimits)}
      }
    `
        : ``
    }

      `
}

export type AnalyticsPlanLimitsFields = 'basic' | 'all' | Record<string, never>

export const AnalyticsPlanLimitsGQLFields = (
  fields: AnalyticsPlanLimitsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        daysLimitation
plan
      `
}

export type AnalyticsQueryTimeframeFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const AnalyticsQueryTimeframeGQLFields = (
  fields: AnalyticsQueryTimeframeFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        from
to
      `
}

export interface CustomAnalyticsResponseFields {
  records?: ReportRecordFields
  timeframe?: AnalyticsQueryTimeframeFields
}
export type AnalyticsResponseFields =
  | 'basic'
  | 'all'
  | CustomAnalyticsResponseFields

export const AnalyticsResponseGQLFields = (fields: AnalyticsResponseFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      records: 'basic',
      timeframe: 'basic',
    }
  }
  return `
        __typename
        maxCacheTime
query

    ${
      fields.records
        ? `
      records {
        ${ReportRecordGQLFields(fields.records)}
      }
    `
        : ``
    }


    ${
      fields.timeframe
        ? `
      timeframe {
        ${AnalyticsQueryTimeframeGQLFields(fields.timeframe)}
      }
    `
        : ``
    }

      `
}

export type ApiRateLimitStatFields = 'basic' | 'all' | Record<string, never>

export const ApiRateLimitStatGQLFields = (fields: ApiRateLimitStatFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        consumableType
consumedPoints
duration
limit
remainingPoints
resetsAt
      `
}

export type ApiRequestLimitFields = 'basic' | 'all' | Record<string, never>

export const ApiRequestLimitGQLFields = (fields: ApiRequestLimitFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        consumableType
duration
limit
      `
}

export interface CustomAppFields {
  authMemberInstallation?: AppInstallationFields
  banner?: MediaFields
  createdBy?: MemberFields
  customCodes?: AppCustomCodesFields
  dynamicBlocks?: DynamicBlockFields
  embeds?: EmbedFields
  favicon?: MediaFields
  globalBanner?: MediaFields
  globalEmbeds?: EmbedFields
  globalFavicon?: MediaFields
  globalImage?: MediaFields
  globalImages?: MediaFields
  globalNetwork?: PluralNetworkFields
  image?: MediaFields
  images?: MediaFields
  network?: NetworkFields
  settingsBlock?: DynamicBlockFields
  updatedBy?: MemberFields
}
export type AppFields = 'basic' | 'all' | CustomAppFields

export const AppGQLFields = (fields: AppFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      authMemberInstallation: 'basic',
      banner: 'basic',
      createdBy: 'basic',
      customCodes: 'basic',
      dynamicBlocks: 'basic',
      embeds: 'basic',
      favicon: 'basic',
      globalBanner: 'basic',
      globalEmbeds: 'basic',
      globalFavicon: 'basic',
      globalImage: 'basic',
      globalImages: 'basic',
      globalNetwork: 'basic',
      image: 'basic',
      images: 'basic',
      network: 'basic',
      settingsBlock: 'basic',
      updatedBy: 'basic',
    }
  }
  return `
        __typename
        about

    ${
      fields.authMemberInstallation
        ? `
      authMemberInstallation {
        ${AppInstallationGQLFields(fields.authMemberInstallation)}
      }
    `
        : ``
    }

authorName
authorUrl

    ${
      fields.banner
        ? `
      banner {
        ${MediaGQLFields(fields.banner)}
      }
    `
        : ``
    }

bannerId
clientId
clientSecret
comingSoon
createdAt

    ${
      fields.createdBy
        ? `
      createdBy {
        ${MemberGQLFields(fields.createdBy)}
      }
    `
        : ``
    }

createdById

    ${
      fields.customCodes
        ? `
      customCodes {
        ${AppCustomCodesGQLFields(fields.customCodes)}
      }
    `
        : ``
    }

description
docsUrl

    ${
      fields.dynamicBlocks
        ? `
      dynamicBlocks {
        ${DynamicBlockGQLFields(fields.dynamicBlocks)}
      }
    `
        : ``
    }

embedIds

    ${
      fields.embeds
        ? `
      embeds {
        ${EmbedGQLFields(fields.embeds)}
      }
    `
        : ``
    }

enabledContexts

    ${
      fields.favicon
        ? `
      favicon {
        ${MediaGQLFields(fields.favicon)}
      }
    `
        : ``
    }

faviconId
federatedSearchEnabled

    ${
      fields.globalBanner
        ? `
      globalBanner {
        ${MediaGQLFields(fields.globalBanner)}
      }
    `
        : ``
    }


    ${
      fields.globalEmbeds
        ? `
      globalEmbeds {
        ${EmbedGQLFields(fields.globalEmbeds)}
      }
    `
        : ``
    }


    ${
      fields.globalFavicon
        ? `
      globalFavicon {
        ${MediaGQLFields(fields.globalFavicon)}
      }
    `
        : ``
    }


    ${
      fields.globalImage
        ? `
      globalImage {
        ${MediaGQLFields(fields.globalImage)}
      }
    `
        : ``
    }


    ${
      fields.globalImages
        ? `
      globalImages {
        ${MediaGQLFields(fields.globalImages)}
      }
    `
        : ``
    }


    ${
      fields.globalNetwork
        ? `
      globalNetwork {
        ${PluralNetworkGQLFields(fields.globalNetwork)}
      }
    `
        : ``
    }

id

    ${
      fields.image
        ? `
      image {
        ${MediaGQLFields(fields.image)}
      }
    `
        : ``
    }

imageId
imageIds

    ${
      fields.images
        ? `
      images {
        ${MediaGQLFields(fields.images)}
      }
    `
        : ``
    }

installed
locked
name

    ${
      fields.network
        ? `
      network {
        ${NetworkGQLFields(fields.network)}
      }
    `
        : ``
    }

networkId
privacyPolicyUrl
privatelyPublished
requiredPermissions
requiredPlan
secretToken

    ${
      fields.settingsBlock
        ? `
      settingsBlock {
        ${DynamicBlockGQLFields(fields.settingsBlock)}
      }
    `
        : ``
    }

slug
standing
status
termsOfServiceUrl
updatedAt

    ${
      fields.updatedBy
        ? `
      updatedBy {
        ${MemberGQLFields(fields.updatedBy)}
      }
    `
        : ``
    }

updatedById
webhookSignSecret
webhookSubscriptions
webhookUrl
      `
}

export type AppActionFields = 'basic' | 'all' | Record<string, never>

export const AppActionGQLFields = (fields: AppActionFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        data
status
      `
}

export interface CustomAppCollaboratorFields {
  app?: AppFields
}
export type AppCollaboratorFields =
  | 'basic'
  | 'all'
  | CustomAppCollaboratorFields

export const AppCollaboratorGQLFields = (fields: AppCollaboratorFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      app: 'basic',
    }
  }
  return `
        __typename
        addedById

    ${
      fields.app
        ? `
      app {
        ${AppGQLFields(fields.app)}
      }
    `
        : ``
    }

appId
createdAt
email
id
type
      `
}

export type AppCustomCodesFields = 'basic' | 'all' | Record<string, never>

export const AppCustomCodesGQLFields = (fields: AppCustomCodesFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        body
head
      `
}

export interface CustomAppEdgeFields {
  node?: AppFields
}
export type AppEdgeFields = 'basic' | 'all' | CustomAppEdgeFields

export const AppEdgeGQLFields = (fields: AppEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${AppGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomAppInstallationFields {
  app?: AppFields
  installedBy?: MemberFields
  network?: NetworkFields
  templatesMappings?: AppInstallationTemplatesMappingsFields
}
export type AppInstallationFields =
  | 'basic'
  | 'all'
  | CustomAppInstallationFields

export const AppInstallationGQLFields = (fields: AppInstallationFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      app: 'basic',
      installedBy: 'basic',
      network: 'basic',
      templatesMappings: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.app
        ? `
      app {
        ${AppGQLFields(fields.app)}
      }
    `
        : ``
    }

appVersion
context
createdAt
id
installedAt

    ${
      fields.installedBy
        ? `
      installedBy {
        ${MemberGQLFields(fields.installedBy)}
      }
    `
        : ``
    }


    ${
      fields.network
        ? `
      network {
        ${NetworkGQLFields(fields.network)}
      }
    `
        : ``
    }

permissions
status

    ${
      fields.templatesMappings
        ? `
      templatesMappings {
        ${AppInstallationTemplatesMappingsGQLFields(fields.templatesMappings)}
      }
    `
        : ``
    }

updatedAt
      `
}

export interface CustomAppInstallationEdgeFields {
  node?: AppInstallationFields
}
export type AppInstallationEdgeFields =
  | 'basic'
  | 'all'
  | CustomAppInstallationEdgeFields

export const AppInstallationEdgeGQLFields = (
  fields: AppInstallationEdgeFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${AppInstallationGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomAppInstallationTemplatesMappingsFields {
  member?: TemplatesMappingFields
  postTypes?: TemplatesMappingFields
}
export type AppInstallationTemplatesMappingsFields =
  | 'basic'
  | 'all'
  | CustomAppInstallationTemplatesMappingsFields

export const AppInstallationTemplatesMappingsGQLFields = (
  fields: AppInstallationTemplatesMappingsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      member: 'basic',
      postTypes: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.member
        ? `
      member {
        ${TemplatesMappingGQLFields(fields.member)}
      }
    `
        : ``
    }


    ${
      fields.postTypes
        ? `
      postTypes {
        ${TemplatesMappingGQLFields(fields.postTypes)}
      }
    `
        : ``
    }

      `
}

export interface CustomAppInteractionFields {
  slate?: SlateFields
}
export type AppInteractionFields = 'basic' | 'all' | CustomAppInteractionFields

export const AppInteractionGQLFields = (fields: AppInteractionFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      slate: 'basic',
    }
  }
  return `
        __typename
        appId
interactionId
props

    ${
      fields.slate
        ? `
      slate {
        ${SlateGQLFields(fields.slate)}
      }
    `
        : ``
    }

type
      `
}

export type AppPublicationFields = 'basic' | 'all' | Record<string, never>

export const AppPublicationGQLFields = (fields: AppPublicationFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        addedById
createdAt
id
itemId
networkId
      `
}

export type AppSettingFields = 'basic' | 'all' | Record<string, never>

export const AppSettingGQLFields = (fields: AppSettingFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        appId
context
entityId
id
networkId
settings
      `
}

export type AppTokenFields = 'basic' | 'all' | Record<string, never>

export const AppTokenGQLFields = (fields: AppTokenFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        accessToken
gatewayUrl
      `
}

export interface CustomAuthInfoWithOtpFields {
  member?: MemberFields
  network?: NetworkFields
  role?: RoleFields
}
export type AuthInfoWithOtpFields =
  | 'basic'
  | 'all'
  | CustomAuthInfoWithOtpFields

export const AuthInfoWithOtpGQLFields = (fields: AuthInfoWithOtpFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      member: 'basic',
      network: 'basic',
      role: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.member
        ? `
      member {
        ${MemberGQLFields(fields.member)}
      }
    `
        : ``
    }


    ${
      fields.network
        ? `
      network {
        ${NetworkGQLFields(fields.network)}
      }
    `
        : ``
    }

otp

    ${
      fields.role
        ? `
      role {
        ${RoleGQLFields(fields.role)}
      }
    `
        : ``
    }

      `
}

export interface CustomAuthTokenFields {
  member?: MemberFields
  network?: NetworkFields
  networkPublicInfo?: NetworkPublicInfoFields
  role?: RoleFields
}
export type AuthTokenFields = 'basic' | 'all' | CustomAuthTokenFields

export const AuthTokenGQLFields = (fields: AuthTokenFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      member: 'basic',
      network: 'basic',
      networkPublicInfo: 'basic',
      role: 'basic',
    }
  }
  return `
        __typename
        accessToken

    ${
      fields.member
        ? `
      member {
        ${MemberGQLFields(fields.member)}
      }
    `
        : ``
    }


    ${
      fields.network
        ? `
      network {
        ${NetworkGQLFields(fields.network)}
      }
    `
        : ``
    }


    ${
      fields.networkPublicInfo
        ? `
      networkPublicInfo {
        ${NetworkPublicInfoGQLFields(fields.networkPublicInfo)}
      }
    `
        : ``
    }

refreshToken

    ${
      fields.role
        ? `
      role {
        ${RoleGQLFields(fields.role)}
      }
    `
        : ``
    }

      `
}

export interface CustomAuthTokenWithOtpFields {
  member?: MemberFields
  network?: NetworkFields
  networkPublicInfo?: NetworkPublicInfoFields
  role?: RoleFields
}
export type AuthTokenWithOtpFields =
  | 'basic'
  | 'all'
  | CustomAuthTokenWithOtpFields

export const AuthTokenWithOtpGQLFields = (fields: AuthTokenWithOtpFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      member: 'basic',
      network: 'basic',
      networkPublicInfo: 'basic',
      role: 'basic',
    }
  }
  return `
        __typename
        accessToken

    ${
      fields.member
        ? `
      member {
        ${MemberGQLFields(fields.member)}
      }
    `
        : ``
    }


    ${
      fields.network
        ? `
      network {
        ${NetworkGQLFields(fields.network)}
      }
    `
        : ``
    }


    ${
      fields.networkPublicInfo
        ? `
      networkPublicInfo {
        ${NetworkPublicInfoGQLFields(fields.networkPublicInfo)}
      }
    `
        : ``
    }

otp
refreshToken

    ${
      fields.role
        ? `
      role {
        ${RoleGQLFields(fields.role)}
      }
    `
        : ``
    }

      `
}

export interface CustomBadgeFields {
  image?: MediaFields
  settings?: BadgeSettingsFields
}
export type BadgeFields = 'basic' | 'all' | CustomBadgeFields

export const BadgeGQLFields = (fields: BadgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      image: 'basic',
      settings: 'basic',
    }
  }
  return `
        __typename
        active
backgroundColor
daysUntilExpired
id

    ${
      fields.image
        ? `
      image {
        ${MediaGQLFields(fields.image)}
      }
    `
        : ``
    }

imageId
longDescription
name
networkId

    ${
      fields.settings
        ? `
      settings {
        ${BadgeSettingsGQLFields(fields.settings)}
      }
    `
        : ``
    }

shortDescription
text
textColor
type
      `
}

export type BadgeSettingsFields = 'basic' | 'all' | Record<string, never>

export const BadgeSettingsGQLFields = (fields: BadgeSettingsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        key
value
      `
}

export interface CustomBaseCustomFieldSchemaFields {
  items?: BaseCustomFieldSchemaFields
  properties?: BaseCustomFieldSchemaFields
  typeOptions?: CustomFieldTypeOptionsFields
  validators?: CustomFieldValidatorFields
}
export type BaseCustomFieldSchemaFields =
  | 'basic'
  | 'all'
  | CustomBaseCustomFieldSchemaFields

export const BaseCustomFieldSchemaGQLFields = (
  fields: BaseCustomFieldSchemaFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      items: 'basic',
      properties: 'basic',
      typeOptions: 'basic',
      validators: 'basic',
    }
  }
  return `
        __typename
        archived
description
externalKeys

    ${
      fields.items
        ? `
      items {
        ${BaseCustomFieldSchemaGQLFields(fields.items)}
      }
    `
        : ``
    }

key
name

    ${
      fields.properties
        ? `
      properties {
        ${BaseCustomFieldSchemaGQLFields(fields.properties)}
      }
    `
        : ``
    }

required
type

    ${
      fields.typeOptions
        ? `
      typeOptions {
        ${CustomFieldTypeOptionsGQLFields(fields.typeOptions)}
      }
    `
        : ``
    }


    ${
      fields.validators
        ? `
      validators {
        ${CustomFieldValidatorGQLFields(fields.validators)}
      }
    `
        : ``
    }

      `
}

export interface CustomBasketFields {
  items?: BasketItemFields
  total?: PriceFields
}
export type BasketFields = 'basic' | 'all' | CustomBasketFields

export const BasketGQLFields = (fields: BasketFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      items: 'basic',
      total: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.items
        ? `
      items {
        ${BasketItemGQLFields(fields.items)}
      }
    `
        : ``
    }

promoErrorMessage
renewalType

    ${
      fields.total
        ? `
      total {
        ${PriceGQLFields(fields.total)}
      }
    `
        : ``
    }

      `
}

export interface CustomBasketItemFields {
  value?: PriceFields
}
export type BasketItemFields = 'basic' | 'all' | CustomBasketItemFields

export const BasketItemGQLFields = (fields: BasketItemFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      value: 'basic',
    }
  }
  return `
        __typename
        note
promoCodeItem
title

    ${
      fields.value
        ? `
      value {
        ${PriceGQLFields(fields.value)}
      }
    `
        : ``
    }

      `
}

export type BillingAddressFields = 'basic' | 'all' | Record<string, never>

export const BillingAddressGQLFields = (fields: BillingAddressFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        city
country
postalCode
state
streetAddress
      `
}

export interface CustomBillingDetailsFields {
  address?: BillingAddressFields
  card?: CardPublicInformationFields
  vat?: VatFields
}
export type BillingDetailsFields = 'basic' | 'all' | CustomBillingDetailsFields

export const BillingDetailsGQLFields = (fields: BillingDetailsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      address: 'basic',
      card: 'basic',
      vat: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.address
        ? `
      address {
        ${BillingAddressGQLFields(fields.address)}
      }
    `
        : ``
    }

billingEmail

    ${
      fields.card
        ? `
      card {
        ${CardPublicInformationGQLFields(fields.card)}
      }
    `
        : ``
    }

companyName

    ${
      fields.vat
        ? `
      vat {
        ${VatGQLFields(fields.vat)}
      }
    `
        : ``
    }

      `
}

export type BillingPortalSessionFields = 'basic' | 'all' | Record<string, never>

export const BillingPortalSessionGQLFields = (
  fields: BillingPortalSessionFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        url
      `
}

export type BillingPriceFields = 'basic' | 'all' | Record<string, never>

export const BillingPriceGQLFields = (fields: BillingPriceFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        amount
default
id
interval
      `
}

export interface CustomBillingProductFields {
  app?: AppFields
  hubContent?: HubContentFields
  prices?: BillingPriceFields
}
export type BillingProductFields = 'basic' | 'all' | CustomBillingProductFields

export const BillingProductGQLFields = (fields: BillingProductFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      app: 'basic',
      hubContent: 'basic',
      prices: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.app
        ? `
      app {
        ${AppGQLFields(fields.app)}
      }
    `
        : ``
    }

appId
categories
description
downgradable
features

    ${
      fields.hubContent
        ? `
      hubContent {
        ${HubContentGQLFields(fields.hubContent)}
      }
    `
        : ``
    }

hubContentId
id
imageUrls
isEnterprise
name
order
popular

    ${
      fields.prices
        ? `
      prices {
        ${BillingPriceGQLFields(fields.prices)}
      }
    `
        : ``
    }

public
purchasable
purchaseLimit
requiredPlanOrder
      `
}

export interface CustomBillingProductsFields {
  addons?: BillingProductFields
  plans?: BillingProductFields
}
export type BillingProductsFields =
  | 'basic'
  | 'all'
  | CustomBillingProductsFields

export const BillingProductsGQLFields = (fields: BillingProductsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      addons: 'basic',
      plans: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.addons
        ? `
      addons {
        ${BillingProductGQLFields(fields.addons)}
      }
    `
        : ``
    }


    ${
      fields.plans
        ? `
      plans {
        ${BillingProductGQLFields(fields.plans)}
      }
    `
        : ``
    }

      `
}

export type BillingSubscriptionFields = 'basic' | 'all' | Record<string, never>

export const BillingSubscriptionGQLFields = (
  fields: BillingSubscriptionFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        createdAt
id
status
updatedAt
      `
}

export type BlockFields = 'basic' | 'all' | Record<string, never>

export const BlockGQLFields = (fields: BlockFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        children
extraProps
id
name
output
props
      `
}

export interface CustomByFields {
  onMember?: MemberFields
}
export type ByFields = 'basic' | 'all' | CustomByFields

export const ByGQLFields = (fields: ByFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      onMember: 'basic',
    }
  }
  return `
        __typename

          ${
            fields.onMember
              ? `
            ... on Member {
              ${MemberGQLFields(fields.onMember)}
            }
          `
              : ``
          }

      `
}

export interface CustomCallbackResponseFields {
  slate?: SlateFields
  toast?: ToastFields
}
export type CallbackResponseFields =
  | 'basic'
  | 'all'
  | CustomCallbackResponseFields

export const CallbackResponseGQLFields = (fields: CallbackResponseFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      slate: 'basic',
      toast: 'basic',
    }
  }
  return `
        __typename
        action

    ${
      fields.slate
        ? `
      slate {
        ${SlateGQLFields(fields.slate)}
      }
    `
        : ``
    }


    ${
      fields.toast
        ? `
      toast {
        ${ToastGQLFields(fields.toast)}
      }
    `
        : ``
    }

      `
}

export type CaptchaSettingsFields = 'basic' | 'all' | Record<string, never>

export const CaptchaSettingsGQLFields = (fields: CaptchaSettingsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        enabled
secretKey
siteKey
      `
}

export type CardPublicInformationFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const CardPublicInformationGQLFields = (
  fields: CardPublicInformationFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        expirationMonth
expirationYear
lastFourDigits
updatedAt
      `
}

export type ChartDataFields = 'basic' | 'all' | Record<string, never>

export const ChartDataGQLFields = (fields: ChartDataFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        label
points
value
      `
}

export type ClientApiUsageFields = 'basic' | 'all' | Record<string, never>

export const ClientApiUsageGQLFields = (fields: ClientApiUsageFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        clientId
clientName
value
      `
}

export interface CustomCollectionFields {
  createdBy?: MemberFields
  network?: NetworkFields

  spaces?: {
    fields: PaginatedSpaceFields
    variables: Types.CollectionSpacesArgs
  }
}
export type CollectionFields = 'basic' | 'all' | CustomCollectionFields

export const CollectionGQLFields = (fields: CollectionFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      createdBy: 'basic',
      network: 'basic',
    }
  }
  return `
        __typename
        createdAt

    ${
      fields.createdBy
        ? `
      createdBy {
        ${MemberGQLFields(fields.createdBy)}
      }
    `
        : ``
    }

customOrderingIndex
description
externalId
id
name

    ${
      fields.network
        ? `
      network {
        ${NetworkGQLFields(fields.network)}
      }
    `
        : ``
    }

relativeUrl
slug

      ${
        fields.spaces
          ? `
        spaces
        ${
          fields.spaces?.variables
            ? `
          (

    ${
      fields.spaces.variables.exploreOnly
        ? `
      exploreOnly: ${fields.spaces.variables.exploreOnly}
    `
        : ``
    }


    ${
      fields.spaces.variables.limit
        ? `
      limit: ${fields.spaces.variables.limit}
    `
        : ``
    }


    ${
      fields.spaces.variables.memberId
        ? `
      memberId: ${fields.spaces.variables.memberId}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PaginatedSpaceGQLFields(fields.spaces.fields)}
        }
      `
          : ``
      }

updatedAt
url
      `
}

export interface CustomCollectionEdgeFields {
  node?: CollectionFields
}
export type CollectionEdgeFields = 'basic' | 'all' | CustomCollectionEdgeFields

export const CollectionEdgeGQLFields = (fields: CollectionEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${CollectionGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export type ContentTranslationSettingsFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const ContentTranslationSettingsGQLFields = (
  fields: ContentTranslationSettingsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        enabled
      `
}

export interface CustomContentTypeTemplateFields {
  postFields?: CustomFieldsSchemaFields
  slate?: SlateFields
}
export type ContentTypeTemplateFields =
  | 'basic'
  | 'all'
  | CustomContentTypeTemplateFields

export const ContentTypeTemplateGQLFields = (
  fields: ContentTypeTemplateFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      postFields: 'basic',
      slate: 'basic',
    }
  }
  return `
        __typename
        allowedReactions
context
forbiddenReactions
languageTemplate
name
pluralName

    ${
      fields.postFields
        ? `
      postFields {
        ${CustomFieldsSchemaGQLFields(fields.postFields)}
      }
    `
        : ``
    }

primaryReactionType
selfRepliable
shortContentTemplate
singleChoiceReactions

    ${
      fields.slate
        ? `
      slate {
        ${SlateGQLFields(fields.slate)}
      }
    `
        : ``
    }

slug
titleTemplate
validReplyTypesIds
      `
}

export interface CustomContextPermissionsFields {
  entityActions?: EntityPermissionsFields
}
export type ContextPermissionsFields =
  | 'basic'
  | 'all'
  | CustomContextPermissionsFields

export const ContextPermissionsGQLFields = (
  fields: ContextPermissionsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      entityActions: 'basic',
    }
  }
  return `
        __typename
        context

    ${
      fields.entityActions
        ? `
      entityActions {
        ${EntityPermissionsGQLFields(fields.entityActions)}
      }
    `
        : ``
    }

      `
}

export interface CustomContextScopesFields {
  entityScopes?: EntityScopesFields
}
export type ContextScopesFields = 'basic' | 'all' | CustomContextScopesFields

export const ContextScopesGQLFields = (fields: ContextScopesFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      entityScopes: 'basic',
    }
  }
  return `
        __typename
        context

    ${
      fields.entityScopes
        ? `
      entityScopes {
        ${EntityScopesGQLFields(fields.entityScopes)}
      }
    `
        : ``
    }

      `
}

export type CustomCodeFields = 'basic' | 'all' | Record<string, never>

export const CustomCodeGQLFields = (fields: CustomCodeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        anonymize
code
position
      `
}

export interface CustomCustomFieldFields {
  relationEntities?: CustomFieldRelationFields
}
export type CustomFieldFields = 'basic' | 'all' | CustomCustomFieldFields

export const CustomFieldGQLFields = (fields: CustomFieldFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      relationEntities: 'basic',
    }
  }
  return `
        __typename
        key

    ${
      fields.relationEntities
        ? `
      relationEntities {
        ${CustomFieldRelationGQLFields(fields.relationEntities)}
      }
    `
        : ``
    }

value
      `
}

export type CustomFieldPrivacyFields = 'basic' | 'all' | Record<string, never>

export const CustomFieldPrivacyGQLFields = (
  fields: CustomFieldPrivacyFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        allow
      `
}

export interface CustomCustomFieldRelationFields {
  medias?: MediaFields
  members?: MemberFields
  posts?: PostFields
  spaces?: SpaceFields
  tags?: TagFields
}
export type CustomFieldRelationFields =
  | 'basic'
  | 'all'
  | CustomCustomFieldRelationFields

export const CustomFieldRelationGQLFields = (
  fields: CustomFieldRelationFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      medias: 'basic',
      members: 'basic',
      posts: 'basic',
      spaces: 'basic',
      tags: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.medias
        ? `
      medias {
        ${MediaGQLFields(fields.medias)}
      }
    `
        : ``
    }


    ${
      fields.members
        ? `
      members {
        ${MemberGQLFields(fields.members)}
      }
    `
        : ``
    }


    ${
      fields.posts
        ? `
      posts {
        ${PostGQLFields(fields.posts)}
      }
    `
        : ``
    }


    ${
      fields.spaces
        ? `
      spaces {
        ${SpaceGQLFields(fields.spaces)}
      }
    `
        : ``
    }


    ${
      fields.tags
        ? `
      tags {
        ${TagGQLFields(fields.tags)}
      }
    `
        : ``
    }

      `
}

export interface CustomCustomFieldSchemaFields {
  items?: BaseCustomFieldSchemaFields
  properties?: BaseCustomFieldSchemaFields
  readPrivacy?: CustomFieldPrivacyFields
  settings?: CustomFieldSettingsFields
  typeOptions?: CustomFieldTypeOptionsFields
  validators?: CustomFieldValidatorFields
  writePrivacy?: CustomFieldPrivacyFields
}
export type CustomFieldSchemaFields =
  | 'basic'
  | 'all'
  | CustomCustomFieldSchemaFields

export const CustomFieldSchemaGQLFields = (fields: CustomFieldSchemaFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      items: 'basic',
      properties: 'basic',
      readPrivacy: 'basic',
      settings: 'basic',
      typeOptions: 'basic',
      validators: 'basic',
      writePrivacy: 'basic',
    }
  }
  return `
        __typename
        archived
default
description
externalKeys

    ${
      fields.items
        ? `
      items {
        ${BaseCustomFieldSchemaGQLFields(fields.items)}
      }
    `
        : ``
    }

key
name

    ${
      fields.properties
        ? `
      properties {
        ${BaseCustomFieldSchemaGQLFields(fields.properties)}
      }
    `
        : ``
    }


    ${
      fields.readPrivacy
        ? `
      readPrivacy {
        ${CustomFieldPrivacyGQLFields(fields.readPrivacy)}
      }
    `
        : ``
    }

required
searchable

    ${
      fields.settings
        ? `
      settings {
        ${CustomFieldSettingsGQLFields(fields.settings)}
      }
    `
        : ``
    }

type

    ${
      fields.typeOptions
        ? `
      typeOptions {
        ${CustomFieldTypeOptionsGQLFields(fields.typeOptions)}
      }
    `
        : ``
    }


    ${
      fields.validators
        ? `
      validators {
        ${CustomFieldValidatorGQLFields(fields.validators)}
      }
    `
        : ``
    }


    ${
      fields.writePrivacy
        ? `
      writePrivacy {
        ${CustomFieldPrivacyGQLFields(fields.writePrivacy)}
      }
    `
        : ``
    }

      `
}

export type CustomFieldSettingsFields = 'basic' | 'all' | Record<string, never>

export const CustomFieldSettingsGQLFields = (
  fields: CustomFieldSettingsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        key
value
      `
}

export type CustomFieldTypeOptionsFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const CustomFieldTypeOptionsGQLFields = (
  fields: CustomFieldTypeOptionsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        dateType
numberType
relationType
richTextType
textType
      `
}

export type CustomFieldValidatorFields = 'basic' | 'all' | Record<string, never>

export const CustomFieldValidatorGQLFields = (
  fields: CustomFieldValidatorFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        customErrorMessage
validation
value
      `
}

export interface CustomCustomFieldsSchemaFields {
  fields?: CustomFieldSchemaFields
}
export type CustomFieldsSchemaFields =
  | 'basic'
  | 'all'
  | CustomCustomFieldsSchemaFields

export const CustomFieldsSchemaGQLFields = (
  fields: CustomFieldsSchemaFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      fields: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.fields
        ? `
      fields {
        ${CustomFieldSchemaGQLFields(fields.fields)}
      }
    `
        : ``
    }

      `
}

export type CustomReactionFields = 'basic' | 'all' | Record<string, never>

export const CustomReactionGQLFields = (fields: CustomReactionFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        activeColor
activeGlyphId
activeName
color
glyphId
key
name
      `
}

export interface CustomCustomSeoDetailFields {
  thumbnail?: MediaFields
}
export type CustomSeoDetailFields =
  | 'basic'
  | 'all'
  | CustomCustomSeoDetailFields

export const CustomSeoDetailGQLFields = (fields: CustomSeoDetailFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      thumbnail: 'basic',
    }
  }
  return `
        __typename
        canonicalUrl
description
noIndex

    ${
      fields.thumbnail
        ? `
      thumbnail {
        ${MediaGQLFields(fields.thumbnail)}
      }
    `
        : ``
    }

thumbnailId
title
      `
}

export interface CustomDailyApiUsageFields {
  values?: ClientApiUsageFields
}
export type DailyApiUsageFields = 'basic' | 'all' | CustomDailyApiUsageFields

export const DailyApiUsageGQLFields = (fields: DailyApiUsageFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      values: 'basic',
    }
  }
  return `
        __typename
        date
total
usageType

    ${
      fields.values
        ? `
      values {
        ${ClientApiUsageGQLFields(fields.values)}
      }
    `
        : ``
    }

      `
}

export type DomainAvailabilityFields = 'basic' | 'all' | Record<string, never>

export const DomainAvailabilityGQLFields = (
  fields: DomainAvailabilityFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        available
      `
}

export type DomainDnsRecordFields = 'basic' | 'all' | Record<string, never>

export const DomainDnsRecordGQLFields = (fields: DomainDnsRecordFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        desiredValue
message
name
status
type
      `
}

export interface CustomDomainParseResultFields {
  parts?: DomainPartsFields
}
export type DomainParseResultFields =
  | 'basic'
  | 'all'
  | CustomDomainParseResultFields

export const DomainParseResultGQLFields = (fields: DomainParseResultFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      parts: 'basic',
    }
  }
  return `
        __typename
        isApex

    ${
      fields.parts
        ? `
      parts {
        ${DomainPartsGQLFields(fields.parts)}
      }
    `
        : ``
    }

      `
}

export type DomainPartsFields = 'basic' | 'all' | Record<string, never>

export const DomainPartsGQLFields = (fields: DomainPartsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        apex
domain
sld
subdomain
tld
      `
}

export interface CustomDomainProbeResultFields {
  dnsRecords?: DomainDnsRecordFields
  parseResult?: DomainParseResultFields
}
export type DomainProbeResultFields =
  | 'basic'
  | 'all'
  | CustomDomainProbeResultFields

export const DomainProbeResultGQLFields = (fields: DomainProbeResultFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      dnsRecords: 'basic',
      parseResult: 'basic',
    }
  }
  return `
        __typename
        customHostnameStatus

    ${
      fields.dnsRecords
        ? `
      dnsRecords {
        ${DomainDnsRecordGQLFields(fields.dnsRecords)}
      }
    `
        : ``
    }


    ${
      fields.parseResult
        ? `
      parseResult {
        ${DomainParseResultGQLFields(fields.parseResult)}
      }
    `
        : ``
    }

status
      `
}

export type DomainTransferStatusFields = 'basic' | 'all' | Record<string, never>

export const DomainTransferStatusGQLFields = (
  fields: DomainTransferStatusFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        aaaarecordSuccess
aaaarecords
arecordSuccess
arecords
cnameSuccess
cnames
domain
ns
root
success
tribeARecords
tribeCname
      `
}

export interface CustomDynamicBlockFields {
  app?: AppFields
  favicon?: MediaFields
  image?: MediaFields

  slate?: {
    fields: SlateFields
    variables: Types.DynamicBlockSlateArgs
  }
}
export type DynamicBlockFields = 'basic' | 'all' | CustomDynamicBlockFields

export const DynamicBlockGQLFields = (fields: DynamicBlockFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      app: 'basic',
      favicon: 'basic',
      image: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.app
        ? `
      app {
        ${AppGQLFields(fields.app)}
      }
    `
        : ``
    }

appId
contexts
description

    ${
      fields.favicon
        ? `
      favicon {
        ${MediaGQLFields(fields.favicon)}
      }
    `
        : ``
    }

faviconId
id

    ${
      fields.image
        ? `
      image {
        ${MediaGQLFields(fields.image)}
      }
    `
        : ``
    }

imageId
key
maxSize
name

      ${
        fields.slate
          ? `
        slate
        ${
          fields.slate?.variables
            ? `
          (

    ${
      fields.slate.variables.context
        ? `
      context: ${fields.slate.variables.context}
    `
        : ``
    }


    ${
      fields.slate.variables.entityId
        ? `
      entityId: ${fields.slate.variables.entityId}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${SlateGQLFields(fields.slate.fields)}
        }
      `
          : ``
      }

staffOnly
      `
}

export interface CustomDynamicBlockEdgeFields {
  node?: DynamicBlockFields
}
export type DynamicBlockEdgeFields =
  | 'basic'
  | 'all'
  | CustomDynamicBlockEdgeFields

export const DynamicBlockEdgeGQLFields = (fields: DynamicBlockEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${DynamicBlockGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export type EmailAvailabilityFields = 'basic' | 'all' | Record<string, never>

export const EmailAvailabilityGQLFields = (fields: EmailAvailabilityFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        available
      `
}

export type EmailLogFields = 'basic' | 'all' | Record<string, never>

export const EmailLogGQLFields = (fields: EmailLogFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        createdAt
id
reason
recipient
status
type
updatedAt
      `
}

export interface CustomEmailLogEdgeFields {
  node?: EmailLogFields
}
export type EmailLogEdgeFields = 'basic' | 'all' | CustomEmailLogEdgeFields

export const EmailLogEdgeGQLFields = (fields: EmailLogEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${EmailLogGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export type EmbedFields = 'basic' | 'all' | Record<string, never>

export const EmbedGQLFields = (fields: EmbedFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        author
author_url
description
html
id
options
provider_name
thumbnail_height
thumbnail_url
thumbnail_width
title
type
url
      `
}

export type EmojiFields = 'basic' | 'all' | Record<string, never>

export const EmojiGQLFields = (fields: EmojiFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        id
text
      `
}

export interface CustomEntityPermissionsFields {
  actions?: ActionPermissionsFields
}
export type EntityPermissionsFields =
  | 'basic'
  | 'all'
  | CustomEntityPermissionsFields

export const EntityPermissionsGQLFields = (fields: EntityPermissionsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      actions: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.actions
        ? `
      actions {
        ${ActionPermissionsGQLFields(fields.actions)}
      }
    `
        : ``
    }

id
      `
}

export interface CustomEntityReportFields {
  data?: ReportDataFields
  entity?: ReportableEntityFields
}
export type EntityReportFields = 'basic' | 'all' | CustomEntityReportFields

export const EntityReportGQLFields = (fields: EntityReportFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      data: 'basic',
      entity: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.data
        ? `
      data {
        ${ReportDataGQLFields(fields.data)}
      }
    `
        : ``
    }

description
endDate

    ${
      fields.entity
        ? `
      entity {
        ${ReportableEntityGQLFields(fields.entity)}
      }
    `
        : ``
    }

entityId
entityType
startDate
tooltip
      `
}

export type EntityScopesFields = 'basic' | 'all' | Record<string, never>

export const EntityScopesGQLFields = (fields: EntityScopesFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        id
scopes
      `
}

export type EventTypeFields = 'basic' | 'all' | Record<string, never>

export const EventTypeGQLFields = (fields: EventTypeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        description
name
noun
requiredScope
shortDescription
verb
      `
}

export interface CustomExportFields {
  file?: FileFields
  filterBy?: MemberListFilterByFields
}
export type ExportFields = 'basic' | 'all' | CustomExportFields

export const ExportGQLFields = (fields: ExportFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      file: 'basic',
      filterBy: 'basic',
    }
  }
  return `
        __typename
        createdAt
endedAt
fields

    ${
      fields.file
        ? `
      file {
        ${FileGQLFields(fields.file)}
      }
    `
        : ``
    }

fileId

    ${
      fields.filterBy
        ? `
      filterBy {
        ${MemberListFilterByGQLFields(fields.filterBy)}
      }
    `
        : ``
    }

id
networkId
startedAt
status
updatedAt
      `
}

export interface CustomExportEdgeFields {
  node?: ExportFields
}
export type ExportEdgeFields = 'basic' | 'all' | CustomExportEdgeFields

export const ExportEdgeGQLFields = (fields: ExportEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${ExportGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export type ExtraPropertyFields = 'basic' | 'all' | Record<string, never>

export const ExtraPropertyGQLFields = (fields: ExtraPropertyFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        key
value
      `
}

export type FileFields = 'basic' | 'all' | Record<string, never>

export const FileGQLFields = (fields: FileFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        downloadUrl
extension
id
name
size
url
      `
}

export interface CustomFooterFields {
  urls?: FooterUrlFields
}
export type FooterFields = 'basic' | 'all' | CustomFooterFields

export const FooterGQLFields = (fields: FooterFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      urls: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.urls
        ? `
      urls {
        ${FooterUrlGQLFields(fields.urls)}
      }
    `
        : ``
    }

      `
}

export type FooterUrlFields = 'basic' | 'all' | Record<string, never>

export const FooterUrlGQLFields = (fields: FooterUrlFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        title
url
      `
}

export type GlobalTokenFields = 'basic' | 'all' | Record<string, never>

export const GlobalTokenGQLFields = (fields: GlobalTokenFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        accessToken
email
      `
}

export type GlyphFields = 'basic' | 'all' | Record<string, never>

export const GlyphGQLFields = (fields: GlyphFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        id
text
variant
      `
}

export interface CustomHubContentFields {
  attachments?: FileFields
  embeds?: EmbedFields
  fields?: HubContentCustomFieldFields
  images?: MediaFields
  thumbnail?: MediaFields
}
export type HubContentFields = 'basic' | 'all' | CustomHubContentFields

export const HubContentGQLFields = (fields: HubContentFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      attachments: 'basic',
      embeds: 'basic',
      fields: 'basic',
      images: 'basic',
      thumbnail: 'basic',
    }
  }
  return `
        __typename
        attachmentIds

    ${
      fields.attachments
        ? `
      attachments {
        ${FileGQLFields(fields.attachments)}
      }
    `
        : ``
    }

createdAt
description
embedIds

    ${
      fields.embeds
        ? `
      embeds {
        ${EmbedGQLFields(fields.embeds)}
      }
    `
        : ``
    }


    ${
      fields.fields
        ? `
      fields {
        ${HubContentCustomFieldGQLFields(fields.fields)}
      }
    `
        : ``
    }

id
imageIds

    ${
      fields.images
        ? `
      images {
        ${MediaGQLFields(fields.images)}
      }
    `
        : ``
    }

language
locked
postTypeId
publishedAt
slug
tagIds

    ${
      fields.thumbnail
        ? `
      thumbnail {
        ${MediaGQLFields(fields.thumbnail)}
      }
    `
        : ``
    }

thumbnailId
title
updatedAt
      `
}

export interface CustomHubContentCustomFieldFields {
  relationEntities?: HubContentCustomFieldRelationFields
}
export type HubContentCustomFieldFields =
  | 'basic'
  | 'all'
  | CustomHubContentCustomFieldFields

export const HubContentCustomFieldGQLFields = (
  fields: HubContentCustomFieldFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      relationEntities: 'basic',
    }
  }
  return `
        __typename
        key

    ${
      fields.relationEntities
        ? `
      relationEntities {
        ${HubContentCustomFieldRelationGQLFields(fields.relationEntities)}
      }
    `
        : ``
    }

value
      `
}

export interface CustomHubContentCustomFieldRelationFields {
  medias?: MediaFields
}
export type HubContentCustomFieldRelationFields =
  | 'basic'
  | 'all'
  | CustomHubContentCustomFieldRelationFields

export const HubContentCustomFieldRelationGQLFields = (
  fields: HubContentCustomFieldRelationFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      medias: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.medias
        ? `
      medias {
        ${MediaGQLFields(fields.medias)}
      }
    `
        : ``
    }

      `
}

export interface CustomImageFields {
  urls?: MediaUrlsFields
}
export type ImageFields = 'basic' | 'all' | CustomImageFields

export const ImageGQLFields = (fields: ImageFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      urls: 'basic',
    }
  }
  return `
        __typename
        cropHeight
cropWidth
cropX
cropY
cropZoom
dominantColorHex
downloadUrl
dpi
height
id
name
url

    ${
      fields.urls
        ? `
      urls {
        ${MediaUrlsGQLFields(fields.urls)}
      }
    `
        : ``
    }

width
      `
}

export interface CustomInFields {
  onSpace?: SpaceFields
}
export type InFields = 'basic' | 'all' | CustomInFields

export const InGQLFields = (fields: InFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      onSpace: 'basic',
    }
  }
  return `
        __typename

          ${
            fields.onSpace
              ? `
            ... on Space {
              ${SpaceGQLFields(fields.onSpace)}
            }
          `
              : ``
          }

      `
}

export interface CustomInputPathPermissionsFields {
  isAuthorized?: IsAuthorizedFields
  values?: ValuePermissionsFields
}
export type InputPathPermissionsFields =
  | 'basic'
  | 'all'
  | CustomInputPathPermissionsFields

export const InputPathPermissionsGQLFields = (
  fields: InputPathPermissionsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      isAuthorized: 'basic',
      values: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.isAuthorized
        ? `
      isAuthorized {
        ${IsAuthorizedGQLFields(fields.isAuthorized)}
      }
    `
        : ``
    }

path

    ${
      fields.values
        ? `
      values {
        ${ValuePermissionsGQLFields(fields.values)}
      }
    `
        : ``
    }

      `
}

export type IntValueFields = 'basic' | 'all' | Record<string, never>

export const IntValueGQLFields = (fields: IntValueFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        int
      `
}

export type InvoiceFields = 'basic' | 'all' | Record<string, never>

export const InvoiceGQLFields = (fields: InvoiceFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        cardLastFourDigits
currency
date
invoiceUrl
status
total
      `
}

export type IsAuthorizedFields = 'basic' | 'all' | Record<string, never>

export const IsAuthorizedGQLFields = (fields: IsAuthorizedFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        authorized
reason
requiredPlan
      `
}

export type JwtSsoAuthUrlsFields = 'basic' | 'all' | Record<string, never>

export const JwtSsoAuthUrlsGQLFields = (fields: JwtSsoAuthUrlsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        authorizationUrl
signUpUrl
      `
}

export type KeyValueFields = 'basic' | 'all' | Record<string, never>

export const KeyValueGQLFields = (fields: KeyValueFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        key
value
      `
}

export interface CustomLeaderboardItemFields {
  member?: MemberFields
}
export type LeaderboardItemFields =
  | 'basic'
  | 'all'
  | CustomLeaderboardItemFields

export const LeaderboardItemGQLFields = (fields: LeaderboardItemFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      member: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.member
        ? `
      member {
        ${MemberGQLFields(fields.member)}
      }
    `
        : ``
    }

memberId
score
      `
}

export interface CustomMassActionFiltersModelFields {
  filterBy?: MemberListFilterByFields
}
export type MassActionFiltersModelFields =
  | 'basic'
  | 'all'
  | CustomMassActionFiltersModelFields

export const MassActionFiltersModelGQLFields = (
  fields: MassActionFiltersModelFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      filterBy: 'basic',
    }
  }
  return `
        __typename
        entityIds

    ${
      fields.filterBy
        ? `
      filterBy {
        ${MemberListFilterByGQLFields(fields.filterBy)}
      }
    `
        : ``
    }

query
      `
}

export interface CustomMassActionRequestFields {
  filters?: MassActionFiltersModelFields
}
export type MassActionRequestFields =
  | 'basic'
  | 'all'
  | CustomMassActionRequestFields

export const MassActionRequestGQLFields = (fields: MassActionRequestFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      filters: 'basic',
    }
  }
  return `
        __typename
        action
context
createdAt
createdById
endedAt
entitiesCount
failedCount

    ${
      fields.filters
        ? `
      filters {
        ${MassActionFiltersModelGQLFields(fields.filters)}
      }
    `
        : ``
    }

id
networkId
processedCount
startedAt
status
updatedAt
      `
}

export interface CustomMediaFields {
  onEmoji?: EmojiFields
  onFile?: FileFields
  onGlyph?: GlyphFields
  onImage?: ImageFields
}
export type MediaFields = 'basic' | 'all' | CustomMediaFields

export const MediaGQLFields = (fields: MediaFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      onEmoji: 'basic',
      onFile: 'basic',
      onGlyph: 'basic',
      onImage: 'basic',
    }
  }
  return `
        __typename

          ${
            fields.onEmoji
              ? `
            ... on Emoji {
              ${EmojiGQLFields(fields.onEmoji)}
            }
          `
              : ``
          }


          ${
            fields.onFile
              ? `
            ... on File {
              ${FileGQLFields(fields.onFile)}
            }
          `
              : ``
          }


          ${
            fields.onGlyph
              ? `
            ... on Glyph {
              ${GlyphGQLFields(fields.onGlyph)}
            }
          `
              : ``
          }


          ${
            fields.onImage
              ? `
            ... on Image {
              ${ImageGQLFields(fields.onImage)}
            }
          `
              : ``
          }

      `
}

export type MediaLimitsFields = 'basic' | 'all' | Record<string, never>

export const MediaLimitsGQLFields = (fields: MediaLimitsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        fileSizeLimit
fileTypesLimit
imageSizeLimit
imageTypesLimit
      `
}

export type MediaNetworkSettingsFields = 'basic' | 'all' | Record<string, never>

export const MediaNetworkSettingsGQLFields = (
  fields: MediaNetworkSettingsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        fileSizeLimit
fileTypesLimit
imageSizeLimit
imageTypesLimit
      `
}

export type MediaUrlsFields = 'basic' | 'all' | Record<string, never>

export const MediaUrlsGQLFields = (fields: MediaUrlsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        full
large
medium
small
thumb
      `
}

export interface CustomMemberFields {
  activeSession?: MemberSessionFields
  attributes?: MemberAttributesFields
  authMemberProps?: MemberAuthMemberPropsFields
  badges?: MemberBadgeFields
  banner?: MediaFields
  blockedMembers?: MemberFields

  extraProperties?: {
    fields: ExtraPropertyFields
    variables: Types.MemberExtraPropertiesArgs
  }

  fields?: CustomFieldFields
  network?: NetworkFields
  profilePicture?: MediaFields
  role?: RoleFields
  sessions?: MemberSessionFields
  settings?: MemberSettingsFields
  shortcuts?: ShortcutFields

  spaces?: {
    fields: PaginatedSpaceFields
    variables: Types.MemberSpacesArgs
  }
}
export type MemberFields = 'basic' | 'all' | CustomMemberFields

export const MemberGQLFields = (fields: MemberFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      activeSession: 'basic',
      attributes: 'basic',
      authMemberProps: 'basic',
      badges: 'basic',
      banner: 'basic',
      blockedMembers: 'basic',
      fields: 'basic',
      network: 'basic',
      profilePicture: 'basic',
      role: 'basic',
      sessions: 'basic',
      settings: 'basic',
      shortcuts: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.activeSession
        ? `
      activeSession {
        ${MemberSessionGQLFields(fields.activeSession)}
      }
    `
        : ``
    }


    ${
      fields.attributes
        ? `
      attributes {
        ${MemberAttributesGQLFields(fields.attributes)}
      }
    `
        : ``
    }


    ${
      fields.authMemberProps
        ? `
      authMemberProps {
        ${MemberAuthMemberPropsGQLFields(fields.authMemberProps)}
      }
    `
        : ``
    }


    ${
      fields.badges
        ? `
      badges {
        ${MemberBadgeGQLFields(fields.badges)}
      }
    `
        : ``
    }


    ${
      fields.banner
        ? `
      banner {
        ${MediaGQLFields(fields.banner)}
      }
    `
        : ``
    }

bannerId
blockedMemberIds

    ${
      fields.blockedMembers
        ? `
      blockedMembers {
        ${MemberGQLFields(fields.blockedMembers)}
      }
    `
        : ``
    }

createdAt
displayName
email
emailStatus
externalId
externalUrl

      ${
        fields.extraProperties
          ? `
        extraProperties
        ${
          fields.extraProperties?.variables
            ? `
          (

    ${
      fields.extraProperties.variables.keys
        ? `
      keys: ${fields.extraProperties.variables.keys}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${ExtraPropertyGQLFields(fields.extraProperties.fields)}
        }
      `
          : ``
      }


    ${
      fields.fields
        ? `
      fields {
        ${CustomFieldGQLFields(fields.fields)}
      }
    `
        : ``
    }

flagged
id
lastSeen
lastSeenAt
locale
name

    ${
      fields.network
        ? `
      network {
        ${NetworkGQLFields(fields.network)}
      }
    `
        : ``
    }

networkId
newEmail
overrideTeammate

    ${
      fields.profilePicture
        ? `
      profilePicture {
        ${MediaGQLFields(fields.profilePicture)}
      }
    `
        : ``
    }

profilePictureId
relativeUrl

    ${
      fields.role
        ? `
      role {
        ${RoleGQLFields(fields.role)}
      }
    `
        : ``
    }

roleId
score

    ${
      fields.sessions
        ? `
      sessions {
        ${MemberSessionGQLFields(fields.sessions)}
      }
    `
        : ``
    }


    ${
      fields.settings
        ? `
      settings {
        ${MemberSettingsGQLFields(fields.settings)}
      }
    `
        : ``
    }


    ${
      fields.shortcuts
        ? `
      shortcuts {
        ${ShortcutGQLFields(fields.shortcuts)}
      }
    `
        : ``
    }


      ${
        fields.spaces
          ? `
        spaces
        ${
          fields.spaces?.variables
            ? `
          (

    ${
      fields.spaces.variables.exploreOnly
        ? `
      exploreOnly: ${fields.spaces.variables.exploreOnly}
    `
        : ``
    }


    ${
      fields.spaces.variables.limit
        ? `
      limit: ${fields.spaces.variables.limit}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PaginatedSpaceGQLFields(fields.spaces.fields)}
        }
      `
          : ``
      }

staffReasons
status
subscribersCount
tagline
teammate
updatedAt
url
username
verifiedAt
      `
}

export type MemberAttributesFields = 'basic' | 'all' | Record<string, never>

export const MemberAttributesGQLFields = (fields: MemberAttributesFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        locale
      `
}

export interface CustomMemberAuthMemberPropsFields {
  permissions?: ActionPermissionsFields
}
export type MemberAuthMemberPropsFields =
  | 'basic'
  | 'all'
  | CustomMemberAuthMemberPropsFields

export const MemberAuthMemberPropsGQLFields = (
  fields: MemberAuthMemberPropsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      permissions: 'basic',
    }
  }
  return `
        __typename
        canSendPrivateMessages
context

    ${
      fields.permissions
        ? `
      permissions {
        ${ActionPermissionsGQLFields(fields.permissions)}
      }
    `
        : ``
    }

scopes
subscribed
      `
}

export interface CustomMemberBadgeFields {
  badge?: BadgeFields
  image?: MediaFields
}
export type MemberBadgeFields = 'basic' | 'all' | CustomMemberBadgeFields

export const MemberBadgeGQLFields = (fields: MemberBadgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      badge: 'basic',
      image: 'basic',
    }
  }
  return `
        __typename
        backgroundColor

    ${
      fields.badge
        ? `
      badge {
        ${BadgeGQLFields(fields.badge)}
      }
    `
        : ``
    }

badgeId

    ${
      fields.image
        ? `
      image {
        ${MediaGQLFields(fields.image)}
      }
    `
        : ``
    }

imageId
longDescription
shortDescription
text
textColor
type
      `
}

export interface CustomMemberEdgeFields {
  node?: MemberFields
}
export type MemberEdgeFields = 'basic' | 'all' | CustomMemberEdgeFields

export const MemberEdgeGQLFields = (fields: MemberEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${MemberGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomMemberForSpaceInvitationFields {
  member?: MemberFields
}
export type MemberForSpaceInvitationFields =
  | 'basic'
  | 'all'
  | CustomMemberForSpaceInvitationFields

export const MemberForSpaceInvitationGQLFields = (
  fields: MemberForSpaceInvitationFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      member: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.member
        ? `
      member {
        ${MemberGQLFields(fields.member)}
      }
    `
        : ``
    }

memberId
spaceAffiliation
      `
}

export interface CustomMemberInvitationFields {
  emailLog?: EmailLogFields
  emailLogs?: EmailLogFields
  invitee?: MemberFields
  inviter?: MemberFields
  network?: NetworkFields
  role?: RoleFields
}
export type MemberInvitationFields =
  | 'basic'
  | 'all'
  | CustomMemberInvitationFields

export const MemberInvitationGQLFields = (fields: MemberInvitationFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      emailLog: 'basic',
      emailLogs: 'basic',
      invitee: 'basic',
      inviter: 'basic',
      network: 'basic',
      role: 'basic',
    }
  }
  return `
        __typename
        createdAt

    ${
      fields.emailLog
        ? `
      emailLog {
        ${EmailLogGQLFields(fields.emailLog)}
      }
    `
        : ``
    }


    ${
      fields.emailLogs
        ? `
      emailLogs {
        ${EmailLogGQLFields(fields.emailLogs)}
      }
    `
        : ``
    }

expiresAt
id
invitationMessage

    ${
      fields.invitee
        ? `
      invitee {
        ${MemberGQLFields(fields.invitee)}
      }
    `
        : ``
    }

inviteeEmail
inviteeId
inviteeName

    ${
      fields.inviter
        ? `
      inviter {
        ${MemberGQLFields(fields.inviter)}
      }
    `
        : ``
    }

inviterId
joinedAt

    ${
      fields.network
        ? `
      network {
        ${NetworkGQLFields(fields.network)}
      }
    `
        : ``
    }

networkId

    ${
      fields.role
        ? `
      role {
        ${RoleGQLFields(fields.role)}
      }
    `
        : ``
    }

roleId
status
      `
}

export interface CustomMemberInvitationEdgeFields {
  node?: MemberInvitationFields
}
export type MemberInvitationEdgeFields =
  | 'basic'
  | 'all'
  | CustomMemberInvitationEdgeFields

export const MemberInvitationEdgeGQLFields = (
  fields: MemberInvitationEdgeFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${MemberInvitationGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomMemberInvitationLinkFields {
  inviter?: MemberFields
  network?: NetworkFields
}
export type MemberInvitationLinkFields =
  | 'basic'
  | 'all'
  | CustomMemberInvitationLinkFields

export const MemberInvitationLinkGQLFields = (
  fields: MemberInvitationLinkFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      inviter: 'basic',
      network: 'basic',
    }
  }
  return `
        __typename
        createdAt
id

    ${
      fields.inviter
        ? `
      inviter {
        ${MemberGQLFields(fields.inviter)}
      }
    `
        : ``
    }

link

    ${
      fields.network
        ? `
      network {
        ${NetworkGQLFields(fields.network)}
      }
    `
        : ``
    }

      `
}

export type MemberListFilterByFields = 'basic' | 'all' | Record<string, never>

export const MemberListFilterByGQLFields = (
  fields: MemberListFilterByFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        key
operator
value
      `
}

export type MemberNetworkNotificationSettingsFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const MemberNetworkNotificationSettingsGQLFields = (
  fields: MemberNetworkNotificationSettingsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        channel
enabled
isDefault
mention
message
reaction
sameAsDefault
      `
}

export interface CustomMemberNotificationSettingsFields {
  network?: MemberNetworkNotificationSettingsFields
  spaces?: MemberSpaceNotificationSettingsFields
}
export type MemberNotificationSettingsFields =
  | 'basic'
  | 'all'
  | CustomMemberNotificationSettingsFields

export const MemberNotificationSettingsGQLFields = (
  fields: MemberNotificationSettingsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      network: 'basic',
      spaces: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.network
        ? `
      network {
        ${MemberNetworkNotificationSettingsGQLFields(fields.network)}
      }
    `
        : ``
    }


    ${
      fields.spaces
        ? `
      spaces {
        ${MemberSpaceNotificationSettingsGQLFields(fields.spaces)}
      }
    `
        : ``
    }

      `
}

export type MemberPostNotificationSettingsFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const MemberPostNotificationSettingsGQLFields = (
  fields: MemberPostNotificationSettingsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        enabled
memberId
postId
      `
}

export type MemberPrivateMessagingSettingsFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const MemberPrivateMessagingSettingsGQLFields = (
  fields: MemberPrivateMessagingSettingsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        privateMessagingEnabled
      `
}

export type MemberSessionFields = 'basic' | 'all' | Record<string, never>

export const MemberSessionGQLFields = (fields: MemberSessionFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        active
country
createdAt
deviceBrand
id
ip
lastActivityAt
os
osVersion
      `
}

export interface CustomMemberSettingsFields {
  privateMessaging?: MemberPrivateMessagingSettingsFields
}
export type MemberSettingsFields = 'basic' | 'all' | CustomMemberSettingsFields

export const MemberSettingsGQLFields = (fields: MemberSettingsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      privateMessaging: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.privateMessaging
        ? `
      privateMessaging {
        ${MemberPrivateMessagingSettingsGQLFields(fields.privateMessaging)}
      }
    `
        : ``
    }

      `
}

export interface CustomMemberSpaceNotificationSettingsFields {
  space?: SpaceFields
}
export type MemberSpaceNotificationSettingsFields =
  | 'basic'
  | 'all'
  | CustomMemberSpaceNotificationSettingsFields

export const MemberSpaceNotificationSettingsGQLFields = (
  fields: MemberSpaceNotificationSettingsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      space: 'basic',
    }
  }
  return `
        __typename
        channel
enabled
isDefault
preference
sameAsDefault

    ${
      fields.space
        ? `
      space {
        ${SpaceGQLFields(fields.space)}
      }
    `
        : ``
    }

      `
}

export type MembersPermissionSettingsFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const MembersPermissionSettingsGQLFields = (
  fields: MembersPermissionSettingsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        canDeleteAccount
      `
}

export type MigrationFields = 'basic' | 'all' | Record<string, never>

export const MigrationGQLFields = (fields: MigrationFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        id
networkId
requesterId
stage
status
      `
}

export type MigrationFieldErrorFields = 'basic' | 'all' | Record<string, never>

export const MigrationFieldErrorGQLFields = (
  fields: MigrationFieldErrorFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        field
message
      `
}

export interface CustomMigrationFileResultFields {
  rowErrors?: MigrationRowErrorsFields
}
export type MigrationFileResultFields =
  | 'basic'
  | 'all'
  | CustomMigrationFileResultFields

export const MigrationFileResultGQLFields = (
  fields: MigrationFileResultFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      rowErrors: 'basic',
    }
  }
  return `
        __typename
        file
insertedRows

    ${
      fields.rowErrors
        ? `
      rowErrors {
        ${MigrationRowErrorsGQLFields(fields.rowErrors)}
      }
    `
        : ``
    }

skippedRows
      `
}

export interface CustomMigrationRowErrorsFields {
  fieldErrors?: MigrationFieldErrorFields
}
export type MigrationRowErrorsFields =
  | 'basic'
  | 'all'
  | CustomMigrationRowErrorsFields

export const MigrationRowErrorsGQLFields = (
  fields: MigrationRowErrorsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      fieldErrors: 'basic',
    }
  }
  return `
        __typename
        externalId

    ${
      fields.fieldErrors
        ? `
      fieldErrors {
        ${MigrationFieldErrorGQLFields(fields.fieldErrors)}
      }
    `
        : ``
    }

message
rowNumber
      `
}

export interface CustomModerationItemFields {
  entity?: ModerationItemEntityFields
  moderator?: MemberFields

  reporters?: {
    fields: PaginatedModerationItemReporterFields
    variables: Types.ModerationItemReportersArgs
  }
}
export type ModerationItemFields = 'basic' | 'all' | CustomModerationItemFields

export const ModerationItemGQLFields = (fields: ModerationItemFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      entity: 'basic',
      moderator: 'basic',
    }
  }
  return `
        __typename
        createdAt
description

    ${
      fields.entity
        ? `
      entity {
        ${ModerationItemEntityGQLFields(fields.entity)}
      }
    `
        : ``
    }

flaggedBy
id
memberId

    ${
      fields.moderator
        ? `
      moderator {
        ${MemberGQLFields(fields.moderator)}
      }
    `
        : ``
    }


      ${
        fields.reporters
          ? `
        reporters
        ${
          fields.reporters?.variables
            ? `
          (

    ${
      fields.reporters.variables.after
        ? `
      after: ${fields.reporters.variables.after}
    `
        : ``
    }


    ${
      fields.reporters.variables.before
        ? `
      before: ${fields.reporters.variables.before}
    `
        : ``
    }


    ${
      fields.reporters.variables.limit
        ? `
      limit: ${fields.reporters.variables.limit}
    `
        : ``
    }


    ${
      fields.reporters.variables.offset
        ? `
      offset: ${fields.reporters.variables.offset}
    `
        : ``
    }


    ${
      fields.reporters.variables.reverse
        ? `
      reverse: ${fields.reporters.variables.reverse}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PaginatedModerationItemReporterGQLFields(fields.reporters.fields)}
        }
      `
          : ``
      }

spaceId
status
updatedAt
      `
}

export interface CustomModerationItemEdgeFields {
  node?: ModerationItemFields
}
export type ModerationItemEdgeFields =
  | 'basic'
  | 'all'
  | CustomModerationItemEdgeFields

export const ModerationItemEdgeGQLFields = (
  fields: ModerationItemEdgeFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${ModerationItemGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomModerationItemEntityFields {
  onMember?: MemberFields
  onPost?: PostFields
}
export type ModerationItemEntityFields =
  | 'basic'
  | 'all'
  | CustomModerationItemEntityFields

export const ModerationItemEntityGQLFields = (
  fields: ModerationItemEntityFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      onMember: 'basic',
      onPost: 'basic',
    }
  }
  return `
        __typename

          ${
            fields.onMember
              ? `
            ... on Member {
              ${MemberGQLFields(fields.onMember)}
            }
          `
              : ``
          }


          ${
            fields.onPost
              ? `
            ... on Post {
              ${PostGQLFields(fields.onPost)}
            }
          `
              : ``
          }

      `
}

export interface CustomModerationItemReporterFields {
  reporter?: MemberFields
}
export type ModerationItemReporterFields =
  | 'basic'
  | 'all'
  | CustomModerationItemReporterFields

export const ModerationItemReporterGQLFields = (
  fields: ModerationItemReporterFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      reporter: 'basic',
    }
  }
  return `
        __typename
        createdAt
description
id
reportCategory

    ${
      fields.reporter
        ? `
      reporter {
        ${MemberGQLFields(fields.reporter)}
      }
    `
        : ``
    }

updatedAt
      `
}

export interface CustomModerationItemReporterEdgeFields {
  node?: ModerationItemReporterFields
}
export type ModerationItemReporterEdgeFields =
  | 'basic'
  | 'all'
  | CustomModerationItemReporterEdgeFields

export const ModerationItemReporterEdgeGQLFields = (
  fields: ModerationItemReporterEdgeFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${ModerationItemReporterGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export type ModerationMemberSettingsFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const ModerationMemberSettingsGQLFields = (
  fields: ModerationMemberSettingsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        minimumHoursToPost
minimumHoursToReply
      `
}

export interface CustomModerationSettingsFields {
  member?: ModerationMemberSettingsFields
}
export type ModerationSettingsFields =
  | 'basic'
  | 'all'
  | CustomModerationSettingsFields

export const ModerationSettingsGQLFields = (
  fields: ModerationSettingsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      member: 'basic',
    }
  }
  return `
        __typename
        akismet
blackListPostTypeIds
blackListSpaceIds
customBlacklist
enableBlacklisting

    ${
      fields.member
        ? `
      member {
        ${ModerationMemberSettingsGQLFields(fields.member)}
      }
    `
        : ``
    }

oopSpam
preventPublish
useDefaultBlacklisting
      `
}

export interface CustomMutationFields {
  addEmoji?: {
    fields: EmojiFields
    variables: Types.MutationAddEmojiArgs
  }

  addEmojis?: {
    fields: EmojiFields
    variables: Types.MutationAddEmojisArgs
  }

  addFcmTokenToSubscriberSettings?: {
    fields: ActionFields
    variables: Types.MutationAddFcmTokenToSubscriberSettingsArgs
  }

  addGroup?: {
    fields: CollectionFields
    variables: Types.MutationAddGroupArgs
  }

  addImage?: {
    fields: SignedUrlFields
    variables: Types.MutationAddImageArgs
  }

  addImages?: {
    fields: SignedUrlFields
    variables: Types.MutationAddImagesArgs
  }

  addMedias?: {
    fields: SignedUrlFields
    variables: Types.MutationAddMediasArgs
  }

  addMemberSchemaField?: {
    fields: NetworkFields
    variables: Types.MutationAddMemberSchemaFieldArgs
  }

  addModerationReport?: {
    fields: ModerationItemFields
    variables: Types.MutationAddModerationReportArgs
  }

  addNetwork?: {
    fields: AuthTokenWithOtpFields
    variables: Types.MutationAddNetworkArgs
  }

  addNewDomain?: {
    fields: DomainTransferStatusFields
    variables: Types.MutationAddNewDomainArgs
  }

  addPost?: {
    fields: PostFields
    variables: Types.MutationAddPostArgs
  }

  addPostTypeField?: {
    fields: PostTypeFields
    variables: Types.MutationAddPostTypeFieldArgs
  }

  addReaction?: {
    fields: ActionFields
    variables: Types.MutationAddReactionArgs
  }

  addReply?: {
    fields: PostFields
    variables: Types.MutationAddReplyArgs
  }

  addSpace?: {
    fields: SpaceFields
    variables: Types.MutationAddSpaceArgs
  }

  addSpaceMembers?: {
    fields: SpaceMemberFields
    variables: Types.MutationAddSpaceMembersArgs
  }

  addTemplateToNetwork?: {
    fields: TemplateRequestFields
    variables: Types.MutationAddTemplateToNetworkArgs
  }

  addTrialPlan?: {
    fields: PlanFields
    variables: Types.MutationAddTrialPlanArgs
  }

  approveSpaceJoinRequest?: {
    fields: ActionFields
    variables: Types.MutationApproveSpaceJoinRequestArgs
  }

  approveSpaceMembershipRequest?: {
    fields: ActionFields
    variables: Types.MutationApproveSpaceMembershipRequestArgs
  }

  archiveMemberSchemaField?: {
    fields: NetworkFields
    variables: Types.MutationArchiveMemberSchemaFieldArgs
  }

  archivePostType?: {
    fields: PostTypeFields
    variables: Types.MutationArchivePostTypeArgs
  }

  archivePostTypeField?: {
    fields: PostTypeFields
    variables: Types.MutationArchivePostTypeFieldArgs
  }

  assignBadge?: {
    fields: ActionFields
    variables: Types.MutationAssignBadgeArgs
  }

  blockMember?: {
    fields: ActionFields
    variables: Types.MutationBlockMemberArgs
  }

  callback?: {
    fields: CallbackResponseFields
    variables: Types.MutationCallbackArgs
  }

  cancelDomainTransfer?: ActionFields

  cancelEmailUpdate?: {
    fields: ActionFields
    variables: Types.MutationCancelEmailUpdateArgs
  }

  cancelNetworkDeletion?: ActionFields

  changeHomepage?: {
    fields: ActionFields
    variables: Types.MutationChangeHomepageArgs
  }

  changeNetworkSubscriptionPlan?: {
    fields: NetworkFields
    variables: Types.MutationChangeNetworkSubscriptionPlanArgs
  }

  clearNewDomain?: ActionFields
  clearNotificationsCount?: ActionFields

  closeSpace?: {
    fields: ActionFields
    variables: Types.MutationCloseSpaceArgs
  }

  commitDomainTransfer?: ActionFields

  confirmMassActionRequest?: {
    fields: MassActionRequestFields
    variables: Types.MutationConfirmMassActionRequestArgs
  }

  confirmResetPassword?: {
    fields: ActionFields
    variables: Types.MutationConfirmResetPasswordArgs
  }

  createAccessGroup?: {
    fields: AccessGroupFields
    variables: Types.MutationCreateAccessGroupArgs
  }

  createBadge?: {
    fields: BadgeFields
    variables: Types.MutationCreateBadgeArgs
  }

  createBillingPortalSession?: {
    fields: BillingPortalSessionFields
    variables: Types.MutationCreateBillingPortalSessionArgs
  }

  createCollection?: {
    fields: CollectionFields
    variables: Types.MutationCreateCollectionArgs
  }

  createEmojis?: {
    fields: EmojiFields
    variables: Types.MutationCreateEmojisArgs
  }

  createExportMemberRequest?: {
    fields: ExportFields
    variables: Types.MutationCreateExportMemberRequestArgs
  }

  createFiles?: {
    fields: SignedUrlFields
    variables: Types.MutationCreateFilesArgs
  }

  createImages?: {
    fields: SignedUrlFields
    variables: Types.MutationCreateImagesArgs
  }

  createMassActionRequest?: {
    fields: MassActionRequestFields
    variables: Types.MutationCreateMassActionRequestArgs
  }

  createModerationReport?: {
    fields: ModerationItemFields
    variables: Types.MutationCreateModerationReportArgs
  }

  createNetwork?: {
    fields: AuthTokenWithOtpFields
    variables: Types.MutationCreateNetworkArgs
  }

  createPermission?: {
    fields: PermissionFields
    variables: Types.MutationCreatePermissionArgs
  }

  createPost?: {
    fields: PostFields
    variables: Types.MutationCreatePostArgs
  }

  createPostType?: {
    fields: PostTypeFields
    variables: Types.MutationCreatePostTypeArgs
  }

  createReply?: {
    fields: PostFields
    variables: Types.MutationCreateReplyArgs
  }

  createReport?: {
    fields: ModerationItemFields
    variables: Types.MutationCreateReportArgs
  }

  createSpace?: {
    fields: SpaceFields
    variables: Types.MutationCreateSpaceArgs
  }

  createSpaceFromTemplate?: {
    fields: TemplateRequestFields
    variables: Types.MutationCreateSpaceFromTemplateArgs
  }

  createSubscriptionCheckoutSession?: {
    fields: SubscriptionCheckoutSessionFields
    variables: Types.MutationCreateSubscriptionCheckoutSessionArgs
  }

  createTag?: {
    fields: TagFields
    variables: Types.MutationCreateTagArgs
  }

  createTemplate?: {
    fields: TemplateFields
    variables: Types.MutationCreateTemplateArgs
  }

  declineSpaceJoinRequest?: {
    fields: ActionFields
    variables: Types.MutationDeclineSpaceJoinRequestArgs
  }

  declineSpaceMembershipRequest?: {
    fields: ActionFields
    variables: Types.MutationDeclineSpaceMembershipRequestArgs
  }

  deleteAccessGroup?: {
    fields: ActionFields
    variables: Types.MutationDeleteAccessGroupArgs
  }

  deleteBadge?: {
    fields: ActionFields
    variables: Types.MutationDeleteBadgeArgs
  }

  deleteCollection?: {
    fields: ActionFields
    variables: Types.MutationDeleteCollectionArgs
  }

  deleteMember?: {
    fields: ActionFields
    variables: Types.MutationDeleteMemberArgs
  }

  deleteMemberSchemaField?: {
    fields: NetworkFields
    variables: Types.MutationDeleteMemberSchemaFieldArgs
  }

  deleteNetwork?: ActionFields

  deleteNotification?: {
    fields: ActionFields
    variables: Types.MutationDeleteNotificationArgs
  }

  deleteNotifications?: {
    fields: ActionFields
    variables: Types.MutationDeleteNotificationsArgs
  }

  deletePageCustomResponse?: {
    fields: ActionFields
    variables: Types.MutationDeletePageCustomResponseArgs
  }

  deletePermission?: {
    fields: ActionFields
    variables: Types.MutationDeletePermissionArgs
  }

  deletePost?: {
    fields: ActionFields
    variables: Types.MutationDeletePostArgs
  }

  deletePostTypeField?: {
    fields: PostTypeFields
    variables: Types.MutationDeletePostTypeFieldArgs
  }

  deleteSpace?: {
    fields: ActionFields
    variables: Types.MutationDeleteSpaceArgs
  }

  deleteSpacePostTypes?: {
    fields: ActionFields
    variables: Types.MutationDeleteSpacePostTypesArgs
  }

  deleteSsoMembership?: {
    fields: ActionFields
    variables: Types.MutationDeleteSsoMembershipArgs
  }

  deleteTag?: {
    fields: ActionFields
    variables: Types.MutationDeleteTagArgs
  }

  deleteTemplate?: {
    fields: ActionFields
    variables: Types.MutationDeleteTemplateArgs
  }

  doResetPassword?: {
    fields: ActionFields
    variables: Types.MutationDoResetPasswordArgs
  }

  editImage?: {
    fields: ImageFields
    variables: Types.MutationEditImageArgs
  }

  extendCurrentTrial?: {
    fields: PlanFields
    variables: Types.MutationExtendCurrentTrialArgs
  }

  globalAddAppCollaborator?: {
    fields: AppCollaboratorFields
    variables: Types.MutationGlobalAddAppCollaboratorArgs
  }

  globalCreateApp?: {
    fields: AppFields
    variables: Types.MutationGlobalCreateAppArgs
  }

  globalCreateEmojis?: {
    fields: EmojiFields
    variables: Types.MutationGlobalCreateEmojisArgs
  }

  globalCreateImages?: {
    fields: SignedUrlFields
    variables: Types.MutationGlobalCreateImagesArgs
  }

  globalDeleteApp?: {
    fields: ActionFields
    variables: Types.MutationGlobalDeleteAppArgs
  }

  globalDeleteAppSetting?: {
    fields: AppSettingFields
    variables: Types.MutationGlobalDeleteAppSettingArgs
  }

  globalPublishAppPrivately?: {
    fields: AppPublicationFields
    variables: Types.MutationGlobalPublishAppPrivatelyArgs
  }

  globalRegenerateClientSecret?: {
    fields: AppFields
    variables: Types.MutationGlobalRegenerateClientSecretArgs
  }

  globalRemoveAppCollaborator?: {
    fields: ActionFields
    variables: Types.MutationGlobalRemoveAppCollaboratorArgs
  }

  globalTestAppWebhook?: {
    fields: ActionFields
    variables: Types.MutationGlobalTestAppWebhookArgs
  }

  globalUnPublishAppPrivately?: {
    fields: ActionFields
    variables: Types.MutationGlobalUnPublishAppPrivatelyArgs
  }

  globalUpdateApp?: {
    fields: AppFields
    variables: Types.MutationGlobalUpdateAppArgs
  }

  globalUpdateAppSetting?: {
    fields: AppSettingFields
    variables: Types.MutationGlobalUpdateAppSettingArgs
  }

  hidePost?: {
    fields: ActionFields
    variables: Types.MutationHidePostArgs
  }

  impersonateLoginToNetwork?: {
    fields: AuthInfoWithOtpFields
    variables: Types.MutationImpersonateLoginToNetworkArgs
  }

  installApp?: {
    fields: AppInstallationFields
    variables: Types.MutationInstallAppArgs
  }

  installAppOnAuthMember?: {
    fields: AppInstallationFields
    variables: Types.MutationInstallAppOnAuthMemberArgs
  }

  interactWithApps?: {
    fields: AppInteractionFields
    variables: Types.MutationInteractWithAppsArgs
  }

  inviteMembers?: {
    fields: MemberInvitationFields
    variables: Types.MutationInviteMembersArgs
  }

  joinNetwork?: {
    fields: AuthTokenFields
    variables: Types.MutationJoinNetworkArgs
  }

  joinNetworkWithInvitationLink?: {
    fields: AuthTokenFields
    variables: Types.MutationJoinNetworkWithInvitationLinkArgs
  }

  joinNetworkWithToken?: {
    fields: AuthTokenFields
    variables: Types.MutationJoinNetworkWithTokenArgs
  }

  joinSpace?: {
    fields: ActionFields
    variables: Types.MutationJoinSpaceArgs
  }

  leaveSpace?: {
    fields: ActionFields
    variables: Types.MutationLeaveSpaceArgs
  }

  loginNetwork?: {
    fields: AuthTokenFields
    variables: Types.MutationLoginNetworkArgs
  }

  loginNetworkWithPassword?: {
    fields: AuthTokenFields
    variables: Types.MutationLoginNetworkWithPasswordArgs
  }

  loginWithSamlResponse?: {
    fields: AuthTokenFields
    variables: Types.MutationLoginWithSamlResponseArgs
  }

  loginWithSsoCode?: {
    fields: AuthTokenFields
    variables: Types.MutationLoginWithSsoCodeArgs
  }

  logoutMemberSessions?: {
    fields: ActionFields
    variables: Types.MutationLogoutMemberSessionsArgs
  }

  logoutNetwork?: {
    fields: ActionFields
    variables: Types.MutationLogoutNetworkArgs
  }

  modifySubscriptionAddon?: {
    fields: ActionFields
    variables: Types.MutationModifySubscriptionAddonArgs
  }

  movePost?: {
    fields: PostFields
    variables: Types.MutationMovePostArgs
  }

  movePosts?: {
    fields: PostFields
    variables: Types.MutationMovePostsArgs
  }

  nominateNewDomain?: {
    fields: ActionFields
    variables: Types.MutationNominateNewDomainArgs
  }

  organizeCollections?: {
    fields: ActionFields
    variables: Types.MutationOrganizeCollectionsArgs
  }

  organizeGroupSpaces?: {
    fields: ActionFields
    variables: Types.MutationOrganizeGroupSpacesArgs
  }

  organizeGroups?: {
    fields: ActionFields
    variables: Types.MutationOrganizeGroupsArgs
  }

  organizeSpacesInCollection?: {
    fields: ActionFields
    variables: Types.MutationOrganizeSpacesInCollectionArgs
  }

  pinPostToSpace?: {
    fields: ActionFields
    variables: Types.MutationPinPostToSpaceArgs
  }

  pinReplyToPost?: {
    fields: ActionFields
    variables: Types.MutationPinReplyToPostArgs
  }

  publishAppPrivately?: {
    fields: AppPublicationFields
    variables: Types.MutationPublishAppPrivatelyArgs
  }

  publishTemplate?: {
    fields: ActionFields
    variables: Types.MutationPublishTemplateArgs
  }

  purchase?: {
    fields: BillingSubscriptionFields
    variables: Types.MutationPurchaseArgs
  }

  readNotification?: {
    fields: ActionFields
    variables: Types.MutationReadNotificationArgs
  }

  readNotifications?: {
    fields: ActionFields
    variables: Types.MutationReadNotificationsArgs
  }

  readSpace?: {
    fields: ActionFields
    variables: Types.MutationReadSpaceArgs
  }

  removeFcmTokenFromSubscriberSettings?: {
    fields: ActionFields
    variables: Types.MutationRemoveFcmTokenFromSubscriberSettingsArgs
  }

  removeGroup?: {
    fields: ActionFields
    variables: Types.MutationRemoveGroupArgs
  }

  removeMember?: {
    fields: ActionFields
    variables: Types.MutationRemoveMemberArgs
  }

  removeNetwork?: ActionFields

  removePost?: {
    fields: ActionFields
    variables: Types.MutationRemovePostArgs
  }

  removeReaction?: {
    fields: ActionFields
    variables: Types.MutationRemoveReactionArgs
  }

  removeSpaceMembers?: {
    fields: ActionFields
    variables: Types.MutationRemoveSpaceMembersArgs
  }

  reorderMemberSchemaFields?: {
    fields: NetworkFields
    variables: Types.MutationReorderMemberSchemaFieldsArgs
  }

  reorderPostTypeFields?: {
    fields: PostTypeFields
    variables: Types.MutationReorderPostTypeFieldsArgs
  }

  requestGlobalTokenCode?: {
    fields: ActionFields
    variables: Types.MutationRequestGlobalTokenCodeArgs
  }

  requestSpaceMembership?: {
    fields: SpaceJoinRequestFields
    variables: Types.MutationRequestSpaceMembershipArgs
  }

  resendGlobalTokenCode?: {
    fields: ActionFields
    variables: Types.MutationResendGlobalTokenCodeArgs
  }

  resendMemberInvitation?: {
    fields: ActionFields
    variables: Types.MutationResendMemberInvitationArgs
  }

  resendVerification?: ActionFields

  resetPassword?: {
    fields: ActionFields
    variables: Types.MutationResetPasswordArgs
  }

  revokeBadge?: {
    fields: ActionFields
    variables: Types.MutationRevokeBadgeArgs
  }

  revokeMemberInvitation?: {
    fields: ActionFields
    variables: Types.MutationRevokeMemberInvitationArgs
  }

  runMigration?: {
    fields: MigrationFields
    variables: Types.MutationRunMigrationArgs
  }

  sendResetPasswordEmail?: {
    fields: ActionFields
    variables: Types.MutationSendResetPasswordEmailArgs
  }

  setPrivatelyPublishedApps?: {
    fields: AppPublicationFields
    variables: Types.MutationSetPrivatelyPublishedAppsArgs
  }

  ssoRedirect?: {
    fields: AuthTokenFields
    variables: Types.MutationSsoRedirectArgs
  }

  subscribe?: {
    fields: ActionFields
    variables: Types.MutationSubscribeArgs
  }

  supportSsoRedirect?: {
    fields: SupportAuthTokenFields
    variables: Types.MutationSupportSsoRedirectArgs
  }

  suspendMember?: {
    fields: ActionFields
    variables: Types.MutationSuspendMemberArgs
  }

  syncTemplate?: {
    fields: TemplateFields
    variables: Types.MutationSyncTemplateArgs
  }

  transferToNewDomain?: ActionFields

  unArchiveMemberSchemaField?: {
    fields: NetworkFields
    variables: Types.MutationUnArchiveMemberSchemaFieldArgs
  }

  unPublishAppPrivately?: {
    variables: Types.MutationUnPublishAppPrivatelyArgs
  }

  unarchivePostType?: {
    fields: PostTypeFields
    variables: Types.MutationUnarchivePostTypeArgs
  }

  unarchivePostTypeField?: {
    fields: PostTypeFields
    variables: Types.MutationUnarchivePostTypeFieldArgs
  }

  unblockMember?: {
    fields: ActionFields
    variables: Types.MutationUnblockMemberArgs
  }

  unhidePost?: {
    fields: ActionFields
    variables: Types.MutationUnhidePostArgs
  }

  uninstallApp?: {
    fields: AppInstallationFields
    variables: Types.MutationUninstallAppArgs
  }

  unpinPostFromSpace?: {
    fields: ActionFields
    variables: Types.MutationUnpinPostFromSpaceArgs
  }

  unpinReplyFromPost?: {
    fields: ActionFields
    variables: Types.MutationUnpinReplyFromPostArgs
  }

  unpublishTemplate?: {
    fields: ActionFields
    variables: Types.MutationUnpublishTemplateArgs
  }

  unsetHomepage?: {
    fields: ActionFields
    variables: Types.MutationUnsetHomepageArgs
  }

  unsubscribe?: {
    fields: ActionFields
    variables: Types.MutationUnsubscribeArgs
  }

  unsubscribeFromNotification?: {
    fields: ActionFields
    variables: Types.MutationUnsubscribeFromNotificationArgs
  }

  unsubscribeWithToken?: {
    fields: ActionFields
    variables: Types.MutationUnsubscribeWithTokenArgs
  }

  unsuspendMember?: {
    fields: ActionFields
    variables: Types.MutationUnsuspendMemberArgs
  }

  updateAccessGroup?: {
    fields: AccessGroupFields
    variables: Types.MutationUpdateAccessGroupArgs
  }

  updateAppInstallation?: {
    fields: AppInstallationFields
    variables: Types.MutationUpdateAppInstallationArgs
  }

  updateAppNetworkSettings?: {
    fields: AppActionFields
    variables: Types.MutationUpdateAppNetworkSettingsArgs
  }

  updateAppSpaceSetting?: {
    fields: AppActionFields
    variables: Types.MutationUpdateAppSpaceSettingArgs
  }

  updateAppStanding?: {
    variables: Types.MutationUpdateAppStandingArgs
  }

  updateAppStatus?: {
    variables: Types.MutationUpdateAppStatusArgs
  }

  updateAuthMember?: {
    fields: MemberFields
    variables: Types.MutationUpdateAuthMemberArgs
  }

  updateBadge?: {
    fields: BadgeFields
    variables: Types.MutationUpdateBadgeArgs
  }

  updateBillingDetails?: {
    fields: BillingDetailsFields
    variables: Types.MutationUpdateBillingDetailsArgs
  }

  updateCollection?: {
    fields: ActionFields
    variables: Types.MutationUpdateCollectionArgs
  }

  updateCustomSso?: {
    fields: SsoFields
    variables: Types.MutationUpdateCustomSsoArgs
  }

  updateDefaultSsoStatus?: {
    fields: ActionFields
    variables: Types.MutationUpdateDefaultSsoStatusArgs
  }

  updateFile?: {
    fields: FileFields
    variables: Types.MutationUpdateFileArgs
  }

  updateGroup?: {
    fields: ActionFields
    variables: Types.MutationUpdateGroupArgs
  }

  updateImage?: {
    fields: ImageFields
    variables: Types.MutationUpdateImageArgs
  }

  updateJwtSso?: {
    fields: SsoFields
    variables: Types.MutationUpdateJwtSsoArgs
  }

  updateMember?: {
    fields: MemberFields
    variables: Types.MutationUpdateMemberArgs
  }

  updateMemberInvitation?: {
    fields: MemberInvitationFields
    variables: Types.MutationUpdateMemberInvitationArgs
  }

  updateMemberNetworkNotificationSettings?: {
    fields: MemberNetworkNotificationSettingsFields
    variables: Types.MutationUpdateMemberNetworkNotificationSettingsArgs
  }

  updateMemberPostNotificationSettings?: {
    fields: MemberPostNotificationSettingsFields
    variables: Types.MutationUpdateMemberPostNotificationSettingsArgs
  }

  updateMemberPrivateMessagingSettings?: {
    fields: MemberPrivateMessagingSettingsFields
    variables: Types.MutationUpdateMemberPrivateMessagingSettingsArgs
  }

  updateMemberSchemaField?: {
    fields: NetworkFields
    variables: Types.MutationUpdateMemberSchemaFieldArgs
  }

  updateMemberSpaceNotificationSettings?: {
    fields: MemberSpaceNotificationSettingsFields
    variables: Types.MutationUpdateMemberSpaceNotificationSettingsArgs
  }

  updateMemberSpaceRole?: {
    fields: ActionFields
    variables: Types.MutationUpdateMemberSpaceRoleArgs
  }

  updateModeration?: {
    fields: ActionFields
    variables: Types.MutationUpdateModerationArgs
  }

  updateModerationItem?: {
    fields: ActionFields
    variables: Types.MutationUpdateModerationItemArgs
  }

  updateModerationSettings?: {
    fields: ModerationSettingsFields
    variables: Types.MutationUpdateModerationSettingsArgs
  }

  updateNetwork?: {
    fields: NetworkFields
    variables: Types.MutationUpdateNetworkArgs
  }

  updateNetworkAvailableLocales?: {
    fields: NetworkFields
    variables: Types.MutationUpdateNetworkAvailableLocalesArgs
  }

  updateNetworkCustomCapacities?: {
    fields: NetworkFields
    variables: Types.MutationUpdateNetworkCustomCapacitiesArgs
  }

  updateNetworkDomainSubfolder?: {
    fields: NetworkFields
    variables: Types.MutationUpdateNetworkDomainSubfolderArgs
  }

  updateNetworkMediaSettings?: {
    fields: MediaNetworkSettingsFields
    variables: Types.MutationUpdateNetworkMediaSettingsArgs
  }

  updateNetworkNotificationSettings?: {
    fields: NotificationNetworkSettingsFields
    variables: Types.MutationUpdateNetworkNotificationSettingsArgs
  }

  updateNetworkStatus?: {
    fields: ActionFields
    variables: Types.MutationUpdateNetworkStatusArgs
  }

  updateNewDomain?: {
    fields: DomainTransferStatusFields
    variables: Types.MutationUpdateNewDomainArgs
  }

  updatePasswordWithToken?: {
    fields: ActionFields
    variables: Types.MutationUpdatePasswordWithTokenArgs
  }

  updatePermission?: {
    fields: PermissionFields
    variables: Types.MutationUpdatePermissionArgs
  }

  updatePost?: {
    fields: PostFields
    variables: Types.MutationUpdatePostArgs
  }

  updatePostType?: {
    fields: PostTypeFields
    variables: Types.MutationUpdatePostTypeArgs
  }

  updatePostTypeField?: {
    fields: PostTypeFields
    variables: Types.MutationUpdatePostTypeFieldArgs
  }

  updatePrivateMessagingSettings?: {
    fields: PrivateMessagingSettingsFields
    variables: Types.MutationUpdatePrivateMessagingSettingsArgs
  }

  updatePublisherSettings?: {
    fields: ActionFields
    variables: Types.MutationUpdatePublisherSettingsArgs
  }

  updateSpace?: {
    fields: SpaceFields
    variables: Types.MutationUpdateSpaceArgs
  }

  updateSpaceDefaultNotificationSettings?: {
    fields: SpaceDefaultNotificationSettingsFields
    variables: Types.MutationUpdateSpaceDefaultNotificationSettingsArgs
  }

  updateSpaceHighlightedTags?: {
    fields: ActionFields
    variables: Types.MutationUpdateSpaceHighlightedTagsArgs
  }

  updateSpaceMemberRole?: {
    fields: ActionFields
    variables: Types.MutationUpdateSpaceMemberRoleArgs
  }

  updateSpacePostTypes?: {
    fields: SpacePostTypeFields
    variables: Types.MutationUpdateSpacePostTypesArgs
  }

  updateSubscriberSettings?: {
    fields: ActionFields
    variables: Types.MutationUpdateSubscriberSettingsArgs
  }

  updateSubscriptionInterval?: {
    fields: ActionFields
    variables: Types.MutationUpdateSubscriptionIntervalArgs
  }

  updateSubscriptionPlan?: {
    fields: ActionFields
    variables: Types.MutationUpdateSubscriptionPlanArgs
  }

  updateTag?: {
    fields: TagFields
    variables: Types.MutationUpdateTagArgs
  }

  updateTemplate?: {
    fields: TemplateFields
    variables: Types.MutationUpdateTemplateArgs
  }

  uploadMigrationFiles?: {
    fields: UploadedMigrationsFields
    variables: Types.MutationUploadMigrationFilesArgs
  }

  upsertExtraProperty?: {
    fields: ActionFields
    variables: Types.MutationUpsertExtraPropertyArgs
  }

  upsertPageCustomResponse?: {
    fields: PageDetailedCustomResponseFields
    variables: Types.MutationUpsertPageCustomResponseArgs
  }

  upsertTheme?: {
    fields: NewThemeFields
    variables: Types.MutationUpsertThemeArgs
  }

  verifyMember?: {
    fields: AuthTokenFields
    variables: Types.MutationVerifyMemberArgs
  }
}
export type MutationFields = 'basic' | 'all' | CustomMutationFields

export type NativeFieldsTemplatesFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const NativeFieldsTemplatesGQLFields = (
  fields: NativeFieldsTemplatesFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        description
thumbnailId
title
      `
}

export type NavigationItemFields = 'basic' | 'all' | Record<string, never>

export const NavigationItemGQLFields = (fields: NavigationItemFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        link
openInNewWindow
text
type
      `
}

export interface CustomNavigationSlatesFields {
  footer?: SlateFields
  header?: SlateFields
  sidebar1?: SlateFields
  sidebar2?: SlateFields
}
export type NavigationSlatesFields =
  | 'basic'
  | 'all'
  | CustomNavigationSlatesFields

export const NavigationSlatesGQLFields = (fields: NavigationSlatesFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      footer: 'basic',
      header: 'basic',
      sidebar1: 'basic',
      sidebar2: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.footer
        ? `
      footer {
        ${SlateGQLFields(fields.footer)}
      }
    `
        : ``
    }

footerDisabled
footerFixed

    ${
      fields.header
        ? `
      header {
        ${SlateGQLFields(fields.header)}
      }
    `
        : ``
    }

headerDisabled
headerFixed

    ${
      fields.sidebar1
        ? `
      sidebar1 {
        ${SlateGQLFields(fields.sidebar1)}
      }
    `
        : ``
    }

sidebar1Disabled
sidebar1Fixed

    ${
      fields.sidebar2
        ? `
      sidebar2 {
        ${SlateGQLFields(fields.sidebar2)}
      }
    `
        : ``
    }

sidebar2Disabled
sidebar2Fixed
      `
}

export interface CustomNetworkFields {
  activeSso?: ActiveSsoFields
  activeTheme?: NewThemeFields
  authMemberProps?: NetworkAuthMemberPropsFields
  badges?: BadgeFields
  createdBy?: MemberFields

  customCodes?: {
    fields: CustomCodeFields
    variables: Types.NetworkCustomCodesArgs
  }

  customSeoDetail?: CustomSeoDetailFields
  defaultSpaces?: SpaceFields

  extraProperties?: {
    fields: ExtraPropertyFields
    variables: Types.NetworkExtraPropertiesArgs
  }

  favicon?: MediaFields
  footer?: FooterFields
  images?: NetworkImagesFields
  landingPages?: NetworkSettingsFields
  limits?: NetworkLimitsFields
  logo?: MediaFields
  mediaLimits?: MediaLimitsFields
  mediaSettings?: MediaNetworkSettingsFields
  memberFields?: CustomFieldsSchemaFields

  members?: {
    fields: PaginatedMemberFields
    variables: Types.NetworkMembersArgs
  }

  navigationSlates?: NavigationSlatesFields
  notificationSettings?: NotificationNetworkSettingsFields
  owner?: MemberFields

  postTypes?: {
    fields: PostTypeFields
    variables: Types.NetworkPostTypesArgs
  }

  roles?: RoleFields
  settings?: SettingsFields
  shortcuts?: ShortcutFields

  spaces?: {
    fields: PaginatedSpaceFields
    variables: Types.NetworkSpacesArgs
  }

  statusReason?: StatusReasonFields
  subscriptionPlan?: PlanFields
  systemSpaces?: SpaceFields
  themes?: ThemesFields
  topNavigation?: TopNavigationFields
  usages?: NetworkUsagesFields
  whoCanInvite?: RoleFields
}
export type NetworkFields = 'basic' | 'all' | CustomNetworkFields

export const NetworkGQLFields = (fields: NetworkFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      activeSso: 'basic',
      activeTheme: 'basic',
      authMemberProps: 'basic',
      badges: 'basic',
      createdBy: 'basic',
      customSeoDetail: 'basic',
      defaultSpaces: 'basic',
      favicon: 'basic',
      footer: 'basic',
      images: 'basic',
      landingPages: 'basic',
      limits: 'basic',
      logo: 'basic',
      mediaLimits: 'basic',
      mediaSettings: 'basic',
      memberFields: 'basic',
      navigationSlates: 'basic',
      notificationSettings: 'basic',
      owner: 'basic',
      roles: 'basic',
      settings: 'basic',
      shortcuts: 'basic',
      statusReason: 'basic',
      subscriptionPlan: 'basic',
      systemSpaces: 'basic',
      themes: 'basic',
      topNavigation: 'basic',
      usages: 'basic',
      whoCanInvite: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.activeSso
        ? `
      activeSso {
        ${ActiveSsoGQLFields(fields.activeSso)}
      }
    `
        : ``
    }


    ${
      fields.activeTheme
        ? `
      activeTheme {
        ${NewThemeGQLFields(fields.activeTheme)}
      }
    `
        : ``
    }

additionalSeatsCapacity
aliases

    ${
      fields.authMemberProps
        ? `
      authMemberProps {
        ${NetworkAuthMemberPropsGQLFields(fields.authMemberProps)}
      }
    `
        : ``
    }

availableLocales

    ${
      fields.badges
        ? `
      badges {
        ${BadgeGQLFields(fields.badges)}
      }
    `
        : ``
    }

billingEmail
brandColor
companyName
createdAt

    ${
      fields.createdBy
        ? `
      createdBy {
        ${MemberGQLFields(fields.createdBy)}
      }
    `
        : ``
    }

createdById

      ${
        fields.customCodes
          ? `
        customCodes
        ${
          fields.customCodes?.variables
            ? `
          (

    ${
      fields.customCodes.variables.anonymize
        ? `
      anonymize: ${fields.customCodes.variables.anonymize}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${CustomCodeGQLFields(fields.customCodes.fields)}
        }
      `
          : ``
      }

customMemberCapacity

    ${
      fields.customSeoDetail
        ? `
      customSeoDetail {
        ${CustomSeoDetailGQLFields(fields.customSeoDetail)}
      }
    `
        : ``
    }


    ${
      fields.defaultSpaces
        ? `
      defaultSpaces {
        ${SpaceGQLFields(fields.defaultSpaces)}
      }
    `
        : ``
    }

description
domain
domainSubfolder

      ${
        fields.extraProperties
          ? `
        extraProperties
        ${
          fields.extraProperties?.variables
            ? `
          (

    ${
      fields.extraProperties.variables.keys
        ? `
      keys: ${fields.extraProperties.variables.keys}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${ExtraPropertyGQLFields(fields.extraProperties.fields)}
        }
      `
          : ``
      }


    ${
      fields.favicon
        ? `
      favicon {
        ${MediaGQLFields(fields.favicon)}
      }
    `
        : ``
    }

faviconId

    ${
      fields.footer
        ? `
      footer {
        ${FooterGQLFields(fields.footer)}
      }
    `
        : ``
    }

hideDefaultAuthenticationForm
id

    ${
      fields.images
        ? `
      images {
        ${NetworkImagesGQLFields(fields.images)}
      }
    `
        : ``
    }

incidentEmails
industry

    ${
      fields.landingPages
        ? `
      landingPages {
        ${NetworkSettingsGQLFields(fields.landingPages)}
      }
    `
        : ``
    }


    ${
      fields.limits
        ? `
      limits {
        ${NetworkLimitsGQLFields(fields.limits)}
      }
    `
        : ``
    }

limitsUpdatedAt
locale

    ${
      fields.logo
        ? `
      logo {
        ${MediaGQLFields(fields.logo)}
      }
    `
        : ``
    }

logoId

    ${
      fields.mediaLimits
        ? `
      mediaLimits {
        ${MediaLimitsGQLFields(fields.mediaLimits)}
      }
    `
        : ``
    }


    ${
      fields.mediaSettings
        ? `
      mediaSettings {
        ${MediaNetworkSettingsGQLFields(fields.mediaSettings)}
      }
    `
        : ``
    }

memberCapacity
memberCapacityDeclared

    ${
      fields.memberFields
        ? `
      memberFields {
        ${CustomFieldsSchemaGQLFields(fields.memberFields)}
      }
    `
        : ``
    }


      ${
        fields.members
          ? `
        members
        ${
          fields.members?.variables
            ? `
          (

    ${
      fields.members.variables.after
        ? `
      after: ${fields.members.variables.after}
    `
        : ``
    }


    ${
      fields.members.variables.before
        ? `
      before: ${fields.members.variables.before}
    `
        : ``
    }


    ${
      fields.members.variables.limit
        ? `
      limit: ${fields.members.variables.limit}
    `
        : ``
    }


    ${
      fields.members.variables.offset
        ? `
      offset: ${fields.members.variables.offset}
    `
        : ``
    }


    ${
      fields.members.variables.reverse
        ? `
      reverse: ${fields.members.variables.reverse}
    `
        : ``
    }


    ${
      fields.members.variables.status
        ? `
      status: ${fields.members.variables.status}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PaginatedMemberGQLFields(fields.members.fields)}
        }
      `
          : ``
      }

membersCount
membership
name

    ${
      fields.navigationSlates
        ? `
      navigationSlates {
        ${NavigationSlatesGQLFields(fields.navigationSlates)}
      }
    `
        : ``
    }

newDomain

    ${
      fields.notificationSettings
        ? `
      notificationSettings {
        ${NotificationNetworkSettingsGQLFields(fields.notificationSettings)}
      }
    `
        : ``
    }

organizationId
overUsedAt

    ${
      fields.owner
        ? `
      owner {
        ${MemberGQLFields(fields.owner)}
      }
    `
        : ``
    }

ownerId
passwordComplexity
planName

      ${
        fields.postTypes
          ? `
        postTypes
        ${
          fields.postTypes?.variables
            ? `
          (

    ${
      fields.postTypes.variables.context
        ? `
      context: ${fields.postTypes.variables.context}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PostTypeGQLFields(fields.postTypes.fields)}
        }
      `
          : ``
      }

privacyPolicyUrl
referralLink
referralProgramEnabled
releaseChannel

    ${
      fields.roles
        ? `
      roles {
        ${RoleGQLFields(fields.roles)}
      }
    `
        : ``
    }

seatCapacityDeclared
seatsCapacity

    ${
      fields.settings
        ? `
      settings {
        ${SettingsGQLFields(fields.settings)}
      }
    `
        : ``
    }


    ${
      fields.shortcuts
        ? `
      shortcuts {
        ${ShortcutGQLFields(fields.shortcuts)}
      }
    `
        : ``
    }


      ${
        fields.spaces
          ? `
        spaces
        ${
          fields.spaces?.variables
            ? `
          (

    ${
      fields.spaces.variables.limit
        ? `
      limit: ${fields.spaces.variables.limit}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PaginatedSpaceGQLFields(fields.spaces.fields)}
        }
      `
          : ``
      }

status
statusLocked

    ${
      fields.statusReason
        ? `
      statusReason {
        ${StatusReasonGQLFields(fields.statusReason)}
      }
    `
        : ``
    }


    ${
      fields.subscriptionPlan
        ? `
      subscriptionPlan {
        ${PlanGQLFields(fields.subscriptionPlan)}
      }
    `
        : ``
    }


    ${
      fields.systemSpaces
        ? `
      systemSpaces {
        ${SpaceGQLFields(fields.systemSpaces)}
      }
    `
        : ``
    }

termsOfServiceUrl

    ${
      fields.themes
        ? `
      themes {
        ${ThemesGQLFields(fields.themes)}
      }
    `
        : ``
    }


    ${
      fields.topNavigation
        ? `
      topNavigation {
        ${TopNavigationGQLFields(fields.topNavigation)}
      }
    `
        : ``
    }

tribeBranding

    ${
      fields.usages
        ? `
      usages {
        ${NetworkUsagesGQLFields(fields.usages)}
      }
    `
        : ``
    }

visibility

    ${
      fields.whoCanInvite
        ? `
      whoCanInvite {
        ${RoleGQLFields(fields.whoCanInvite)}
      }
    `
        : ``
    }

whoCanInviteIds
willUnpublishAt
      `
}

export interface CustomNetworkAuthMemberPropsFields {
  permissions?: ActionPermissionsFields

  totalUnreadPostsCount?: {
    variables: Types.NetworkAuthMemberPropsTotalUnreadPostsCountArgs
  }
}
export type NetworkAuthMemberPropsFields =
  | 'basic'
  | 'all'
  | CustomNetworkAuthMemberPropsFields

export const NetworkAuthMemberPropsGQLFields = (
  fields: NetworkAuthMemberPropsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      permissions: 'basic',
    }
  }
  return `
        __typename
        context

    ${
      fields.permissions
        ? `
      permissions {
        ${ActionPermissionsGQLFields(fields.permissions)}
      }
    `
        : ``
    }

privateMessagingEnabled
scopes
totalUnreadPostsCount
        ${
          fields.totalUnreadPostsCount?.variables
            ? `
          (

    ${
      fields.totalUnreadPostsCount.variables.type
        ? `
      type: ${fields.totalUnreadPostsCount.variables.type}
    `
        : ``
    }

          )
        `
            : ``
        }

      `
}

export interface CustomNetworkImagesFields {
  darkFavicon?: MediaFields
  darkLogo?: MediaFields
  darkMobileLogo?: MediaFields
  lightFavicon?: MediaFields
  lightLogo?: MediaFields
  lightMobileLogo?: MediaFields
}
export type NetworkImagesFields = 'basic' | 'all' | CustomNetworkImagesFields

export const NetworkImagesGQLFields = (fields: NetworkImagesFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      darkFavicon: 'basic',
      darkLogo: 'basic',
      darkMobileLogo: 'basic',
      lightFavicon: 'basic',
      lightLogo: 'basic',
      lightMobileLogo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.darkFavicon
        ? `
      darkFavicon {
        ${MediaGQLFields(fields.darkFavicon)}
      }
    `
        : ``
    }


    ${
      fields.darkLogo
        ? `
      darkLogo {
        ${MediaGQLFields(fields.darkLogo)}
      }
    `
        : ``
    }


    ${
      fields.darkMobileLogo
        ? `
      darkMobileLogo {
        ${MediaGQLFields(fields.darkMobileLogo)}
      }
    `
        : ``
    }


    ${
      fields.lightFavicon
        ? `
      lightFavicon {
        ${MediaGQLFields(fields.lightFavicon)}
      }
    `
        : ``
    }


    ${
      fields.lightLogo
        ? `
      lightLogo {
        ${MediaGQLFields(fields.lightLogo)}
      }
    `
        : ``
    }


    ${
      fields.lightMobileLogo
        ? `
      lightMobileLogo {
        ${MediaGQLFields(fields.lightMobileLogo)}
      }
    `
        : ``
    }

      `
}

export interface CustomNetworkLimitsFields {
  analyticsLimits?: AnalyticsLimitsFields
  usageLimits?: NetworkUsageLimitsFields
}
export type NetworkLimitsFields = 'basic' | 'all' | CustomNetworkLimitsFields

export const NetworkLimitsGQLFields = (fields: NetworkLimitsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      analyticsLimits: 'basic',
      usageLimits: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.analyticsLimits
        ? `
      analyticsLimits {
        ${AnalyticsLimitsGQLFields(fields.analyticsLimits)}
      }
    `
        : ``
    }

fileSizeLimit
fileTypesLimit
imageSizeLimit
imageTypesLimit

    ${
      fields.usageLimits
        ? `
      usageLimits {
        ${NetworkUsageLimitsGQLFields(fields.usageLimits)}
      }
    `
        : ``
    }

      `
}

export interface CustomNetworkPublicInfoFields {
  favicon?: MediaFields
  images?: NetworkImagesFields
  jwtSsoAuthUrls?: JwtSsoAuthUrlsFields
  logo?: MediaFields
  systemSpaces?: SpaceFields
}
export type NetworkPublicInfoFields =
  | 'basic'
  | 'all'
  | CustomNetworkPublicInfoFields

export const NetworkPublicInfoGQLFields = (fields: NetworkPublicInfoFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      favicon: 'basic',
      images: 'basic',
      jwtSsoAuthUrls: 'basic',
      logo: 'basic',
      systemSpaces: 'basic',
    }
  }
  return `
        __typename
        domain
domainSubfolder

    ${
      fields.favicon
        ? `
      favicon {
        ${MediaGQLFields(fields.favicon)}
      }
    `
        : ``
    }

faviconId
id

    ${
      fields.images
        ? `
      images {
        ${NetworkImagesGQLFields(fields.images)}
      }
    `
        : ``
    }


    ${
      fields.jwtSsoAuthUrls
        ? `
      jwtSsoAuthUrls {
        ${JwtSsoAuthUrlsGQLFields(fields.jwtSsoAuthUrls)}
      }
    `
        : ``
    }


    ${
      fields.logo
        ? `
      logo {
        ${MediaGQLFields(fields.logo)}
      }
    `
        : ``
    }

logoId
membership
name
status

    ${
      fields.systemSpaces
        ? `
      systemSpaces {
        ${SpaceGQLFields(fields.systemSpaces)}
      }
    `
        : ``
    }

visibility
      `
}

export type NetworkSettingsFields = 'basic' | 'all' | Record<string, never>

export const NetworkSettingsGQLFields = (fields: NetworkSettingsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        landingPageForGuest
landingPageForMember
landingPageForNewMember
      `
}

export interface CustomNetworkUsageLimitsFields {
  apiRequestCapacity?: ApiRequestLimitFields
}
export type NetworkUsageLimitsFields =
  | 'basic'
  | 'all'
  | CustomNetworkUsageLimitsFields

export const NetworkUsageLimitsGQLFields = (
  fields: NetworkUsageLimitsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      apiRequestCapacity: 'basic',
    }
  }
  return `
        __typename
        aiSearchAccess
analyticsAccess
analyticsDaysLimit
apiAccess

    ${
      fields.apiRequestCapacity
        ? `
      apiRequestCapacity {
        ${ApiRequestLimitGQLFields(fields.apiRequestCapacity)}
      }
    `
        : ``
    }

appIds
auditLogAccess
customDomain
emailCustomization
jwtSSOAccess
membersCapacity
monthlyEmailCapacity
monthlyVisitorCapacity
oauth2SSOAccess
postTypesCapacity
samlSSOAccess
seatsCapacity
sitemap
spacesCapacity
storageCapacity
translationLocaleCapacity
      `
}

export interface CustomNetworkUsagesFields {
  usages?: NetworkUsageLimitsFields
}
export type NetworkUsagesFields = 'basic' | 'all' | CustomNetworkUsagesFields

export const NetworkUsagesGQLFields = (fields: NetworkUsagesFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      usages: 'basic',
    }
  }
  return `
        __typename
        fileStorageUsage
imageStorageUsage

    ${
      fields.usages
        ? `
      usages {
        ${NetworkUsageLimitsGQLFields(fields.usages)}
      }
    `
        : ``
    }

      `
}

export interface CustomNewThemeFields {
  colorTokens?: ThemeColorTokensFields
  colors?: ThemeColorsFields
  typography?: ThemeTokenFields
}
export type NewThemeFields = 'basic' | 'all' | CustomNewThemeFields

export const NewThemeGQLFields = (fields: NewThemeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      colorTokens: 'basic',
      colors: 'basic',
      typography: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.colorTokens
        ? `
      colorTokens {
        ${ThemeColorTokensGQLFields(fields.colorTokens)}
      }
    `
        : ``
    }


    ${
      fields.colors
        ? `
      colors {
        ${ThemeColorsGQLFields(fields.colors)}
      }
    `
        : ``
    }

id
name

    ${
      fields.typography
        ? `
      typography {
        ${ThemeTokenGQLFields(fields.typography)}
      }
    `
        : ``
    }

      `
}

export interface CustomNotificationFields {
  actor?: PayloadFields
  meta?: NotificationMetaFields
  object?: PayloadFields
  space?: SpaceFields
  target?: PayloadFields
}
export type NotificationFields = 'basic' | 'all' | CustomNotificationFields

export const NotificationGQLFields = (fields: NotificationFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      actor: 'basic',
      meta: 'basic',
      object: 'basic',
      space: 'basic',
      target: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.actor
        ? `
      actor {
        ${PayloadGQLFields(fields.actor)}
      }
    `
        : ``
    }

createdAt
id

    ${
      fields.meta
        ? `
      meta {
        ${NotificationMetaGQLFields(fields.meta)}
      }
    `
        : ``
    }


    ${
      fields.object
        ? `
      object {
        ${PayloadGQLFields(fields.object)}
      }
    `
        : ``
    }

read

    ${
      fields.space
        ? `
      space {
        ${SpaceGQLFields(fields.space)}
      }
    `
        : ``
    }

summary

    ${
      fields.target
        ? `
      target {
        ${PayloadGQLFields(fields.target)}
      }
    `
        : ``
    }

verb
      `
}

export interface CustomNotificationEdgeFields {
  node?: NotificationFields
}
export type NotificationEdgeFields =
  | 'basic'
  | 'all'
  | CustomNotificationEdgeFields

export const NotificationEdgeGQLFields = (fields: NotificationEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${NotificationGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export type NotificationMetaFields = 'basic' | 'all' | Record<string, never>

export const NotificationMetaGQLFields = (fields: NotificationMetaFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        body
fullTitle
reason
relativeUrl
textBody
textFullTitle
textTitle
title
url
      `
}

export type NotificationNetworkSettingsFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const NotificationNetworkSettingsGQLFields = (
  fields: NotificationNetworkSettingsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        emailEnabled
emailFrom
emailNotificationEnabled
      `
}

export type NotificationsCountFields = 'basic' | 'all' | Record<string, never>

export const NotificationsCountGQLFields = (
  fields: NotificationsCountFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        new
      `
}

export type OAuthCodeFields = 'basic' | 'all' | Record<string, never>

export const OAuthCodeGQLFields = (fields: OAuthCodeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        code
      `
}

export type OAuthTokensFields = 'basic' | 'all' | Record<string, never>

export const OAuthTokensGQLFields = (fields: OAuthTokensFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        accessToken
expiresIn
memberId
refreshToken
      `
}

export interface CustomPageFields {
  customResponse?: PageCustomResponseFields
  entity?: PageEntityFields
  seoDetail?: PageSeoFields
  space?: SpaceFields
}
export type PageFields = 'basic' | 'all' | CustomPageFields

export const PageGQLFields = (fields: PageFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      customResponse: 'basic',
      entity: 'basic',
      seoDetail: 'basic',
      space: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.customResponse
        ? `
      customResponse {
        ${PageCustomResponseGQLFields(fields.customResponse)}
      }
    `
        : ``
    }


    ${
      fields.entity
        ? `
      entity {
        ${PageEntityGQLFields(fields.entity)}
      }
    `
        : ``
    }


    ${
      fields.seoDetail
        ? `
      seoDetail {
        ${PageSeoGQLFields(fields.seoDetail)}
      }
    `
        : ``
    }


    ${
      fields.space
        ? `
      space {
        ${SpaceGQLFields(fields.space)}
      }
    `
        : ``
    }

      `
}

export interface CustomPageCustomResponseFields {
  headers?: PageCustomResponseHeaderFields
}
export type PageCustomResponseFields =
  | 'basic'
  | 'all'
  | CustomPageCustomResponseFields

export const PageCustomResponseGQLFields = (
  fields: PageCustomResponseFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      headers: 'basic',
    }
  }
  return `
        __typename
        body

    ${
      fields.headers
        ? `
      headers {
        ${PageCustomResponseHeaderGQLFields(fields.headers)}
      }
    `
        : ``
    }

httpCode
      `
}

export type PageCustomResponseHeaderFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const PageCustomResponseHeaderGQLFields = (
  fields: PageCustomResponseHeaderFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        key
value
      `
}

export interface CustomPageDetailedCustomResponseFields {
  headers?: PageCustomResponseHeaderFields
}
export type PageDetailedCustomResponseFields =
  | 'basic'
  | 'all'
  | CustomPageDetailedCustomResponseFields

export const PageDetailedCustomResponseGQLFields = (
  fields: PageDetailedCustomResponseFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      headers: 'basic',
    }
  }
  return `
        __typename
        body
createdAt
createdById
creatorType

    ${
      fields.headers
        ? `
      headers {
        ${PageCustomResponseHeaderGQLFields(fields.headers)}
      }
    `
        : ``
    }

httpCode
updatedAt
updatedById
      `
}

export interface CustomPageDetailedCustomResponseEdgeFields {
  node?: PageDetailedCustomResponseFields
}
export type PageDetailedCustomResponseEdgeFields =
  | 'basic'
  | 'all'
  | CustomPageDetailedCustomResponseEdgeFields

export const PageDetailedCustomResponseEdgeGQLFields = (
  fields: PageDetailedCustomResponseEdgeFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${PageDetailedCustomResponseGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomPageEntityFields {
  onCollection?: CollectionFields
  onMember?: MemberFields
  onPost?: PostFields
}
export type PageEntityFields = 'basic' | 'all' | CustomPageEntityFields

export const PageEntityGQLFields = (fields: PageEntityFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      onCollection: 'basic',
      onMember: 'basic',
      onPost: 'basic',
    }
  }
  return `
        __typename

          ${
            fields.onCollection
              ? `
            ... on Collection {
              ${CollectionGQLFields(fields.onCollection)}
            }
          `
              : ``
          }


          ${
            fields.onMember
              ? `
            ... on Member {
              ${MemberGQLFields(fields.onMember)}
            }
          `
              : ``
          }


          ${
            fields.onPost
              ? `
            ... on Post {
              ${PostGQLFields(fields.onPost)}
            }
          `
              : ``
          }

      `
}

export type PageInfoFields = 'basic' | 'all' | Record<string, never>

export const PageInfoGQLFields = (fields: PageInfoFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        endCursor
hasNextPage
      `
}

export interface CustomPageSeoFields {
  additionalMeta?: PageTagFields
  thumbnail?: MediaFields
}
export type PageSeoFields = 'basic' | 'all' | CustomPageSeoFields

export const PageSeoGQLFields = (fields: PageSeoFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      additionalMeta: 'basic',
      thumbnail: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.additionalMeta
        ? `
      additionalMeta {
        ${PageTagGQLFields(fields.additionalMeta)}
      }
    `
        : ``
    }

description
jsonld

    ${
      fields.thumbnail
        ? `
      thumbnail {
        ${MediaGQLFields(fields.thumbnail)}
      }
    `
        : ``
    }

title
      `
}

export interface CustomPageTagFields {
  properties?: KeyValueFields
}
export type PageTagFields = 'basic' | 'all' | CustomPageTagFields

export const PageTagGQLFields = (fields: PageTagFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      properties: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.properties
        ? `
      properties {
        ${KeyValueGQLFields(fields.properties)}
      }
    `
        : ``
    }

type
      `
}

export interface CustomPaginatedAppFields {
  edges?: AppEdgeFields
  nodes?: AppFields
  pageInfo?: PageInfoFields
}
export type PaginatedAppFields = 'basic' | 'all' | CustomPaginatedAppFields

export const PaginatedAppGQLFields = (fields: PaginatedAppFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${AppEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${AppGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedAppInstallationFields {
  edges?: AppInstallationEdgeFields
  nodes?: AppInstallationFields
  pageInfo?: PageInfoFields
}
export type PaginatedAppInstallationFields =
  | 'basic'
  | 'all'
  | CustomPaginatedAppInstallationFields

export const PaginatedAppInstallationGQLFields = (
  fields: PaginatedAppInstallationFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${AppInstallationEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${AppInstallationGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedDynamicBlockFields {
  edges?: DynamicBlockEdgeFields
  nodes?: DynamicBlockFields
  pageInfo?: PageInfoFields
}
export type PaginatedDynamicBlockFields =
  | 'basic'
  | 'all'
  | CustomPaginatedDynamicBlockFields

export const PaginatedDynamicBlockGQLFields = (
  fields: PaginatedDynamicBlockFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${DynamicBlockEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${DynamicBlockGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedEmailLogFields {
  edges?: EmailLogEdgeFields
  nodes?: EmailLogFields
  pageInfo?: PageInfoFields
}
export type PaginatedEmailLogFields =
  | 'basic'
  | 'all'
  | CustomPaginatedEmailLogFields

export const PaginatedEmailLogGQLFields = (fields: PaginatedEmailLogFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${EmailLogEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${EmailLogGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedExportFields {
  edges?: ExportEdgeFields
  nodes?: ExportFields
  pageInfo?: PageInfoFields
}
export type PaginatedExportFields =
  | 'basic'
  | 'all'
  | CustomPaginatedExportFields

export const PaginatedExportGQLFields = (fields: PaginatedExportFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${ExportEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${ExportGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedMemberFields {
  edges?: MemberEdgeFields
  nodes?: MemberFields
  pageInfo?: PageInfoFields
}
export type PaginatedMemberFields =
  | 'basic'
  | 'all'
  | CustomPaginatedMemberFields

export const PaginatedMemberGQLFields = (fields: PaginatedMemberFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${MemberEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${MemberGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedMemberInvitationFields {
  edges?: MemberInvitationEdgeFields
  nodes?: MemberInvitationFields
  pageInfo?: PageInfoFields
}
export type PaginatedMemberInvitationFields =
  | 'basic'
  | 'all'
  | CustomPaginatedMemberInvitationFields

export const PaginatedMemberInvitationGQLFields = (
  fields: PaginatedMemberInvitationFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${MemberInvitationEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${MemberInvitationGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedModerationFields {
  edges?: ModerationItemEdgeFields
  nodes?: ModerationItemFields
  pageInfo?: PageInfoFields
}
export type PaginatedModerationFields =
  | 'basic'
  | 'all'
  | CustomPaginatedModerationFields

export const PaginatedModerationGQLFields = (
  fields: PaginatedModerationFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${ModerationItemEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${ModerationItemGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedModerationItemReporterFields {
  edges?: ModerationItemReporterEdgeFields
  nodes?: ModerationItemReporterFields
  pageInfo?: PageInfoFields
}
export type PaginatedModerationItemReporterFields =
  | 'basic'
  | 'all'
  | CustomPaginatedModerationItemReporterFields

export const PaginatedModerationItemReporterGQLFields = (
  fields: PaginatedModerationItemReporterFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${ModerationItemReporterEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${ModerationItemReporterGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedNetworkFields {
  edges?: PluralNetworkEdgeFields
  nodes?: PluralNetworkFields
  pageInfo?: PageInfoFields
}
export type PaginatedNetworkFields =
  | 'basic'
  | 'all'
  | CustomPaginatedNetworkFields

export const PaginatedNetworkGQLFields = (fields: PaginatedNetworkFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${PluralNetworkEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${PluralNetworkGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedNotificationFields {
  edges?: NotificationEdgeFields
  nodes?: NotificationFields
  pageInfo?: PageInfoFields
}
export type PaginatedNotificationFields =
  | 'basic'
  | 'all'
  | CustomPaginatedNotificationFields

export const PaginatedNotificationGQLFields = (
  fields: PaginatedNotificationFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${NotificationEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${NotificationGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedPageCustomResponseFields {
  edges?: PageDetailedCustomResponseEdgeFields
  nodes?: PageDetailedCustomResponseFields
  pageInfo?: PageInfoFields
}
export type PaginatedPageCustomResponseFields =
  | 'basic'
  | 'all'
  | CustomPaginatedPageCustomResponseFields

export const PaginatedPageCustomResponseGQLFields = (
  fields: PaginatedPageCustomResponseFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${PageDetailedCustomResponseEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${PageDetailedCustomResponseGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedPluralMemberFields {
  edges?: PluralMemberEdgeFields
  nodes?: PluralMemberFields
  pageInfo?: PageInfoFields
}
export type PaginatedPluralMemberFields =
  | 'basic'
  | 'all'
  | CustomPaginatedPluralMemberFields

export const PaginatedPluralMemberGQLFields = (
  fields: PaginatedPluralMemberFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${PluralMemberEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${PluralMemberGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedPostFields {
  edges?: PostEdgeFields
  nodes?: PostFields
  pageInfo?: PageInfoFields
}
export type PaginatedPostFields = 'basic' | 'all' | CustomPaginatedPostFields

export const PaginatedPostGQLFields = (fields: PaginatedPostFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${PostEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${PostGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedPostReactionParticipantFields {
  edges?: PostReactionParticipantEdgeFields
  nodes?: PostReactionParticipantFields
  pageInfo?: PageInfoFields
}
export type PaginatedPostReactionParticipantFields =
  | 'basic'
  | 'all'
  | CustomPaginatedPostReactionParticipantFields

export const PaginatedPostReactionParticipantGQLFields = (
  fields: PaginatedPostReactionParticipantFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${PostReactionParticipantEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${PostReactionParticipantGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedPostTypeFields {
  edges?: PostTypeEdgeFields
  nodes?: PostTypeFields
  pageInfo?: PageInfoFields
}
export type PaginatedPostTypeFields =
  | 'basic'
  | 'all'
  | CustomPaginatedPostTypeFields

export const PaginatedPostTypeGQLFields = (fields: PaginatedPostTypeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${PostTypeEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${PostTypeGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedSitemapFields {
  edges?: SitemapEdgeFields
  nodes?: SitemapFields
  pageInfo?: PageInfoFields
}
export type PaginatedSitemapFields =
  | 'basic'
  | 'all'
  | CustomPaginatedSitemapFields

export const PaginatedSitemapGQLFields = (fields: PaginatedSitemapFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${SitemapEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${SitemapGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedSpaceFields {
  edges?: SpaceEdgeFields
  nodes?: SpaceFields
  pageInfo?: PageInfoFields
}
export type PaginatedSpaceFields = 'basic' | 'all' | CustomPaginatedSpaceFields

export const PaginatedSpaceGQLFields = (fields: PaginatedSpaceFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${SpaceEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${SpaceGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedSpaceJoinRequestFields {
  edges?: SpaceJoinRequestEdgeFields
  nodes?: SpaceJoinRequestFields
  pageInfo?: PageInfoFields
}
export type PaginatedSpaceJoinRequestFields =
  | 'basic'
  | 'all'
  | CustomPaginatedSpaceJoinRequestFields

export const PaginatedSpaceJoinRequestGQLFields = (
  fields: PaginatedSpaceJoinRequestFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${SpaceJoinRequestEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${SpaceJoinRequestGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedSpaceMemberFields {
  edges?: SpaceMemberEdgeFields
  nodes?: SpaceMemberFields
  pageInfo?: PageInfoFields
}
export type PaginatedSpaceMemberFields =
  | 'basic'
  | 'all'
  | CustomPaginatedSpaceMemberFields

export const PaginatedSpaceMemberGQLFields = (
  fields: PaginatedSpaceMemberFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${SpaceMemberEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${SpaceMemberGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedSpacePostTypeFields {
  edges?: SpacePostTypeEdgeFields
  nodes?: SpacePostTypeFields
  pageInfo?: PageInfoFields
}
export type PaginatedSpacePostTypeFields =
  | 'basic'
  | 'all'
  | CustomPaginatedSpacePostTypeFields

export const PaginatedSpacePostTypeGQLFields = (
  fields: PaginatedSpacePostTypeFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${SpacePostTypeEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${SpacePostTypeGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedTagFields {
  edges?: TagEdgeFields
  nodes?: TagFields
  pageInfo?: PageInfoFields
}
export type PaginatedTagFields = 'basic' | 'all' | CustomPaginatedTagFields

export const PaginatedTagGQLFields = (fields: PaginatedTagFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${TagEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${TagGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomPaginatedTemplateFields {
  edges?: TemplateEdgeFields
  nodes?: TemplateFields
  pageInfo?: PageInfoFields
}
export type PaginatedTemplateFields =
  | 'basic'
  | 'all'
  | CustomPaginatedTemplateFields

export const PaginatedTemplateGQLFields = (fields: PaginatedTemplateFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      edges: 'basic',
      nodes: 'basic',
      pageInfo: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.edges
        ? `
      edges {
        ${TemplateEdgeGQLFields(fields.edges)}
      }
    `
        : ``
    }


    ${
      fields.nodes
        ? `
      nodes {
        ${TemplateGQLFields(fields.nodes)}
      }
    `
        : ``
    }


    ${
      fields.pageInfo
        ? `
      pageInfo {
        ${PageInfoGQLFields(fields.pageInfo)}
      }
    `
        : ``
    }

totalCount
      `
}

export type PasswordComplexityFields = 'basic' | 'all' | Record<string, never>

export const PasswordComplexityGQLFields = (
  fields: PasswordComplexityFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        complex
      `
}

export interface CustomPathPermissionsFields {
  isAuthorized?: IsAuthorizedFields
}
export type PathPermissionsFields =
  | 'basic'
  | 'all'
  | CustomPathPermissionsFields

export const PathPermissionsGQLFields = (fields: PathPermissionsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      isAuthorized: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.isAuthorized
        ? `
      isAuthorized {
        ${IsAuthorizedGQLFields(fields.isAuthorized)}
      }
    `
        : ``
    }

path
      `
}

export interface CustomPayloadFields {
  media?: MediaFields
  member?: MemberFields
  post?: PostFields
  space?: SpaceFields
}
export type PayloadFields = 'basic' | 'all' | CustomPayloadFields

export const PayloadGQLFields = (fields: PayloadFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      media: 'basic',
      member: 'basic',
      post: 'basic',
      space: 'basic',
    }
  }
  return `
        __typename
        id

    ${
      fields.media
        ? `
      media {
        ${MediaGQLFields(fields.media)}
      }
    `
        : ``
    }


    ${
      fields.member
        ? `
      member {
        ${MemberGQLFields(fields.member)}
      }
    `
        : ``
    }

name

    ${
      fields.post
        ? `
      post {
        ${PostGQLFields(fields.post)}
      }
    `
        : ``
    }


    ${
      fields.space
        ? `
      space {
        ${SpaceGQLFields(fields.space)}
      }
    `
        : ``
    }

summary
type
      `
}

export type PermissionFields = 'basic' | 'all' | Record<string, never>

export const PermissionGQLFields = (fields: PermissionFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        createdAt
description
id
name
scopes
      `
}

export interface CustomPermissionsFields {
  actions?: ActionPermissionsFields
  contextAwareActions?: ContextPermissionsFields
}
export type PermissionsFields = 'basic' | 'all' | CustomPermissionsFields

export const PermissionsGQLFields = (fields: PermissionsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      actions: 'basic',
      contextAwareActions: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.actions
        ? `
      actions {
        ${ActionPermissionsGQLFields(fields.actions)}
      }
    `
        : ``
    }


    ${
      fields.contextAwareActions
        ? `
      contextAwareActions {
        ${ContextPermissionsGQLFields(fields.contextAwareActions)}
      }
    `
        : ``
    }

      `
}

export type PlanFields = 'basic' | 'all' | Record<string, never>

export const PlanGQLFields = (fields: PlanFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        createdAt
endDate
extendable
memberCapacity
name
renewDate
renewalType
seatCapacity
startDate
trial
      `
}

export interface CustomPluralMemberFields {
  activeSession?: MemberSessionFields
  attributes?: MemberAttributesFields
  authMemberProps?: MemberAuthMemberPropsFields
  badges?: MemberBadgeFields
  banner?: MediaFields
  blockedMembers?: MemberFields

  extraProperties?: {
    fields: ExtraPropertyFields
    variables: Types.PluralMemberExtraPropertiesArgs
  }

  fields?: CustomFieldFields
  network?: NetworkFields
  profilePicture?: MediaFields
  role?: RoleFields
  sessions?: MemberSessionFields
  settings?: MemberSettingsFields
  shortcuts?: ShortcutFields

  spaces?: {
    fields: PaginatedSpaceFields
    variables: Types.PluralMemberSpacesArgs
  }
}
export type PluralMemberFields = 'basic' | 'all' | CustomPluralMemberFields

export const PluralMemberGQLFields = (fields: PluralMemberFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      activeSession: 'basic',
      attributes: 'basic',
      authMemberProps: 'basic',
      badges: 'basic',
      banner: 'basic',
      blockedMembers: 'basic',
      fields: 'basic',
      network: 'basic',
      profilePicture: 'basic',
      role: 'basic',
      sessions: 'basic',
      settings: 'basic',
      shortcuts: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.activeSession
        ? `
      activeSession {
        ${MemberSessionGQLFields(fields.activeSession)}
      }
    `
        : ``
    }


    ${
      fields.attributes
        ? `
      attributes {
        ${MemberAttributesGQLFields(fields.attributes)}
      }
    `
        : ``
    }


    ${
      fields.authMemberProps
        ? `
      authMemberProps {
        ${MemberAuthMemberPropsGQLFields(fields.authMemberProps)}
      }
    `
        : ``
    }


    ${
      fields.badges
        ? `
      badges {
        ${MemberBadgeGQLFields(fields.badges)}
      }
    `
        : ``
    }


    ${
      fields.banner
        ? `
      banner {
        ${MediaGQLFields(fields.banner)}
      }
    `
        : ``
    }

bannerId
blockedMemberIds

    ${
      fields.blockedMembers
        ? `
      blockedMembers {
        ${MemberGQLFields(fields.blockedMembers)}
      }
    `
        : ``
    }

createdAt
displayName
email
emailStatus
externalId
externalUrl

      ${
        fields.extraProperties
          ? `
        extraProperties
        ${
          fields.extraProperties?.variables
            ? `
          (

    ${
      fields.extraProperties.variables.keys
        ? `
      keys: ${fields.extraProperties.variables.keys}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${ExtraPropertyGQLFields(fields.extraProperties.fields)}
        }
      `
          : ``
      }


    ${
      fields.fields
        ? `
      fields {
        ${CustomFieldGQLFields(fields.fields)}
      }
    `
        : ``
    }

flagged
id
lastSeen
lastSeenAt
locale
name

    ${
      fields.network
        ? `
      network {
        ${NetworkGQLFields(fields.network)}
      }
    `
        : ``
    }

networkId
newEmail
overrideTeammate

    ${
      fields.profilePicture
        ? `
      profilePicture {
        ${MediaGQLFields(fields.profilePicture)}
      }
    `
        : ``
    }

profilePictureId
relativeUrl

    ${
      fields.role
        ? `
      role {
        ${RoleGQLFields(fields.role)}
      }
    `
        : ``
    }

roleId
score

    ${
      fields.sessions
        ? `
      sessions {
        ${MemberSessionGQLFields(fields.sessions)}
      }
    `
        : ``
    }


    ${
      fields.settings
        ? `
      settings {
        ${MemberSettingsGQLFields(fields.settings)}
      }
    `
        : ``
    }


    ${
      fields.shortcuts
        ? `
      shortcuts {
        ${ShortcutGQLFields(fields.shortcuts)}
      }
    `
        : ``
    }


      ${
        fields.spaces
          ? `
        spaces
        ${
          fields.spaces?.variables
            ? `
          (

    ${
      fields.spaces.variables.exploreOnly
        ? `
      exploreOnly: ${fields.spaces.variables.exploreOnly}
    `
        : ``
    }


    ${
      fields.spaces.variables.limit
        ? `
      limit: ${fields.spaces.variables.limit}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PaginatedSpaceGQLFields(fields.spaces.fields)}
        }
      `
          : ``
      }

staffReasons
status
subscribersCount
tagline
teammate
updatedAt
url
username
verifiedAt
      `
}

export interface CustomPluralMemberEdgeFields {
  node?: PluralMemberFields
}
export type PluralMemberEdgeFields =
  | 'basic'
  | 'all'
  | CustomPluralMemberEdgeFields

export const PluralMemberEdgeGQLFields = (fields: PluralMemberEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${PluralMemberGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomPluralNetworkFields {
  activeSso?: ActiveSsoFields
  activeTheme?: NewThemeFields
  authMemberProps?: NetworkAuthMemberPropsFields
  badges?: BadgeFields
  createdBy?: MemberFields

  customCodes?: {
    fields: CustomCodeFields
    variables: Types.PluralNetworkCustomCodesArgs
  }

  customSeoDetail?: CustomSeoDetailFields
  defaultSpaces?: SpaceFields

  extraProperties?: {
    fields: ExtraPropertyFields
    variables: Types.PluralNetworkExtraPropertiesArgs
  }

  favicon?: MediaFields
  footer?: FooterFields
  globalFavicon?: MediaFields
  globalLogo?: MediaFields
  images?: NetworkImagesFields
  landingPages?: NetworkSettingsFields
  limits?: NetworkLimitsFields
  logo?: MediaFields
  mediaLimits?: MediaLimitsFields
  mediaSettings?: MediaNetworkSettingsFields
  memberFields?: CustomFieldsSchemaFields

  members?: {
    fields: PaginatedMemberFields
    variables: Types.PluralNetworkMembersArgs
  }

  navigationSlates?: NavigationSlatesFields
  notificationSettings?: NotificationNetworkSettingsFields
  owner?: MemberFields

  postTypes?: {
    fields: PostTypeFields
    variables: Types.PluralNetworkPostTypesArgs
  }

  roles?: RoleFields
  settings?: SettingsFields
  shortcuts?: ShortcutFields

  spaces?: {
    fields: PaginatedSpaceFields
    variables: Types.PluralNetworkSpacesArgs
  }

  statusReason?: StatusReasonFields
  subscriptionPlan?: PlanFields
  systemSpaces?: SpaceFields
  themes?: ThemesFields
  topNavigation?: TopNavigationFields
  usages?: NetworkUsagesFields
  whoCanInvite?: RoleFields
}
export type PluralNetworkFields = 'basic' | 'all' | CustomPluralNetworkFields

export const PluralNetworkGQLFields = (fields: PluralNetworkFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      activeSso: 'basic',
      activeTheme: 'basic',
      authMemberProps: 'basic',
      badges: 'basic',
      createdBy: 'basic',
      customSeoDetail: 'basic',
      defaultSpaces: 'basic',
      favicon: 'basic',
      footer: 'basic',
      globalFavicon: 'basic',
      globalLogo: 'basic',
      images: 'basic',
      landingPages: 'basic',
      limits: 'basic',
      logo: 'basic',
      mediaLimits: 'basic',
      mediaSettings: 'basic',
      memberFields: 'basic',
      navigationSlates: 'basic',
      notificationSettings: 'basic',
      owner: 'basic',
      roles: 'basic',
      settings: 'basic',
      shortcuts: 'basic',
      statusReason: 'basic',
      subscriptionPlan: 'basic',
      systemSpaces: 'basic',
      themes: 'basic',
      topNavigation: 'basic',
      usages: 'basic',
      whoCanInvite: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.activeSso
        ? `
      activeSso {
        ${ActiveSsoGQLFields(fields.activeSso)}
      }
    `
        : ``
    }


    ${
      fields.activeTheme
        ? `
      activeTheme {
        ${NewThemeGQLFields(fields.activeTheme)}
      }
    `
        : ``
    }

additionalSeatsCapacity
aliases

    ${
      fields.authMemberProps
        ? `
      authMemberProps {
        ${NetworkAuthMemberPropsGQLFields(fields.authMemberProps)}
      }
    `
        : ``
    }

availableLocales

    ${
      fields.badges
        ? `
      badges {
        ${BadgeGQLFields(fields.badges)}
      }
    `
        : ``
    }

billingEmail
brandColor
companyName
createdAt

    ${
      fields.createdBy
        ? `
      createdBy {
        ${MemberGQLFields(fields.createdBy)}
      }
    `
        : ``
    }

createdById

      ${
        fields.customCodes
          ? `
        customCodes
        ${
          fields.customCodes?.variables
            ? `
          (

    ${
      fields.customCodes.variables.anonymize
        ? `
      anonymize: ${fields.customCodes.variables.anonymize}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${CustomCodeGQLFields(fields.customCodes.fields)}
        }
      `
          : ``
      }

customMemberCapacity

    ${
      fields.customSeoDetail
        ? `
      customSeoDetail {
        ${CustomSeoDetailGQLFields(fields.customSeoDetail)}
      }
    `
        : ``
    }


    ${
      fields.defaultSpaces
        ? `
      defaultSpaces {
        ${SpaceGQLFields(fields.defaultSpaces)}
      }
    `
        : ``
    }

description
domain
domainSubfolder

      ${
        fields.extraProperties
          ? `
        extraProperties
        ${
          fields.extraProperties?.variables
            ? `
          (

    ${
      fields.extraProperties.variables.keys
        ? `
      keys: ${fields.extraProperties.variables.keys}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${ExtraPropertyGQLFields(fields.extraProperties.fields)}
        }
      `
          : ``
      }


    ${
      fields.favicon
        ? `
      favicon {
        ${MediaGQLFields(fields.favicon)}
      }
    `
        : ``
    }

faviconId

    ${
      fields.footer
        ? `
      footer {
        ${FooterGQLFields(fields.footer)}
      }
    `
        : ``
    }


    ${
      fields.globalFavicon
        ? `
      globalFavicon {
        ${MediaGQLFields(fields.globalFavicon)}
      }
    `
        : ``
    }


    ${
      fields.globalLogo
        ? `
      globalLogo {
        ${MediaGQLFields(fields.globalLogo)}
      }
    `
        : ``
    }

hideDefaultAuthenticationForm
id

    ${
      fields.images
        ? `
      images {
        ${NetworkImagesGQLFields(fields.images)}
      }
    `
        : ``
    }

incidentEmails
industry

    ${
      fields.landingPages
        ? `
      landingPages {
        ${NetworkSettingsGQLFields(fields.landingPages)}
      }
    `
        : ``
    }


    ${
      fields.limits
        ? `
      limits {
        ${NetworkLimitsGQLFields(fields.limits)}
      }
    `
        : ``
    }

limitsUpdatedAt
locale

    ${
      fields.logo
        ? `
      logo {
        ${MediaGQLFields(fields.logo)}
      }
    `
        : ``
    }

logoId

    ${
      fields.mediaLimits
        ? `
      mediaLimits {
        ${MediaLimitsGQLFields(fields.mediaLimits)}
      }
    `
        : ``
    }


    ${
      fields.mediaSettings
        ? `
      mediaSettings {
        ${MediaNetworkSettingsGQLFields(fields.mediaSettings)}
      }
    `
        : ``
    }

memberCapacity
memberCapacityDeclared

    ${
      fields.memberFields
        ? `
      memberFields {
        ${CustomFieldsSchemaGQLFields(fields.memberFields)}
      }
    `
        : ``
    }


      ${
        fields.members
          ? `
        members
        ${
          fields.members?.variables
            ? `
          (

    ${
      fields.members.variables.after
        ? `
      after: ${fields.members.variables.after}
    `
        : ``
    }


    ${
      fields.members.variables.before
        ? `
      before: ${fields.members.variables.before}
    `
        : ``
    }


    ${
      fields.members.variables.limit
        ? `
      limit: ${fields.members.variables.limit}
    `
        : ``
    }


    ${
      fields.members.variables.offset
        ? `
      offset: ${fields.members.variables.offset}
    `
        : ``
    }


    ${
      fields.members.variables.reverse
        ? `
      reverse: ${fields.members.variables.reverse}
    `
        : ``
    }


    ${
      fields.members.variables.status
        ? `
      status: ${fields.members.variables.status}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PaginatedMemberGQLFields(fields.members.fields)}
        }
      `
          : ``
      }

membersCount
membership
name

    ${
      fields.navigationSlates
        ? `
      navigationSlates {
        ${NavigationSlatesGQLFields(fields.navigationSlates)}
      }
    `
        : ``
    }

newDomain

    ${
      fields.notificationSettings
        ? `
      notificationSettings {
        ${NotificationNetworkSettingsGQLFields(fields.notificationSettings)}
      }
    `
        : ``
    }

organizationId
overUsedAt

    ${
      fields.owner
        ? `
      owner {
        ${MemberGQLFields(fields.owner)}
      }
    `
        : ``
    }

ownerId
passwordComplexity
planName

      ${
        fields.postTypes
          ? `
        postTypes
        ${
          fields.postTypes?.variables
            ? `
          (

    ${
      fields.postTypes.variables.context
        ? `
      context: ${fields.postTypes.variables.context}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PostTypeGQLFields(fields.postTypes.fields)}
        }
      `
          : ``
      }

privacyPolicyUrl
referralLink
referralProgramEnabled
releaseChannel

    ${
      fields.roles
        ? `
      roles {
        ${RoleGQLFields(fields.roles)}
      }
    `
        : ``
    }

seatCapacityDeclared
seatsCapacity

    ${
      fields.settings
        ? `
      settings {
        ${SettingsGQLFields(fields.settings)}
      }
    `
        : ``
    }


    ${
      fields.shortcuts
        ? `
      shortcuts {
        ${ShortcutGQLFields(fields.shortcuts)}
      }
    `
        : ``
    }


      ${
        fields.spaces
          ? `
        spaces
        ${
          fields.spaces?.variables
            ? `
          (

    ${
      fields.spaces.variables.limit
        ? `
      limit: ${fields.spaces.variables.limit}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PaginatedSpaceGQLFields(fields.spaces.fields)}
        }
      `
          : ``
      }

status
statusLocked

    ${
      fields.statusReason
        ? `
      statusReason {
        ${StatusReasonGQLFields(fields.statusReason)}
      }
    `
        : ``
    }


    ${
      fields.subscriptionPlan
        ? `
      subscriptionPlan {
        ${PlanGQLFields(fields.subscriptionPlan)}
      }
    `
        : ``
    }


    ${
      fields.systemSpaces
        ? `
      systemSpaces {
        ${SpaceGQLFields(fields.systemSpaces)}
      }
    `
        : ``
    }

termsOfServiceUrl

    ${
      fields.themes
        ? `
      themes {
        ${ThemesGQLFields(fields.themes)}
      }
    `
        : ``
    }


    ${
      fields.topNavigation
        ? `
      topNavigation {
        ${TopNavigationGQLFields(fields.topNavigation)}
      }
    `
        : ``
    }

tribeBranding

    ${
      fields.usages
        ? `
      usages {
        ${NetworkUsagesGQLFields(fields.usages)}
      }
    `
        : ``
    }

visibility

    ${
      fields.whoCanInvite
        ? `
      whoCanInvite {
        ${RoleGQLFields(fields.whoCanInvite)}
      }
    `
        : ``
    }

whoCanInviteIds
willUnpublishAt
      `
}

export interface CustomPluralNetworkEdgeFields {
  node?: PluralNetworkFields
}
export type PluralNetworkEdgeFields =
  | 'basic'
  | 'all'
  | CustomPluralNetworkEdgeFields

export const PluralNetworkEdgeGQLFields = (fields: PluralNetworkEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${PluralNetworkGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomPostFields {
  attachments?: FileFields
  authMemberProps?: PostAuthMemberPropsFields

  contentSummary?: {
    fields: PostContentSummaryFields
    variables: Types.PostContentSummaryArgs
  }

  createdBy?: SpaceMemberFields
  customSeoDetail?: CustomSeoDetailFields
  embeds?: EmbedFields
  fields?: CustomFieldFields
  images?: MediaFields
  lastActivityDetail?: PostLastActivityDetailFields
  mappingFields?: PostMappingFieldFields
  mentions?: MemberFields
  owner?: SpaceMemberFields
  pinnedReplies?: PostFields
  postType?: PostTypeFields
  reactions?: PostReactionDetailFields
  repliedTo?: PostFields
  repliedTos?: PostFields

  replies?: {
    fields: PaginatedPostFields
    variables: Types.PostRepliesArgs
  }

  seoDetail?: PostSeoDetailFields

  shortContent?: {
    variables: Types.PostShortContentArgs
  }

  shortcuts?: ShortcutFields
  space?: SpaceFields
  tags?: TagFields
  thumbnail?: MediaFields
  topRepliers?: PostTopReplierFields
}
export type PostFields = 'basic' | 'all' | CustomPostFields

export const PostGQLFields = (fields: PostFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      attachments: 'basic',
      authMemberProps: 'basic',
      createdBy: 'basic',
      customSeoDetail: 'basic',
      embeds: 'basic',
      fields: 'basic',
      images: 'basic',
      lastActivityDetail: 'basic',
      mappingFields: 'basic',
      mentions: 'basic',
      owner: 'basic',
      pinnedReplies: 'basic',
      postType: 'basic',
      reactions: 'basic',
      repliedTo: 'basic',
      repliedTos: 'basic',
      seoDetail: 'basic',
      shortcuts: 'basic',
      space: 'basic',
      tags: 'basic',
      thumbnail: 'basic',
      topRepliers: 'basic',
    }
  }
  return `
        __typename
        allowedEmojis
allowedReactions
attachmentIds

    ${
      fields.attachments
        ? `
      attachments {
        ${FileGQLFields(fields.attachments)}
      }
    `
        : ``
    }


    ${
      fields.authMemberProps
        ? `
      authMemberProps {
        ${PostAuthMemberPropsGQLFields(fields.authMemberProps)}
      }
    `
        : ``
    }


      ${
        fields.contentSummary
          ? `
        contentSummary
        ${
          fields.contentSummary?.variables
            ? `
          (

    ${
      fields.contentSummary.variables.format
        ? `
      format: ${fields.contentSummary.variables.format}
    `
        : ``
    }


    ${
      fields.contentSummary.variables.length
        ? `
      length: ${fields.contentSummary.variables.length}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PostContentSummaryGQLFields(fields.contentSummary.fields)}
        }
      `
          : ``
      }

createdAt

    ${
      fields.createdBy
        ? `
      createdBy {
        ${SpaceMemberGQLFields(fields.createdBy)}
      }
    `
        : ``
    }

createdById

    ${
      fields.customSeoDetail
        ? `
      customSeoDetail {
        ${CustomSeoDetailGQLFields(fields.customSeoDetail)}
      }
    `
        : ``
    }

description
embedIds

    ${
      fields.embeds
        ? `
      embeds {
        ${EmbedGQLFields(fields.embeds)}
      }
    `
        : ``
    }

externalId
externalUrl

    ${
      fields.fields
        ? `
      fields {
        ${CustomFieldGQLFields(fields.fields)}
      }
    `
        : ``
    }

followersCount
forbiddenEmojis
forbiddenReactions
hasMoreContent
id
imageIds

    ${
      fields.images
        ? `
      images {
        ${MediaGQLFields(fields.images)}
      }
    `
        : ``
    }

isAnonymous
isHidden
language
lastActivityAt

    ${
      fields.lastActivityDetail
        ? `
      lastActivityDetail {
        ${PostLastActivityDetailGQLFields(fields.lastActivityDetail)}
      }
    `
        : ``
    }

locked

    ${
      fields.mappingFields
        ? `
      mappingFields {
        ${PostMappingFieldGQLFields(fields.mappingFields)}
      }
    `
        : ``
    }

mentionedMembers

    ${
      fields.mentions
        ? `
      mentions {
        ${MemberGQLFields(fields.mentions)}
      }
    `
        : ``
    }

negativeReactions
negativeReactionsCount
networkId

    ${
      fields.owner
        ? `
      owner {
        ${SpaceMemberGQLFields(fields.owner)}
      }
    `
        : ``
    }

ownerId
pinnedInto

    ${
      fields.pinnedReplies
        ? `
      pinnedReplies {
        ${PostGQLFields(fields.pinnedReplies)}
      }
    `
        : ``
    }

positiveReactions
positiveReactionsCount

    ${
      fields.postType
        ? `
      postType {
        ${PostTypeGQLFields(fields.postType)}
      }
    `
        : ``
    }

postTypeId
primaryReactionType
publishedAt

    ${
      fields.reactions
        ? `
      reactions {
        ${PostReactionDetailGQLFields(fields.reactions)}
      }
    `
        : ``
    }

reactionsCount
relativeUrl

    ${
      fields.repliedTo
        ? `
      repliedTo {
        ${PostGQLFields(fields.repliedTo)}
      }
    `
        : ``
    }

repliedToId
repliedToIds

    ${
      fields.repliedTos
        ? `
      repliedTos {
        ${PostGQLFields(fields.repliedTos)}
      }
    `
        : ``
    }


      ${
        fields.replies
          ? `
        replies
        ${
          fields.replies?.variables
            ? `
          (

    ${
      fields.replies.variables.after
        ? `
      after: ${fields.replies.variables.after}
    `
        : ``
    }


    ${
      fields.replies.variables.before
        ? `
      before: ${fields.replies.variables.before}
    `
        : ``
    }


    ${
      fields.replies.variables.excludePins
        ? `
      excludePins: ${fields.replies.variables.excludePins}
    `
        : ``
    }


    ${
      fields.replies.variables.limit
        ? `
      limit: ${fields.replies.variables.limit}
    `
        : ``
    }


    ${
      fields.replies.variables.offset
        ? `
      offset: ${fields.replies.variables.offset}
    `
        : ``
    }


    ${
      fields.replies.variables.orderBy
        ? `
      orderBy: ${fields.replies.variables.orderBy}
    `
        : ``
    }


    ${
      fields.replies.variables.orderByString
        ? `
      orderByString: ${fields.replies.variables.orderByString}
    `
        : ``
    }


    ${
      fields.replies.variables.reverse
        ? `
      reverse: ${fields.replies.variables.reverse}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PaginatedPostGQLFields(fields.replies.fields)}
        }
      `
          : ``
      }

repliesCount

    ${
      fields.seoDetail
        ? `
      seoDetail {
        ${PostSeoDetailGQLFields(fields.seoDetail)}
      }
    `
        : ``
    }

shortContent
        ${
          fields.shortContent?.variables
            ? `
          (

    ${
      fields.shortContent.variables.format
        ? `
      format: ${fields.shortContent.variables.format}
    `
        : ``
    }


    ${
      fields.shortContent.variables.length
        ? `
      length: ${fields.shortContent.variables.length}
    `
        : ``
    }

          )
        `
            : ``
        }


    ${
      fields.shortcuts
        ? `
      shortcuts {
        ${ShortcutGQLFields(fields.shortcuts)}
      }
    `
        : ``
    }

singleChoiceReactions
slug

    ${
      fields.space
        ? `
      space {
        ${SpaceGQLFields(fields.space)}
      }
    `
        : ``
    }

spaceId
status
subscribersCount
tagIds

    ${
      fields.tags
        ? `
      tags {
        ${TagGQLFields(fields.tags)}
      }
    `
        : ``
    }

textContent

    ${
      fields.thumbnail
        ? `
      thumbnail {
        ${MediaGQLFields(fields.thumbnail)}
      }
    `
        : ``
    }

thumbnailId
title

    ${
      fields.topRepliers
        ? `
      topRepliers {
        ${PostTopReplierGQLFields(fields.topRepliers)}
      }
    `
        : ``
    }

topicIds
totalRepliesCount
updatedAt
url
      `
}

export interface CustomPostAuthMemberPropsFields {
  availableReplyTypes?: PostTypeFields
  permissions?: ActionPermissionsFields
}
export type PostAuthMemberPropsFields =
  | 'basic'
  | 'all'
  | CustomPostAuthMemberPropsFields

export const PostAuthMemberPropsGQLFields = (
  fields: PostAuthMemberPropsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      availableReplyTypes: 'basic',
      permissions: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.availableReplyTypes
        ? `
      availableReplyTypes {
        ${PostTypeGQLFields(fields.availableReplyTypes)}
      }
    `
        : ``
    }

canReact
context
memberPostNotificationSettingsEnabled

    ${
      fields.permissions
        ? `
      permissions {
        ${ActionPermissionsGQLFields(fields.permissions)}
      }
    `
        : ``
    }

scopes
subscribed
      `
}

export type PostContentSummaryFields = 'basic' | 'all' | Record<string, never>

export const PostContentSummaryGQLFields = (
  fields: PostContentSummaryFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        hasMoreContent
summary
      `
}

export interface CustomPostEdgeFields {
  node?: PostFields
}
export type PostEdgeFields = 'basic' | 'all' | CustomPostEdgeFields

export const PostEdgeGQLFields = (fields: PostEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${PostGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomPostLastActivityDetailFields {
  entity?: PostLastActivityEntityFields
}
export type PostLastActivityDetailFields =
  | 'basic'
  | 'all'
  | CustomPostLastActivityDetailFields

export const PostLastActivityDetailGQLFields = (
  fields: PostLastActivityDetailFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      entity: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.entity
        ? `
      entity {
        ${PostLastActivityEntityGQLFields(fields.entity)}
      }
    `
        : ``
    }

entityId
entityType
      `
}

export interface CustomPostLastActivityEntityFields {
  onPost?: PostFields
  onPostReactionParticipant?: PostReactionParticipantFields
}
export type PostLastActivityEntityFields =
  | 'basic'
  | 'all'
  | CustomPostLastActivityEntityFields

export const PostLastActivityEntityGQLFields = (
  fields: PostLastActivityEntityFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      onPost: 'basic',
      onPostReactionParticipant: 'basic',
    }
  }
  return `
        __typename

          ${
            fields.onPost
              ? `
            ... on Post {
              ${PostGQLFields(fields.onPost)}
            }
          `
              : ``
          }


          ${
            fields.onPostReactionParticipant
              ? `
            ... on PostReactionParticipant {
              ${PostReactionParticipantGQLFields(
                fields.onPostReactionParticipant,
              )}
            }
          `
              : ``
          }

      `
}

export type PostMappingFieldFields = 'basic' | 'all' | Record<string, never>

export const PostMappingFieldGQLFields = (fields: PostMappingFieldFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        key
type
value
      `
}

export interface CustomPostReactionDetailFields {
  participants?: {
    fields: PaginatedPostReactionParticipantFields
    variables: Types.PostReactionDetailParticipantsArgs
  }
}
export type PostReactionDetailFields =
  | 'basic'
  | 'all'
  | CustomPostReactionDetailFields

export const PostReactionDetailGQLFields = (
  fields: PostReactionDetailFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        count

      ${
        fields.participants
          ? `
        participants
        ${
          fields.participants?.variables
            ? `
          (

    ${
      fields.participants.variables.limit
        ? `
      limit: ${fields.participants.variables.limit}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PaginatedPostReactionParticipantGQLFields(
            fields.participants.fields,
          )}
        }
      `
          : ``
      }

reacted
reaction
      `
}

export interface CustomPostReactionParticipantFields {
  participant?: MemberFields
  reaction?: PostReactionDetailFields
}
export type PostReactionParticipantFields =
  | 'basic'
  | 'all'
  | CustomPostReactionParticipantFields

export const PostReactionParticipantGQLFields = (
  fields: PostReactionParticipantFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      participant: 'basic',
      reaction: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.participant
        ? `
      participant {
        ${MemberGQLFields(fields.participant)}
      }
    `
        : ``
    }


    ${
      fields.reaction
        ? `
      reaction {
        ${PostReactionDetailGQLFields(fields.reaction)}
      }
    `
        : ``
    }

      `
}

export interface CustomPostReactionParticipantEdgeFields {
  node?: PostReactionParticipantFields
}
export type PostReactionParticipantEdgeFields =
  | 'basic'
  | 'all'
  | CustomPostReactionParticipantEdgeFields

export const PostReactionParticipantEdgeGQLFields = (
  fields: PostReactionParticipantEdgeFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${PostReactionParticipantGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export type PostSeoDetailFields = 'basic' | 'all' | Record<string, never>

export const PostSeoDetailGQLFields = (fields: PostSeoDetailFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        description
image
title
      `
}

export interface CustomPostTopReplierFields {
  member?: MemberFields
}
export type PostTopReplierFields = 'basic' | 'all' | CustomPostTopReplierFields

export const PostTopReplierGQLFields = (fields: PostTopReplierFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      member: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.member
        ? `
      member {
        ${MemberGQLFields(fields.member)}
      }
    `
        : ``
    }

repliesCount
      `
}

export interface CustomPostTypeFields {
  actions?: PostTypeActionFields
  authMemberProps?: PostTypeAuthMemberPropsFields
  customReactions?: CustomReactionFields
  icon?: MediaFields
  mappings?: PostTypeMappingFields
  nativeFieldsTemplates?: NativeFieldsTemplatesFields
  postFields?: CustomFieldsSchemaFields
  recommendationsSettings?: PostTypeRecommendationSettingsFields
  slate?: SlateFields
  validReplyTypes?: PostTypeFields
}
export type PostTypeFields = 'basic' | 'all' | CustomPostTypeFields

export const PostTypeGQLFields = (fields: PostTypeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      actions: 'basic',
      authMemberProps: 'basic',
      customReactions: 'basic',
      icon: 'basic',
      mappings: 'basic',
      nativeFieldsTemplates: 'basic',
      postFields: 'basic',
      recommendationsSettings: 'basic',
      slate: 'basic',
      validReplyTypes: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.actions
        ? `
      actions {
        ${PostTypeActionGQLFields(fields.actions)}
      }
    `
        : ``
    }

allowedEmojis
allowedReactions
archived

    ${
      fields.authMemberProps
        ? `
      authMemberProps {
        ${PostTypeAuthMemberPropsGQLFields(fields.authMemberProps)}
      }
    `
        : ``
    }

context
createdAt

    ${
      fields.customReactions
        ? `
      customReactions {
        ${CustomReactionGQLFields(fields.customReactions)}
      }
    `
        : ``
    }

description
excludedNativeShortcuts
forbiddenEmojis
forbiddenReactions

    ${
      fields.icon
        ? `
      icon {
        ${MediaGQLFields(fields.icon)}
      }
    `
        : ``
    }

iconId
id
languageTemplate
layout

    ${
      fields.mappings
        ? `
      mappings {
        ${PostTypeMappingGQLFields(fields.mappings)}
      }
    `
        : ``
    }

name

    ${
      fields.nativeFieldsTemplates
        ? `
      nativeFieldsTemplates {
        ${NativeFieldsTemplatesGQLFields(fields.nativeFieldsTemplates)}
      }
    `
        : ``
    }

negativeReactions
pluralName
positiveReactions

    ${
      fields.postFields
        ? `
      postFields {
        ${CustomFieldsSchemaGQLFields(fields.postFields)}
      }
    `
        : ``
    }

primaryReactionType

    ${
      fields.recommendationsSettings
        ? `
      recommendationsSettings {
        ${PostTypeRecommendationSettingsGQLFields(
          fields.recommendationsSettings,
        )}
      }
    `
        : ``
    }

shortContentTemplate
singleChoiceReactions

    ${
      fields.slate
        ? `
      slate {
        ${SlateGQLFields(fields.slate)}
      }
    `
        : ``
    }

slug
titleTemplate
updatedAt

    ${
      fields.validReplyTypes
        ? `
      validReplyTypes {
        ${PostTypeGQLFields(fields.validReplyTypes)}
      }
    `
        : ``
    }

      `
}

export type PostTypeActionFields = 'basic' | 'all' | Record<string, never>

export const PostTypeActionGQLFields = (fields: PostTypeActionFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        props
shortcutKey
      `
}

export interface CustomPostTypeAuthMemberPropsFields {
  availableSpaces?: SpaceFields
  permissions?: ActionPermissionsFields
}
export type PostTypeAuthMemberPropsFields =
  | 'basic'
  | 'all'
  | CustomPostTypeAuthMemberPropsFields

export const PostTypeAuthMemberPropsGQLFields = (
  fields: PostTypeAuthMemberPropsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      availableSpaces: 'basic',
      permissions: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.availableSpaces
        ? `
      availableSpaces {
        ${SpaceGQLFields(fields.availableSpaces)}
      }
    `
        : ``
    }

context

    ${
      fields.permissions
        ? `
      permissions {
        ${ActionPermissionsGQLFields(fields.permissions)}
      }
    `
        : ``
    }

scopes
      `
}

export interface CustomPostTypeEdgeFields {
  node?: PostTypeFields
}
export type PostTypeEdgeFields = 'basic' | 'all' | CustomPostTypeEdgeFields

export const PostTypeEdgeGQLFields = (fields: PostTypeEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${PostTypeGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export type PostTypeMappingFields = 'basic' | 'all' | Record<string, never>

export const PostTypeMappingGQLFields = (fields: PostTypeMappingFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        default
description
field
isMainContent
isSearchable
key
required
title
type
      `
}

export type PostTypeRecommendationSettingsFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const PostTypeRecommendationSettingsGQLFields = (
  fields: PostTypeRecommendationSettingsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        fields
sourceSelf
sourceSpaces
      `
}

export type PriceFields = 'basic' | 'all' | Record<string, never>

export const PriceGQLFields = (fields: PriceFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        currency
formattedValue
value
      `
}

export type PrivateMessagingSettingsFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const PrivateMessagingSettingsGQLFields = (
  fields: PrivateMessagingSettingsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        membersCanTurnOffPrivateMessaging
membersCanUsePrivateMessaging
privateMessagingEnabled
      `
}

export interface CustomProductFields {
  prices?: ProductPriceFields
}
export type ProductFields = 'basic' | 'all' | CustomProductFields

export const ProductGQLFields = (fields: ProductFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      prices: 'basic',
    }
  }
  return `
        __typename
        description
features
name

    ${
      fields.prices
        ? `
      prices {
        ${ProductPriceGQLFields(fields.prices)}
      }
    `
        : ``
    }

seatCapacity
      `
}

export interface CustomProductPriceFields {
  additionalSeatPrice?: PriceFields
  planPrice?: PriceFields
}
export type ProductPriceFields = 'basic' | 'all' | CustomProductPriceFields

export const ProductPriceGQLFields = (fields: ProductPriceFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      additionalSeatPrice: 'basic',
      planPrice: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.additionalSeatPrice
        ? `
      additionalSeatPrice {
        ${PriceGQLFields(fields.additionalSeatPrice)}
      }
    `
        : ``
    }


    ${
      fields.planPrice
        ? `
      planPrice {
        ${PriceGQLFields(fields.planPrice)}
      }
    `
        : ``
    }

renewalType
      `
}

export type PublisherFields = 'basic' | 'all' | Record<string, never>

export const PublisherGQLFields = (fields: PublisherFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        id
notifyImplicitSubscriptions
      `
}

export interface CustomPublisherEdgeFields {
  node?: PublisherFields
}
export type PublisherEdgeFields = 'basic' | 'all' | CustomPublisherEdgeFields

export const PublisherEdgeGQLFields = (fields: PublisherEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${PublisherGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomQueryFields {
  accessGroup?: {
    fields: AccessGroupFields
    variables: Types.QueryAccessGroupArgs
  }

  accessGroups?: AccessGroupFields

  activityLogs?: {
    fields: ReportRecordFields
    variables: Types.QueryActivityLogsArgs
  }

  analytics?: {
    fields: AnalyticsResponseFields
    variables: Types.QueryAnalyticsArgs
  }

  apiRateLimitStats?: ApiRateLimitStatFields
  apiUsages?: DailyApiUsageFields

  app?: {
    fields: AppFields
    variables: Types.QueryAppArgs
  }

  apps?: {
    fields: PaginatedAppFields
    variables: Types.QueryAppsArgs
  }

  authMember?: MemberFields

  authMemberAppInstallation?: {
    fields: AppInstallationFields
    variables: Types.QueryAuthMemberAppInstallationArgs
  }

  authMemberAppInstallations?: {
    fields: PaginatedAppInstallationFields
    variables: Types.QueryAuthMemberAppInstallationsArgs
  }

  availableDynamicBlocks?: {
    fields: DynamicBlockFields
    variables: Types.QueryAvailableDynamicBlocksArgs
  }

  baskets?: {
    fields: BasketFields
    variables: Types.QueryBasketsArgs
  }

  billingDetails?: BillingDetailsFields
  billingProducts?: BillingProductsFields
  cancelSubscription?: ActionFields

  checkDomainAvailability?: {
    fields: DomainAvailabilityFields
    variables: Types.QueryCheckDomainAvailabilityArgs
  }

  checkEmailAvailability?: {
    fields: EmailAvailabilityFields
    variables: Types.QueryCheckEmailAvailabilityArgs
  }

  checkInvitationLinkValidity?: {
    fields: MemberInvitationLinkFields
    variables: Types.QueryCheckInvitationLinkValidityArgs
  }

  checkMemberInvitationValidity?: {
    fields: MemberInvitationFields
    variables: Types.QueryCheckMemberInvitationValidityArgs
  }

  checkNewDomainStatus?: {
    fields: DomainTransferStatusFields
    variables: Types.QueryCheckNewDomainStatusArgs
  }

  checkPasswordComplexity?: {
    fields: PasswordComplexityFields
    variables: Types.QueryCheckPasswordComplexityArgs
  }

  checkUsernameAvailability?: {
    fields: UsernameAvailabilityFields
    variables: Types.QueryCheckUsernameAvailabilityArgs
  }

  collection?: {
    fields: CollectionFields
    variables: Types.QueryCollectionArgs
  }

  collections?: {
    fields: CollectionFields
    variables: Types.QueryCollectionsArgs
  }

  domainAvailability?: {
    fields: DomainAvailabilityFields
    variables: Types.QueryDomainAvailabilityArgs
  }

  dynamicBlock?: {
    fields: DynamicBlockFields
    variables: Types.QueryDynamicBlockArgs
  }

  dynamicBlockByKey?: {
    fields: DynamicBlockFields
    variables: Types.QueryDynamicBlockByKeyArgs
  }

  dynamicBlocks?: {
    fields: PaginatedDynamicBlockFields
    variables: Types.QueryDynamicBlocksArgs
  }

  emailAvailability?: {
    fields: EmailAvailabilityFields
    variables: Types.QueryEmailAvailabilityArgs
  }

  emailLogs?: {
    fields: PaginatedEmailLogFields
    variables: Types.QueryEmailLogsArgs
  }

  embed?: {
    fields: EmbedFields
    variables: Types.QueryEmbedArgs
  }

  exploreSpaces?: {
    fields: PaginatedSpaceFields
    variables: Types.QueryExploreSpacesArgs
  }

  exportMemberRequest?: {
    fields: ExportFields
    variables: Types.QueryExportMemberRequestArgs
  }

  exportMemberRequests?: {
    fields: PaginatedExportFields
    variables: Types.QueryExportMemberRequestsArgs
  }

  extraProperties?: {
    fields: ExtraPropertyFields
    variables: Types.QueryExtraPropertiesArgs
  }

  feed?: {
    fields: PaginatedPostFields
    variables: Types.QueryFeedArgs
  }

  getAppById?: {
    fields: AppFields
    variables: Types.QueryGetAppByIdArgs
  }

  getAppBySlug?: {
    fields: AppFields
    variables: Types.QueryGetAppBySlugArgs
  }

  getAppNetworkSettings?: {
    variables: Types.QueryGetAppNetworkSettingsArgs
  }

  getAppSpaceSettings?: {
    variables: Types.QueryGetAppSpaceSettingsArgs
  }

  getApps?: {
    fields: PaginatedAppFields
    variables: Types.QueryGetAppsArgs
  }

  getAuthMember?: MemberFields

  getEmbed?: {
    fields: EmbedFields
    variables: Types.QueryGetEmbedArgs
  }

  getFeed?: {
    fields: PaginatedPostFields
    variables: Types.QueryGetFeedArgs
  }

  getGroup?: {
    fields: CollectionFields
    variables: Types.QueryGetGroupArgs
  }

  getGroups?: CollectionFields

  getMedia?: {
    fields: MediaFields
    variables: Types.QueryGetMediaArgs
  }

  getMember?: {
    fields: MemberFields
    variables: Types.QueryGetMemberArgs
  }

  getMemberAppInstallations?: {
    fields: PaginatedAppInstallationFields
    variables: Types.QueryGetMemberAppInstallationsArgs
  }

  getMemberInvitation?: {
    fields: MemberInvitationFields
    variables: Types.QueryGetMemberInvitationArgs
  }

  getMemberInvitationLink?: MemberInvitationLinkFields

  getMemberInvitations?: {
    fields: PaginatedMemberInvitationFields
    variables: Types.QueryGetMemberInvitationsArgs
  }

  getMemberNetworkNotificationSettings?: {
    fields: MemberNetworkNotificationSettingsFields
    variables: Types.QueryGetMemberNetworkNotificationSettingsArgs
  }

  getMemberNotificationSettings?: {
    fields: MemberNotificationSettingsFields
    variables: Types.QueryGetMemberNotificationSettingsArgs
  }

  getMemberPosts?: {
    fields: PaginatedPostFields
    variables: Types.QueryGetMemberPostsArgs
  }

  getMemberSpaceNotificationSettings?: {
    fields: MemberSpaceNotificationSettingsFields
    variables: Types.QueryGetMemberSpaceNotificationSettingsArgs
  }

  getMemberSpaces?: {
    fields: PaginatedSpaceMemberFields
    variables: Types.QueryGetMemberSpacesArgs
  }

  getMembers?: {
    fields: PaginatedMemberFields
    variables: Types.QueryGetMembersArgs
  }

  getModerationById?: {
    fields: ModerationItemFields
    variables: Types.QueryGetModerationByIdArgs
  }

  getModerationReporters?: {
    fields: PaginatedModerationItemReporterFields
    variables: Types.QueryGetModerationReportersArgs
  }

  getModerationSetting?: ModerationSettingsFields

  getModerations?: {
    fields: PaginatedModerationFields
    variables: Types.QueryGetModerationsArgs
  }

  getNetwork?: NetworkFields

  getNetworkAppInstallations?: {
    fields: PaginatedAppInstallationFields
    variables: Types.QueryGetNetworkAppInstallationsArgs
  }

  getNetworkApps?: {
    fields: AppFields
    variables: Types.QueryGetNetworkAppsArgs
  }

  getNetworkPublicInfo?: NetworkPublicInfoFields

  getNetworks?: {
    fields: PaginatedNetworkFields
    variables: Types.QueryGetNetworksArgs
  }

  getNotifications?: {
    fields: PaginatedNotificationFields
    variables: Types.QueryGetNotificationsArgs
  }

  getNotificationsCount?: NotificationsCountFields

  getPost?: {
    fields: PostFields
    variables: Types.QueryGetPostArgs
  }

  getPostAppInstallations?: {
    fields: PaginatedAppInstallationFields
    variables: Types.QueryGetPostAppInstallationsArgs
  }

  getPostReactionParticipants?: {
    fields: PaginatedPostReactionParticipantFields
    variables: Types.QueryGetPostReactionParticipantsArgs
  }

  getPosts?: {
    fields: PaginatedPostFields
    variables: Types.QueryGetPostsArgs
  }

  getReplies?: {
    fields: PaginatedPostFields
    variables: Types.QueryGetRepliesArgs
  }

  getReport?: {
    fields: ReportFields
    variables: Types.QueryGetReportArgs
  }

  getRoles?: RoleFields

  getScopes?: {
    fields: ScopesFields
    variables: Types.QueryGetScopesArgs
  }

  getSpace?: {
    fields: SpaceFields
    variables: Types.QueryGetSpaceArgs
  }

  getSpaceAppInstallations?: {
    fields: PaginatedAppInstallationFields
    variables: Types.QueryGetSpaceAppInstallationsArgs
  }

  getSpaceMembers?: {
    fields: PaginatedSpaceMemberFields
    variables: Types.QueryGetSpaceMembersArgs
  }

  getSpaceMembershipRequestForMember?: {
    fields: SpaceJoinRequestFields
    variables: Types.QueryGetSpaceMembershipRequestForMemberArgs
  }

  getSpaceMembershipRequests?: {
    fields: SpaceJoinRequestFields
    variables: Types.QueryGetSpaceMembershipRequestsArgs
  }

  getSpacePinnedPosts?: {
    fields: PostFields
    variables: Types.QueryGetSpacePinnedPostsArgs
  }

  getSpaceReport?: {
    fields: ReportFields
    variables: Types.QueryGetSpaceReportArgs
  }

  getSpaceRoles?: {
    fields: SpaceRoleFields
    variables: Types.QueryGetSpaceRolesArgs
  }

  getSsoMemberships?: {
    fields: SsoMembershipFields
    variables: Types.QueryGetSsoMembershipsArgs
  }

  getSupportNetworkTokens?: {
    fields: SupportLimitedAuthTokenFields
    variables: Types.QueryGetSupportNetworkTokensArgs
  }

  getTokens?: {
    fields: AuthTokenFields
    variables: Types.QueryGetTokensArgs
  }

  globalApp?: {
    fields: AppFields
    variables: Types.QueryGlobalAppArgs
  }

  globalAppCollaborators?: {
    fields: AppCollaboratorFields
    variables: Types.QueryGlobalAppCollaboratorsArgs
  }

  globalAppPublications?: {
    fields: AppPublicationFields
    variables: Types.QueryGlobalAppPublicationsArgs
  }

  globalAppSettings?: {
    fields: AppSettingFields
    variables: Types.QueryGlobalAppSettingsArgs
  }

  globalApps?: {
    fields: PaginatedAppFields
    variables: Types.QueryGlobalAppsArgs
  }

  globalEmbed?: {
    fields: EmbedFields
    variables: Types.QueryGlobalEmbedArgs
  }

  globalEventTypes?: EventTypeFields

  globalMedia?: {
    fields: MediaFields
    variables: Types.QueryGlobalMediaArgs
  }

  globalNetworks?: {
    fields: PaginatedNetworkFields
    variables: Types.QueryGlobalNetworksArgs
  }

  globalToken?: {
    fields: GlobalTokenFields
    variables: Types.QueryGlobalTokenArgs
  }

  homepage?: {
    fields: PageFields
    variables: Types.QueryHomepageArgs
  }

  impersonateLoginToNetwork?: {
    fields: AuthInfoWithOtpFields
    variables: Types.QueryImpersonateLoginToNetworkArgs
  }

  invitationLinkValidity?: {
    fields: MemberInvitationLinkFields
    variables: Types.QueryInvitationLinkValidityArgs
  }

  invoices?: InvoiceFields

  leaderboard?: {
    fields: MemberFields
    variables: Types.QueryLeaderboardArgs
  }

  leaderboardWithScores?: {
    fields: LeaderboardItemFields
    variables: Types.QueryLeaderboardWithScoresArgs
  }

  limitedToken?: {
    fields: AppTokenFields
    variables: Types.QueryLimitedTokenArgs
  }

  loginNetwork?: {
    fields: AuthTokenFields
    variables: Types.QueryLoginNetworkArgs
  }

  loginNetworkWithPassword?: {
    fields: AuthTokenFields
    variables: Types.QueryLoginNetworkWithPasswordArgs
  }

  loginSupportWithSso?: {
    fields: SupportSsoUrlFields
    variables: Types.QueryLoginSupportWithSsoArgs
  }

  loginSupportWithSsoCode?: {
    fields: SupportAuthTokenFields
    variables: Types.QueryLoginSupportWithSsoCodeArgs
  }

  loginWithSso?: {
    fields: SsoUrlFields
    variables: Types.QueryLoginWithSsoArgs
  }

  loginWithSsoCode?: {
    fields: AuthTokenFields
    variables: Types.QueryLoginWithSsoCodeArgs
  }

  massActionRequest?: {
    fields: MassActionRequestFields
    variables: Types.QueryMassActionRequestArgs
  }

  massActionRequestInProgress?: MassActionRequestFields

  media?: {
    fields: MediaFields
    variables: Types.QueryMediaArgs
  }

  member?: {
    fields: MemberFields
    variables: Types.QueryMemberArgs
  }

  memberInvitation?: {
    fields: MemberInvitationFields
    variables: Types.QueryMemberInvitationArgs
  }

  memberInvitationLink?: MemberInvitationLinkFields

  memberInvitationValidity?: {
    fields: MemberInvitationFields
    variables: Types.QueryMemberInvitationValidityArgs
  }

  memberInvitations?: {
    fields: PaginatedMemberInvitationFields
    variables: Types.QueryMemberInvitationsArgs
  }

  memberNotificationSettings?: {
    fields: MemberNotificationSettingsFields
    variables: Types.QueryMemberNotificationSettingsArgs
  }

  memberPostNotificationSettings?: {
    fields: MemberPostNotificationSettingsFields
    variables: Types.QueryMemberPostNotificationSettingsArgs
  }

  memberPosts?: {
    fields: PaginatedPostFields
    variables: Types.QueryMemberPostsArgs
  }

  memberScore?: {
    fields: ReportFields
    variables: Types.QueryMemberScoreArgs
  }

  memberSpaceMembershipRequest?: {
    fields: SpaceJoinRequestFields
    variables: Types.QueryMemberSpaceMembershipRequestArgs
  }

  memberSpaces?: {
    fields: PaginatedSpaceMemberFields
    variables: Types.QueryMemberSpacesArgs
  }

  members?: {
    fields: PaginatedMemberFields
    variables: Types.QueryMembersArgs
  }

  membersByIds?: {
    fields: MemberFields
    variables: Types.QueryMembersByIdsArgs
  }

  migrationSampleFile?: {
    variables: Types.QueryMigrationSampleFileArgs
  }

  moderationItem?: {
    fields: ModerationItemFields
    variables: Types.QueryModerationItemArgs
  }

  moderationItemReporters?: {
    fields: PaginatedModerationItemReporterFields
    variables: Types.QueryModerationItemReportersArgs
  }

  moderationItems?: {
    fields: PaginatedModerationFields
    variables: Types.QueryModerationItemsArgs
  }

  moderationSettings?: ModerationSettingsFields
  network?: NetworkFields

  networkAppInstallations?: {
    fields: PaginatedAppInstallationFields
    variables: Types.QueryNetworkAppInstallationsArgs
  }

  networkApps?: {
    fields: AppFields
    variables: Types.QueryNetworkAppsArgs
  }

  networkPublicInfo?: NetworkPublicInfoFields

  networks?: {
    fields: PaginatedNetworkFields
    variables: Types.QueryNetworksArgs
  }

  networksMembers?: {
    fields: PaginatedPluralMemberFields
    variables: Types.QueryNetworksMembersArgs
  }

  newDomainStatus?: {
    fields: DomainTransferStatusFields
    variables: Types.QueryNewDomainStatusArgs
  }

  notifications?: {
    fields: PaginatedNotificationFields
    variables: Types.QueryNotificationsArgs
  }

  notificationsCount?: NotificationsCountFields

  oAuthCode?: {
    fields: OAuthCodeFields
    variables: Types.QueryOAuthCodeArgs
  }

  oAuthTokens?: {
    fields: OAuthTokensFields
    variables: Types.QueryOAuthTokensArgs
  }

  page?: {
    fields: PageFields
    variables: Types.QueryPageArgs
  }

  pageCustomResponses?: {
    fields: PaginatedPageCustomResponseFields
    variables: Types.QueryPageCustomResponsesArgs
  }

  passwordComplexity?: {
    fields: PasswordComplexityFields
    variables: Types.QueryPasswordComplexityArgs
  }

  permission?: {
    fields: PermissionFields
    variables: Types.QueryPermissionArgs
  }

  permissions?: PermissionFields

  post?: {
    fields: PostFields
    variables: Types.QueryPostArgs
  }

  postModerationItems?: {
    fields: PaginatedModerationFields
    variables: Types.QueryPostModerationItemsArgs
  }

  postReactionParticipants?: {
    fields: PaginatedPostReactionParticipantFields
    variables: Types.QueryPostReactionParticipantsArgs
  }

  postType?: {
    fields: PostTypeFields
    variables: Types.QueryPostTypeArgs
  }

  postTypes?: {
    fields: PaginatedPostTypeFields
    variables: Types.QueryPostTypesArgs
  }

  posts?: {
    fields: PaginatedPostFields
    variables: Types.QueryPostsArgs
  }

  postsByIds?: {
    fields: PostFields
    variables: Types.QueryPostsByIdsArgs
  }

  privateMessage?: {
    fields: SpaceFields
    variables: Types.QueryPrivateMessageArgs
  }

  probeDomain?: {
    fields: DomainProbeResultFields
    variables: Types.QueryProbeDomainArgs
  }

  products?: ProductFields

  publisherSettings?: {
    fields: PublisherFields
    variables: Types.QueryPublisherSettingsArgs
  }

  recommendResources?: {
    fields: SmartSearchResourceFields
    variables: Types.QueryRecommendResourcesArgs
  }

  redirect?: {
    fields: RedirectFields
    variables: Types.QueryRedirectArgs
  }

  replies?: {
    fields: PaginatedPostFields
    variables: Types.QueryRepliesArgs
  }

  report?: {
    fields: ReportFields
    variables: Types.QueryReportArgs
  }

  roles?: {
    fields: RoleFields
    variables: Types.QueryRolesArgs
  }

  scopes?: {
    fields: ScopesFields
    variables: Types.QueryScopesArgs
  }

  search?: {
    fields: SearchResultFields
    variables: Types.QuerySearchArgs
  }

  searchMembersForSpaceInvitation?: {
    fields: MemberForSpaceInvitationFields
    variables: Types.QuerySearchMembersForSpaceInvitationArgs
  }

  sitemap?: {
    fields: PaginatedSitemapFields
    variables: Types.QuerySitemapArgs
  }

  smartSearch?: {
    fields: SmartSearchResultFields
    variables: Types.QuerySmartSearchArgs
  }

  space?: {
    fields: SpaceFields
    variables: Types.QuerySpaceArgs
  }

  spaceDefaultNotificationSettings?: {
    fields: SpaceDefaultNotificationSettingsFields
    variables: Types.QuerySpaceDefaultNotificationSettingsArgs
  }

  spaceJoinRequests?: {
    fields: PaginatedSpaceJoinRequestFields
    variables: Types.QuerySpaceJoinRequestsArgs
  }

  spaceMember?: {
    fields: SpaceMemberFields
    variables: Types.QuerySpaceMemberArgs
  }

  spaceMembers?: {
    fields: PaginatedSpaceMemberFields
    variables: Types.QuerySpaceMembersArgs
  }

  spaceMembershipRequests?: {
    fields: SpaceJoinRequestFields
    variables: Types.QuerySpaceMembershipRequestsArgs
  }

  spacePinnedPosts?: {
    fields: PostFields
    variables: Types.QuerySpacePinnedPostsArgs
  }

  spacePostType?: {
    fields: SpacePostTypeFields
    variables: Types.QuerySpacePostTypeArgs
  }

  spacePostTypes?: {
    fields: PaginatedSpacePostTypeFields
    variables: Types.QuerySpacePostTypesArgs
  }

  spaceRoles?: {
    fields: SpaceRoleFields
    variables: Types.QuerySpaceRolesArgs
  }

  spaces?: {
    fields: PaginatedSpaceFields
    variables: Types.QuerySpacesArgs
  }

  spacesByIds?: {
    fields: SpaceFields
    variables: Types.QuerySpacesByIdsArgs
  }

  spacesBySlugs?: {
    fields: SpaceFields
    variables: Types.QuerySpacesBySlugsArgs
  }

  ssoMemberships?: {
    fields: SsoMembershipFields
    variables: Types.QuerySsoMembershipsArgs
  }

  ssoUrl?: {
    fields: SsoUrlFields
    variables: Types.QuerySsoUrlArgs
  }

  ssos?: {
    fields: SsoFields
    variables: Types.QuerySsosArgs
  }

  subscriberSettings?: SubscriberFields

  subscription?: {
    fields: BillingSubscriptionFields
    variables: Types.QuerySubscriptionArgs
  }

  subscriptionInformation?: SubscriptionInformationFields

  supportNetworkTokens?: {
    fields: SupportLimitedAuthTokenFields
    variables: Types.QuerySupportNetworkTokensArgs
  }

  supportSsoUrl?: {
    fields: SupportSsoUrlFields
    variables: Types.QuerySupportSsoUrlArgs
  }

  systemSpaces?: SpaceFields

  tagPosts?: {
    fields: PaginatedPostFields
    variables: Types.QueryTagPostsArgs
  }

  tags?: {
    fields: PaginatedTagFields
    variables: Types.QueryTagsArgs
  }

  template?: {
    fields: TemplateFields
    variables: Types.QueryTemplateArgs
  }

  templateRequest?: {
    fields: TemplateRequestFields
    variables: Types.QueryTemplateRequestArgs
  }

  templates?: {
    fields: PaginatedTemplateFields
    variables: Types.QueryTemplatesArgs
  }

  tokens?: {
    fields: AuthTokenFields
    variables: Types.QueryTokensArgs
  }

  translateMarkup?: {
    variables: Types.QueryTranslateMarkupArgs
  }

  usernameAvailability?: {
    fields: UsernameAvailabilityFields
    variables: Types.QueryUsernameAvailabilityArgs
  }

  vatTypes?: VatTypeInfoFields
}
export type QueryFields = 'basic' | 'all' | CustomQueryFields

export type RedirectFields = 'basic' | 'all' | Record<string, never>

export const RedirectGQLFields = (fields: RedirectFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        resolvedUrl
url
      `
}

export interface CustomReportFields {
  data?: ReportDataFields
}
export type ReportFields = 'basic' | 'all' | CustomReportFields

export const ReportGQLFields = (fields: ReportFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      data: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.data
        ? `
      data {
        ${ReportDataGQLFields(fields.data)}
      }
    `
        : ``
    }

description
endDate
previousValue
slug
startDate
title
tooltip
value
      `
}

export interface CustomReportDataFields {
  previousValue?: ReportDataValueFields
  value?: ReportDataValueFields
}
export type ReportDataFields = 'basic' | 'all' | CustomReportDataFields

export const ReportDataGQLFields = (fields: ReportDataFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      previousValue: 'basic',
      value: 'basic',
    }
  }
  return `
        __typename
        description
key

    ${
      fields.previousValue
        ? `
      previousValue {
        ${ReportDataValueGQLFields(fields.previousValue)}
      }
    `
        : ``
    }

type

    ${
      fields.value
        ? `
      value {
        ${ReportDataValueGQLFields(fields.value)}
      }
    `
        : ``
    }

      `
}

export interface CustomReportDataValueFields {
  onChartData?: ChartDataFields
  onEntityReport?: EntityReportFields
  onIntValue?: IntValueFields
  onStringValue?: StringValueFields
}
export type ReportDataValueFields =
  | 'basic'
  | 'all'
  | CustomReportDataValueFields

export const ReportDataValueGQLFields = (fields: ReportDataValueFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      onChartData: 'basic',
      onEntityReport: 'basic',
      onIntValue: 'basic',
      onStringValue: 'basic',
    }
  }
  return `
        __typename

          ${
            fields.onChartData
              ? `
            ... on ChartData {
              ${ChartDataGQLFields(fields.onChartData)}
            }
          `
              : ``
          }


          ${
            fields.onEntityReport
              ? `
            ... on EntityReport {
              ${EntityReportGQLFields(fields.onEntityReport)}
            }
          `
              : ``
          }


          ${
            fields.onIntValue
              ? `
            ... on IntValue {
              ${IntValueGQLFields(fields.onIntValue)}
            }
          `
              : ``
          }


          ${
            fields.onStringValue
              ? `
            ... on StringValue {
              ${StringValueGQLFields(fields.onStringValue)}
            }
          `
              : ``
          }

      `
}

export interface CustomReportEntitiesFields {
  actor?: MemberFields
  app?: AppFields
  member?: MemberFields
  network?: NetworkFields
  person?: MemberFields
  post?: PostFields
  space?: SpaceFields
  tag?: TagFields
}
export type ReportEntitiesFields = 'basic' | 'all' | CustomReportEntitiesFields

export const ReportEntitiesGQLFields = (fields: ReportEntitiesFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      actor: 'basic',
      app: 'basic',
      member: 'basic',
      network: 'basic',
      person: 'basic',
      post: 'basic',
      space: 'basic',
      tag: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.actor
        ? `
      actor {
        ${MemberGQLFields(fields.actor)}
      }
    `
        : ``
    }


    ${
      fields.app
        ? `
      app {
        ${AppGQLFields(fields.app)}
      }
    `
        : ``
    }


    ${
      fields.member
        ? `
      member {
        ${MemberGQLFields(fields.member)}
      }
    `
        : ``
    }


    ${
      fields.network
        ? `
      network {
        ${NetworkGQLFields(fields.network)}
      }
    `
        : ``
    }


    ${
      fields.person
        ? `
      person {
        ${MemberGQLFields(fields.person)}
      }
    `
        : ``
    }


    ${
      fields.post
        ? `
      post {
        ${PostGQLFields(fields.post)}
      }
    `
        : ``
    }


    ${
      fields.space
        ? `
      space {
        ${SpaceGQLFields(fields.space)}
      }
    `
        : ``
    }


    ${
      fields.tag
        ? `
      tag {
        ${TagGQLFields(fields.tag)}
      }
    `
        : ``
    }

      `
}

export type ReportPayloadFields = 'basic' | 'all' | Record<string, never>

export const ReportPayloadGQLFields = (fields: ReportPayloadFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        key
value
      `
}

export interface CustomReportRecordFields {
  entities?: ReportEntitiesFields
  payload?: ReportPayloadFields
}
export type ReportRecordFields = 'basic' | 'all' | CustomReportRecordFields

export const ReportRecordGQLFields = (fields: ReportRecordFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      entities: 'basic',
      payload: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.entities
        ? `
      entities {
        ${ReportEntitiesGQLFields(fields.entities)}
      }
    `
        : ``
    }


    ${
      fields.payload
        ? `
      payload {
        ${ReportPayloadGQLFields(fields.payload)}
      }
    `
        : ``
    }

      `
}

export interface CustomReportableEntityFields {
  onMember?: MemberFields
  onPost?: PostFields
  onSpace?: SpaceFields
  onTag?: TagFields
}
export type ReportableEntityFields =
  | 'basic'
  | 'all'
  | CustomReportableEntityFields

export const ReportableEntityGQLFields = (fields: ReportableEntityFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      onMember: 'basic',
      onPost: 'basic',
      onSpace: 'basic',
      onTag: 'basic',
    }
  }
  return `
        __typename

          ${
            fields.onMember
              ? `
            ... on Member {
              ${MemberGQLFields(fields.onMember)}
            }
          `
              : ``
          }


          ${
            fields.onPost
              ? `
            ... on Post {
              ${PostGQLFields(fields.onPost)}
            }
          `
              : ``
          }


          ${
            fields.onSpace
              ? `
            ... on Space {
              ${SpaceGQLFields(fields.onSpace)}
            }
          `
              : ``
          }


          ${
            fields.onTag
              ? `
            ... on Tag {
              ${TagGQLFields(fields.onTag)}
            }
          `
              : ``
          }

      `
}

export type RoleFields = 'basic' | 'all' | Record<string, never>

export const RoleGQLFields = (fields: RoleFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        description
id
name
scopes
type
visible
      `
}

export interface CustomScopesFields {
  contextAwareScopes?: ContextScopesFields
}
export type ScopesFields = 'basic' | 'all' | CustomScopesFields

export const ScopesGQLFields = (fields: ScopesFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      contextAwareScopes: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.contextAwareScopes
        ? `
      contextAwareScopes {
        ${ContextScopesGQLFields(fields.contextAwareScopes)}
      }
    `
        : ``
    }

scopes
      `
}

export interface CustomSearchEntityFields {
  by?: ByFields
  entity?: SearchObjectEntityFields
  in?: InFields
  media?: MediaFields
}
export type SearchEntityFields = 'basic' | 'all' | CustomSearchEntityFields

export const SearchEntityGQLFields = (fields: SearchEntityFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      by: 'basic',
      entity: 'basic',
      in: 'basic',
      media: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.by
        ? `
      by {
        ${ByGQLFields(fields.by)}
      }
    `
        : ``
    }

content
created

    ${
      fields.entity
        ? `
      entity {
        ${SearchObjectEntityGQLFields(fields.entity)}
      }
    `
        : ``
    }

entityId
entityType
id

    ${
      fields.in
        ? `
      in {
        ${InGQLFields(fields.in)}
      }
    `
        : ``
    }


    ${
      fields.media
        ? `
      media {
        ${MediaGQLFields(fields.media)}
      }
    `
        : ``
    }

subtitle
tags
title
url
      `
}

export interface CustomSearchEntityGroupFields {
  hits?: SearchEntityFields
}
export type SearchEntityGroupFields =
  | 'basic'
  | 'all'
  | CustomSearchEntityGroupFields

export const SearchEntityGroupGQLFields = (fields: SearchEntityGroupFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      hits: 'basic',
    }
  }
  return `
        __typename
        entityType

    ${
      fields.hits
        ? `
      hits {
        ${SearchEntityGQLFields(fields.hits)}
      }
    `
        : ``
    }

      `
}

export interface CustomSearchObjectEntityFields {
  onMember?: MemberFields
  onPost?: PostFields
  onSpace?: SpaceFields
}
export type SearchObjectEntityFields =
  | 'basic'
  | 'all'
  | CustomSearchObjectEntityFields

export const SearchObjectEntityGQLFields = (
  fields: SearchObjectEntityFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      onMember: 'basic',
      onPost: 'basic',
      onSpace: 'basic',
    }
  }
  return `
        __typename

          ${
            fields.onMember
              ? `
            ... on Member {
              ${MemberGQLFields(fields.onMember)}
            }
          `
              : ``
          }


          ${
            fields.onPost
              ? `
            ... on Post {
              ${PostGQLFields(fields.onPost)}
            }
          `
              : ``
          }


          ${
            fields.onSpace
              ? `
            ... on Space {
              ${SpaceGQLFields(fields.onSpace)}
            }
          `
              : ``
          }

      `
}

export interface CustomSearchResultFields {
  hits?: SearchEntityGroupFields
}
export type SearchResultFields = 'basic' | 'all' | CustomSearchResultFields

export const SearchResultGQLFields = (fields: SearchResultFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      hits: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.hits
        ? `
      hits {
        ${SearchEntityGroupGQLFields(fields.hits)}
      }
    `
        : ``
    }

totalCount
      `
}

export interface CustomSettingsFields {
  captcha?: CaptchaSettingsFields
  contentTranslation?: ContentTranslationSettingsFields
  memberPermissions?: MembersPermissionSettingsFields
  privateMessaging?: PrivateMessagingSettingsFields
}
export type SettingsFields = 'basic' | 'all' | CustomSettingsFields

export const SettingsGQLFields = (fields: SettingsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      captcha: 'basic',
      contentTranslation: 'basic',
      memberPermissions: 'basic',
      privateMessaging: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.captcha
        ? `
      captcha {
        ${CaptchaSettingsGQLFields(fields.captcha)}
      }
    `
        : ``
    }


    ${
      fields.contentTranslation
        ? `
      contentTranslation {
        ${ContentTranslationSettingsGQLFields(fields.contentTranslation)}
      }
    `
        : ``
    }

emailEnabled
emailFrom
emailNotificationEnabled
fileSizeLimit
fileTypesLimit
imageSizeLimit
imageTypesLimit

    ${
      fields.memberPermissions
        ? `
      memberPermissions {
        ${MembersPermissionSettingsGQLFields(fields.memberPermissions)}
      }
    `
        : ``
    }

membersCanTurnOffPrivateMessaging
membersCanUsePrivateMessaging

    ${
      fields.privateMessaging
        ? `
      privateMessaging {
        ${PrivateMessagingSettingsGQLFields(fields.privateMessaging)}
      }
    `
        : ``
    }

privateMessagingEnabled
      `
}

export interface CustomShortcutFields {
  app?: AppFields
  favicon?: MediaFields
}
export type ShortcutFields = 'basic' | 'all' | CustomShortcutFields

export const ShortcutGQLFields = (fields: ShortcutFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      app: 'basic',
      favicon: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.app
        ? `
      app {
        ${AppGQLFields(fields.app)}
      }
    `
        : ``
    }

appId
description

    ${
      fields.favicon
        ? `
      favicon {
        ${MediaGQLFields(fields.favicon)}
      }
    `
        : ``
    }

faviconId
key
name
      `
}

export interface CustomSignedUrlFields {
  urls?: MediaUrlsFields
}
export type SignedUrlFields = 'basic' | 'all' | CustomSignedUrlFields

export const SignedUrlGQLFields = (fields: SignedUrlFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      urls: 'basic',
    }
  }
  return `
        __typename
        fields
mediaDownloadUrl
mediaId
mediaUrl
signedUrl

    ${
      fields.urls
        ? `
      urls {
        ${MediaUrlsGQLFields(fields.urls)}
      }
    `
        : ``
    }

      `
}

export type SitemapFields = 'basic' | 'all' | Record<string, never>

export const SitemapGQLFields = (fields: SitemapFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        id
lastModifiedAt
url
      `
}

export interface CustomSitemapEdgeFields {
  node?: SitemapFields
}
export type SitemapEdgeFields = 'basic' | 'all' | CustomSitemapEdgeFields

export const SitemapEdgeGQLFields = (fields: SitemapEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${SitemapGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomSlateFields {
  blocks?: BlockFields
  restrictions?: SlateRestrictionsFields
}
export type SlateFields = 'basic' | 'all' | CustomSlateFields

export const SlateGQLFields = (fields: SlateFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      blocks: 'basic',
      restrictions: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.blocks
        ? `
      blocks {
        ${BlockGQLFields(fields.blocks)}
      }
    `
        : ``
    }

id

    ${
      fields.restrictions
        ? `
      restrictions {
        ${SlateRestrictionsGQLFields(fields.restrictions)}
      }
    `
        : ``
    }

rootBlock
      `
}

export type SlateRestrictionsFields = 'basic' | 'all' | Record<string, never>

export const SlateRestrictionsGQLFields = (fields: SlateRestrictionsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        lockedChildrenBlocks
nonEditableBlocks
nonRemovableBlocks
      `
}

export interface CustomSmartSearchResourceFields {
  additional?: SmartSearchResourceAdditionalFields
  entity?: SearchObjectEntityFields
  info?: SmartSearchResourceInfoFields
}
export type SmartSearchResourceFields =
  | 'basic'
  | 'all'
  | CustomSmartSearchResourceFields

export const SmartSearchResourceGQLFields = (
  fields: SmartSearchResourceFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      additional: 'basic',
      entity: 'basic',
      info: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.additional
        ? `
      additional {
        ${SmartSearchResourceAdditionalGQLFields(fields.additional)}
      }
    `
        : ``
    }


    ${
      fields.entity
        ? `
      entity {
        ${SearchObjectEntityGQLFields(fields.entity)}
      }
    `
        : ``
    }


    ${
      fields.info
        ? `
      info {
        ${SmartSearchResourceInfoGQLFields(fields.info)}
      }
    `
        : ``
    }

source
type
uuid
      `
}

export type SmartSearchResourceAdditionalFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const SmartSearchResourceAdditionalGQLFields = (
  fields: SmartSearchResourceAdditionalFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        score
      `
}

export interface CustomSmartSearchResourceInfoFields {
  icon?: MediaFields
}
export type SmartSearchResourceInfoFields =
  | 'basic'
  | 'all'
  | CustomSmartSearchResourceInfoFields

export const SmartSearchResourceInfoGQLFields = (
  fields: SmartSearchResourceInfoFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      icon: 'basic',
    }
  }
  return `
        __typename
        description

    ${
      fields.icon
        ? `
      icon {
        ${MediaGQLFields(fields.icon)}
      }
    `
        : ``
    }

id
spaceId
title
url
      `
}

export interface CustomSmartSearchResultFields {
  resources?: SmartSearchResourceFields
}
export type SmartSearchResultFields =
  | 'basic'
  | 'all'
  | CustomSmartSearchResultFields

export const SmartSearchResultGQLFields = (fields: SmartSearchResultFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      resources: 'basic',
    }
  }
  return `
        __typename
        answer

    ${
      fields.resources
        ? `
      resources {
        ${SmartSearchResourceGQLFields(fields.resources)}
      }
    `
        : ``
    }

      `
}

export interface CustomSpaceFields {
  address?: SpaceAddressFields
  authMemberProps?: SpaceAuthMemberPropsFields
  banner?: MediaFields
  collection?: CollectionFields
  createdBy?: MemberFields
  customSeoDetail?: CustomSeoDetailFields
  highlightedTags?: TagFields
  image?: MediaFields

  installedApps?: {
    fields: PaginatedAppInstallationFields
    variables: Types.SpaceInstalledAppsArgs
  }

  members?: {
    fields: PaginatedSpaceMemberFields
    variables: Types.SpaceMembersArgs
  }

  network?: NetworkFields
  pinnedPosts?: PostFields

  posts?: {
    fields: PaginatedPostFields
    variables: Types.SpacePostsArgs
  }

  roles?: SpaceRoleFields
  seoDetail?: SpaceSeoDetailFields
  shortcuts?: ShortcutFields
  slate?: SlateFields
  tagFilter?: TagFilterFields

  tags?: {
    fields: PaginatedTagFields
    variables: Types.SpaceTagsArgs
  }
}
export type SpaceFields = 'basic' | 'all' | CustomSpaceFields

export const SpaceGQLFields = (fields: SpaceFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      address: 'basic',
      authMemberProps: 'basic',
      banner: 'basic',
      collection: 'basic',
      createdBy: 'basic',
      customSeoDetail: 'basic',
      highlightedTags: 'basic',
      image: 'basic',
      network: 'basic',
      pinnedPosts: 'basic',
      roles: 'basic',
      seoDetail: 'basic',
      shortcuts: 'basic',
      slate: 'basic',
      tagFilter: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.address
        ? `
      address {
        ${SpaceAddressGQLFields(fields.address)}
      }
    `
        : ``
    }


    ${
      fields.authMemberProps
        ? `
      authMemberProps {
        ${SpaceAuthMemberPropsGQLFields(fields.authMemberProps)}
      }
    `
        : ``
    }


    ${
      fields.banner
        ? `
      banner {
        ${MediaGQLFields(fields.banner)}
      }
    `
        : ``
    }

bannerId

    ${
      fields.collection
        ? `
      collection {
        ${CollectionGQLFields(fields.collection)}
      }
    `
        : ``
    }

createdAt

    ${
      fields.createdBy
        ? `
      createdBy {
        ${MemberGQLFields(fields.createdBy)}
      }
    `
        : ``
    }

createdById
customOrderingIndexInGroup

    ${
      fields.customSeoDetail
        ? `
      customSeoDetail {
        ${CustomSeoDetailGQLFields(fields.customSeoDetail)}
      }
    `
        : ``
    }

description
externalId
externalUrl
groupId
hidden
highlightedTagIds

    ${
      fields.highlightedTags
        ? `
      highlightedTags {
        ${TagGQLFields(fields.highlightedTags)}
      }
    `
        : ``
    }

id

    ${
      fields.image
        ? `
      image {
        ${MediaGQLFields(fields.image)}
      }
    `
        : ``
    }

imageId

      ${
        fields.installedApps
          ? `
        installedApps
        ${
          fields.installedApps?.variables
            ? `
          (

    ${
      fields.installedApps.variables.after
        ? `
      after: ${fields.installedApps.variables.after}
    `
        : ``
    }


    ${
      fields.installedApps.variables.before
        ? `
      before: ${fields.installedApps.variables.before}
    `
        : ``
    }


    ${
      fields.installedApps.variables.limit
        ? `
      limit: ${fields.installedApps.variables.limit}
    `
        : ``
    }


    ${
      fields.installedApps.variables.offset
        ? `
      offset: ${fields.installedApps.variables.offset}
    `
        : ``
    }


    ${
      fields.installedApps.variables.reverse
        ? `
      reverse: ${fields.installedApps.variables.reverse}
    `
        : ``
    }


    ${
      fields.installedApps.variables.status
        ? `
      status: ${fields.installedApps.variables.status}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PaginatedAppInstallationGQLFields(fields.installedApps.fields)}
        }
      `
          : ``
      }

inviteOnly
isHomepage
isNewUserHomepage
isReturningUserHomepage
key
layout

      ${
        fields.members
          ? `
        members
        ${
          fields.members?.variables
            ? `
          (

    ${
      fields.members.variables.limit
        ? `
      limit: ${fields.members.variables.limit}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PaginatedSpaceMemberGQLFields(fields.members.fields)}
        }
      `
          : ``
      }

membersCount
name

    ${
      fields.network
        ? `
      network {
        ${NetworkGQLFields(fields.network)}
      }
    `
        : ``
    }

networkId
nonAdminsCanInvite

    ${
      fields.pinnedPosts
        ? `
      pinnedPosts {
        ${PostGQLFields(fields.pinnedPosts)}
      }
    `
        : ``
    }


      ${
        fields.posts
          ? `
        posts
        ${
          fields.posts?.variables
            ? `
          (

    ${
      fields.posts.variables.excludePins
        ? `
      excludePins: ${fields.posts.variables.excludePins}
    `
        : ``
    }


    ${
      fields.posts.variables.limit
        ? `
      limit: ${fields.posts.variables.limit}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PaginatedPostGQLFields(fields.posts.fields)}
        }
      `
          : ``
      }

postsCount
private
relativeUrl

    ${
      fields.roles
        ? `
      roles {
        ${SpaceRoleGQLFields(fields.roles)}
      }
    `
        : ``
    }


    ${
      fields.seoDetail
        ? `
      seoDetail {
        ${SpaceSeoDetailGQLFields(fields.seoDetail)}
      }
    `
        : ``
    }


    ${
      fields.shortcuts
        ? `
      shortcuts {
        ${ShortcutGQLFields(fields.shortcuts)}
      }
    `
        : ``
    }


    ${
      fields.slate
        ? `
      slate {
        ${SlateGQLFields(fields.slate)}
      }
    `
        : ``
    }

slug
subscribersCount

    ${
      fields.tagFilter
        ? `
      tagFilter {
        ${TagFilterGQLFields(fields.tagFilter)}
      }
    `
        : ``
    }


      ${
        fields.tags
          ? `
        tags
        ${
          fields.tags?.variables
            ? `
          (

    ${
      fields.tags.variables.after
        ? `
      after: ${fields.tags.variables.after}
    `
        : ``
    }


    ${
      fields.tags.variables.before
        ? `
      before: ${fields.tags.variables.before}
    `
        : ``
    }


    ${
      fields.tags.variables.limit
        ? `
      limit: ${fields.tags.variables.limit}
    `
        : ``
    }


    ${
      fields.tags.variables.offset
        ? `
      offset: ${fields.tags.variables.offset}
    `
        : ``
    }


    ${
      fields.tags.variables.orderBy
        ? `
      orderBy: ${fields.tags.variables.orderBy}
    `
        : ``
    }


    ${
      fields.tags.variables.query
        ? `
      query: ${fields.tags.variables.query}
    `
        : ``
    }


    ${
      fields.tags.variables.reverse
        ? `
      reverse: ${fields.tags.variables.reverse}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${PaginatedTagGQLFields(fields.tags.fields)}
        }
      `
          : ``
      }

type
updatedAt
url
whoCanPost
whoCanReact
whoCanReply
      `
}

export type SpaceAddressFields = 'basic' | 'all' | Record<string, never>

export const SpaceAddressGQLFields = (fields: SpaceAddressFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        editable
exact
path
      `
}

export interface CustomSpaceAuthMemberPropsFields {
  availablePostTypes?: PostTypeFields
  permissions?: ActionPermissionsFields
  spaceMember?: SpaceMemberFields
}
export type SpaceAuthMemberPropsFields =
  | 'basic'
  | 'all'
  | CustomSpaceAuthMemberPropsFields

export const SpaceAuthMemberPropsGQLFields = (
  fields: SpaceAuthMemberPropsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      availablePostTypes: 'basic',
      permissions: 'basic',
      spaceMember: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.availablePostTypes
        ? `
      availablePostTypes {
        ${PostTypeGQLFields(fields.availablePostTypes)}
      }
    `
        : ``
    }

context
lastReadAt
membershipStatus

    ${
      fields.permissions
        ? `
      permissions {
        ${ActionPermissionsGQLFields(fields.permissions)}
      }
    `
        : ``
    }

scopes

    ${
      fields.spaceMember
        ? `
      spaceMember {
        ${SpaceMemberGQLFields(fields.spaceMember)}
      }
    `
        : ``
    }

subscribed
unreadPostsCount
      `
}

export type SpaceDefaultNotificationSettingsFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const SpaceDefaultNotificationSettingsGQLFields = (
  fields: SpaceDefaultNotificationSettingsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        channel
enabled
isDefault
preference
sameAsDefault
      `
}

export interface CustomSpaceEdgeFields {
  node?: SpaceFields
}
export type SpaceEdgeFields = 'basic' | 'all' | CustomSpaceEdgeFields

export const SpaceEdgeGQLFields = (fields: SpaceEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${SpaceGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomSpaceJoinRequestFields {
  member?: MemberFields
}
export type SpaceJoinRequestFields =
  | 'basic'
  | 'all'
  | CustomSpaceJoinRequestFields

export const SpaceJoinRequestGQLFields = (fields: SpaceJoinRequestFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      member: 'basic',
    }
  }
  return `
        __typename
        id

    ${
      fields.member
        ? `
      member {
        ${MemberGQLFields(fields.member)}
      }
    `
        : ``
    }

spaceId
status
      `
}

export interface CustomSpaceJoinRequestEdgeFields {
  node?: SpaceJoinRequestFields
}
export type SpaceJoinRequestEdgeFields =
  | 'basic'
  | 'all'
  | CustomSpaceJoinRequestEdgeFields

export const SpaceJoinRequestEdgeGQLFields = (
  fields: SpaceJoinRequestEdgeFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${SpaceJoinRequestGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomSpaceMemberFields {
  member?: MemberFields
  role?: SpaceRoleFields
  space?: SpaceFields
}
export type SpaceMemberFields = 'basic' | 'all' | CustomSpaceMemberFields

export const SpaceMemberGQLFields = (fields: SpaceMemberFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      member: 'basic',
      role: 'basic',
      space: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.member
        ? `
      member {
        ${MemberGQLFields(fields.member)}
      }
    `
        : ``
    }


    ${
      fields.role
        ? `
      role {
        ${SpaceRoleGQLFields(fields.role)}
      }
    `
        : ``
    }


    ${
      fields.space
        ? `
      space {
        ${SpaceGQLFields(fields.space)}
      }
    `
        : ``
    }

      `
}

export interface CustomSpaceMemberEdgeFields {
  node?: SpaceMemberFields
}
export type SpaceMemberEdgeFields =
  | 'basic'
  | 'all'
  | CustomSpaceMemberEdgeFields

export const SpaceMemberEdgeGQLFields = (fields: SpaceMemberEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${SpaceMemberGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomSpacePostTypeFields {
  postType?: PostTypeFields
  space?: SpaceFields
}
export type SpacePostTypeFields = 'basic' | 'all' | CustomSpacePostTypeFields

export const SpacePostTypeGQLFields = (fields: SpacePostTypeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      postType: 'basic',
      space: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.postType
        ? `
      postType {
        ${PostTypeGQLFields(fields.postType)}
      }
    `
        : ``
    }

postTypeId

    ${
      fields.space
        ? `
      space {
        ${SpaceGQLFields(fields.space)}
      }
    `
        : ``
    }

spaceId
whoCanPost
whoCanReact
whoCanReply
      `
}

export interface CustomSpacePostTypeEdgeFields {
  node?: SpacePostTypeFields
}
export type SpacePostTypeEdgeFields =
  | 'basic'
  | 'all'
  | CustomSpacePostTypeEdgeFields

export const SpacePostTypeEdgeGQLFields = (fields: SpacePostTypeEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${SpacePostTypeGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomSpaceRoleFields {
  network?: NetworkFields
}
export type SpaceRoleFields = 'basic' | 'all' | CustomSpaceRoleFields

export const SpaceRoleGQLFields = (fields: SpaceRoleFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      network: 'basic',
    }
  }
  return `
        __typename
        description
id
name

    ${
      fields.network
        ? `
      network {
        ${NetworkGQLFields(fields.network)}
      }
    `
        : ``
    }

scopes
type
      `
}

export interface CustomSpaceSeoDetailFields {
  image?: MediaFields
}
export type SpaceSeoDetailFields = 'basic' | 'all' | CustomSpaceSeoDetailFields

export const SpaceSeoDetailGQLFields = (fields: SpaceSeoDetailFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      image: 'basic',
    }
  }
  return `
        __typename
        description

    ${
      fields.image
        ? `
      image {
        ${MediaGQLFields(fields.image)}
      }
    `
        : ``
    }

title
      `
}

export type SsoFields = 'basic' | 'all' | Record<string, never>

export const SsoGQLFields = (fields: SsoFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        allowSignUp
authorizationUrl
buttonText
clientId
clientSecret
defaultRelayState
idpUrl
logoutUrl
name
provider
scopes
settingsUrl
signUpUrl
status
tokenUrl
type
userProfileUrl
      `
}

export type SsoMembershipFields = 'basic' | 'all' | Record<string, never>

export const SsoMembershipGQLFields = (fields: SsoMembershipFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        id
memberId
ssoType
      `
}

export type SsoUrlFields = 'basic' | 'all' | Record<string, never>

export const SsoUrlGQLFields = (fields: SsoUrlFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        url
      `
}

export type StatusReasonFields = 'basic' | 'all' | Record<string, never>

export const StatusReasonGQLFields = (fields: StatusReasonFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        changedAt
changedBy
changedById
reason
      `
}

export type StringValueFields = 'basic' | 'all' | Record<string, never>

export const StringValueGQLFields = (fields: StringValueFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        string
      `
}

export type SubscriberFields = 'basic' | 'all' | Record<string, never>

export const SubscriberGQLFields = (fields: SubscriberFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        emailSubscriptionEnabled
fcmDeviceTokens
id
mentionEmailEnabled
mentionSubscriptionEnabled
messageEmailEnabled
networkId
reactionEmailEnabled
reactionSubscriptionEnabled
      `
}

export interface CustomSubscriberEdgeFields {
  node?: SubscriberFields
}
export type SubscriberEdgeFields = 'basic' | 'all' | CustomSubscriberEdgeFields

export const SubscriberEdgeGQLFields = (fields: SubscriberEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${SubscriberGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomSubscriptionFields {
  network?: NetworkFields
  post?: PostFields

  smartSearch?: {
    fields: SmartSearchResultFields
    variables: Types.SubscriptionSmartSearchArgs
  }

  space?: SpaceFields
}
export type SubscriptionFields = 'basic' | 'all' | CustomSubscriptionFields

export const SubscriptionGQLFields = (fields: SubscriptionFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      network: 'basic',
      post: 'basic',
      space: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.network
        ? `
      network {
        ${NetworkGQLFields(fields.network)}
      }
    `
        : ``
    }


    ${
      fields.post
        ? `
      post {
        ${PostGQLFields(fields.post)}
      }
    `
        : ``
    }


      ${
        fields.smartSearch
          ? `
        smartSearch
        ${
          fields.smartSearch?.variables
            ? `
          (

    ${
      fields.smartSearch.variables.input
        ? `
      input: ${fields.smartSearch.variables.input}
    `
        : ``
    }

          )
        `
            : ``
        }
       {
          ${SmartSearchResultGQLFields(fields.smartSearch.fields)}
        }
      `
          : ``
      }


    ${
      fields.space
        ? `
      space {
        ${SpaceGQLFields(fields.space)}
      }
    `
        : ``
    }

      `
}

export type SubscriptionCheckoutSessionFields =
  | 'basic'
  | 'all'
  | Record<string, never>

export const SubscriptionCheckoutSessionGQLFields = (
  fields: SubscriptionCheckoutSessionFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        url
      `
}

export interface CustomSubscriptionInformationFields {
  addons?: AddonInformationFields
  currentProduct?: BillingProductFields
}
export type SubscriptionInformationFields =
  | 'basic'
  | 'all'
  | CustomSubscriptionInformationFields

export const SubscriptionInformationGQLFields = (
  fields: SubscriptionInformationFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      addons: 'basic',
      currentProduct: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.addons
        ? `
      addons {
        ${AddonInformationGQLFields(fields.addons)}
      }
    `
        : ``
    }


    ${
      fields.currentProduct
        ? `
      currentProduct {
        ${BillingProductGQLFields(fields.currentProduct)}
      }
    `
        : ``
    }

isManual
planId
planName
planPriceId
planPriceInterval
      `
}

export type SupportAuthTokenFields = 'basic' | 'all' | Record<string, never>

export const SupportAuthTokenGQLFields = (fields: SupportAuthTokenFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        accessToken
refreshToken
      `
}

export interface CustomSupportLimitedAuthTokenFields {
  member?: MemberFields
}
export type SupportLimitedAuthTokenFields =
  | 'basic'
  | 'all'
  | CustomSupportLimitedAuthTokenFields

export const SupportLimitedAuthTokenGQLFields = (
  fields: SupportLimitedAuthTokenFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      member: 'basic',
    }
  }
  return `
        __typename
        accessToken

    ${
      fields.member
        ? `
      member {
        ${MemberGQLFields(fields.member)}
      }
    `
        : ``
    }

      `
}

export type SupportSsoUrlFields = 'basic' | 'all' | Record<string, never>

export const SupportSsoUrlGQLFields = (fields: SupportSsoUrlFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        url
      `
}

export type TagFields = 'basic' | 'all' | Record<string, never>

export const TagGQLFields = (fields: TagFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        description
id
slug
spaceId
title
      `
}

export interface CustomTagEdgeFields {
  node?: TagFields
}
export type TagEdgeFields = 'basic' | 'all' | CustomTagEdgeFields

export const TagEdgeGQLFields = (fields: TagEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${TagGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export type TagFilterFields = 'basic' | 'all' | Record<string, never>

export const TagFilterGQLFields = (fields: TagFilterFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        filterType
filters
      `
}

export interface CustomTemplateFields {
  apps?: AppFields
  banner?: MediaFields
  contentTypes?: ContentTypeTemplateFields
  createdBy?: MemberFields
  embeds?: EmbedFields
  entity?: TemplateEntityFields
  favicon?: MediaFields
  hubContent?: HubContentFields
  image?: MediaFields
  images?: MediaFields
  network?: NetworkFields
  requiredTemplates?: TemplateFields
  slate?: SlateFields
  updatedBy?: MemberFields
}
export type TemplateFields = 'basic' | 'all' | CustomTemplateFields

export const TemplateGQLFields = (fields: TemplateFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      apps: 'basic',
      banner: 'basic',
      contentTypes: 'basic',
      createdBy: 'basic',
      embeds: 'basic',
      entity: 'basic',
      favicon: 'basic',
      hubContent: 'basic',
      image: 'basic',
      images: 'basic',
      network: 'basic',
      requiredTemplates: 'basic',
      slate: 'basic',
      updatedBy: 'basic',
    }
  }
  return `
        __typename
        about
appIds

    ${
      fields.apps
        ? `
      apps {
        ${AppGQLFields(fields.apps)}
      }
    `
        : ``
    }

authorName
authorUrl

    ${
      fields.banner
        ? `
      banner {
        ${MediaGQLFields(fields.banner)}
      }
    `
        : ``
    }

bannerId
categories
comingSoon

    ${
      fields.contentTypes
        ? `
      contentTypes {
        ${ContentTypeTemplateGQLFields(fields.contentTypes)}
      }
    `
        : ``
    }

createdAt

    ${
      fields.createdBy
        ? `
      createdBy {
        ${MemberGQLFields(fields.createdBy)}
      }
    `
        : ``
    }

createdById
description
embedIds

    ${
      fields.embeds
        ? `
      embeds {
        ${EmbedGQLFields(fields.embeds)}
      }
    `
        : ``
    }


    ${
      fields.entity
        ? `
      entity {
        ${TemplateEntityGQLFields(fields.entity)}
      }
    `
        : ``
    }

entityId
entityProperties
entityType

    ${
      fields.favicon
        ? `
      favicon {
        ${MediaGQLFields(fields.favicon)}
      }
    `
        : ``
    }

faviconId

    ${
      fields.hubContent
        ? `
      hubContent {
        ${HubContentGQLFields(fields.hubContent)}
      }
    `
        : ``
    }

hubContentId
id

    ${
      fields.image
        ? `
      image {
        ${MediaGQLFields(fields.image)}
      }
    `
        : ``
    }

imageId
imageIds

    ${
      fields.images
        ? `
      images {
        ${MediaGQLFields(fields.images)}
      }
    `
        : ``
    }

installed
name

    ${
      fields.network
        ? `
      network {
        ${NetworkGQLFields(fields.network)}
      }
    `
        : ``
    }

networkId
previewHtml
privacyPolicyUrl
requiredPlan
requiredTemplateIds

    ${
      fields.requiredTemplates
        ? `
      requiredTemplates {
        ${TemplateGQLFields(fields.requiredTemplates)}
      }
    `
        : ``
    }


    ${
      fields.slate
        ? `
      slate {
        ${SlateGQLFields(fields.slate)}
      }
    `
        : ``
    }

slug
standing
status
syncedAt
termsOfServiceUrl
updatedAt

    ${
      fields.updatedBy
        ? `
      updatedBy {
        ${MemberGQLFields(fields.updatedBy)}
      }
    `
        : ``
    }

updatedById
      `
}

export interface CustomTemplateCreatedEntityFields {
  onSpace?: SpaceFields
}
export type TemplateCreatedEntityFields =
  | 'basic'
  | 'all'
  | CustomTemplateCreatedEntityFields

export const TemplateCreatedEntityGQLFields = (
  fields: TemplateCreatedEntityFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      onSpace: 'basic',
    }
  }
  return `
        __typename

          ${
            fields.onSpace
              ? `
            ... on Space {
              ${SpaceGQLFields(fields.onSpace)}
            }
          `
              : ``
          }

      `
}

export interface CustomTemplateEdgeFields {
  node?: TemplateFields
}
export type TemplateEdgeFields = 'basic' | 'all' | CustomTemplateEdgeFields

export const TemplateEdgeGQLFields = (fields: TemplateEdgeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      node: 'basic',
    }
  }
  return `
        __typename
        cursor

    ${
      fields.node
        ? `
      node {
        ${TemplateGQLFields(fields.node)}
      }
    `
        : ``
    }

      `
}

export interface CustomTemplateEntityFields {
  onNetwork?: NetworkFields
  onPost?: PostFields
  onPostType?: PostTypeFields
  onSpace?: SpaceFields
  onSpacePostType?: SpacePostTypeFields
  onTag?: TagFields
}
export type TemplateEntityFields = 'basic' | 'all' | CustomTemplateEntityFields

export const TemplateEntityGQLFields = (fields: TemplateEntityFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      onNetwork: 'basic',
      onPost: 'basic',
      onPostType: 'basic',
      onSpace: 'basic',
      onSpacePostType: 'basic',
      onTag: 'basic',
    }
  }
  return `
        __typename

          ${
            fields.onNetwork
              ? `
            ... on Network {
              ${NetworkGQLFields(fields.onNetwork)}
            }
          `
              : ``
          }


          ${
            fields.onPost
              ? `
            ... on Post {
              ${PostGQLFields(fields.onPost)}
            }
          `
              : ``
          }


          ${
            fields.onPostType
              ? `
            ... on PostType {
              ${PostTypeGQLFields(fields.onPostType)}
            }
          `
              : ``
          }


          ${
            fields.onSpace
              ? `
            ... on Space {
              ${SpaceGQLFields(fields.onSpace)}
            }
          `
              : ``
          }


          ${
            fields.onSpacePostType
              ? `
            ... on SpacePostType {
              ${SpacePostTypeGQLFields(fields.onSpacePostType)}
            }
          `
              : ``
          }


          ${
            fields.onTag
              ? `
            ... on Tag {
              ${TagGQLFields(fields.onTag)}
            }
          `
              : ``
          }

      `
}

export type TemplateFieldMappingFields = 'basic' | 'all' | Record<string, never>

export const TemplateFieldMappingGQLFields = (
  fields: TemplateFieldMappingFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        from
to
      `
}

export interface CustomTemplateRequestFields {
  createdEntity?: TemplateCreatedEntityFields
}
export type TemplateRequestFields =
  | 'basic'
  | 'all'
  | CustomTemplateRequestFields

export const TemplateRequestGQLFields = (fields: TemplateRequestFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      createdEntity: 'basic',
    }
  }
  return `
        __typename
        createdAt
createdById

    ${
      fields.createdEntity
        ? `
      createdEntity {
        ${TemplateCreatedEntityGQLFields(fields.createdEntity)}
      }
    `
        : ``
    }

createdEntityAt
createdEntityId
createdEntityType
id
networkId
processedPercentage
status
templateId
updatedAt
      `
}

export interface CustomTemplatesMappingFields {
  fields?: TemplateFieldMappingFields
}
export type TemplatesMappingFields =
  | 'basic'
  | 'all'
  | CustomTemplatesMappingFields

export const TemplatesMappingGQLFields = (fields: TemplatesMappingFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      fields: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.fields
        ? `
      fields {
        ${TemplateFieldMappingGQLFields(fields.fields)}
      }
    `
        : ``
    }

from
to
      `
}

export interface CustomThemeFields {
  tokens?: ThemeTokensFields
}
export type ThemeFields = 'basic' | 'all' | CustomThemeFields

export const ThemeGQLFields = (fields: ThemeFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      tokens: 'basic',
    }
  }
  return `
        __typename
        name
status

    ${
      fields.tokens
        ? `
      tokens {
        ${ThemeTokensGQLFields(fields.tokens)}
      }
    `
        : ``
    }

      `
}

export interface CustomThemeColorFields {
  weights?: ThemeTokenFields
}
export type ThemeColorFields = 'basic' | 'all' | CustomThemeColorFields

export const ThemeColorGQLFields = (fields: ThemeColorFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      weights: 'basic',
    }
  }
  return `
        __typename
        key

    ${
      fields.weights
        ? `
      weights {
        ${ThemeTokenGQLFields(fields.weights)}
      }
    `
        : ``
    }

      `
}

export type ThemeColorTokenFields = 'basic' | 'all' | Record<string, never>

export const ThemeColorTokenGQLFields = (fields: ThemeColorTokenFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        key
value
      `
}

export interface CustomThemeColorTokensFields {
  dark?: ThemeColorTokenFields
  light?: ThemeColorTokenFields
}
export type ThemeColorTokensFields =
  | 'basic'
  | 'all'
  | CustomThemeColorTokensFields

export const ThemeColorTokensGQLFields = (fields: ThemeColorTokensFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      dark: 'basic',
      light: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.dark
        ? `
      dark {
        ${ThemeColorTokenGQLFields(fields.dark)}
      }
    `
        : ``
    }


    ${
      fields.light
        ? `
      light {
        ${ThemeColorTokenGQLFields(fields.light)}
      }
    `
        : ``
    }

      `
}

export interface CustomThemeColorsFields {
  dark?: ThemeColorFields
  light?: ThemeColorFields
}
export type ThemeColorsFields = 'basic' | 'all' | CustomThemeColorsFields

export const ThemeColorsGQLFields = (fields: ThemeColorsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      dark: 'basic',
      light: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.dark
        ? `
      dark {
        ${ThemeColorGQLFields(fields.dark)}
      }
    `
        : ``
    }


    ${
      fields.light
        ? `
      light {
        ${ThemeColorGQLFields(fields.light)}
      }
    `
        : ``
    }

      `
}

export type ThemeTokenFields = 'basic' | 'all' | Record<string, never>

export const ThemeTokenGQLFields = (fields: ThemeTokenFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        key
value
      `
}

export interface CustomThemeTokensFields {
  breakpoints?: ThemeTokenFields
  colors?: ThemeTokenFields
  fontSizes?: ThemeTokenFields
  fontWeights?: ThemeTokenFields
  opacity?: ThemeTokenFields
  shadows?: ThemeTokenFields
  sizes?: ThemeTokenFields
  textStyles?: ThemeTokenFields
  zIndices?: ThemeTokenFields
}
export type ThemeTokensFields = 'basic' | 'all' | CustomThemeTokensFields

export const ThemeTokensGQLFields = (fields: ThemeTokensFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      breakpoints: 'basic',
      colors: 'basic',
      fontSizes: 'basic',
      fontWeights: 'basic',
      opacity: 'basic',
      shadows: 'basic',
      sizes: 'basic',
      textStyles: 'basic',
      zIndices: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.breakpoints
        ? `
      breakpoints {
        ${ThemeTokenGQLFields(fields.breakpoints)}
      }
    `
        : ``
    }


    ${
      fields.colors
        ? `
      colors {
        ${ThemeTokenGQLFields(fields.colors)}
      }
    `
        : ``
    }


    ${
      fields.fontSizes
        ? `
      fontSizes {
        ${ThemeTokenGQLFields(fields.fontSizes)}
      }
    `
        : ``
    }


    ${
      fields.fontWeights
        ? `
      fontWeights {
        ${ThemeTokenGQLFields(fields.fontWeights)}
      }
    `
        : ``
    }


    ${
      fields.opacity
        ? `
      opacity {
        ${ThemeTokenGQLFields(fields.opacity)}
      }
    `
        : ``
    }


    ${
      fields.shadows
        ? `
      shadows {
        ${ThemeTokenGQLFields(fields.shadows)}
      }
    `
        : ``
    }


    ${
      fields.sizes
        ? `
      sizes {
        ${ThemeTokenGQLFields(fields.sizes)}
      }
    `
        : ``
    }


    ${
      fields.textStyles
        ? `
      textStyles {
        ${ThemeTokenGQLFields(fields.textStyles)}
      }
    `
        : ``
    }


    ${
      fields.zIndices
        ? `
      zIndices {
        ${ThemeTokenGQLFields(fields.zIndices)}
      }
    `
        : ``
    }

      `
}

export interface CustomThemesFields {
  active?: ThemeFields
  drafts?: ThemeFields
  published?: ThemeFields
}
export type ThemesFields = 'basic' | 'all' | CustomThemesFields

export const ThemesGQLFields = (fields: ThemesFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      active: 'basic',
      drafts: 'basic',
      published: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.active
        ? `
      active {
        ${ThemeGQLFields(fields.active)}
      }
    `
        : ``
    }


    ${
      fields.drafts
        ? `
      drafts {
        ${ThemeGQLFields(fields.drafts)}
      }
    `
        : ``
    }


    ${
      fields.published
        ? `
      published {
        ${ThemeGQLFields(fields.published)}
      }
    `
        : ``
    }

      `
}

export type ToastFields = 'basic' | 'all' | Record<string, never>

export const ToastGQLFields = (fields: ToastFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        description
duration
status
title
      `
}

export interface CustomTopNavigationFields {
  items?: NavigationItemFields
}
export type TopNavigationFields = 'basic' | 'all' | CustomTopNavigationFields

export const TopNavigationGQLFields = (fields: TopNavigationFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      items: 'basic',
    }
  }
  return `
        __typename
        alignment
enabled

    ${
      fields.items
        ? `
      items {
        ${NavigationItemGQLFields(fields.items)}
      }
    `
        : ``
    }

      `
}

export interface CustomUploadedMigrationsFields {
  results?: MigrationFileResultFields
}
export type UploadedMigrationsFields =
  | 'basic'
  | 'all'
  | CustomUploadedMigrationsFields

export const UploadedMigrationsGQLFields = (
  fields: UploadedMigrationsFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      results: 'basic',
    }
  }
  return `
        __typename
        migrationId

    ${
      fields.results
        ? `
      results {
        ${MigrationFileResultGQLFields(fields.results)}
      }
    `
        : ``
    }

      `
}

export type UsernameAvailabilityFields = 'basic' | 'all' | Record<string, never>

export const UsernameAvailabilityGQLFields = (
  fields: UsernameAvailabilityFields,
) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        available
      `
}

export interface CustomValuePermissionsFields {
  isAuthorized?: IsAuthorizedFields
}
export type ValuePermissionsFields =
  | 'basic'
  | 'all'
  | CustomValuePermissionsFields

export const ValuePermissionsGQLFields = (fields: ValuePermissionsFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {
      isAuthorized: 'basic',
    }
  }
  return `
        __typename

    ${
      fields.isAuthorized
        ? `
      isAuthorized {
        ${IsAuthorizedGQLFields(fields.isAuthorized)}
      }
    `
        : ``
    }

value
      `
}

export type VatFields = 'basic' | 'all' | Record<string, never>

export const VatGQLFields = (fields: VatFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        text
vatId
vatType
      `
}

export type VatTypeInfoFields = 'basic' | 'all' | Record<string, never>

export const VatTypeInfoGQLFields = (fields: VatTypeInfoFields) => {
  if (fields === 'basic') {
    fields = {}
  }
  if (fields === 'all') {
    fields = {}
  }
  return `
        __typename
        key
placeholder
text
value
      `
}

export const QueryFunctions = {
  accessGroup: (fields: AccessGroupFields) => gql`
      query accessGroup($id: ID!) {
        accessGroup(id: $id) {
          ${AccessGroupGQLFields(fields)}
        }
      }
    `,
  accessGroups: (fields: AccessGroupFields) => gql`
      query accessGroups {
        accessGroups {
          ${AccessGroupGQLFields(fields)}
        }
      }
    `,
  activityLogs: (fields: ReportRecordFields) => gql`
      query activityLogs($query: String!) {
        activityLogs(query: $query) {
          ${ReportRecordGQLFields(fields)}
        }
      }
    `,
  analytics: (fields: AnalyticsResponseFields) => gql`
      query analytics($queries: [String!]!) {
        analytics(queries: $queries) {
          ${AnalyticsResponseGQLFields(fields)}
        }
      }
    `,
  apiRateLimitStats: (fields: ApiRateLimitStatFields) => gql`
      query apiRateLimitStats {
        apiRateLimitStats {
          ${ApiRateLimitStatGQLFields(fields)}
        }
      }
    `,
  apiUsages: (fields: DailyApiUsageFields) => gql`
      query apiUsages {
        apiUsages {
          ${DailyApiUsageGQLFields(fields)}
        }
      }
    `,
  app: (fields: AppFields) => gql`
      query app($clientId: String
$id: ID
$redirectUri: String
$slug: String) {
        app(clientId: $clientId
id: $id
redirectUri: $redirectUri
slug: $slug) {
          ${AppGQLFields(fields)}
        }
      }
    `,
  apps: (fields: PaginatedAppFields) => gql`
      query apps($after: String
$before: String
$limit: Int!
$offset: Int
$reverse: Boolean
$standing: StoreItemStanding
$status: StoreItemStatus) {
        apps(after: $after
before: $before
limit: $limit
offset: $offset
reverse: $reverse
standing: $standing
status: $status) {
          ${PaginatedAppGQLFields(fields)}
        }
      }
    `,
  authMember: (fields: MemberFields) => gql`
      query authMember {
        authMember {
          ${MemberGQLFields(fields)}
        }
      }
    `,
  authMemberAppInstallation: (fields: AppInstallationFields) => gql`
      query authMemberAppInstallation($appId: String!) {
        authMemberAppInstallation(appId: $appId) {
          ${AppInstallationGQLFields(fields)}
        }
      }
    `,
  authMemberAppInstallations: (fields: PaginatedAppInstallationFields) => gql`
      query authMemberAppInstallations($after: String
$before: String
$limit: Int!
$offset: Int
$reverse: Boolean
$status: AppInstallationStatus) {
        authMemberAppInstallations(after: $after
before: $before
limit: $limit
offset: $offset
reverse: $reverse
status: $status) {
          ${PaginatedAppInstallationGQLFields(fields)}
        }
      }
    `,
  availableDynamicBlocks: (fields: DynamicBlockFields) => gql`
      query availableDynamicBlocks($context: PermissionContext
$size: DynamicBlockSize) {
        availableDynamicBlocks(context: $context
size: $size) {
          ${DynamicBlockGQLFields(fields)}
        }
      }
    `,
  baskets: (fields: BasketFields) => gql`
      query baskets($input: BasketsInput!) {
        baskets(input: $input) {
          ${BasketGQLFields(fields)}
        }
      }
    `,
  billingDetails: (fields: BillingDetailsFields) => gql`
      query billingDetails {
        billingDetails {
          ${BillingDetailsGQLFields(fields)}
        }
      }
    `,
  billingProducts: (fields: BillingProductsFields) => gql`
      query billingProducts {
        billingProducts {
          ${BillingProductsGQLFields(fields)}
        }
      }
    `,
  cancelSubscription: (fields: ActionFields) => gql`
      query cancelSubscription {
        cancelSubscription {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  checkDomainAvailability: (fields: DomainAvailabilityFields) => gql`
      query checkDomainAvailability($input: DomainAvailabilityInput!) {
        checkDomainAvailability(input: $input) {
          ${DomainAvailabilityGQLFields(fields)}
        }
      }
    `,
  checkEmailAvailability: (fields: EmailAvailabilityFields) => gql`
      query checkEmailAvailability($email: String!) {
        checkEmailAvailability(email: $email) {
          ${EmailAvailabilityGQLFields(fields)}
        }
      }
    `,
  checkInvitationLinkValidity: (fields: MemberInvitationLinkFields) => gql`
      query checkInvitationLinkValidity($invitationLinkId: String!) {
        checkInvitationLinkValidity(invitationLinkId: $invitationLinkId) {
          ${MemberInvitationLinkGQLFields(fields)}
        }
      }
    `,
  checkMemberInvitationValidity: (fields: MemberInvitationFields) => gql`
      query checkMemberInvitationValidity($token: String!) {
        checkMemberInvitationValidity(token: $token) {
          ${MemberInvitationGQLFields(fields)}
        }
      }
    `,
  checkNewDomainStatus: (fields: DomainTransferStatusFields) => gql`
      query checkNewDomainStatus($domain: String!) {
        checkNewDomainStatus(domain: $domain) {
          ${DomainTransferStatusGQLFields(fields)}
        }
      }
    `,
  checkPasswordComplexity: (fields: PasswordComplexityFields) => gql`
      query checkPasswordComplexity($password: String!) {
        checkPasswordComplexity(password: $password) {
          ${PasswordComplexityGQLFields(fields)}
        }
      }
    `,
  checkUsernameAvailability: (fields: UsernameAvailabilityFields) => gql`
      query checkUsernameAvailability($username: String!) {
        checkUsernameAvailability(username: $username) {
          ${UsernameAvailabilityGQLFields(fields)}
        }
      }
    `,
  collection: (fields: CollectionFields) => gql`
      query collection($id: ID) {
        collection(id: $id) {
          ${CollectionGQLFields(fields)}
        }
      }
    `,
  collections: (fields: CollectionFields) => gql`
      query collections($orderBy: CollectionListOrderByEnum
$reverse: Boolean) {
        collections(orderBy: $orderBy
reverse: $reverse) {
          ${CollectionGQLFields(fields)}
        }
      }
    `,
  domainAvailability: (fields: DomainAvailabilityFields) => gql`
      query domainAvailability($input: DomainAvailabilityInput!) {
        domainAvailability(input: $input) {
          ${DomainAvailabilityGQLFields(fields)}
        }
      }
    `,
  dynamicBlock: (fields: DynamicBlockFields) => gql`
      query dynamicBlock($blockId: ID!) {
        dynamicBlock(blockId: $blockId) {
          ${DynamicBlockGQLFields(fields)}
        }
      }
    `,
  dynamicBlockByKey: (fields: DynamicBlockFields) => gql`
      query dynamicBlockByKey($appId: ID!
$key: String!) {
        dynamicBlockByKey(appId: $appId
key: $key) {
          ${DynamicBlockGQLFields(fields)}
        }
      }
    `,
  dynamicBlocks: (fields: PaginatedDynamicBlockFields) => gql`
      query dynamicBlocks($after: String
$appIds: [ID!]
$before: String
$contexts: [PermissionContext!]
$limit: Int!
$offset: Int
$reverse: Boolean) {
        dynamicBlocks(after: $after
appIds: $appIds
before: $before
contexts: $contexts
limit: $limit
offset: $offset
reverse: $reverse) {
          ${PaginatedDynamicBlockGQLFields(fields)}
        }
      }
    `,
  emailAvailability: (fields: EmailAvailabilityFields) => gql`
      query emailAvailability($email: String!) {
        emailAvailability(email: $email) {
          ${EmailAvailabilityGQLFields(fields)}
        }
      }
    `,
  emailLogs: (fields: PaginatedEmailLogFields) => gql`
      query emailLogs($after: String
$limit: Int!
$recipient: String
$reverse: Boolean
$statuses: [EmailStatus!]
$types: [EmailType!]) {
        emailLogs(after: $after
limit: $limit
recipient: $recipient
reverse: $reverse
statuses: $statuses
types: $types) {
          ${PaginatedEmailLogGQLFields(fields)}
        }
      }
    `,
  embed: (fields: EmbedFields) => gql`
      query embed($input: EmbedInput!) {
        embed(input: $input) {
          ${EmbedGQLFields(fields)}
        }
      }
    `,
  exploreSpaces: (fields: PaginatedSpaceFields) => gql`
      query exploreSpaces($after: String
$before: String
$collectionId: String
$limit: Int!
$offset: Int
$reverse: Boolean) {
        exploreSpaces(after: $after
before: $before
collectionId: $collectionId
limit: $limit
offset: $offset
reverse: $reverse) {
          ${PaginatedSpaceGQLFields(fields)}
        }
      }
    `,
  exportMemberRequest: (fields: ExportFields) => gql`
      query exportMemberRequest($id: ID!) {
        exportMemberRequest(id: $id) {
          ${ExportGQLFields(fields)}
        }
      }
    `,
  exportMemberRequests: (fields: PaginatedExportFields) => gql`
      query exportMemberRequests($after: String
$before: String
$limit: Int!
$offset: Int
$orderBy: ExportListOrderByEnum
$query: String
$reverse: Boolean
$statuses: [ExportStatus!]) {
        exportMemberRequests(after: $after
before: $before
limit: $limit
offset: $offset
orderBy: $orderBy
query: $query
reverse: $reverse
statuses: $statuses) {
          ${PaginatedExportGQLFields(fields)}
        }
      }
    `,
  extraProperties: (fields: ExtraPropertyFields) => gql`
      query extraProperties($entityId: String
$entityType: ExtraPropertyEntityType!
$keys: [String!]) {
        extraProperties(entityId: $entityId
entityType: $entityType
keys: $keys) {
          ${ExtraPropertyGQLFields(fields)}
        }
      }
    `,
  feed: (fields: PaginatedPostFields) => gql`
      query feed($after: String
$before: String
$filterBy: [PostListFilterByInput!]
$limit: Int!
$offset: Int
$onlyMemberSpaces: Boolean
$orderBy: PostListOrderByEnum
$orderByString: String
$postTypeIds: [String!]
$reverse: Boolean) {
        feed(after: $after
before: $before
filterBy: $filterBy
limit: $limit
offset: $offset
onlyMemberSpaces: $onlyMemberSpaces
orderBy: $orderBy
orderByString: $orderByString
postTypeIds: $postTypeIds
reverse: $reverse) {
          ${PaginatedPostGQLFields(fields)}
        }
      }
    `,
  getAppById: (fields: AppFields) => gql`
      query getAppById($id: ID!) {
        getAppById(id: $id) {
          ${AppGQLFields(fields)}
        }
      }
    `,
  getAppBySlug: (fields: AppFields) => gql`
      query getAppBySlug($slug: String!) {
        getAppBySlug(slug: $slug) {
          ${AppGQLFields(fields)}
        }
      }
    `,
  getAppNetworkSettings: () => gql`
    query getAppNetworkSettings($appId: ID!) {
      getAppNetworkSettings(appId: $appId)
    }
  `,
  getAppSpaceSettings: () => gql`
    query getAppSpaceSettings($appId: ID!, $spaceId: ID!) {
      getAppSpaceSettings(appId: $appId, spaceId: $spaceId)
    }
  `,
  getApps: (fields: PaginatedAppFields) => gql`
      query getApps($after: String
$before: String
$limit: Int!
$offset: Int
$reverse: Boolean
$status: StoreItemStatus) {
        getApps(after: $after
before: $before
limit: $limit
offset: $offset
reverse: $reverse
status: $status) {
          ${PaginatedAppGQLFields(fields)}
        }
      }
    `,
  getAuthMember: (fields: MemberFields) => gql`
      query getAuthMember {
        getAuthMember {
          ${MemberGQLFields(fields)}
        }
      }
    `,
  getEmbed: (fields: EmbedFields) => gql`
      query getEmbed($input: EmbedInput!) {
        getEmbed(input: $input) {
          ${EmbedGQLFields(fields)}
        }
      }
    `,
  getFeed: (fields: PaginatedPostFields) => gql`
      query getFeed($after: String
$before: String
$filterBy: [PostListFilterByInput!]
$limit: Int!
$offset: Int
$onlyMemberSpaces: Boolean
$orderBy: PostListOrderByEnum
$postTypeIds: [String!]
$reverse: Boolean) {
        getFeed(after: $after
before: $before
filterBy: $filterBy
limit: $limit
offset: $offset
onlyMemberSpaces: $onlyMemberSpaces
orderBy: $orderBy
postTypeIds: $postTypeIds
reverse: $reverse) {
          ${PaginatedPostGQLFields(fields)}
        }
      }
    `,
  getGroup: (fields: CollectionFields) => gql`
      query getGroup($groupId: ID) {
        getGroup(groupId: $groupId) {
          ${CollectionGQLFields(fields)}
        }
      }
    `,
  getGroups: (fields: CollectionFields) => gql`
      query getGroups {
        getGroups {
          ${CollectionGQLFields(fields)}
        }
      }
    `,
  getMedia: (fields: MediaFields) => gql`
      query getMedia($mediaId: ID!) {
        getMedia(mediaId: $mediaId) {
          ${MediaGQLFields(fields)}
        }
      }
    `,
  getMember: (fields: MemberFields) => gql`
      query getMember($memberId: ID
$username: String) {
        getMember(memberId: $memberId
username: $username) {
          ${MemberGQLFields(fields)}
        }
      }
    `,
  getMemberAppInstallations: (fields: PaginatedAppInstallationFields) => gql`
      query getMemberAppInstallations($after: String
$before: String
$limit: Int!
$offset: Int
$reverse: Boolean
$status: AppInstallationStatus) {
        getMemberAppInstallations(after: $after
before: $before
limit: $limit
offset: $offset
reverse: $reverse
status: $status) {
          ${PaginatedAppInstallationGQLFields(fields)}
        }
      }
    `,
  getMemberInvitation: (fields: MemberInvitationFields) => gql`
      query getMemberInvitation($id: ID!) {
        getMemberInvitation(id: $id) {
          ${MemberInvitationGQLFields(fields)}
        }
      }
    `,
  getMemberInvitationLink: (fields: MemberInvitationLinkFields) => gql`
      query getMemberInvitationLink {
        getMemberInvitationLink {
          ${MemberInvitationLinkGQLFields(fields)}
        }
      }
    `,
  getMemberInvitations: (fields: PaginatedMemberInvitationFields) => gql`
      query getMemberInvitations($after: String
$before: String
$limit: Int!
$offset: Int
$query: String
$reverse: Boolean
$roleIds: [String!]
$status: MemberInvitationStatus) {
        getMemberInvitations(after: $after
before: $before
limit: $limit
offset: $offset
query: $query
reverse: $reverse
roleIds: $roleIds
status: $status) {
          ${PaginatedMemberInvitationGQLFields(fields)}
        }
      }
    `,
  getMemberNetworkNotificationSettings: (
    fields: MemberNetworkNotificationSettingsFields,
  ) => gql`
      query getMemberNetworkNotificationSettings($memberId: ID) {
        getMemberNetworkNotificationSettings(memberId: $memberId) {
          ${MemberNetworkNotificationSettingsGQLFields(fields)}
        }
      }
    `,
  getMemberNotificationSettings: (
    fields: MemberNotificationSettingsFields,
  ) => gql`
      query getMemberNotificationSettings($memberId: ID) {
        getMemberNotificationSettings(memberId: $memberId) {
          ${MemberNotificationSettingsGQLFields(fields)}
        }
      }
    `,
  getMemberPosts: (fields: PaginatedPostFields) => gql`
      query getMemberPosts($after: String
$before: String
$limit: Int!
$memberId: ID!
$offset: Int
$reverse: Boolean) {
        getMemberPosts(after: $after
before: $before
limit: $limit
memberId: $memberId
offset: $offset
reverse: $reverse) {
          ${PaginatedPostGQLFields(fields)}
        }
      }
    `,
  getMemberSpaceNotificationSettings: (
    fields: MemberSpaceNotificationSettingsFields,
  ) => gql`
      query getMemberSpaceNotificationSettings($memberId: ID
$spaceId: ID!) {
        getMemberSpaceNotificationSettings(memberId: $memberId
spaceId: $spaceId) {
          ${MemberSpaceNotificationSettingsGQLFields(fields)}
        }
      }
    `,
  getMemberSpaces: (fields: PaginatedSpaceMemberFields) => gql`
      query getMemberSpaces($after: String
$before: String
$groupId: String
$limit: Int!
$memberId: ID!
$offset: Int
$reverse: Boolean
$roleIds: [ID!]) {
        getMemberSpaces(after: $after
before: $before
groupId: $groupId
limit: $limit
memberId: $memberId
offset: $offset
reverse: $reverse
roleIds: $roleIds) {
          ${PaginatedSpaceMemberGQLFields(fields)}
        }
      }
    `,
  getMembers: (fields: PaginatedMemberFields) => gql`
      query getMembers($after: String
$before: String
$limit: Int!
$offset: Int
$query: String
$reverse: Boolean
$roleIds: [ID!]
$status: [MemberStatusInput!]) {
        getMembers(after: $after
before: $before
limit: $limit
offset: $offset
query: $query
reverse: $reverse
roleIds: $roleIds
status: $status) {
          ${PaginatedMemberGQLFields(fields)}
        }
      }
    `,
  getModerationById: (fields: ModerationItemFields) => gql`
      query getModerationById($moderationId: ID!) {
        getModerationById(moderationId: $moderationId) {
          ${ModerationItemGQLFields(fields)}
        }
      }
    `,
  getModerationReporters: (
    fields: PaginatedModerationItemReporterFields,
  ) => gql`
      query getModerationReporters($after: String
$before: String
$limit: Int!
$moderationId: String!
$offset: Int
$reverse: Boolean) {
        getModerationReporters(after: $after
before: $before
limit: $limit
moderationId: $moderationId
offset: $offset
reverse: $reverse) {
          ${PaginatedModerationItemReporterGQLFields(fields)}
        }
      }
    `,
  getModerationSetting: (fields: ModerationSettingsFields) => gql`
      query getModerationSetting {
        getModerationSetting {
          ${ModerationSettingsGQLFields(fields)}
        }
      }
    `,
  getModerations: (fields: PaginatedModerationFields) => gql`
      query getModerations($after: String
$before: String
$entityId: String
$entityType: ModerationEntityType
$flaggedBy: FlaggedType
$limit: Int!
$offset: Int
$reverse: Boolean
$spaceId: String
$status: ModerationStatus) {
        getModerations(after: $after
before: $before
entityId: $entityId
entityType: $entityType
flaggedBy: $flaggedBy
limit: $limit
offset: $offset
reverse: $reverse
spaceId: $spaceId
status: $status) {
          ${PaginatedModerationGQLFields(fields)}
        }
      }
    `,
  getNetwork: (fields: NetworkFields) => gql`
      query getNetwork {
        getNetwork {
          ${NetworkGQLFields(fields)}
        }
      }
    `,
  getNetworkAppInstallations: (fields: PaginatedAppInstallationFields) => gql`
      query getNetworkAppInstallations($after: String
$before: String
$limit: Int!
$offset: Int
$reverse: Boolean
$status: AppInstallationStatus) {
        getNetworkAppInstallations(after: $after
before: $before
limit: $limit
offset: $offset
reverse: $reverse
status: $status) {
          ${PaginatedAppInstallationGQLFields(fields)}
        }
      }
    `,
  getNetworkApps: (fields: AppFields) => gql`
      query getNetworkApps($status: AppInstallationStatus) {
        getNetworkApps(status: $status) {
          ${AppGQLFields(fields)}
        }
      }
    `,
  getNetworkPublicInfo: (fields: NetworkPublicInfoFields) => gql`
      query getNetworkPublicInfo {
        getNetworkPublicInfo {
          ${NetworkPublicInfoGQLFields(fields)}
        }
      }
    `,
  getNetworks: (fields: PaginatedNetworkFields) => gql`
      query getNetworks($after: String
$before: String
$limit: Int!
$offset: Int
$query: String
$reverse: Boolean) {
        getNetworks(after: $after
before: $before
limit: $limit
offset: $offset
query: $query
reverse: $reverse) {
          ${PaginatedNetworkGQLFields(fields)}
        }
      }
    `,
  getNotifications: (fields: PaginatedNotificationFields) => gql`
      query getNotifications($after: String
$limit: Int!) {
        getNotifications(after: $after
limit: $limit) {
          ${PaginatedNotificationGQLFields(fields)}
        }
      }
    `,
  getNotificationsCount: (fields: NotificationsCountFields) => gql`
      query getNotificationsCount {
        getNotificationsCount {
          ${NotificationsCountGQLFields(fields)}
        }
      }
    `,
  getPost: (fields: PostFields) => gql`
      query getPost($postId: ID!) {
        getPost(postId: $postId) {
          ${PostGQLFields(fields)}
        }
      }
    `,
  getPostAppInstallations: (fields: PaginatedAppInstallationFields) => gql`
      query getPostAppInstallations($after: String
$before: String
$limit: Int!
$offset: Int
$postId: ID!
$reverse: Boolean
$status: AppInstallationStatus) {
        getPostAppInstallations(after: $after
before: $before
limit: $limit
offset: $offset
postId: $postId
reverse: $reverse
status: $status) {
          ${PaginatedAppInstallationGQLFields(fields)}
        }
      }
    `,
  getPostReactionParticipants: (
    fields: PaginatedPostReactionParticipantFields,
  ) => gql`
      query getPostReactionParticipants($after: String
$before: String
$limit: Int!
$offset: Int
$postId: ID!
$reaction: ID!
$reverse: Boolean) {
        getPostReactionParticipants(after: $after
before: $before
limit: $limit
offset: $offset
postId: $postId
reaction: $reaction
reverse: $reverse) {
          ${PaginatedPostReactionParticipantGQLFields(fields)}
        }
      }
    `,
  getPosts: (fields: PaginatedPostFields) => gql`
      query getPosts($after: String
$before: String
$excludePins: Boolean
$filterBy: [PostListFilterByInput!]
$limit: Int!
$offset: Int
$orderBy: PostListOrderByEnum
$postTypeIds: [String!]
$reverse: Boolean
$spaceIds: [ID!]) {
        getPosts(after: $after
before: $before
excludePins: $excludePins
filterBy: $filterBy
limit: $limit
offset: $offset
orderBy: $orderBy
postTypeIds: $postTypeIds
reverse: $reverse
spaceIds: $spaceIds) {
          ${PaginatedPostGQLFields(fields)}
        }
      }
    `,
  getReplies: (fields: PaginatedPostFields) => gql`
      query getReplies($after: String
$before: String
$excludePins: Boolean
$limit: Int!
$offset: Int
$orderBy: PostListOrderByEnum
$postId: ID!
$reverse: Boolean) {
        getReplies(after: $after
before: $before
excludePins: $excludePins
limit: $limit
offset: $offset
orderBy: $orderBy
postId: $postId
reverse: $reverse) {
          ${PaginatedPostGQLFields(fields)}
        }
      }
    `,
  getReport: (fields: ReportFields) => gql`
      query getReport($input: ReportInput!) {
        getReport(input: $input) {
          ${ReportGQLFields(fields)}
        }
      }
    `,
  getRoles: (fields: RoleFields) => gql`
      query getRoles {
        getRoles {
          ${RoleGQLFields(fields)}
        }
      }
    `,
  getScopes: (fields: ScopesFields) => gql`
      query getScopes($contexts: [PermissionsContextInput!]) {
        getScopes(contexts: $contexts) {
          ${ScopesGQLFields(fields)}
        }
      }
    `,
  getSpace: (fields: SpaceFields) => gql`
      query getSpace($spaceId: ID
$spaceSlug: ID) {
        getSpace(spaceId: $spaceId
spaceSlug: $spaceSlug) {
          ${SpaceGQLFields(fields)}
        }
      }
    `,
  getSpaceAppInstallations: (fields: PaginatedAppInstallationFields) => gql`
      query getSpaceAppInstallations($after: String
$before: String
$limit: Int!
$offset: Int
$reverse: Boolean
$spaceId: ID!
$status: AppInstallationStatus) {
        getSpaceAppInstallations(after: $after
before: $before
limit: $limit
offset: $offset
reverse: $reverse
spaceId: $spaceId
status: $status) {
          ${PaginatedAppInstallationGQLFields(fields)}
        }
      }
    `,
  getSpaceMembers: (fields: PaginatedSpaceMemberFields) => gql`
      query getSpaceMembers($after: String
$before: String
$limit: Int!
$offset: Int
$reverse: Boolean
$roleIds: [ID!]
$spaceId: ID!) {
        getSpaceMembers(after: $after
before: $before
limit: $limit
offset: $offset
reverse: $reverse
roleIds: $roleIds
spaceId: $spaceId) {
          ${PaginatedSpaceMemberGQLFields(fields)}
        }
      }
    `,
  getSpaceMembershipRequestForMember: (fields: SpaceJoinRequestFields) => gql`
      query getSpaceMembershipRequestForMember($status: SpaceJoinRequestStatus) {
        getSpaceMembershipRequestForMember(status: $status) {
          ${SpaceJoinRequestGQLFields(fields)}
        }
      }
    `,
  getSpaceMembershipRequests: (fields: SpaceJoinRequestFields) => gql`
      query getSpaceMembershipRequests($spaceId: ID!
$status: SpaceJoinRequestStatus) {
        getSpaceMembershipRequests(spaceId: $spaceId
status: $status) {
          ${SpaceJoinRequestGQLFields(fields)}
        }
      }
    `,
  getSpacePinnedPosts: (fields: PostFields) => gql`
      query getSpacePinnedPosts($spaceId: ID!) {
        getSpacePinnedPosts(spaceId: $spaceId) {
          ${PostGQLFields(fields)}
        }
      }
    `,
  getSpaceReport: (fields: ReportFields) => gql`
      query getSpaceReport($input: ReportInput!
$spaceId: String!) {
        getSpaceReport(input: $input
spaceId: $spaceId) {
          ${ReportGQLFields(fields)}
        }
      }
    `,
  getSpaceRoles: (fields: SpaceRoleFields) => gql`
      query getSpaceRoles($spaceId: ID) {
        getSpaceRoles(spaceId: $spaceId) {
          ${SpaceRoleGQLFields(fields)}
        }
      }
    `,
  getSsoMemberships: (fields: SsoMembershipFields) => gql`
      query getSsoMemberships($memberId: String!) {
        getSsoMemberships(memberId: $memberId) {
          ${SsoMembershipGQLFields(fields)}
        }
      }
    `,
  getSupportNetworkTokens: (fields: SupportLimitedAuthTokenFields) => gql`
      query getSupportNetworkTokens($input: CreateLimitedSupportTokenInput!) {
        getSupportNetworkTokens(input: $input) {
          ${SupportLimitedAuthTokenGQLFields(fields)}
        }
      }
    `,
  getTokens: (fields: AuthTokenFields) => gql`
      query getTokens($networkDomain: String
$networkId: ID
$otp: String
$refreshToken: String) {
        getTokens(networkDomain: $networkDomain
networkId: $networkId
otp: $otp
refreshToken: $refreshToken) {
          ${AuthTokenGQLFields(fields)}
        }
      }
    `,
  globalApp: (fields: AppFields) => gql`
      query globalApp($id: ID
$slug: String) {
        globalApp(id: $id
slug: $slug) {
          ${AppGQLFields(fields)}
        }
      }
    `,
  globalAppCollaborators: (fields: AppCollaboratorFields) => gql`
      query globalAppCollaborators($appId: String!) {
        globalAppCollaborators(appId: $appId) {
          ${AppCollaboratorGQLFields(fields)}
        }
      }
    `,
  globalAppPublications: (fields: AppPublicationFields) => gql`
      query globalAppPublications($appId: ID!) {
        globalAppPublications(appId: $appId) {
          ${AppPublicationGQLFields(fields)}
        }
      }
    `,
  globalAppSettings: (fields: AppSettingFields) => gql`
      query globalAppSettings($context: PermissionContext!
$entityId: ID
$networkId: ID!) {
        globalAppSettings(context: $context
entityId: $entityId
networkId: $networkId) {
          ${AppSettingGQLFields(fields)}
        }
      }
    `,
  globalApps: (fields: PaginatedAppFields) => gql`
      query globalApps($after: String
$before: String
$limit: Int!
$offset: Int
$reverse: Boolean
$standing: StoreItemStanding
$status: StoreItemStatus) {
        globalApps(after: $after
before: $before
limit: $limit
offset: $offset
reverse: $reverse
standing: $standing
status: $status) {
          ${PaginatedAppGQLFields(fields)}
        }
      }
    `,
  globalEmbed: (fields: EmbedFields) => gql`
      query globalEmbed($input: EmbedInput!) {
        globalEmbed(input: $input) {
          ${EmbedGQLFields(fields)}
        }
      }
    `,
  globalEventTypes: (fields: EventTypeFields) => gql`
      query globalEventTypes {
        globalEventTypes {
          ${EventTypeGQLFields(fields)}
        }
      }
    `,
  globalMedia: (fields: MediaFields) => gql`
      query globalMedia($id: ID!) {
        globalMedia(id: $id) {
          ${MediaGQLFields(fields)}
        }
      }
    `,
  globalNetworks: (fields: PaginatedNetworkFields) => gql`
      query globalNetworks($after: String
$before: String
$limit: Int!
$offset: Int
$query: String
$reverse: Boolean
$roleType: RoleType) {
        globalNetworks(after: $after
before: $before
limit: $limit
offset: $offset
query: $query
reverse: $reverse
roleType: $roleType) {
          ${PaginatedNetworkGQLFields(fields)}
        }
      }
    `,
  globalToken: (fields: GlobalTokenFields) => gql`
      query globalToken($input: GlobalTokenInput!) {
        globalToken(input: $input) {
          ${GlobalTokenGQLFields(fields)}
        }
      }
    `,
  homepage: (fields: PageFields) => gql`
      query homepage($type: HomeSpaceType) {
        homepage(type: $type) {
          ${PageGQLFields(fields)}
        }
      }
    `,
  impersonateLoginToNetwork: (fields: AuthInfoWithOtpFields) => gql`
      query impersonateLoginToNetwork($input: ImpersonateLoginToNetworkInput!) {
        impersonateLoginToNetwork(input: $input) {
          ${AuthInfoWithOtpGQLFields(fields)}
        }
      }
    `,
  invitationLinkValidity: (fields: MemberInvitationLinkFields) => gql`
      query invitationLinkValidity($id: String!) {
        invitationLinkValidity(id: $id) {
          ${MemberInvitationLinkGQLFields(fields)}
        }
      }
    `,
  invoices: (fields: InvoiceFields) => gql`
      query invoices {
        invoices {
          ${InvoiceGQLFields(fields)}
        }
      }
    `,
  leaderboard: (fields: MemberFields) => gql`
      query leaderboard($input: ReportInput!) {
        leaderboard(input: $input) {
          ${MemberGQLFields(fields)}
        }
      }
    `,
  leaderboardWithScores: (fields: LeaderboardItemFields) => gql`
      query leaderboardWithScores($includeAuthMember: Boolean!
$input: ReportInput!) {
        leaderboardWithScores(includeAuthMember: $includeAuthMember
input: $input) {
          ${LeaderboardItemGQLFields(fields)}
        }
      }
    `,
  limitedToken: (fields: AppTokenFields) => gql`
      query limitedToken($context: PermissionContext
$entityId: String
$impersonateMemberId: String
$networkId: String!) {
        limitedToken(context: $context
entityId: $entityId
impersonateMemberId: $impersonateMemberId
networkId: $networkId) {
          ${AppTokenGQLFields(fields)}
        }
      }
    `,
  loginNetwork: (fields: AuthTokenFields) => gql`
      query loginNetwork($input: LoginNetworkWithPasswordInput!) {
        loginNetwork(input: $input) {
          ${AuthTokenGQLFields(fields)}
        }
      }
    `,
  loginNetworkWithPassword: (fields: AuthTokenFields) => gql`
      query loginNetworkWithPassword($input: LoginNetworkWithPasswordInput!) {
        loginNetworkWithPassword(input: $input) {
          ${AuthTokenGQLFields(fields)}
        }
      }
    `,
  loginSupportWithSso: (fields: SupportSsoUrlFields) => gql`
      query loginSupportWithSso($input: SupportSsoUrlInput) {
        loginSupportWithSso(input: $input) {
          ${SupportSsoUrlGQLFields(fields)}
        }
      }
    `,
  loginSupportWithSsoCode: (fields: SupportAuthTokenFields) => gql`
      query loginSupportWithSsoCode($input: LoginSupportWithSsoCodeInput!) {
        loginSupportWithSsoCode(input: $input) {
          ${SupportAuthTokenGQLFields(fields)}
        }
      }
    `,
  loginWithSso: (fields: SsoUrlFields) => gql`
      query loginWithSso($input: SsoUrlInput!) {
        loginWithSso(input: $input) {
          ${SsoUrlGQLFields(fields)}
        }
      }
    `,
  loginWithSsoCode: (fields: AuthTokenFields) => gql`
      query loginWithSsoCode($input: LoginWithSsoCodeInput!) {
        loginWithSsoCode(input: $input) {
          ${AuthTokenGQLFields(fields)}
        }
      }
    `,
  massActionRequest: (fields: MassActionRequestFields) => gql`
      query massActionRequest($id: String!) {
        massActionRequest(id: $id) {
          ${MassActionRequestGQLFields(fields)}
        }
      }
    `,
  massActionRequestInProgress: (fields: MassActionRequestFields) => gql`
      query massActionRequestInProgress {
        massActionRequestInProgress {
          ${MassActionRequestGQLFields(fields)}
        }
      }
    `,
  media: (fields: MediaFields) => gql`
      query media($id: ID!) {
        media(id: $id) {
          ${MediaGQLFields(fields)}
        }
      }
    `,
  member: (fields: MemberFields) => gql`
      query member($externalId: String
$id: ID
$username: String) {
        member(externalId: $externalId
id: $id
username: $username) {
          ${MemberGQLFields(fields)}
        }
      }
    `,
  memberInvitation: (fields: MemberInvitationFields) => gql`
      query memberInvitation($id: ID!) {
        memberInvitation(id: $id) {
          ${MemberInvitationGQLFields(fields)}
        }
      }
    `,
  memberInvitationLink: (fields: MemberInvitationLinkFields) => gql`
      query memberInvitationLink {
        memberInvitationLink {
          ${MemberInvitationLinkGQLFields(fields)}
        }
      }
    `,
  memberInvitationValidity: (fields: MemberInvitationFields) => gql`
      query memberInvitationValidity($token: String!) {
        memberInvitationValidity(token: $token) {
          ${MemberInvitationGQLFields(fields)}
        }
      }
    `,
  memberInvitations: (fields: PaginatedMemberInvitationFields) => gql`
      query memberInvitations($after: String
$before: String
$filterBy: [MemberInvitationFilterByInput!]
$limit: Int!
$offset: Int
$query: String
$reverse: Boolean
$roleIds: [String!]
$status: MemberInvitationStatus) {
        memberInvitations(after: $after
before: $before
filterBy: $filterBy
limit: $limit
offset: $offset
query: $query
reverse: $reverse
roleIds: $roleIds
status: $status) {
          ${PaginatedMemberInvitationGQLFields(fields)}
        }
      }
    `,
  memberNotificationSettings: (fields: MemberNotificationSettingsFields) => gql`
      query memberNotificationSettings($id: ID) {
        memberNotificationSettings(id: $id) {
          ${MemberNotificationSettingsGQLFields(fields)}
        }
      }
    `,
  memberPostNotificationSettings: (
    fields: MemberPostNotificationSettingsFields,
  ) => gql`
      query memberPostNotificationSettings($memberId: ID
$postId: ID!) {
        memberPostNotificationSettings(memberId: $memberId
postId: $postId) {
          ${MemberPostNotificationSettingsGQLFields(fields)}
        }
      }
    `,
  memberPosts: (fields: PaginatedPostFields) => gql`
      query memberPosts($after: String
$before: String
$hasParent: Boolean
$limit: Int!
$memberId: ID!
$offset: Int
$reverse: Boolean) {
        memberPosts(after: $after
before: $before
hasParent: $hasParent
limit: $limit
memberId: $memberId
offset: $offset
reverse: $reverse) {
          ${PaginatedPostGQLFields(fields)}
        }
      }
    `,
  memberScore: (fields: ReportFields) => gql`
      query memberScore($input: ReportInput!
$spaceId: String) {
        memberScore(input: $input
spaceId: $spaceId) {
          ${ReportGQLFields(fields)}
        }
      }
    `,
  memberSpaceMembershipRequest: (fields: SpaceJoinRequestFields) => gql`
      query memberSpaceMembershipRequest($status: SpaceJoinRequestStatus) {
        memberSpaceMembershipRequest(status: $status) {
          ${SpaceJoinRequestGQLFields(fields)}
        }
      }
    `,
  memberSpaces: (fields: PaginatedSpaceMemberFields) => gql`
      query memberSpaces($after: String
$before: String
$collectionId: String
$limit: Int!
$memberId: ID!
$offset: Int
$reverse: Boolean
$roleIds: [ID!]) {
        memberSpaces(after: $after
before: $before
collectionId: $collectionId
limit: $limit
memberId: $memberId
offset: $offset
reverse: $reverse
roleIds: $roleIds) {
          ${PaginatedSpaceMemberGQLFields(fields)}
        }
      }
    `,
  members: (fields: PaginatedMemberFields) => gql`
      query members($after: String
$before: String
$filterBy: [MemberListFilterByInput!]
$limit: Int!
$offset: Int
$orderBy: String
$query: String
$reverse: Boolean
$roleIds: [ID!]
$status: [MemberStatusInput!]) {
        members(after: $after
before: $before
filterBy: $filterBy
limit: $limit
offset: $offset
orderBy: $orderBy
query: $query
reverse: $reverse
roleIds: $roleIds
status: $status) {
          ${PaginatedMemberGQLFields(fields)}
        }
      }
    `,
  membersByIds: (fields: MemberFields) => gql`
      query membersByIds($ids: [String!]!) {
        membersByIds(ids: $ids) {
          ${MemberGQLFields(fields)}
        }
      }
    `,
  migrationSampleFile: () => gql`
    query migrationSampleFile($type: PermissionContext) {
      migrationSampleFile(type: $type)
    }
  `,
  moderationItem: (fields: ModerationItemFields) => gql`
      query moderationItem($id: ID!) {
        moderationItem(id: $id) {
          ${ModerationItemGQLFields(fields)}
        }
      }
    `,
  moderationItemReporters: (
    fields: PaginatedModerationItemReporterFields,
  ) => gql`
      query moderationItemReporters($after: String
$before: String
$limit: Int!
$moderationId: String!
$offset: Int
$reverse: Boolean) {
        moderationItemReporters(after: $after
before: $before
limit: $limit
moderationId: $moderationId
offset: $offset
reverse: $reverse) {
          ${PaginatedModerationItemReporterGQLFields(fields)}
        }
      }
    `,
  moderationItems: (fields: PaginatedModerationFields) => gql`
      query moderationItems($after: String
$before: String
$entityId: String
$entityType: ModerationEntityType
$flaggedBy: FlaggedType
$limit: Int!
$offset: Int
$reportCategories: [ReportCategory!]
$reverse: Boolean
$spaceId: String
$status: ModerationStatus) {
        moderationItems(after: $after
before: $before
entityId: $entityId
entityType: $entityType
flaggedBy: $flaggedBy
limit: $limit
offset: $offset
reportCategories: $reportCategories
reverse: $reverse
spaceId: $spaceId
status: $status) {
          ${PaginatedModerationGQLFields(fields)}
        }
      }
    `,
  moderationSettings: (fields: ModerationSettingsFields) => gql`
      query moderationSettings {
        moderationSettings {
          ${ModerationSettingsGQLFields(fields)}
        }
      }
    `,
  network: (fields: NetworkFields) => gql`
      query network {
        network {
          ${NetworkGQLFields(fields)}
        }
      }
    `,
  networkAppInstallations: (fields: PaginatedAppInstallationFields) => gql`
      query networkAppInstallations($after: String
$before: String
$limit: Int!
$offset: Int
$reverse: Boolean
$status: AppInstallationStatus) {
        networkAppInstallations(after: $after
before: $before
limit: $limit
offset: $offset
reverse: $reverse
status: $status) {
          ${PaginatedAppInstallationGQLFields(fields)}
        }
      }
    `,
  networkApps: (fields: AppFields) => gql`
      query networkApps($status: AppInstallationStatus) {
        networkApps(status: $status) {
          ${AppGQLFields(fields)}
        }
      }
    `,
  networkPublicInfo: (fields: NetworkPublicInfoFields) => gql`
      query networkPublicInfo {
        networkPublicInfo {
          ${NetworkPublicInfoGQLFields(fields)}
        }
      }
    `,
  networks: (fields: PaginatedNetworkFields) => gql`
      query networks($after: String
$before: String
$limit: Int!
$offset: Int
$query: String
$reverse: Boolean) {
        networks(after: $after
before: $before
limit: $limit
offset: $offset
query: $query
reverse: $reverse) {
          ${PaginatedNetworkGQLFields(fields)}
        }
      }
    `,
  networksMembers: (fields: PaginatedPluralMemberFields) => gql`
      query networksMembers($after: String
$before: String
$limit: Int!
$offset: Int
$query: String
$reverse: Boolean
$roleType: RoleType
$status: [MemberStatus!]) {
        networksMembers(after: $after
before: $before
limit: $limit
offset: $offset
query: $query
reverse: $reverse
roleType: $roleType
status: $status) {
          ${PaginatedPluralMemberGQLFields(fields)}
        }
      }
    `,
  newDomainStatus: (fields: DomainTransferStatusFields) => gql`
      query newDomainStatus($domain: String!) {
        newDomainStatus(domain: $domain) {
          ${DomainTransferStatusGQLFields(fields)}
        }
      }
    `,
  notifications: (fields: PaginatedNotificationFields) => gql`
      query notifications($after: String
$limit: Int!) {
        notifications(after: $after
limit: $limit) {
          ${PaginatedNotificationGQLFields(fields)}
        }
      }
    `,
  notificationsCount: (fields: NotificationsCountFields) => gql`
      query notificationsCount {
        notificationsCount {
          ${NotificationsCountGQLFields(fields)}
        }
      }
    `,
  oAuthCode: (fields: OAuthCodeFields) => gql`
      query oAuthCode($input: OAuthCodeInput!) {
        oAuthCode(input: $input) {
          ${OAuthCodeGQLFields(fields)}
        }
      }
    `,
  oAuthTokens: (fields: OAuthTokensFields) => gql`
      query oAuthTokens($input: OAuthTokenInput!) {
        oAuthTokens(input: $input) {
          ${OAuthTokensGQLFields(fields)}
        }
      }
    `,
  otp: () => gql`
    query otp {
      otp
    }
  `,
  page: (fields: PageFields) => gql`
      query page($path: String!) {
        page(path: $path) {
          ${PageGQLFields(fields)}
        }
      }
    `,
  pageCustomResponses: (fields: PaginatedPageCustomResponseFields) => gql`
      query pageCustomResponses($after: String
$before: String
$creatorType: CreatorType
$limit: Int!
$offset: Int
$reverse: Boolean) {
        pageCustomResponses(after: $after
before: $before
creatorType: $creatorType
limit: $limit
offset: $offset
reverse: $reverse) {
          ${PaginatedPageCustomResponseGQLFields(fields)}
        }
      }
    `,
  passwordComplexity: (fields: PasswordComplexityFields) => gql`
      query passwordComplexity($password: String!) {
        passwordComplexity(password: $password) {
          ${PasswordComplexityGQLFields(fields)}
        }
      }
    `,
  permission: (fields: PermissionFields) => gql`
      query permission($id: ID!) {
        permission(id: $id) {
          ${PermissionGQLFields(fields)}
        }
      }
    `,
  permissions: (fields: PermissionFields) => gql`
      query permissions {
        permissions {
          ${PermissionGQLFields(fields)}
        }
      }
    `,
  post: (fields: PostFields) => gql`
      query post($id: ID!) {
        post(id: $id) {
          ${PostGQLFields(fields)}
        }
      }
    `,
  postModerationItems: (fields: PaginatedModerationFields) => gql`
      query postModerationItems($after: String
$before: String
$entityId: String
$flaggedBy: FlaggedType
$limit: Int!
$offset: Int
$reportCategories: [ReportCategory!]
$reverse: Boolean
$spaceId: String
$status: ModerationStatus) {
        postModerationItems(after: $after
before: $before
entityId: $entityId
flaggedBy: $flaggedBy
limit: $limit
offset: $offset
reportCategories: $reportCategories
reverse: $reverse
spaceId: $spaceId
status: $status) {
          ${PaginatedModerationGQLFields(fields)}
        }
      }
    `,
  postReactionParticipants: (
    fields: PaginatedPostReactionParticipantFields,
  ) => gql`
      query postReactionParticipants($after: String
$before: String
$limit: Int!
$offset: Int
$postId: ID!
$reaction: ID!
$reverse: Boolean) {
        postReactionParticipants(after: $after
before: $before
limit: $limit
offset: $offset
postId: $postId
reaction: $reaction
reverse: $reverse) {
          ${PaginatedPostReactionParticipantGQLFields(fields)}
        }
      }
    `,
  postType: (fields: PostTypeFields) => gql`
      query postType($id: ID!) {
        postType(id: $id) {
          ${PostTypeGQLFields(fields)}
        }
      }
    `,
  postTypes: (fields: PaginatedPostTypeFields) => gql`
      query postTypes($after: String
$before: String
$context: PostTypeContext
$limit: Int!
$offset: Int
$query: String
$reverse: Boolean) {
        postTypes(after: $after
before: $before
context: $context
limit: $limit
offset: $offset
query: $query
reverse: $reverse) {
          ${PaginatedPostTypeGQLFields(fields)}
        }
      }
    `,
  posts: (fields: PaginatedPostFields) => gql`
      query posts($after: String
$before: String
$excludePins: Boolean
$filterBy: [PostListFilterByInput!]
$limit: Int!
$offset: Int
$orderBy: PostListOrderByEnum
$orderByString: String
$postTypeIds: [String!]
$query: String
$reverse: Boolean
$spaceIds: [ID!]) {
        posts(after: $after
before: $before
excludePins: $excludePins
filterBy: $filterBy
limit: $limit
offset: $offset
orderBy: $orderBy
orderByString: $orderByString
postTypeIds: $postTypeIds
query: $query
reverse: $reverse
spaceIds: $spaceIds) {
          ${PaginatedPostGQLFields(fields)}
        }
      }
    `,
  postsByIds: (fields: PostFields) => gql`
      query postsByIds($ids: [ID!]!
$statuses: [ID!]) {
        postsByIds(ids: $ids
statuses: $statuses) {
          ${PostGQLFields(fields)}
        }
      }
    `,
  privateMessage: (fields: SpaceFields) => gql`
      query privateMessage($input: PrivateMessageInput!) {
        privateMessage(input: $input) {
          ${SpaceGQLFields(fields)}
        }
      }
    `,
  probeDomain: (fields: DomainProbeResultFields) => gql`
      query probeDomain($domain: String!) {
        probeDomain(domain: $domain) {
          ${DomainProbeResultGQLFields(fields)}
        }
      }
    `,
  products: (fields: ProductFields) => gql`
      query products {
        products {
          ${ProductGQLFields(fields)}
        }
      }
    `,
  publisherSettings: (fields: PublisherFields) => gql`
      query publisherSettings($publisherId: String!) {
        publisherSettings(publisherId: $publisherId) {
          ${PublisherGQLFields(fields)}
        }
      }
    `,
  recommendResources: (fields: SmartSearchResourceFields) => gql`
      query recommendResources($input: RecommendResourcesInput!) {
        recommendResources(input: $input) {
          ${SmartSearchResourceGQLFields(fields)}
        }
      }
    `,
  redirect: (fields: RedirectFields) => gql`
      query redirect($url: String!) {
        redirect(url: $url) {
          ${RedirectGQLFields(fields)}
        }
      }
    `,
  replies: (fields: PaginatedPostFields) => gql`
      query replies($after: String
$before: String
$excludePins: Boolean
$limit: Int!
$offset: Int
$orderBy: PostListOrderByEnum
$postId: ID!
$reverse: Boolean) {
        replies(after: $after
before: $before
excludePins: $excludePins
limit: $limit
offset: $offset
orderBy: $orderBy
postId: $postId
reverse: $reverse) {
          ${PaginatedPostGQLFields(fields)}
        }
      }
    `,
  report: (fields: ReportFields) => gql`
      query report($input: ReportInput!
$spaceId: String) {
        report(input: $input
spaceId: $spaceId) {
          ${ReportGQLFields(fields)}
        }
      }
    `,
  roles: (fields: RoleFields) => gql`
      query roles($orderBy: RoleListOrderByEnum
$reverse: Boolean) {
        roles(orderBy: $orderBy
reverse: $reverse) {
          ${RoleGQLFields(fields)}
        }
      }
    `,
  scopes: (fields: ScopesFields) => gql`
      query scopes($contexts: [PermissionsContextInput!]) {
        scopes(contexts: $contexts) {
          ${ScopesGQLFields(fields)}
        }
      }
    `,
  search: (fields: SearchResultFields) => gql`
      query search($input: SearchInput!) {
        search(input: $input) {
          ${SearchResultGQLFields(fields)}
        }
      }
    `,
  searchMembersForSpaceInvitation: (
    fields: MemberForSpaceInvitationFields,
  ) => gql`
      query searchMembersForSpaceInvitation($input: SearchMembersForSpaceInvitationInput!) {
        searchMembersForSpaceInvitation(input: $input) {
          ${MemberForSpaceInvitationGQLFields(fields)}
        }
      }
    `,
  sitemap: (fields: PaginatedSitemapFields) => gql`
      query sitemap($after: String
$before: String
$limit: Int!
$offset: Int
$reverse: Boolean
$spacePath: ID) {
        sitemap(after: $after
before: $before
limit: $limit
offset: $offset
reverse: $reverse
spacePath: $spacePath) {
          ${PaginatedSitemapGQLFields(fields)}
        }
      }
    `,
  smartSearch: (fields: SmartSearchResultFields) => gql`
      query smartSearch($input: SmartSearchInput!) {
        smartSearch(input: $input) {
          ${SmartSearchResultGQLFields(fields)}
        }
      }
    `,
  space: (fields: SpaceFields) => gql`
      query space($id: ID
$path: String
$slug: ID) {
        space(id: $id
path: $path
slug: $slug) {
          ${SpaceGQLFields(fields)}
        }
      }
    `,
  spaceDefaultNotificationSettings: (
    fields: SpaceDefaultNotificationSettingsFields,
  ) => gql`
      query spaceDefaultNotificationSettings($spaceId: ID!) {
        spaceDefaultNotificationSettings(spaceId: $spaceId) {
          ${SpaceDefaultNotificationSettingsGQLFields(fields)}
        }
      }
    `,
  spaceJoinRequests: (fields: PaginatedSpaceJoinRequestFields) => gql`
      query spaceJoinRequests($after: String
$before: String
$limit: Int!
$memberId: ID
$offset: Int
$reverse: Boolean
$spaceId: ID
$status: SpaceJoinRequestStatus) {
        spaceJoinRequests(after: $after
before: $before
limit: $limit
memberId: $memberId
offset: $offset
reverse: $reverse
spaceId: $spaceId
status: $status) {
          ${PaginatedSpaceJoinRequestGQLFields(fields)}
        }
      }
    `,
  spaceMember: (fields: SpaceMemberFields) => gql`
      query spaceMember($memberId: ID!
$spaceId: ID!) {
        spaceMember(memberId: $memberId
spaceId: $spaceId) {
          ${SpaceMemberGQLFields(fields)}
        }
      }
    `,
  spaceMembers: (fields: PaginatedSpaceMemberFields) => gql`
      query spaceMembers($after: String
$before: String
$limit: Int!
$offset: Int
$orderBy: SpaceMemberListOrderByEnum
$reverse: Boolean
$roleIds: [ID!]
$spaceId: ID!) {
        spaceMembers(after: $after
before: $before
limit: $limit
offset: $offset
orderBy: $orderBy
reverse: $reverse
roleIds: $roleIds
spaceId: $spaceId) {
          ${PaginatedSpaceMemberGQLFields(fields)}
        }
      }
    `,
  spaceMembershipRequests: (fields: SpaceJoinRequestFields) => gql`
      query spaceMembershipRequests($spaceId: ID!
$status: SpaceJoinRequestStatus) {
        spaceMembershipRequests(spaceId: $spaceId
status: $status) {
          ${SpaceJoinRequestGQLFields(fields)}
        }
      }
    `,
  spacePinnedPosts: (fields: PostFields) => gql`
      query spacePinnedPosts($spaceId: ID!) {
        spacePinnedPosts(spaceId: $spaceId) {
          ${PostGQLFields(fields)}
        }
      }
    `,
  spacePostType: (fields: SpacePostTypeFields) => gql`
      query spacePostType($postTypeId: ID!
$spaceId: ID!) {
        spacePostType(postTypeId: $postTypeId
spaceId: $spaceId) {
          ${SpacePostTypeGQLFields(fields)}
        }
      }
    `,
  spacePostTypes: (fields: PaginatedSpacePostTypeFields) => gql`
      query spacePostTypes($after: String
$before: String
$context: PostTypeContext
$limit: Int!
$offset: Int
$reverse: Boolean
$spaceId: ID!) {
        spacePostTypes(after: $after
before: $before
context: $context
limit: $limit
offset: $offset
reverse: $reverse
spaceId: $spaceId) {
          ${PaginatedSpacePostTypeGQLFields(fields)}
        }
      }
    `,
  spaceRoles: (fields: SpaceRoleFields) => gql`
      query spaceRoles($orderBy: SpaceRoleListOrderByEnum
$reverse: Boolean
$spaceId: ID) {
        spaceRoles(orderBy: $orderBy
reverse: $reverse
spaceId: $spaceId) {
          ${SpaceRoleGQLFields(fields)}
        }
      }
    `,
  spaces: (fields: PaginatedSpaceFields) => gql`
      query spaces($after: String
$before: String
$collectionId: String
$exploreOnly: Boolean
$filterBy: [EntityListFilterByInput!]
$limit: Int!
$memberId: ID
$offset: Int
$orderBy: SpaceListOrderByEnum
$orderByString: String
$query: String
$reverse: Boolean
$type: [SpaceType!]) {
        spaces(after: $after
before: $before
collectionId: $collectionId
exploreOnly: $exploreOnly
filterBy: $filterBy
limit: $limit
memberId: $memberId
offset: $offset
orderBy: $orderBy
orderByString: $orderByString
query: $query
reverse: $reverse
type: $type) {
          ${PaginatedSpaceGQLFields(fields)}
        }
      }
    `,
  spacesByIds: (fields: SpaceFields) => gql`
      query spacesByIds($ids: [ID!]!) {
        spacesByIds(ids: $ids) {
          ${SpaceGQLFields(fields)}
        }
      }
    `,
  spacesBySlugs: (fields: SpaceFields) => gql`
      query spacesBySlugs($slugs: [String!]!) {
        spacesBySlugs(slugs: $slugs) {
          ${SpaceGQLFields(fields)}
        }
      }
    `,
  ssoMemberships: (fields: SsoMembershipFields) => gql`
      query ssoMemberships($memberId: String!) {
        ssoMemberships(memberId: $memberId) {
          ${SsoMembershipGQLFields(fields)}
        }
      }
    `,
  ssoUrl: (fields: SsoUrlFields) => gql`
      query ssoUrl($input: SsoUrlInput!) {
        ssoUrl(input: $input) {
          ${SsoUrlGQLFields(fields)}
        }
      }
    `,
  ssos: (fields: SsoFields) => gql`
      query ssos($status: SsoStatus) {
        ssos(status: $status) {
          ${SsoGQLFields(fields)}
        }
      }
    `,
  subscriberSettings: (fields: SubscriberFields) => gql`
      query subscriberSettings {
        subscriberSettings {
          ${SubscriberGQLFields(fields)}
        }
      }
    `,
  subscription: (fields: BillingSubscriptionFields) => gql`
      query subscription($input: subscriptionInput!) {
        subscription(input: $input) {
          ${BillingSubscriptionGQLFields(fields)}
        }
      }
    `,
  subscriptionInformation: (fields: SubscriptionInformationFields) => gql`
      query subscriptionInformation {
        subscriptionInformation {
          ${SubscriptionInformationGQLFields(fields)}
        }
      }
    `,
  supportNetworkTokens: (fields: SupportLimitedAuthTokenFields) => gql`
      query supportNetworkTokens($networkId: String!) {
        supportNetworkTokens(networkId: $networkId) {
          ${SupportLimitedAuthTokenGQLFields(fields)}
        }
      }
    `,
  supportSsoUrl: (fields: SupportSsoUrlFields) => gql`
      query supportSsoUrl($input: SupportSsoUrlInput) {
        supportSsoUrl(input: $input) {
          ${SupportSsoUrlGQLFields(fields)}
        }
      }
    `,
  systemSpaces: (fields: SpaceFields) => gql`
      query systemSpaces {
        systemSpaces {
          ${SpaceGQLFields(fields)}
        }
      }
    `,
  tagPosts: (fields: PaginatedPostFields) => gql`
      query tagPosts($after: String
$before: String
$limit: Int!
$offset: Int
$reverse: Boolean
$spaceId: ID
$tagId: ID
$tagSlug: String) {
        tagPosts(after: $after
before: $before
limit: $limit
offset: $offset
reverse: $reverse
spaceId: $spaceId
tagId: $tagId
tagSlug: $tagSlug) {
          ${PaginatedPostGQLFields(fields)}
        }
      }
    `,
  tags: (fields: PaginatedTagFields) => gql`
      query tags($after: String
$before: String
$ids: [ID!]
$limit: Int!
$offset: Int
$orderBy: TagsOrderByEnum
$query: String
$reverse: Boolean
$spaceId: ID) {
        tags(after: $after
before: $before
ids: $ids
limit: $limit
offset: $offset
orderBy: $orderBy
query: $query
reverse: $reverse
spaceId: $spaceId) {
          ${PaginatedTagGQLFields(fields)}
        }
      }
    `,
  template: (fields: TemplateFields) => gql`
      query template($id: String!) {
        template(id: $id) {
          ${TemplateGQLFields(fields)}
        }
      }
    `,
  templateRequest: (fields: TemplateRequestFields) => gql`
      query templateRequest($id: String!) {
        templateRequest(id: $id) {
          ${TemplateRequestGQLFields(fields)}
        }
      }
    `,
  templates: (fields: PaginatedTemplateFields) => gql`
      query templates($after: String
$before: String
$categories: [String!]
$entityTypes: [TemplateEntityType!]
$limit: Int!
$offset: Int
$query: String
$reverse: Boolean
$status: StoreItemStatus) {
        templates(after: $after
before: $before
categories: $categories
entityTypes: $entityTypes
limit: $limit
offset: $offset
query: $query
reverse: $reverse
status: $status) {
          ${PaginatedTemplateGQLFields(fields)}
        }
      }
    `,
  tokens: (fields: AuthTokenFields) => gql`
      query tokens($networkDomain: String
$networkId: ID
$otp: String
$refreshToken: String
$ssoToken: String) {
        tokens(networkDomain: $networkDomain
networkId: $networkId
otp: $otp
refreshToken: $refreshToken
ssoToken: $ssoToken) {
          ${AuthTokenGQLFields(fields)}
        }
      }
    `,
  translateMarkup: () => gql`
    query translateMarkup(
      $format: TranslationMarkupFormat
      $input: [TranslationMarkupInput!]
      $markups: [String!]
    ) {
      translateMarkup(format: $format, input: $input, markups: $markups)
    }
  `,
  usernameAvailability: (fields: UsernameAvailabilityFields) => gql`
      query usernameAvailability($username: String!) {
        usernameAvailability(username: $username) {
          ${UsernameAvailabilityGQLFields(fields)}
        }
      }
    `,
  vatTypes: (fields: VatTypeInfoFields) => gql`
      query vatTypes {
        vatTypes {
          ${VatTypeInfoGQLFields(fields)}
        }
      }
    `,
}

export const MutationFunctions = {
  addEmoji: (fields: EmojiFields) => gql`
      mutation addEmoji($input: CreateEmojiInput!) {
        addEmoji(input: $input) {
          ${EmojiGQLFields(fields)}
        }
      }
    `,
  addEmojis: (fields: EmojiFields) => gql`
      mutation addEmojis($input: [CreateEmojiInput!]!) {
        addEmojis(input: $input) {
          ${EmojiGQLFields(fields)}
        }
      }
    `,
  addFcmTokenToSubscriberSettings: (fields: ActionFields) => gql`
      mutation addFcmTokenToSubscriberSettings($token: String!) {
        addFcmTokenToSubscriberSettings(token: $token) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  addGroup: (fields: CollectionFields) => gql`
      mutation addGroup($input: CreateCollectionInput!) {
        addGroup(input: $input) {
          ${CollectionGQLFields(fields)}
        }
      }
    `,
  addImage: (fields: SignedUrlFields) => gql`
      mutation addImage($input: CreateImageInput!) {
        addImage(input: $input) {
          ${SignedUrlGQLFields(fields)}
        }
      }
    `,
  addImages: (fields: SignedUrlFields) => gql`
      mutation addImages($input: [CreateImageInput!]!) {
        addImages(input: $input) {
          ${SignedUrlGQLFields(fields)}
        }
      }
    `,
  addMedias: (fields: SignedUrlFields) => gql`
      mutation addMedias($input: AddMediasInput!) {
        addMedias(input: $input) {
          ${SignedUrlGQLFields(fields)}
        }
      }
    `,
  addMemberSchemaField: (fields: NetworkFields) => gql`
      mutation addMemberSchemaField($input: CustomFieldSchemaInput!) {
        addMemberSchemaField(input: $input) {
          ${NetworkGQLFields(fields)}
        }
      }
    `,
  addModerationReport: (fields: ModerationItemFields) => gql`
      mutation addModerationReport($input: CreateReportInput!) {
        addModerationReport(input: $input) {
          ${ModerationItemGQLFields(fields)}
        }
      }
    `,
  addNetwork: (fields: AuthTokenWithOtpFields) => gql`
      mutation addNetwork($input: AddNetworkInput!) {
        addNetwork(input: $input) {
          ${AuthTokenWithOtpGQLFields(fields)}
        }
      }
    `,
  addNewDomain: (fields: DomainTransferStatusFields) => gql`
      mutation addNewDomain($input: DomainAvailabilityInput!) {
        addNewDomain(input: $input) {
          ${DomainTransferStatusGQLFields(fields)}
        }
      }
    `,
  addPost: (fields: PostFields) => gql`
      mutation addPost($input: CreatePostInput!
$spaceId: ID!) {
        addPost(input: $input
spaceId: $spaceId) {
          ${PostGQLFields(fields)}
        }
      }
    `,
  addPostTypeField: (fields: PostTypeFields) => gql`
      mutation addPostTypeField($id: ID!
$input: CustomFieldSchemaInput!) {
        addPostTypeField(id: $id
input: $input) {
          ${PostTypeGQLFields(fields)}
        }
      }
    `,
  addReaction: (fields: ActionFields) => gql`
      mutation addReaction($input: AddReactionInput!
$postId: ID!) {
        addReaction(input: $input
postId: $postId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  addReply: (fields: PostFields) => gql`
      mutation addReply($input: CreatePostInput!
$postId: ID!) {
        addReply(input: $input
postId: $postId) {
          ${PostGQLFields(fields)}
        }
      }
    `,
  addSpace: (fields: SpaceFields) => gql`
      mutation addSpace($input: CreateSpaceInput!) {
        addSpace(input: $input) {
          ${SpaceGQLFields(fields)}
        }
      }
    `,
  addSpaceMembers: (fields: SpaceMemberFields) => gql`
      mutation addSpaceMembers($input: [AddSpaceMemberInput!]!
$spaceId: ID!) {
        addSpaceMembers(input: $input
spaceId: $spaceId) {
          ${SpaceMemberGQLFields(fields)}
        }
      }
    `,
  addTemplateToNetwork: (fields: TemplateRequestFields) => gql`
      mutation addTemplateToNetwork($excludeEntityTypes: [TemplateEntityType!]
$templateId: String!) {
        addTemplateToNetwork(excludeEntityTypes: $excludeEntityTypes
templateId: $templateId) {
          ${TemplateRequestGQLFields(fields)}
        }
      }
    `,
  addTrialPlan: (fields: PlanFields) => gql`
      mutation addTrialPlan($trial: AddTrialInput!) {
        addTrialPlan(trial: $trial) {
          ${PlanGQLFields(fields)}
        }
      }
    `,
  approveSpaceJoinRequest: (fields: ActionFields) => gql`
      mutation approveSpaceJoinRequest($spaceId: ID!
$spaceJoinRequestId: ID!) {
        approveSpaceJoinRequest(spaceId: $spaceId
spaceJoinRequestId: $spaceJoinRequestId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  approveSpaceMembershipRequest: (fields: ActionFields) => gql`
      mutation approveSpaceMembershipRequest($spaceId: ID!
$spaceMembershipRequestId: ID!) {
        approveSpaceMembershipRequest(spaceId: $spaceId
spaceMembershipRequestId: $spaceMembershipRequestId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  archiveMemberSchemaField: (fields: NetworkFields) => gql`
      mutation archiveMemberSchemaField($key: String!) {
        archiveMemberSchemaField(key: $key) {
          ${NetworkGQLFields(fields)}
        }
      }
    `,
  archivePostType: (fields: PostTypeFields) => gql`
      mutation archivePostType($id: ID!) {
        archivePostType(id: $id) {
          ${PostTypeGQLFields(fields)}
        }
      }
    `,
  archivePostTypeField: (fields: PostTypeFields) => gql`
      mutation archivePostTypeField($id: ID!
$key: String!) {
        archivePostTypeField(id: $id
key: $key) {
          ${PostTypeGQLFields(fields)}
        }
      }
    `,
  assignBadge: (fields: ActionFields) => gql`
      mutation assignBadge($id: String!
$input: AssignOrRevokeBadgeInput!) {
        assignBadge(id: $id
input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  blockMember: (fields: ActionFields) => gql`
      mutation blockMember($memberId: String!) {
        blockMember(memberId: $memberId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  callback: (fields: CallbackResponseFields) => gql`
      mutation callback($appId: ID!
$callbackId: ID!
$context: PermissionContext!
$dynamicBlockId: ID
$entityId: ID
$inputs: String) {
        callback(appId: $appId
callbackId: $callbackId
context: $context
dynamicBlockId: $dynamicBlockId
entityId: $entityId
inputs: $inputs) {
          ${CallbackResponseGQLFields(fields)}
        }
      }
    `,
  cancelDomainTransfer: (fields: ActionFields) => gql`
      mutation cancelDomainTransfer {
        cancelDomainTransfer {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  cancelEmailUpdate: (fields: ActionFields) => gql`
      mutation cancelEmailUpdate($id: ID) {
        cancelEmailUpdate(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  cancelNetworkDeletion: (fields: ActionFields) => gql`
      mutation cancelNetworkDeletion {
        cancelNetworkDeletion {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  changeHomepage: (fields: ActionFields) => gql`
      mutation changeHomepage($spaceId: String!
$type: HomeSpaceType) {
        changeHomepage(spaceId: $spaceId
type: $type) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  changeNetworkSubscriptionPlan: (fields: NetworkFields) => gql`
      mutation changeNetworkSubscriptionPlan($name: PlanName!) {
        changeNetworkSubscriptionPlan(name: $name) {
          ${NetworkGQLFields(fields)}
        }
      }
    `,
  clearNewDomain: (fields: ActionFields) => gql`
      mutation clearNewDomain {
        clearNewDomain {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  clearNotificationsCount: (fields: ActionFields) => gql`
      mutation clearNotificationsCount {
        clearNotificationsCount {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  closeSpace: (fields: ActionFields) => gql`
      mutation closeSpace($spaceId: ID!) {
        closeSpace(spaceId: $spaceId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  commitDomainTransfer: (fields: ActionFields) => gql`
      mutation commitDomainTransfer {
        commitDomainTransfer {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  confirmMassActionRequest: (fields: MassActionRequestFields) => gql`
      mutation confirmMassActionRequest($massActionRequestId: String!) {
        confirmMassActionRequest(massActionRequestId: $massActionRequestId) {
          ${MassActionRequestGQLFields(fields)}
        }
      }
    `,
  confirmResetPassword: (fields: ActionFields) => gql`
      mutation confirmResetPassword($input: ConfirmResetPasswordInput!) {
        confirmResetPassword(input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  createAccessGroup: (fields: AccessGroupFields) => gql`
      mutation createAccessGroup($input: CreateAccessGroupInput!) {
        createAccessGroup(input: $input) {
          ${AccessGroupGQLFields(fields)}
        }
      }
    `,
  createBadge: (fields: BadgeFields) => gql`
      mutation createBadge($input: CreateBadgeInput!) {
        createBadge(input: $input) {
          ${BadgeGQLFields(fields)}
        }
      }
    `,
  createBillingPortalSession: (fields: BillingPortalSessionFields) => gql`
      mutation createBillingPortalSession($returnUrl: String!) {
        createBillingPortalSession(returnUrl: $returnUrl) {
          ${BillingPortalSessionGQLFields(fields)}
        }
      }
    `,
  createCollection: (fields: CollectionFields) => gql`
      mutation createCollection($input: CreateCollectionInput!) {
        createCollection(input: $input) {
          ${CollectionGQLFields(fields)}
        }
      }
    `,
  createEmojis: (fields: EmojiFields) => gql`
      mutation createEmojis($input: [CreateEmojiInput!]!) {
        createEmojis(input: $input) {
          ${EmojiGQLFields(fields)}
        }
      }
    `,
  createExportMemberRequest: (fields: ExportFields) => gql`
      mutation createExportMemberRequest($input: ExportInput!) {
        createExportMemberRequest(input: $input) {
          ${ExportGQLFields(fields)}
        }
      }
    `,
  createFiles: (fields: SignedUrlFields) => gql`
      mutation createFiles($input: [CreateFileInput!]!) {
        createFiles(input: $input) {
          ${SignedUrlGQLFields(fields)}
        }
      }
    `,
  createImages: (fields: SignedUrlFields) => gql`
      mutation createImages($input: [CreateImageInput!]!) {
        createImages(input: $input) {
          ${SignedUrlGQLFields(fields)}
        }
      }
    `,
  createMassActionRequest: (fields: MassActionRequestFields) => gql`
      mutation createMassActionRequest($input: MassActionRequestInput!) {
        createMassActionRequest(input: $input) {
          ${MassActionRequestGQLFields(fields)}
        }
      }
    `,
  createModerationReport: (fields: ModerationItemFields) => gql`
      mutation createModerationReport($input: CreateReportInput!) {
        createModerationReport(input: $input) {
          ${ModerationItemGQLFields(fields)}
        }
      }
    `,
  createNetwork: (fields: AuthTokenWithOtpFields) => gql`
      mutation createNetwork($input: AddNetworkInput!) {
        createNetwork(input: $input) {
          ${AuthTokenWithOtpGQLFields(fields)}
        }
      }
    `,
  createPermission: (fields: PermissionFields) => gql`
      mutation createPermission($input: CreatePermissionInput!) {
        createPermission(input: $input) {
          ${PermissionGQLFields(fields)}
        }
      }
    `,
  createPost: (fields: PostFields) => gql`
      mutation createPost($input: CreatePostInput!
$spaceId: ID!) {
        createPost(input: $input
spaceId: $spaceId) {
          ${PostGQLFields(fields)}
        }
      }
    `,
  createPostType: (fields: PostTypeFields) => gql`
      mutation createPostType($input: CreatePostTypeInput!) {
        createPostType(input: $input) {
          ${PostTypeGQLFields(fields)}
        }
      }
    `,
  createReply: (fields: PostFields) => gql`
      mutation createReply($input: CreatePostInput!
$postId: ID!) {
        createReply(input: $input
postId: $postId) {
          ${PostGQLFields(fields)}
        }
      }
    `,
  createReport: (fields: ModerationItemFields) => gql`
      mutation createReport($input: CreateReportInput!) {
        createReport(input: $input) {
          ${ModerationItemGQLFields(fields)}
        }
      }
    `,
  createSpace: (fields: SpaceFields) => gql`
      mutation createSpace($input: CreateSpaceInput!) {
        createSpace(input: $input) {
          ${SpaceGQLFields(fields)}
        }
      }
    `,
  createSpaceFromTemplate: (fields: TemplateRequestFields) => gql`
      mutation createSpaceFromTemplate($excludeEntityTypes: [TemplateEntityType!]
$input: CreateSpaceInput!
$style: String
$templateId: String!) {
        createSpaceFromTemplate(excludeEntityTypes: $excludeEntityTypes
input: $input
style: $style
templateId: $templateId) {
          ${TemplateRequestGQLFields(fields)}
        }
      }
    `,
  createSubscriptionCheckoutSession: (
    fields: SubscriptionCheckoutSessionFields,
  ) => gql`
      mutation createSubscriptionCheckoutSession($cancelUrl: String!
$priceId: String!
$successUrl: String!) {
        createSubscriptionCheckoutSession(cancelUrl: $cancelUrl
priceId: $priceId
successUrl: $successUrl) {
          ${SubscriptionCheckoutSessionGQLFields(fields)}
        }
      }
    `,
  createTag: (fields: TagFields) => gql`
      mutation createTag($input: CreateTagInput!) {
        createTag(input: $input) {
          ${TagGQLFields(fields)}
        }
      }
    `,
  createTemplate: (fields: TemplateFields) => gql`
      mutation createTemplate($input: CreateTemplateInput!) {
        createTemplate(input: $input) {
          ${TemplateGQLFields(fields)}
        }
      }
    `,
  declineSpaceJoinRequest: (fields: ActionFields) => gql`
      mutation declineSpaceJoinRequest($spaceId: ID!
$spaceJoinRequestId: ID!) {
        declineSpaceJoinRequest(spaceId: $spaceId
spaceJoinRequestId: $spaceJoinRequestId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  declineSpaceMembershipRequest: (fields: ActionFields) => gql`
      mutation declineSpaceMembershipRequest($spaceId: ID!
$spaceMembershipRequestId: ID!) {
        declineSpaceMembershipRequest(spaceId: $spaceId
spaceMembershipRequestId: $spaceMembershipRequestId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  deleteAccessGroup: (fields: ActionFields) => gql`
      mutation deleteAccessGroup($id: ID!) {
        deleteAccessGroup(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  deleteBadge: (fields: ActionFields) => gql`
      mutation deleteBadge($id: String!) {
        deleteBadge(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  deleteCollection: (fields: ActionFields) => gql`
      mutation deleteCollection($id: ID!) {
        deleteCollection(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  deleteMember: (fields: ActionFields) => gql`
      mutation deleteMember($id: ID!) {
        deleteMember(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  deleteMemberSchemaField: (fields: NetworkFields) => gql`
      mutation deleteMemberSchemaField($key: String!) {
        deleteMemberSchemaField(key: $key) {
          ${NetworkGQLFields(fields)}
        }
      }
    `,
  deleteNetwork: (fields: ActionFields) => gql`
      mutation deleteNetwork {
        deleteNetwork {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  deleteNotification: (fields: ActionFields) => gql`
      mutation deleteNotification($notificationId: ID!) {
        deleteNotification(notificationId: $notificationId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  deleteNotifications: (fields: ActionFields) => gql`
      mutation deleteNotifications($ids: [ID!]) {
        deleteNotifications(ids: $ids) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  deletePageCustomResponse: (fields: ActionFields) => gql`
      mutation deletePageCustomResponse($path: String!) {
        deletePageCustomResponse(path: $path) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  deletePermission: (fields: ActionFields) => gql`
      mutation deletePermission($id: ID!) {
        deletePermission(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  deletePost: (fields: ActionFields) => gql`
      mutation deletePost($id: ID!) {
        deletePost(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  deletePostTypeField: (fields: PostTypeFields) => gql`
      mutation deletePostTypeField($id: ID!
$key: String!) {
        deletePostTypeField(id: $id
key: $key) {
          ${PostTypeGQLFields(fields)}
        }
      }
    `,
  deleteSpace: (fields: ActionFields) => gql`
      mutation deleteSpace($id: ID!) {
        deleteSpace(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  deleteSpacePostTypes: (fields: ActionFields) => gql`
      mutation deleteSpacePostTypes($postTypeIds: [ID!]!
$spaceId: ID!) {
        deleteSpacePostTypes(postTypeIds: $postTypeIds
spaceId: $spaceId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  deleteSsoMembership: (fields: ActionFields) => gql`
      mutation deleteSsoMembership($memberId: String!
$type: SsoType!) {
        deleteSsoMembership(memberId: $memberId
type: $type) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  deleteTag: (fields: ActionFields) => gql`
      mutation deleteTag($id: ID!) {
        deleteTag(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  deleteTemplate: (fields: ActionFields) => gql`
      mutation deleteTemplate($id: String!) {
        deleteTemplate(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  doResetPassword: (fields: ActionFields) => gql`
      mutation doResetPassword($input: UpdatePasswordWithTokenInput!) {
        doResetPassword(input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  editImage: (fields: ImageFields) => gql`
      mutation editImage($imageId: String!
$input: UpdateImageInput!) {
        editImage(imageId: $imageId
input: $input) {
          ${ImageGQLFields(fields)}
        }
      }
    `,
  extendCurrentTrial: (fields: PlanFields) => gql`
      mutation extendCurrentTrial($newEndDate: DateTime!) {
        extendCurrentTrial(newEndDate: $newEndDate) {
          ${PlanGQLFields(fields)}
        }
      }
    `,
  globalAddAppCollaborator: (fields: AppCollaboratorFields) => gql`
      mutation globalAddAppCollaborator($appId: String!
$input: AddAppCollaboratorInput!) {
        globalAddAppCollaborator(appId: $appId
input: $input) {
          ${AppCollaboratorGQLFields(fields)}
        }
      }
    `,
  globalCreateApp: (fields: AppFields) => gql`
      mutation globalCreateApp($input: CreateAppInput!) {
        globalCreateApp(input: $input) {
          ${AppGQLFields(fields)}
        }
      }
    `,
  globalCreateEmojis: (fields: EmojiFields) => gql`
      mutation globalCreateEmojis($input: [CreateEmojiInput!]!) {
        globalCreateEmojis(input: $input) {
          ${EmojiGQLFields(fields)}
        }
      }
    `,
  globalCreateImages: (fields: SignedUrlFields) => gql`
      mutation globalCreateImages($input: [CreateImageInput!]!) {
        globalCreateImages(input: $input) {
          ${SignedUrlGQLFields(fields)}
        }
      }
    `,
  globalDeleteApp: (fields: ActionFields) => gql`
      mutation globalDeleteApp($id: ID!) {
        globalDeleteApp(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  globalDeleteAppSetting: (fields: AppSettingFields) => gql`
      mutation globalDeleteAppSetting($context: PermissionContext!
$entityId: ID
$networkId: ID!) {
        globalDeleteAppSetting(context: $context
entityId: $entityId
networkId: $networkId) {
          ${AppSettingGQLFields(fields)}
        }
      }
    `,
  globalPublishAppPrivately: (fields: AppPublicationFields) => gql`
      mutation globalPublishAppPrivately($appId: ID!
$networkId: ID!) {
        globalPublishAppPrivately(appId: $appId
networkId: $networkId) {
          ${AppPublicationGQLFields(fields)}
        }
      }
    `,
  globalRegenerateClientSecret: (fields: AppFields) => gql`
      mutation globalRegenerateClientSecret($appId: ID!) {
        globalRegenerateClientSecret(appId: $appId) {
          ${AppGQLFields(fields)}
        }
      }
    `,
  globalRemoveAppCollaborator: (fields: ActionFields) => gql`
      mutation globalRemoveAppCollaborator($appId: String!
$collaboratorId: String!) {
        globalRemoveAppCollaborator(appId: $appId
collaboratorId: $collaboratorId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  globalTestAppWebhook: (fields: ActionFields) => gql`
      mutation globalTestAppWebhook($appId: ID!
$input: TestAppWebhookInput!) {
        globalTestAppWebhook(appId: $appId
input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  globalUnPublishAppPrivately: (fields: ActionFields) => gql`
      mutation globalUnPublishAppPrivately($appId: ID!
$networkId: ID!) {
        globalUnPublishAppPrivately(appId: $appId
networkId: $networkId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  globalUpdateApp: (fields: AppFields) => gql`
      mutation globalUpdateApp($id: ID!
$input: UpdateAppInput!) {
        globalUpdateApp(id: $id
input: $input) {
          ${AppGQLFields(fields)}
        }
      }
    `,
  globalUpdateAppSetting: (fields: AppSettingFields) => gql`
      mutation globalUpdateAppSetting($context: PermissionContext!
$entityId: ID
$networkId: ID!
$settings: String!) {
        globalUpdateAppSetting(context: $context
entityId: $entityId
networkId: $networkId
settings: $settings) {
          ${AppSettingGQLFields(fields)}
        }
      }
    `,
  hidePost: (fields: ActionFields) => gql`
      mutation hidePost($id: ID!) {
        hidePost(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  impersonateLoginToNetwork: (fields: AuthInfoWithOtpFields) => gql`
      mutation impersonateLoginToNetwork($input: ImpersonateLoginToNetworkInput!) {
        impersonateLoginToNetwork(input: $input) {
          ${AuthInfoWithOtpGQLFields(fields)}
        }
      }
    `,
  installApp: (fields: AppInstallationFields) => gql`
      mutation installApp($appId: ID!
$input: InstallAppInput) {
        installApp(appId: $appId
input: $input) {
          ${AppInstallationGQLFields(fields)}
        }
      }
    `,
  installAppOnAuthMember: (fields: AppInstallationFields) => gql`
      mutation installAppOnAuthMember($appId: ID!
$input: InstallAppInput) {
        installAppOnAuthMember(appId: $appId
input: $input) {
          ${AppInstallationGQLFields(fields)}
        }
      }
    `,
  interactWithApps: (fields: AppInteractionFields) => gql`
      mutation interactWithApps($context: PermissionContext!
$entityId: ID
$input: [InteractWithAppInput!]!) {
        interactWithApps(context: $context
entityId: $entityId
input: $input) {
          ${AppInteractionGQLFields(fields)}
        }
      }
    `,
  inviteMembers: (fields: MemberInvitationFields) => gql`
      mutation inviteMembers($input: InviteMembersInput!) {
        inviteMembers(input: $input) {
          ${MemberInvitationGQLFields(fields)}
        }
      }
    `,
  joinNetwork: (fields: AuthTokenFields) => gql`
      mutation joinNetwork($input: JoinNetworkInput!) {
        joinNetwork(input: $input) {
          ${AuthTokenGQLFields(fields)}
        }
      }
    `,
  joinNetworkWithInvitationLink: (fields: AuthTokenFields) => gql`
      mutation joinNetworkWithInvitationLink($input: JoinNetworkWithLinkInput!) {
        joinNetworkWithInvitationLink(input: $input) {
          ${AuthTokenGQLFields(fields)}
        }
      }
    `,
  joinNetworkWithToken: (fields: AuthTokenFields) => gql`
      mutation joinNetworkWithToken($input: JoinNetworkWithTokenInput!) {
        joinNetworkWithToken(input: $input) {
          ${AuthTokenGQLFields(fields)}
        }
      }
    `,
  joinSpace: (fields: ActionFields) => gql`
      mutation joinSpace($spaceId: ID!) {
        joinSpace(spaceId: $spaceId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  leaveSpace: (fields: ActionFields) => gql`
      mutation leaveSpace($spaceId: ID!) {
        leaveSpace(spaceId: $spaceId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  loginNetwork: (fields: AuthTokenFields) => gql`
      mutation loginNetwork($input: LoginNetworkWithPasswordInput!) {
        loginNetwork(input: $input) {
          ${AuthTokenGQLFields(fields)}
        }
      }
    `,
  loginNetworkWithPassword: (fields: AuthTokenFields) => gql`
      mutation loginNetworkWithPassword($input: LoginNetworkWithPasswordInput!) {
        loginNetworkWithPassword(input: $input) {
          ${AuthTokenGQLFields(fields)}
        }
      }
    `,
  loginWithSamlResponse: (fields: AuthTokenFields) => gql`
      mutation loginWithSamlResponse($input: LoginWithSamlResponseInput!) {
        loginWithSamlResponse(input: $input) {
          ${AuthTokenGQLFields(fields)}
        }
      }
    `,
  loginWithSsoCode: (fields: AuthTokenFields) => gql`
      mutation loginWithSsoCode($input: LoginWithSsoCodeInput!) {
        loginWithSsoCode(input: $input) {
          ${AuthTokenGQLFields(fields)}
        }
      }
    `,
  logoutMemberSessions: (fields: ActionFields) => gql`
      mutation logoutMemberSessions($memberId: String!) {
        logoutMemberSessions(memberId: $memberId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  logoutNetwork: (fields: ActionFields) => gql`
      mutation logoutNetwork($input: LogoutNetworkInput) {
        logoutNetwork(input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  modifySubscriptionAddon: (fields: ActionFields) => gql`
      mutation modifySubscriptionAddon($priceId: String!
$quantity: Float!) {
        modifySubscriptionAddon(priceId: $priceId
quantity: $quantity) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  movePost: (fields: PostFields) => gql`
      mutation movePost($convertPost: ConvertPostInput
$id: ID!
$sendNotification: Boolean
$spaceId: ID!) {
        movePost(convertPost: $convertPost
id: $id
sendNotification: $sendNotification
spaceId: $spaceId) {
          ${PostGQLFields(fields)}
        }
      }
    `,
  movePosts: (fields: PostFields) => gql`
      mutation movePosts($convertPost: ConvertPostInput
$ids: [ID!]!
$sendNotification: Boolean
$spaceId: ID!) {
        movePosts(convertPost: $convertPost
ids: $ids
sendNotification: $sendNotification
spaceId: $spaceId) {
          ${PostGQLFields(fields)}
        }
      }
    `,
  nominateNewDomain: (fields: ActionFields) => gql`
      mutation nominateNewDomain($domain: String!) {
        nominateNewDomain(domain: $domain) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  organizeCollections: (fields: ActionFields) => gql`
      mutation organizeCollections($ids: [String!]!) {
        organizeCollections(ids: $ids) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  organizeGroupSpaces: (fields: ActionFields) => gql`
      mutation organizeGroupSpaces($groupId: String!
$spaceIds: [String!]!) {
        organizeGroupSpaces(groupId: $groupId
spaceIds: $spaceIds) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  organizeGroups: (fields: ActionFields) => gql`
      mutation organizeGroups($groupIds: [String!]!) {
        organizeGroups(groupIds: $groupIds) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  organizeSpacesInCollection: (fields: ActionFields) => gql`
      mutation organizeSpacesInCollection($collectionId: String!
$spaceIds: [String!]!) {
        organizeSpacesInCollection(collectionId: $collectionId
spaceIds: $spaceIds) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  pinPostToSpace: (fields: ActionFields) => gql`
      mutation pinPostToSpace($postId: ID!) {
        pinPostToSpace(postId: $postId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  pinReplyToPost: (fields: ActionFields) => gql`
      mutation pinReplyToPost($postId: ID!
$replyId: ID!) {
        pinReplyToPost(postId: $postId
replyId: $replyId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  publishAppPrivately: (fields: AppPublicationFields) => gql`
      mutation publishAppPrivately($appId: ID!) {
        publishAppPrivately(appId: $appId) {
          ${AppPublicationGQLFields(fields)}
        }
      }
    `,
  publishTemplate: (fields: ActionFields) => gql`
      mutation publishTemplate($id: String!) {
        publishTemplate(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  purchase: (fields: BillingSubscriptionFields) => gql`
      mutation purchase($input: PurchaseInput!) {
        purchase(input: $input) {
          ${BillingSubscriptionGQLFields(fields)}
        }
      }
    `,
  readNotification: (fields: ActionFields) => gql`
      mutation readNotification($notificationId: ID!) {
        readNotification(notificationId: $notificationId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  readNotifications: (fields: ActionFields) => gql`
      mutation readNotifications($ids: [ID!]) {
        readNotifications(ids: $ids) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  readSpace: (fields: ActionFields) => gql`
      mutation readSpace($date: DateTime!
$spaceId: String!) {
        readSpace(date: $date
spaceId: $spaceId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  removeFcmTokenFromSubscriberSettings: (fields: ActionFields) => gql`
      mutation removeFcmTokenFromSubscriberSettings($token: String!) {
        removeFcmTokenFromSubscriberSettings(token: $token) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  removeGroup: (fields: ActionFields) => gql`
      mutation removeGroup($groupId: ID) {
        removeGroup(groupId: $groupId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  removeMember: (fields: ActionFields) => gql`
      mutation removeMember($memberId: ID!) {
        removeMember(memberId: $memberId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  removeNetwork: (fields: ActionFields) => gql`
      mutation removeNetwork {
        removeNetwork {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  removePost: (fields: ActionFields) => gql`
      mutation removePost($postId: ID!) {
        removePost(postId: $postId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  removeReaction: (fields: ActionFields) => gql`
      mutation removeReaction($participantId: String
$postId: ID!
$reaction: String!) {
        removeReaction(participantId: $participantId
postId: $postId
reaction: $reaction) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  removeSpaceMembers: (fields: ActionFields) => gql`
      mutation removeSpaceMembers($memberIds: [ID!]!
$spaceId: ID!) {
        removeSpaceMembers(memberIds: $memberIds
spaceId: $spaceId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  reorderMemberSchemaFields: (fields: NetworkFields) => gql`
      mutation reorderMemberSchemaFields($input: [ReOrderSchemaFieldInput!]!) {
        reorderMemberSchemaFields(input: $input) {
          ${NetworkGQLFields(fields)}
        }
      }
    `,
  reorderPostTypeFields: (fields: PostTypeFields) => gql`
      mutation reorderPostTypeFields($id: ID!
$input: [ReOrderSchemaFieldInput!]!) {
        reorderPostTypeFields(id: $id
input: $input) {
          ${PostTypeGQLFields(fields)}
        }
      }
    `,
  requestGlobalTokenCode: (fields: ActionFields) => gql`
      mutation requestGlobalTokenCode($input: RequestGlobalTokenInput!) {
        requestGlobalTokenCode(input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  requestSpaceMembership: (fields: SpaceJoinRequestFields) => gql`
      mutation requestSpaceMembership($spaceId: ID!) {
        requestSpaceMembership(spaceId: $spaceId) {
          ${SpaceJoinRequestGQLFields(fields)}
        }
      }
    `,
  resendGlobalTokenCode: (fields: ActionFields) => gql`
      mutation resendGlobalTokenCode($input: RequestGlobalTokenInput!) {
        resendGlobalTokenCode(input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  resendMemberInvitation: (fields: ActionFields) => gql`
      mutation resendMemberInvitation($id: ID!) {
        resendMemberInvitation(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  resendVerification: (fields: ActionFields) => gql`
      mutation resendVerification {
        resendVerification {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  resetPassword: (fields: ActionFields) => gql`
      mutation resetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  revokeBadge: (fields: ActionFields) => gql`
      mutation revokeBadge($id: String!
$input: AssignOrRevokeBadgeInput!) {
        revokeBadge(id: $id
input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  revokeMemberInvitation: (fields: ActionFields) => gql`
      mutation revokeMemberInvitation($id: ID!) {
        revokeMemberInvitation(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  runMigration: (fields: MigrationFields) => gql`
      mutation runMigration($id: String!) {
        runMigration(id: $id) {
          ${MigrationGQLFields(fields)}
        }
      }
    `,
  sendResetPasswordEmail: (fields: ActionFields) => gql`
      mutation sendResetPasswordEmail($email: String!) {
        sendResetPasswordEmail(email: $email) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  setPrivatelyPublishedApps: (fields: AppPublicationFields) => gql`
      mutation setPrivatelyPublishedApps($ids: [ID!]!) {
        setPrivatelyPublishedApps(ids: $ids) {
          ${AppPublicationGQLFields(fields)}
        }
      }
    `,
  ssoRedirect: (fields: AuthTokenFields) => gql`
      mutation ssoRedirect($input: LoginWithSsoCodeInput!) {
        ssoRedirect(input: $input) {
          ${AuthTokenGQLFields(fields)}
        }
      }
    `,
  subscribe: (fields: ActionFields) => gql`
      mutation subscribe($publisherId: String!) {
        subscribe(publisherId: $publisherId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  supportSsoRedirect: (fields: SupportAuthTokenFields) => gql`
      mutation supportSsoRedirect($input: LoginSupportWithSsoCodeInput!) {
        supportSsoRedirect(input: $input) {
          ${SupportAuthTokenGQLFields(fields)}
        }
      }
    `,
  suspendMember: (fields: ActionFields) => gql`
      mutation suspendMember($memberId: String!) {
        suspendMember(memberId: $memberId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  syncTemplate: (fields: TemplateFields) => gql`
      mutation syncTemplate($id: String!
$upsertKey: String) {
        syncTemplate(id: $id
upsertKey: $upsertKey) {
          ${TemplateGQLFields(fields)}
        }
      }
    `,
  transferToNewDomain: (fields: ActionFields) => gql`
      mutation transferToNewDomain {
        transferToNewDomain {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  unArchiveMemberSchemaField: (fields: NetworkFields) => gql`
      mutation unArchiveMemberSchemaField($key: String!) {
        unArchiveMemberSchemaField(key: $key) {
          ${NetworkGQLFields(fields)}
        }
      }
    `,
  unPublishAppPrivately: () => gql`
    mutation unPublishAppPrivately($appId: ID!, $networkId: ID!) {
      unPublishAppPrivately(appId: $appId, networkId: $networkId)
    }
  `,
  unarchivePostType: (fields: PostTypeFields) => gql`
      mutation unarchivePostType($id: ID!) {
        unarchivePostType(id: $id) {
          ${PostTypeGQLFields(fields)}
        }
      }
    `,
  unarchivePostTypeField: (fields: PostTypeFields) => gql`
      mutation unarchivePostTypeField($id: ID!
$key: String!) {
        unarchivePostTypeField(id: $id
key: $key) {
          ${PostTypeGQLFields(fields)}
        }
      }
    `,
  unblockMember: (fields: ActionFields) => gql`
      mutation unblockMember($memberId: String!) {
        unblockMember(memberId: $memberId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  unhidePost: (fields: ActionFields) => gql`
      mutation unhidePost($id: ID!) {
        unhidePost(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  uninstallApp: (fields: AppInstallationFields) => gql`
      mutation uninstallApp($appInstallationId: ID!
$reason: String) {
        uninstallApp(appInstallationId: $appInstallationId
reason: $reason) {
          ${AppInstallationGQLFields(fields)}
        }
      }
    `,
  unpinPostFromSpace: (fields: ActionFields) => gql`
      mutation unpinPostFromSpace($postId: ID!) {
        unpinPostFromSpace(postId: $postId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  unpinReplyFromPost: (fields: ActionFields) => gql`
      mutation unpinReplyFromPost($postId: ID!
$replyId: ID!) {
        unpinReplyFromPost(postId: $postId
replyId: $replyId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  unpublishTemplate: (fields: ActionFields) => gql`
      mutation unpublishTemplate($id: String!) {
        unpublishTemplate(id: $id) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  unsetHomepage: (fields: ActionFields) => gql`
      mutation unsetHomepage($type: HomeSpaceType!) {
        unsetHomepage(type: $type) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  unsubscribe: (fields: ActionFields) => gql`
      mutation unsubscribe($publisherId: String!) {
        unsubscribe(publisherId: $publisherId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  unsubscribeFromNotification: (fields: ActionFields) => gql`
      mutation unsubscribeFromNotification($input: UnsubscribeWithTokenInput!) {
        unsubscribeFromNotification(input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  unsubscribeWithToken: (fields: ActionFields) => gql`
      mutation unsubscribeWithToken($input: UnsubscribeWithTokenInput!) {
        unsubscribeWithToken(input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  unsuspendMember: (fields: ActionFields) => gql`
      mutation unsuspendMember($memberId: String!) {
        unsuspendMember(memberId: $memberId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  updateAccessGroup: (fields: AccessGroupFields) => gql`
      mutation updateAccessGroup($id: ID!
$input: UpdateAccessGroupInput!) {
        updateAccessGroup(id: $id
input: $input) {
          ${AccessGroupGQLFields(fields)}
        }
      }
    `,
  updateAppInstallation: (fields: AppInstallationFields) => gql`
      mutation updateAppInstallation($appInstallationId: ID!
$input: UpdateAppInstallationInput!) {
        updateAppInstallation(appInstallationId: $appInstallationId
input: $input) {
          ${AppInstallationGQLFields(fields)}
        }
      }
    `,
  updateAppNetworkSettings: (fields: AppActionFields) => gql`
      mutation updateAppNetworkSettings($appId: ID!
$settings: String!) {
        updateAppNetworkSettings(appId: $appId
settings: $settings) {
          ${AppActionGQLFields(fields)}
        }
      }
    `,
  updateAppSpaceSetting: (fields: AppActionFields) => gql`
      mutation updateAppSpaceSetting($appId: ID!
$settings: String!
$spaceId: ID!) {
        updateAppSpaceSetting(appId: $appId
settings: $settings
spaceId: $spaceId) {
          ${AppActionGQLFields(fields)}
        }
      }
    `,
  updateAppStanding: () => gql`
    mutation updateAppStanding($appId: ID!, $standing: StoreItemStanding!) {
      updateAppStanding(appId: $appId, standing: $standing)
    }
  `,
  updateAppStatus: () => gql`
    mutation updateAppStatus($appId: ID!, $status: StoreItemStatus!) {
      updateAppStatus(appId: $appId, status: $status)
    }
  `,
  updateAuthMember: (fields: MemberFields) => gql`
      mutation updateAuthMember($input: UpdateMemberInput!) {
        updateAuthMember(input: $input) {
          ${MemberGQLFields(fields)}
        }
      }
    `,
  updateBadge: (fields: BadgeFields) => gql`
      mutation updateBadge($id: String!
$input: UpdateBadgeInput!) {
        updateBadge(id: $id
input: $input) {
          ${BadgeGQLFields(fields)}
        }
      }
    `,
  updateBillingDetails: (fields: BillingDetailsFields) => gql`
      mutation updateBillingDetails($input: BillingDetailsInput!) {
        updateBillingDetails(input: $input) {
          ${BillingDetailsGQLFields(fields)}
        }
      }
    `,
  updateCollection: (fields: ActionFields) => gql`
      mutation updateCollection($id: ID!
$input: UpdateCollectionInput!) {
        updateCollection(id: $id
input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  updateCustomSso: (fields: SsoFields) => gql`
      mutation updateCustomSso($input: UpdateCustomSsoInput!) {
        updateCustomSso(input: $input) {
          ${SsoGQLFields(fields)}
        }
      }
    `,
  updateDefaultSsoStatus: (fields: ActionFields) => gql`
      mutation updateDefaultSsoStatus($sso: DefaultSsoType!
$status: SsoStatus!) {
        updateDefaultSsoStatus(sso: $sso
status: $status) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  updateFile: (fields: FileFields) => gql`
      mutation updateFile($id: String!
$input: UpdateFileInput!) {
        updateFile(id: $id
input: $input) {
          ${FileGQLFields(fields)}
        }
      }
    `,
  updateGroup: (fields: ActionFields) => gql`
      mutation updateGroup($groupId: ID
$input: UpdateCollectionInput!) {
        updateGroup(groupId: $groupId
input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  updateImage: (fields: ImageFields) => gql`
      mutation updateImage($id: String!
$input: UpdateImageInput!) {
        updateImage(id: $id
input: $input) {
          ${ImageGQLFields(fields)}
        }
      }
    `,
  updateJwtSso: (fields: SsoFields) => gql`
      mutation updateJwtSso($input: UpdateJwtSsoInput!) {
        updateJwtSso(input: $input) {
          ${SsoGQLFields(fields)}
        }
      }
    `,
  updateMember: (fields: MemberFields) => gql`
      mutation updateMember($id: ID
$input: UpdateMemberInput!) {
        updateMember(id: $id
input: $input) {
          ${MemberGQLFields(fields)}
        }
      }
    `,
  updateMemberInvitation: (fields: MemberInvitationFields) => gql`
      mutation updateMemberInvitation($id: ID!
$input: UpdateMemberInvitationInput!) {
        updateMemberInvitation(id: $id
input: $input) {
          ${MemberInvitationGQLFields(fields)}
        }
      }
    `,
  updateMemberNetworkNotificationSettings: (
    fields: MemberNetworkNotificationSettingsFields,
  ) => gql`
      mutation updateMemberNetworkNotificationSettings($channel: NotificationChannel!
$input: UpdateMemberNetworkNotificationSettingsInput!
$memberId: ID) {
        updateMemberNetworkNotificationSettings(channel: $channel
input: $input
memberId: $memberId) {
          ${MemberNetworkNotificationSettingsGQLFields(fields)}
        }
      }
    `,
  updateMemberPostNotificationSettings: (
    fields: MemberPostNotificationSettingsFields,
  ) => gql`
      mutation updateMemberPostNotificationSettings($input: UpdateMemberPostNotificationSettingsInput!
$memberId: ID
$postId: ID!) {
        updateMemberPostNotificationSettings(input: $input
memberId: $memberId
postId: $postId) {
          ${MemberPostNotificationSettingsGQLFields(fields)}
        }
      }
    `,
  updateMemberPrivateMessagingSettings: (
    fields: MemberPrivateMessagingSettingsFields,
  ) => gql`
      mutation updateMemberPrivateMessagingSettings($input: UpdateMemberPrivateMessagingSettingsInput!) {
        updateMemberPrivateMessagingSettings(input: $input) {
          ${MemberPrivateMessagingSettingsGQLFields(fields)}
        }
      }
    `,
  updateMemberSchemaField: (fields: NetworkFields) => gql`
      mutation updateMemberSchemaField($input: UpdateCustomFieldSchemaInput!) {
        updateMemberSchemaField(input: $input) {
          ${NetworkGQLFields(fields)}
        }
      }
    `,
  updateMemberSpaceNotificationSettings: (
    fields: MemberSpaceNotificationSettingsFields,
  ) => gql`
      mutation updateMemberSpaceNotificationSettings($channel: NotificationChannel!
$input: UpdateMemberSpaceNotificationSettingsInput!
$memberId: ID
$spaceId: ID!) {
        updateMemberSpaceNotificationSettings(channel: $channel
input: $input
memberId: $memberId
spaceId: $spaceId) {
          ${MemberSpaceNotificationSettingsGQLFields(fields)}
        }
      }
    `,
  updateMemberSpaceRole: (fields: ActionFields) => gql`
      mutation updateMemberSpaceRole($input: UpdateSpaceMemberRoleInput!
$memberId: ID!
$spaceId: ID!) {
        updateMemberSpaceRole(input: $input
memberId: $memberId
spaceId: $spaceId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  updateModeration: (fields: ActionFields) => gql`
      mutation updateModeration($id: ID!
$input: UpdateModerationItemInput!) {
        updateModeration(id: $id
input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  updateModerationItem: (fields: ActionFields) => gql`
      mutation updateModerationItem($id: ID!
$input: UpdateModerationItemInput!) {
        updateModerationItem(id: $id
input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  updateModerationSettings: (fields: ModerationSettingsFields) => gql`
      mutation updateModerationSettings($input: UpdateModerationSettingsInput!) {
        updateModerationSettings(input: $input) {
          ${ModerationSettingsGQLFields(fields)}
        }
      }
    `,
  updateNetwork: (fields: NetworkFields) => gql`
      mutation updateNetwork($input: UpdateNetworkInput!) {
        updateNetwork(input: $input) {
          ${NetworkGQLFields(fields)}
        }
      }
    `,
  updateNetworkAvailableLocales: (fields: NetworkFields) => gql`
      mutation updateNetworkAvailableLocales($availableLocales: [String!]!) {
        updateNetworkAvailableLocales(availableLocales: $availableLocales) {
          ${NetworkGQLFields(fields)}
        }
      }
    `,
  updateNetworkCustomCapacities: (fields: NetworkFields) => gql`
      mutation updateNetworkCustomCapacities($additionalSeats: Int
$customMemberCapacity: Int) {
        updateNetworkCustomCapacities(additionalSeats: $additionalSeats
customMemberCapacity: $customMemberCapacity) {
          ${NetworkGQLFields(fields)}
        }
      }
    `,
  updateNetworkDomainSubfolder: (fields: NetworkFields) => gql`
      mutation updateNetworkDomainSubfolder($domainSubfolder: String) {
        updateNetworkDomainSubfolder(domainSubfolder: $domainSubfolder) {
          ${NetworkGQLFields(fields)}
        }
      }
    `,
  updateNetworkMediaSettings: (fields: MediaNetworkSettingsFields) => gql`
      mutation updateNetworkMediaSettings($input: UpdateMediaNetworkSettingsInput!) {
        updateNetworkMediaSettings(input: $input) {
          ${MediaNetworkSettingsGQLFields(fields)}
        }
      }
    `,
  updateNetworkNotificationSettings: (
    fields: NotificationNetworkSettingsFields,
  ) => gql`
      mutation updateNetworkNotificationSettings($input: UpdateNotificationNetworkSettingsInput!) {
        updateNetworkNotificationSettings(input: $input) {
          ${NotificationNetworkSettingsGQLFields(fields)}
        }
      }
    `,
  updateNetworkStatus: (fields: ActionFields) => gql`
      mutation updateNetworkStatus($input: UpdateNetworkStatusInput!) {
        updateNetworkStatus(input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  updateNewDomain: (fields: DomainTransferStatusFields) => gql`
      mutation updateNewDomain($input: UpdateNewDomainInput!) {
        updateNewDomain(input: $input) {
          ${DomainTransferStatusGQLFields(fields)}
        }
      }
    `,
  updatePasswordWithToken: (fields: ActionFields) => gql`
      mutation updatePasswordWithToken($input: UpdatePasswordWithTokenInput!) {
        updatePasswordWithToken(input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  updatePermission: (fields: PermissionFields) => gql`
      mutation updatePermission($id: ID!
$input: UpdatePermissionInput!) {
        updatePermission(id: $id
input: $input) {
          ${PermissionGQLFields(fields)}
        }
      }
    `,
  updatePost: (fields: PostFields) => gql`
      mutation updatePost($id: ID!
$input: UpdatePostInput!) {
        updatePost(id: $id
input: $input) {
          ${PostGQLFields(fields)}
        }
      }
    `,
  updatePostType: (fields: PostTypeFields) => gql`
      mutation updatePostType($id: ID!
$input: UpdatePostTypeInput!) {
        updatePostType(id: $id
input: $input) {
          ${PostTypeGQLFields(fields)}
        }
      }
    `,
  updatePostTypeField: (fields: PostTypeFields) => gql`
      mutation updatePostTypeField($id: ID!
$input: UpdateCustomFieldSchemaInput!) {
        updatePostTypeField(id: $id
input: $input) {
          ${PostTypeGQLFields(fields)}
        }
      }
    `,
  updatePrivateMessagingSettings: (
    fields: PrivateMessagingSettingsFields,
  ) => gql`
      mutation updatePrivateMessagingSettings($input: UpdatePrivateMessagingSettingsInput!) {
        updatePrivateMessagingSettings(input: $input) {
          ${PrivateMessagingSettingsGQLFields(fields)}
        }
      }
    `,
  updatePublisherSettings: (fields: ActionFields) => gql`
      mutation updatePublisherSettings($input: UpdatePublisherInput!
$publisherId: String!) {
        updatePublisherSettings(input: $input
publisherId: $publisherId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  updateSpace: (fields: SpaceFields) => gql`
      mutation updateSpace($id: ID!
$input: UpdateSpaceInput!) {
        updateSpace(id: $id
input: $input) {
          ${SpaceGQLFields(fields)}
        }
      }
    `,
  updateSpaceDefaultNotificationSettings: (
    fields: SpaceDefaultNotificationSettingsFields,
  ) => gql`
      mutation updateSpaceDefaultNotificationSettings($channel: NotificationChannel!
$input: UpdateSpaceDefaultNotificationSettingsInput!
$spaceId: ID!) {
        updateSpaceDefaultNotificationSettings(channel: $channel
input: $input
spaceId: $spaceId) {
          ${SpaceDefaultNotificationSettingsGQLFields(fields)}
        }
      }
    `,
  updateSpaceHighlightedTags: (fields: ActionFields) => gql`
      mutation updateSpaceHighlightedTags($input: UpdateHighlightedTags!
$spaceId: ID!) {
        updateSpaceHighlightedTags(input: $input
spaceId: $spaceId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  updateSpaceMemberRole: (fields: ActionFields) => gql`
      mutation updateSpaceMemberRole($input: UpdateSpaceMemberRoleInput!
$memberId: ID!
$spaceId: ID!) {
        updateSpaceMemberRole(input: $input
memberId: $memberId
spaceId: $spaceId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  updateSpacePostTypes: (fields: SpacePostTypeFields) => gql`
      mutation updateSpacePostTypes($input: [UpdateSpacePostTypeInput!]!
$spaceId: ID!) {
        updateSpacePostTypes(input: $input
spaceId: $spaceId) {
          ${SpacePostTypeGQLFields(fields)}
        }
      }
    `,
  updateSubscriberSettings: (fields: ActionFields) => gql`
      mutation updateSubscriberSettings($input: UpdateSubscriberInput!) {
        updateSubscriberSettings(input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  updateSubscriptionInterval: (fields: ActionFields) => gql`
      mutation updateSubscriptionInterval($interval: BillingPriceInterval!) {
        updateSubscriptionInterval(interval: $interval) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  updateSubscriptionPlan: (fields: ActionFields) => gql`
      mutation updateSubscriptionPlan($priceId: String!) {
        updateSubscriptionPlan(priceId: $priceId) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  updateTag: (fields: TagFields) => gql`
      mutation updateTag($id: ID!
$input: UpdateTagInput!) {
        updateTag(id: $id
input: $input) {
          ${TagGQLFields(fields)}
        }
      }
    `,
  updateTemplate: (fields: TemplateFields) => gql`
      mutation updateTemplate($id: String!
$input: UpdateTemplateInput!) {
        updateTemplate(id: $id
input: $input) {
          ${TemplateGQLFields(fields)}
        }
      }
    `,
  uploadMigrationFiles: (fields: UploadedMigrationsFields) => gql`
      mutation uploadMigrationFiles($baseUrl: String
$changeMemberEmails: Boolean
$dryRun: Boolean
$members: Upload
$migrationId: String
$posts: Upload
$spaces: Upload) {
        uploadMigrationFiles(baseUrl: $baseUrl
changeMemberEmails: $changeMemberEmails
dryRun: $dryRun
members: $members
migrationId: $migrationId
posts: $posts
spaces: $spaces) {
          ${UploadedMigrationsGQLFields(fields)}
        }
      }
    `,
  upsertExtraProperty: (fields: ActionFields) => gql`
      mutation upsertExtraProperty($input: UpsertExtraPropertyInput!) {
        upsertExtraProperty(input: $input) {
          ${ActionGQLFields(fields)}
        }
      }
    `,
  upsertPageCustomResponse: (fields: PageDetailedCustomResponseFields) => gql`
      mutation upsertPageCustomResponse($input: UpsertPageCustomResponse!
$path: String!) {
        upsertPageCustomResponse(input: $input
path: $path) {
          ${PageDetailedCustomResponseGQLFields(fields)}
        }
      }
    `,
  upsertTheme: (fields: NewThemeFields) => gql`
      mutation upsertTheme($input: UpsertTheme!) {
        upsertTheme(input: $input) {
          ${NewThemeGQLFields(fields)}
        }
      }
    `,
  verifyMember: (fields: AuthTokenFields) => gql`
      mutation verifyMember($input: VerifyMemberInput!) {
        verifyMember(input: $input) {
          ${AuthTokenGQLFields(fields)}
        }
      }
    `,
}
