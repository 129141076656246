import { forwardRef } from 'react'

import { Post } from '@tribeplatform/gql-client/types'

import { ReplyBar } from '../Post/ReplyBar.js'
import { PostContext } from '../Post/types.js'
import { REPLY_LOAD_MORE_REPLIES_LIMIT } from './constants.js'
import { GenericReply } from './GenericReply.js'
import { useGenericReply } from './hooks/useGenericReply.js'

interface Props {
  reply: Post
  canReply: boolean
  readonly?: boolean
  context?: PostContext
  post?: Post
  isHighlighted?: boolean
}

export const Reply = forwardRef<HTMLDivElement, Props>(
  (
    { reply, canReply, readonly = false, context, post, isHighlighted = false },
    ref,
  ) => {
    const {
      isFetching,
      unloadedReplies,
      replies,
      showReplyBar,
      editing,
      replyBarRef,
      isHidden,
      hasReplyPermission,
      fetchNextPage,
      onReply,
      stopEditing,
      startEditing,
    } = useGenericReply({
      limit: REPLY_LOAD_MORE_REPLIES_LIMIT,
      reply,
    })

    if (isHidden) {
      return null
    }

    return (
      <GenericReply
        ref={ref}
        context={context}
        post={post}
        reply={reply}
        canReply={canReply && hasReplyPermission}
        isFetching={isFetching}
        onLoadMore={fetchNextPage}
        editing={editing}
        onStopEditing={stopEditing}
        onStartEditing={startEditing}
        onReply={onReply}
        readonly={readonly}
        isHighlighted={isHighlighted}
        unloadedReplies={unloadedReplies}
      >
        {replies?.length > 0 || (canReply && showReplyBar) ? (
          <div className="relative flex flex-col space-y-6 mt-6 p-2">
            {replies
              ?.map(reply => (
                <Reply
                  reply={reply}
                  canReply={canReply}
                  readonly={readonly}
                  key={reply.id}
                />
              ))
              ?.reverse()}
            {canReply && showReplyBar && (
              <ReplyBar ref={replyBarRef} post={reply} defaultExpand />
            )}
          </div>
        ) : null}
      </GenericReply>
    )
  },
)
