import './window-logger.js'
import './lib/datadog.rum.js'

import { createRoot, hydrateRoot } from 'react-dom/client'
import { createMemoryRouter, Route } from 'react-router'
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom'

import { SpinnerIcon } from '@bettermode/icons/line/Spinner'
import { DocumentContext } from '@tribeplatform/react-ui-kit/hooks'

import { AppInitialProps } from '../@types/index.js'
import { RuntimeConfigs } from '../configs/runtime.js'
import { AppClient } from './App.client.js'
import { APP_ROOT_ELEMENT_ID } from './constants/app.constants.js'
import { getAuthToken, getInjectedAppProps } from './entry.client.utils.js'
import { polyfillJsFeatures } from './lib/js-polyfills.js'
import { intlPolyfill } from './utils/intlPolyfill.js'
import { localizeLibsForClient } from './utils/localizeLibs.js'
import { fetchTranslationData } from './utils/translation/fetchTranslationData.js'
import { I18nStore } from './utils/translation/I18nStore.js'

const getCommonAppData = async (lang: string): Promise<AppInitialProps> => {
  const injectedProps = getInjectedAppProps()
  try {
    const translationData = await fetchTranslationData(
      lang,
      injectedProps.networkPath.subfolder,
    )

    return {
      ...injectedProps,
      url: {
        hostname: window.location.hostname,
        originalUrl: window.location.href,
        path: window.location.pathname,
        protocol: window.location.protocol,
        params: {},
      },
      i18n: I18nStore.getOrCreate(lang, translationData),
    }
  } catch (e) {
    logger.error('Could not extract app data', e)
    throw e
  }
}

const Client = ({
  appData,
  isEmbed,
}: {
  appData: AppInitialProps
  isEmbed: boolean
}) => {
  if (!appData?.authToken) {
    return (
      <div className="flex items-center justify-center h-screen w-screen">
        <SpinnerIcon className="animate-spin h-7 w-7" />
      </div>
    )
  }

  const routes = createRoutesFromElements(
    <Route
      path="*"
      element={
        <AppClient
          pageProps={appData}
          apiUrl={RuntimeConfigs.GQL_ENDPOINT}
          betaApiUrl={RuntimeConfigs.BETA_GQL_ENDPOINT}
        />
      }
    />,
  )

  const { basename } = appData.networkPath
  const router = isEmbed
    ? createMemoryRouter(routes, {
        basename,
        initialEntries: [
          `${window.location.pathname}${window.location.search}`,
        ],
      })
    : createBrowserRouter(routes, { basename })

  return <RouterProvider router={router} />
}

const render = async () => {
  await polyfillJsFeatures()

  const container = document.getElementById(APP_ROOT_ELEMENT_ID)
  /**
   * The `isPWA` and `isEmbed` variables are added
   * to the window via a server-injected head script.
   * See entry.server.tsx for more details
   */
  // eslint-disable-next-line dot-notation
  const isPWA = window['isPWA'] === true
  // eslint-disable-next-line dot-notation
  const isEmbed = window['isEmbed'] === true

  const { lang } = document.documentElement

  const commonAppData = await getCommonAppData(lang)
  const authToken = await getAuthToken(
    isPWA ? commonAppData.networkPath.originalDomain : undefined,
  )

  const appData = {
    ...commonAppData,
    token: authToken,
  }

  await Promise.all([intlPolyfill(lang), localizeLibsForClient(lang)])

  if (isPWA) {
    const root = createRoot(container)
    root.render(
      <DocumentContext.Provider value={{ document }}>
        <Client appData={appData} isEmbed={isEmbed} />
      </DocumentContext.Provider>,
    )
  } else {
    // Load all components needed before rendering
    hydrateRoot(
      container,
      <DocumentContext.Provider value={{ document }}>
        <Client appData={appData} isEmbed={isEmbed} />
      </DocumentContext.Provider>,
      {
        // The type for onRecoverableError is wrong and doesn't include the errInfo param
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onRecoverableError: (error, errInfo) => {
          logger.error('Received a hydration error', {
            error,
            errInfo,
          })
        },
      },
    )
  }
}

render()
