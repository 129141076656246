export enum LogLevels {
  silent = 0,
  error,
  warn,
  info,
  debug,
}

export type LogLevel = keyof typeof LogLevels

export interface WebLogger {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(message: any, ...data: any[]): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn(message: any, ...data: any[]): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info(message: any, ...data: any[]): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debug(message: any, ...data: any[]): void
}
