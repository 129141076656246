import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react'

type Values = Record<'readonly' | string, unknown | never>
type EmbedContext = {
  values: Values
  setValues: Dispatch<SetStateAction<Values>>
}
const EmbedContext = createContext<EmbedContext>({
  values: {},
  setValues: undefined,
})

export const EmbedProvider: FC<{ children: ReactNode; values: Values }> = ({
  children,
  values: initialValues,
}) => {
  const [values, setValues] = useState<EmbedContext['values']>(initialValues)

  return (
    <EmbedContext.Provider value={{ values, setValues }}>
      {children}
    </EmbedContext.Provider>
  )
}

export const useEmbed = () => useContext(EmbedContext)

EmbedContext.displayName = 'EmbedContext'
