import { useCallback, useMemo } from 'react'

import {
  useListFilters,
  useInlineFilters,
  prepareFilters,
  applyStoredFilters,
  type LegacyFilter,
  type RawInlineFilter,
  type Filter,
} from '@tribeplatform/react-components/Filters'
import { useStaticIntl } from '@tribeplatform/react-components/i18n'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

import { convertToMemberListFilter } from '../utils/convertToMemberListFilter.js'
import { staticFiltersFactory } from '../utils/staticFilters.js'
import { useCustomFields } from './useCustomFields.js'

interface useMemberFiltersProps {
  filterBy: LegacyFilter[]
  rawInlineFilters: RawInlineFilter[]
  storedFilters: LegacyFilter[]
  setStoredFilters: (newFilters: Filter[]) => void
}
export const useMemberFilters = ({
  filterBy,
  rawInlineFilters,
  storedFilters,
  setStoredFilters,
}: useMemberFiltersProps) => {
  const { data: network } = useNetwork()
  const { memberFields: customFields } = useCustomFields({
    excludePrivateFields: true,
  })
  const staticFilters = useStaticIntl(staticFiltersFactory)
  const ListFilters = useMemo(
    () => convertToMemberListFilter(filterBy, network?.roles),
    [filterBy, network?.roles],
  )
  const { filters, allFilters } = useListFilters({
    entityListFilters: ListFilters,
    customFields,
    staticFilters,
  })
  const { processedInlineFilters, setProcessedInlineFilters } =
    useInlineFilters({
      entityListFilters: ListFilters,
      filters,
      allFilters,
      rawInlineFilters,
    })
  const inlineFiltersMergedWithStoredFilters = useMemo(() => {
    return applyStoredFilters(storedFilters, processedInlineFilters)
  }, [storedFilters, processedInlineFilters])
  const combinedFilters = useMemo(
    () => prepareFilters([...filters, ...inlineFiltersMergedWithStoredFilters]),
    [filters, inlineFiltersMergedWithStoredFilters],
  )
  const hasInlineFilters = rawInlineFilters.some(({ enabled }) => !!enabled)

  const setInlineFilters = useCallback(
    (newFilters: Filter[]) => {
      setStoredFilters(newFilters)
      setProcessedInlineFilters(newFilters)
    },
    [setProcessedInlineFilters, setStoredFilters],
  )

  return {
    inlineFilters: inlineFiltersMergedWithStoredFilters,
    setInlineFilters,
    combinedFilters,
    hasInlineFilters,
  }
}
