import type { Post } from '@tribeplatform/gql-client/types'
import { Truncate } from '@tribeplatform/react-ui-kit/Truncate'

import { useTruncateSettings } from '../../Feed/TruncateSettingsProvider.js'
import { HtmlContent } from '../../HtmlContent/HtmlContent.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import type { PostContext } from '../../Post/types.js'
import { TRUNCATE_SCROLL_OFFSET } from '../utils.js'

interface RichTextViewProps {
  context: PostContext
  collapsible: boolean
  value: string
  entity: Post
}
export const RichTextView = ({
  context,
  collapsible,
  value,
  entity,
}: RichTextViewProps) => {
  const { $t } = useI18n()
  const {
    displayStyle: contextDisplayStyle,
    truncateSize: contextTruncateSize,
  } = useTruncateSettings()
  const displayStyle =
    contextDisplayStyle ?? (collapsible ? 'expandable' : 'full')
  const truncateSize =
    contextTruncateSize ?? (context === 'moderation' ? 'sm' : 'md')

  if (displayStyle !== 'full') {
    return (
      <Truncate
        size={truncateSize}
        scrollOffset={TRUNCATE_SCROLL_OFFSET}
        expandable={displayStyle === 'expandable'}
        seeMoreText={$t({
          defaultMessage: 'See more',
          id: 'Generics.SeeMore',
        })}
        seeLessText={$t({
          defaultMessage: 'See less',
          id: 'Generics.SeeLess',
        })}
        gradientTo={
          context === 'pinnedReply' ? 'to-surface-positive' : undefined
        }
      >
        <article className="prose text-md text-content break-words min-w-0">
          <HtmlContent
            value={value}
            attachments={entity?.attachments}
            embeds={entity?.embeds}
            mentions={entity?.mentions}
          />
        </article>
      </Truncate>
    )
  }

  return (
    <article className="prose text-md text-content break-words min-w-0">
      <HtmlContent
        value={value}
        attachments={entity?.attachments}
        embeds={entity?.embeds}
        mentions={entity?.mentions}
      />
    </article>
  )
}
