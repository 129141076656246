import { clsx } from 'clsx'

import {
  SpaceBackgroundImage,
  SpaceImage,
} from '@tribeplatform/react-components/Space'
import { useSpace } from '@tribeplatform/react-sdk/hooks'
import { useSlate } from '@tribeplatform/react-slate-kit/hooks'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { PageHeader } from '@tribeplatform/react-ui-kit/PageHeader'

import { useEmbed } from '../../../embed/EmbedProvider.js'
import { SpaceActionButtons } from '../SpaceActionButtons.js'
import { SpacePrivacyIcon } from '../SpacePrivacyIcon.js'
import { SpaceHeaderProps } from './types.js'

export const SpaceHeader = ({
  space: _space,
  spaceId: _spaceId,
  view = 'card',
  attached = 'bottom',
  className,
  ...rest
}: SpaceHeaderProps) => {
  const { context: slateContext } = useSlate()
  const spaceId = _spaceId || _space?.id || slateContext?.spaceId

  const { data: space } = useSpace({
    variables: {
      id: spaceId,
    },
    useQueryOptions: {
      enabled: !!spaceId,
    },
  })
  const { values } = useEmbed()

  if (!space) {
    return null
  }

  if (view === 'simple') {
    return (
      <div
        className={clsx(
          'flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-s-3 items-center px-3 sm:px-0 py-3',
          className,
        )}
        {...rest}
      >
        <div className="flex space-s-5 items-center flex-grow w-full min-w-0">
          <div className="flex items-center justify-center">
            <SpaceImage space={space} size="4x" readOnly />
          </div>
          <div className="flex w-full flex-col space-y-1 text-content-on-background break-words min-w-0">
            <h1 className="text-heading-lg font-semibold">{space.name}</h1>
            <div>{space.description}</div>
          </div>
        </div>
        <div className="flex w-full sm:w-auto space-s-3 items-center flex-shrink-0">
          {!values.readonly && <SpaceActionButtons space={space} />}
        </div>
      </div>
    )
  }

  return (
    <Card className={className} attached={attached} {...rest}>
      <PageHeader
        title={space?.name}
        secondaryTitle={<SpacePrivacyIcon space={space} />}
        avatar={<SpaceImage space={space} size="6x" readOnly />}
        backgroundImage={
          <Card.Media top>
            <SpaceBackgroundImage
              key={spaceId || _space?.id}
              space={space}
              readonly={Boolean(values.readonly)}
            />
          </Card.Media>
        }
        action={!values.readonly && <SpaceActionButtons space={space} />}
      />
    </Card>
  )
}
