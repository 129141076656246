import { SpaceType } from '@tribeplatform/gql-client/types'
import type { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

export const SpaceHeaderConfig = ({ $t }: I18nShape) => ({
  defaultProps: {
    view: 'card',
  },
  displayName: $t({
    defaultMessage: 'Space header',
    id: 'Blocks.SpaceHeader.DisplayName',
  }),
  Icon: <SvgIcon name="image" />,
  contextAwareInfo: ({ context }) => {
    const removable = context.space?.type === SpaceType.Group
    return {
      removable,
    }
  },
})
