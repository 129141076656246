import { useMemo } from 'react'

import type { Member } from '@tribeplatform/gql-client/types'
import { useMembersByIds } from '@tribeplatform/react-sdk/hooks'
import { Multiselect } from '@tribeplatform/react-ui-kit/Multiselect'

import { MemberAvatar } from '../common/components/index.js'
import { useI18n } from '../i18n/index.js'
import { LOGGED_IN_MEMBER_ID } from './MemberPicker.js'

interface MemberPickerFromIdsProps {
  value: string[]
  options: string[]
  onChange: (memberIds: string[]) => void
  placeholder?: string
  noWrapper?: boolean
  className?: string
}
export const MemberPickerFromIds = ({
  value,
  options,
  onChange,
  placeholder,
  noWrapper,
  className,
}: MemberPickerFromIdsProps) => {
  const { $t } = useI18n()
  const memberIdsToFetch = options.filter(id => id !== LOGGED_IN_MEMBER_ID)
  const { data: membersData, isInitialLoading } = useMembersByIds({
    variables: { ids: memberIdsToFetch },
    fields: {
      profilePicture: { onImage: { urls: 'basic' } },
    },
    useQueryOptions: {
      enabled: memberIdsToFetch.length > 0,
    },
  })
  const memberOptions = useMemo<Member[]>(() => {
    if (!membersData) {
      return []
    }

    if (options.some(id => id === LOGGED_IN_MEMBER_ID)) {
      const loggedInMember = {
        id: LOGGED_IN_MEMBER_ID,
        name: $t({
          defaultMessage: 'Logged in {MEMBER}',
          id: 'Generics.LoggedInMember',
        }),
      } as Member
      return [loggedInMember, ...membersData]
    }

    return membersData
  }, [$t, membersData, options])

  const getPlaceHolder = () => {
    if (isInitialLoading || !memberOptions) {
      return $t({
        id: 'Generics.LoadingDotDotDot',
        defaultMessage: 'Loading...',
      })
    }

    return placeholder
  }

  return (
    <Multiselect
      value={value}
      options={options}
      onChange={newValues => onChange(newValues)}
      disabled={isInitialLoading}
      className={className}
    >
      <Multiselect.Button placeholder={getPlaceHolder()}>
        {memberOptions
          .filter(({ id }) => value.includes(id))
          .map((member, index) => (
            <Multiselect.SelectedItem
              key={member.id}
              value={member.id}
              index={index}
            >
              <span className="flex items-center">
                {member.id !== LOGGED_IN_MEMBER_ID && (
                  <MemberAvatar size="xl" member={member} />
                )}
                <span className="ms-3 block truncate">{member.name}</span>
              </span>
            </Multiselect.SelectedItem>
          ))}
      </Multiselect.Button>
      <Multiselect.Items noWrapper={noWrapper}>
        {memberOptions.map((member, index) => (
          <Multiselect.Item key={member.id} value={member.id} index={index}>
            <div className="flex items-center">
              {member.id !== LOGGED_IN_MEMBER_ID && (
                <MemberAvatar member={member} size="xl" />
              )}
              <span className="ms-3 block truncate">{member.name}</span>
            </div>
          </Multiselect.Item>
        ))}
      </Multiselect.Items>
    </Multiselect>
  )
}
