import { SpaceIdPicker } from '../../EntityPicker/SpaceIdPicker.js'
import { SpacePickerFromIds } from '../../EntityPicker/SpacePickerFromIds.js'
import { useI18n } from '../../i18n/index.js'
import type { Filter } from '../types.js'
import { InfiniteSpreadSpacePicker } from './entityPickers/InfiniteSpreadSpacePicker.js'
import { InlineSpaceIdPicker } from './entityPickers/InlineSpaceIdPicker.js'
import { InlineSpacePickerFromIds } from './entityPickers/InlineSpacePickerFromIds.js'
import { SpreadSpacePicker } from './entityPickers/SpreadSpacePicker.js'

interface RelationSpacePickerProps {
  spread: boolean
  options: string[]
  value: string[]
  inline: boolean
  className?: string
  onChange: (value: Filter['value']) => void
}
export const RelationSpacePicker = ({
  spread,
  options,
  value,
  inline,
  className,
  onChange,
}: RelationSpacePickerProps) => {
  const { $t } = useI18n()

  if (!inline) {
    return options ? (
      <SpacePickerFromIds
        value={value}
        options={options}
        onChange={onChange}
        placeholder={$t({
          defaultMessage: 'Select {SPACES}',
          id: 'Filters.Values.Spaces.Placeholder',
        })}
        className={className}
        noWrapper
      />
    ) : (
      <SpaceIdPicker
        value={value}
        onChange={onChange}
        placeholder={$t({
          defaultMessage: 'Select {SPACES}',
          id: 'Filters.Values.Spaces.Placeholder',
        })}
        className={className}
        multiple
        noWrapper
      />
    )
  }

  if (spread) {
    return options ? (
      <SpreadSpacePicker value={value} options={options} onChange={onChange} />
    ) : (
      <InfiniteSpreadSpacePicker value={value} onChange={onChange} />
    )
  }

  return options ? (
    <InlineSpacePickerFromIds
      value={value}
      options={options}
      className={className}
      onChange={onChange}
    />
  ) : (
    <InlineSpaceIdPicker
      value={value}
      className={className}
      onChange={onChange}
    />
  )
}
