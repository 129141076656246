import { useCallback, useState } from 'react'

import { clsx } from 'clsx'

import { NetworkVisibility } from '@tribeplatform/gql-client/types'
import { CookieContextProvider } from '@tribeplatform/react-components/Apps'
import { useFirstRenderContext } from '@tribeplatform/react-components/common/ssr'
import { useStaticIntl } from '@tribeplatform/react-components/i18n'
import {
  SidebarFooter,
  useLayoutFrame,
} from '@tribeplatform/react-components/LayoutFrame'
import { useAuthMember, useAuthToken } from '@tribeplatform/react-sdk/hooks'
import { MobileMenu } from '@tribeplatform/react-ui-kit/Frame'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { useKeypressListener } from '@tribeplatform/react-ui-kit/utils'

import { useBlock } from '../../../hooks/block.hook.js'
import { useSlate } from '../../../hooks/slate.hook.js'
import { actionItemsFactory } from '../config.js'
import {
  ActionItemId,
  type ActionsSection,
  type MenuSection,
  type NavbarProps,
} from '../types.js'
import { convertToActionItems } from '../utils/converters.js'
import { checkMobileMenuEmpty } from '../utils/mobileMenu.js'
import { MobileMenuActions } from './components/MobileMenuActions.js'
import { MobileMenuSectionRenderer } from './components/MobileMenuSectionRenderer.js'

interface HamburgerMenuProps {
  mobileMenuSections: NavbarProps['mobileMenuSections']
  actionsSection: ActionsSection
  headerMenu: MenuSection
  headerSubmenu: NavbarProps['subNavigation']
  fixedWidth?: boolean
}
export const HamburgerMenu = ({
  mobileMenuSections,
  actionsSection,
  headerMenu,
  headerSubmenu,
  fixedWidth,
}: HamburgerMenuProps) => {
  const [open, setOpen] = useState(false)
  const { isFirstRender } = useFirstRenderContext()
  const {
    data: { networkPublicInfo },
  } = useAuthToken()
  const { isGuest } = useAuthMember()
  const {
    extraContext: { activeSectionId },
  } = useBlock<NavbarProps, { activeSectionId?: string }>()
  const { mode } = useSlate()
  const isNetworkPrivate =
    networkPublicInfo?.visibility === NetworkVisibility.private
  const isPreviewMode = mode !== 'live'
  const isMobileMenuOpenInCustomizer =
    isPreviewMode && activeSectionId === 'mobileMenu'
  const enabledMobileMenuSections = mobileMenuSections.filter(
    ({ enabled }) => !!enabled,
  )
  const { sidebar1, showSidebar } = useLayoutFrame({})
  const isMobileMenuEmpty = checkMobileMenuEmpty({
    sections: mobileMenuSections,
    hasHeaderMenu: headerMenu.items.length > 0,
    hasHeaderSubMenu: headerSubmenu.menuItems.length > 0,
    hasSidebar: showSidebar && !!sidebar1,
  })
  const allActionItems = useStaticIntl(actionItemsFactory)
  const enabledRawActions = actionsSection.items.filter(
    ({ id, enabled }) =>
      !!enabled &&
      id !== ActionItemId.mainMenu &&
      id !== ActionItemId.notifications &&
      id !== ActionItemId.messages,
  )
  const enabledActions = convertToActionItems(enabledRawActions, allActionItems)

  const handleClose = useCallback(() => {
    if (isMobileMenuOpenInCustomizer) {
      return
    }

    setOpen(false)
  }, [isMobileMenuOpenInCustomizer])

  useKeypressListener('Escape', handleClose, 'keydown')

  if (isMobileMenuEmpty && enabledActions.length === 0) {
    return null
  }

  if (isNetworkPrivate && isGuest) {
    return null
  }

  return (
    <div
      className={clsx(
        'flex h-full items-center lg:hidden',
        fixedWidth ? 'w-1/2' : 'shrink-0',
      )}
    >
      <button
        type="button"
        onClick={() => setOpen(true)}
        aria-label="Navigation"
      >
        <SvgIcon name="menu" className="h-8 w-8" aria-hidden="true" />
      </button>
      {!isFirstRender && (
        <MobileMenu
          open={open || isMobileMenuOpenInCustomizer}
          onClose={handleClose}
        >
          {enabledActions.length > 0 && (
            <MobileMenuActions actions={enabledActions} close={handleClose} />
          )}
          {enabledMobileMenuSections.map(section => (
            <MobileMenuSectionRenderer
              key={section.id}
              section={section}
              headerMenu={headerMenu}
              headerSubmenu={headerSubmenu}
              close={handleClose}
            />
          ))}
          <div className="mt-4">
            <CookieContextProvider>
              <SidebarFooter />
            </CookieContextProvider>
          </div>
        </MobileMenu>
      )}
    </div>
  )
}
